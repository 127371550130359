import { Modal } from "react-bootstrap";
import "./Styles.css";
import { useEffect, useState } from "react";
// import * as orderConfilmApi from "../../api/apiCheckin/orderConfilmApi";
import * as paymentApi from "../../../../api/apiCheckin/paymentApi";
import { getLanguageCheckIn } from "../../../../Services/languageCheckin";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";

interface ModalPayBillTablePOSProps {
  show: boolean;
  staffId?: string;
  setStaffName?: (name: string) => void;
  setStaffId?: (id: string) => void;
  handleClose: () => void;
  listStaff: paymentApi.TipStaff[];
  heightModal: number;
}

const ModalSelectStaffTip = ({
  show,
  handleClose,
  staffId = "",
  listStaff = [],
  setStaffId,
  setStaffName,
  heightModal,
}: ModalPayBillTablePOSProps) => {
  const languageUI = getLanguageCheckIn();
  const [NameSelected, setNameSelected] = useState<string>("");
  const [staffSelected, setStaffSelected] = useState<string>("");
  // const [showModalConfirmSelectTip, setShowModalConfirmSelectTip] =
  //   useState(false);
  // const handleCloseConfirm = () => {
  //   setShowModalConfirmSelectTip(false);
  // };
  function handleConfirm(staff_id: string = "", staff_name: string = "") {
    setStaffSelected(staff_id);
    setNameSelected(staff_name);
    setStaffId?.(staff_id);
    setStaffName?.(staff_name);
    // setShowModalConfirmSelectTip(false);
    handleClose();
  }
  // const SubmitChoosed = () => {
  //   setStaffId?.(staffSelected);
  //   setStaffName?.(NameSelected);
  //   setShowModalConfirmSelectTip(false);
  //   handleClose();
  // };
  useEffect(() => {
    if (show) {
      setStaffSelected(staffId);
    }
  }, [show]);
  return (
    <>
      <Modal show={show} onHide={handleClose} className="tip-staff">
        <Modal.Dialog className="bg-gray-light">
          <Modal.Header className="position-relative"></Modal.Header>
          <Modal.Body>
            <div className="px-3 h-100">
              <p className="text-select-name-employee">
                {languageUI.Selectemployeewanttotip}{" "}
              </p>
              <div className={`box-select-employee h-100-50px`}>
                {listStaff.map((items, i) => (
                  <div
                    key={i}
                    className={`${
                      staffSelected == items.id
                        ? "select-employee-items-2"
                        : "select-employee-items"
                    }`}
                    onClick={() => handleConfirm(items?.id, items?.staff_name)}
                  >
                    <PersonOutlineIcon
                      className={`icon-ployee-items ${
                        staffSelected == items.id
                          ? "text-ffffff"
                          : "text-A5A5BA"
                      }`}
                    />
                    <p
                      className={`name-ployee-items ${
                        staffSelected == items.id
                          ? "text-ffffff"
                          : "text-A5A5BA"
                      }`}
                    >
                      {items.staff_name}
                    </p>
                  </div>
                  // <div key={i} className={`d-flex justify-content-center align-items-center  ${valueTip == items.id ? "custom-choose-categories-2" : "custom-choose-categories"}`} onClick={() => { setValueTip(items.id) }}>
                  //     <p className={`custom-text-choose-categorie mb-0 ${valueTip == items.id ? "text-ffffff" : "text-8E8EA9"}`}>{items.name}</p>
                  // </div>
                ))}
              </div>
            </div>
          </Modal.Body>
        </Modal.Dialog>
      </Modal>
    </>
  );
};

export default ModalSelectStaffTip;
