import React, { useEffect, useState } from "react";
import { Form, InputGroup, ProgressBar, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { gNumFoodNotPay, gNumberCardOrderOnline } from "../../../api/apiCheckin/addCartApi";
import Swal from "sweetalert2";
import { resetAuth } from "../../../redux/actions";
import { billIcon, chevronRightWhite } from "../../../components/ImgExport";
import { targetupload } from "../../../api/urlImage";
import NoData from "../../../assets/images/not-found-file.svg"
import * as chooseMenuApi from "../../../api/apiCheckin/chooseMenuApi";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import * as Request from "../../../api/Request";
import FoodItemOnline from "../Component/FoodItemOnline";
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import './styles.css'
import { getLanguageCheckIn } from "../../../Services/languageCheckin";

interface FoodMenuProps {
  id?: number;
  image?: any;
  foodNumber?: number;
  name?: string;
  price?: number;
}
const MenuOnline: React.FC = () => {
  const navigate = useNavigate();
  const languageUI = getLanguageCheckIn();
  const dispatch = useDispatch<AppDispatch>();
  const [listMenu, setListMenu] = useState([]);
  const nameStore = localStorage.getItem("namestore");

  const [foodMenu, setFoodMenu] = useState<FoodMenuProps[]>(
    JSON.parse(localStorage.getItem("foodMenu") || "[]")
  );
  const [searchFood, setSearchFood] = useState("");
  const [searchFoodGroup, setSearchFoodGroup] = useState("");
  const [foodTotal, setFootTotal] = useState<number>(0);
  const [foodTotalPay, setFootTotalPay] = useState<number>(0);

  const [ownerID, setOwnerID] = useState<any>("");
  const [showLoadingPage, setShowLoadingPage] = useState<boolean>(true)
  const [hiddenBackState, setHiddenBackState] = useState<boolean>(true)

  const [now, setNow] = useState(10)
  useEffect(() => {
    if (now < 90) {
      setTimeout(() => {
        setNow(now + 10)
      }, 100);
    }

  }, [now])
  const params: any = useParams();
  const customerID = params.owner_id;



  //Get info customer
  const gInfoCustomer = async (id_customer: any) => {
    await Request.fetchAPI(`checkin/gInfoCustomer.php?id=${id_customer}`, "get")
      .then((res) => {
        if (res.status == 1) {
          setOwnerID(res?.data?.admin_id);
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  // //Get info owner
  // const getInforOwner = async (id_owner: any) => {
  //   await Request.fetchAPI(`checkin/gInfoOwner.php?id=${id_owner}`, "get").then(
  //     (res) => {
  //       if (res?.status == 1) {
  //         setOwneTimeSendSMS(res?.data?.type_send_review_message);
  //       }
  //     }
  //   );
  // };
  // search
  const handleSearch = async (clear: boolean = false) => {
    await Request.fetchAPI(
      `/checkin/gListMenu.php?owner_id=${ownerID}&foodgroup_id=${clear ? "" : searchFoodGroup}&food_name=${searchFood}`,
      "get"
    ).then((res) => {
      if (res.status == 1) {
        setListMenu(res.data);

        if (searchFood === ' ') {
          setHiddenBackState(true)
        }
        else {
          setHiddenBackState(false)
        }
      }
    });
  };

  useEffect(() => {
    if (searchFoodGroup != '') {
      handleSearch();
    }

  }, [searchFoodGroup])

  // const handleOnchageFoodGroup = (CategoryItem?: chooseMenuApi.FoodCategory) => {
  //   if (CategoryItem && CategoryItem?.id != CategoryChoosed) {
  //     setSearchFoodGroup(CategoryItem?.id ?? '');
  //     setCategoryChoosed(CategoryItem?.id ?? '');
  //     setCategoryActive([CategoryItem, ...categoryActive.filter((item, i) => i == 0)]);

  //   }
  //   dismisModalViewMore()


  // };

  const handleOnchageFoodName = (e: any) => {

    // if (e?.target.value == "") getListMenuFood(ownerID);
    setSearchFood(e?.target.value);
  };

  const getListMenuFood = async (ownerID: any) => {
    await Request.fetchAPI(
      `/checkin/gListMenu.php?owner_id=${ownerID}`,
      "get"
    ).then((res) => {
      if (res.status == 1) {
        setListMenu(res.data);
        // setListCateGory(res.data);

      }
    });
  };


  const handleDecrece = (id: any) => {
    const updateFoodMenuNumber = [...foodMenu];
    for (let i = 0; i <= foodMenu.length; i++) {
      if (updateFoodMenuNumber[i]?.id == id) {
        updateFoodMenuNumber[i].foodNumber =
          Number(updateFoodMenuNumber[i]?.foodNumber) + 1;
      }
    }
    setFoodMenu(updateFoodMenuNumber);
  };

  //add food to shipping
  const handleAddToCart = (
    menu_id: any,
    image: any,
    name: string,
    price: any
  ) => {
    var item: FoodMenuProps = {
      id: menu_id,
      image: image,
      name: name,
      foodNumber: 1,
      price: price,
    };

    const checkId = foodMenu.find((listMenu) => listMenu.id == item.id);
    if (checkId == undefined) {
      setFoodMenu((data) => [...data, item]);
    } else handleDecrece(item.id);

    Swal.fire({
      position: "top-end",
      icon: "success",
      title: "Added your choice ",
      showConfirmButton: false,
      timer: 1500,
    });
    localStorage.setItem("foodMenu", JSON.stringify(foodMenu));
  };
  localStorage.setItem("foodMenu", JSON.stringify(foodMenu));



  //get num cart
  const CountNumCart = async () => {
    const res = await gNumberCardOrderOnline(customerID, ownerID)
    if (Number(res.status) === 1) {
      setFootTotal(Number(res?.total))
    }
  }
  //get số sản ohaamr chờ thanh toán
  const CountNumFood = async () => {
    var table_id = localStorage.getItem("table_id");
    if (table_id) {
      const num = await gNumFoodNotPay(customerID, ownerID, table_id);
      setFootTotalPay(Number(num?.total ?? 0))
    }
  }


  useEffect(() => {
    if (customerID)
      gInfoCustomer(customerID);
  }, [customerID]);

  // useEffect(() => {
  //   if (ownerTimeSendSMS == 1) {

  //     sendSmsReview();
  //   }
  // }, [ownerTimeSendSMS]);

  useEffect(() => {
    dispatch(resetAuth());
  }, [dispatch]);

  useEffect(() => {
    if (ownerID) {
      getListMenuFood(ownerID);
      gListFoodCategory(ownerID)
      // getInforOwner(ownerID);
      CountNumCart()
      CountNumFood()
    }
  }, [ownerID]);


  //////////////
  const gListFoodCategory = async (ownerID: any) => {
    const Category = await chooseMenuApi.gFoodCateGory(ownerID);
    if (Category.status == 1) {
      if (Category?.data && Category?.data?.length > 0) {
      }
      setShowLoadingPage(false)
      setNow(100)
    } else {
      setShowLoadingPage(false)
      setNow(100)
    }
  };
  const handleClearSearch = async () => {
    setHiddenBackState(true)
    setSearchFood('');
    setSearchFoodGroup('');
    await Request.fetchAPI(
      `/checkin/gListMenu.php?owner_id=${ownerID}`,
      "get"
    ).then((res) => {
      if (res.status == 1) {
        setListMenu(res.data);
      }
    });


  }

  return (
    showLoadingPage ? (
      <div className='w-100 h-100 bg-wait-screen'>
        <div className="d-flex justify-content-center align-items-center flex-column gap-3" style={{ minHeight: "100vh" }}>
          <h3 className="name-store text-white ">
            {nameStore}
          </h3>
          <ProgressBar variant="warning" now={now} className="w-50" />
        </div>
      </div>
    ) : (
      <>

        {/* Menubody */}
        <header className="w-100 custom-header-choose-menu d-flex align-items-center gap-3">
          <button className='px-4 py-1 border-none bg-orange rounded-4 shadow-button-default ' onClick={() => navigate("/online/statusOrderMethodOnline/" + customerID)}><ArrowBackRoundedIcon className='text-white' /></button>
          {/* <img src={logoPrijack} alt="" /> */}
          <p className="title-name-store mb-0">{nameStore}</p>
        </header>
        <div className=" d-flex box-menu-food-order custom-pading-header bg-white">
          <div className="px-2 px-sm-2 px-md-3 px-lg-4 position-relative pt-0 bg-white pb-footer">
            <Row>
              <p className="text-header">
                {languageUI.exploreUniqueFlavors}
                <br />
                {languageUI.atOurRestaurant} 🍲
              </p>
              <div className="col-12 col-425-100 mt-0 ">
                <div className="rounded-6px overflow-hidden bg-F6F6F9 border">
                  <InputGroup className="h-40px">
                    <Form.Control
                      placeholder={languageUI.Whatareyoulookingfor}
                      className="border-none bg-F6F6F9 text-8E8EA9 focus-none h-40px"
                      value={searchFood}
                      onChange={handleOnchageFoodName}
                      onKeyPress={event => {
                        if (event.key === 'Enter') {
                          handleSearch();
                        }
                      }}
                    />
                    <InputGroup.Text className=" border-none bg-F6F6F9 h-40px">
                      <button
                        className="bg-F6F6F9"
                        style={{ border: "none", background: "none" }}
                        onClick={() => handleSearch()}
                      >
                        <div className="d-flex align-items-center">
                          <p className="mb-0 me-1 text-name-food text-8E8EA9">{languageUI.Search}</p>
                          <i className="fas fa-search text-8E8EA9"></i>
                        </div>
                      </button>
                    </InputGroup.Text>
                  </InputGroup>
                </div>
              </div>

            </Row>
            <div className="btn-clear-search-food mt-2 px-2" hidden={hiddenBackState} onClick={handleClearSearch}>
              <img src={chevronRightWhite} className="rotate-180deg" alt="" />
              {languageUI.Back}
              <img src={chevronRightWhite} className="rotate-180deg opacity-0" alt="" />

            </div>
            {listMenu.length > 0 ? (
              <>
                {listMenu.map((menu: any, index: number) => (
                  <div key={index}>
                    <h4 className="header-title mb-0 mt-3 px-2 font-bold text-2xl text-capitalize text-black">{menu.foodgroup_name}</h4>
                    <div className="box-menu-in-phone">
                      {menu.list_menu.map((food: any) => (
                        <FoodItemOnline
                          rank={food.rank}
                          key={food.menu_id}
                          menu_id={food?.menu_id}
                          customerID={customerID}
                          ownerID={ownerID}
                          img={`${targetupload}${food.imageCourse}`}
                          side_id={food.side_id}
                          name={food.menu_name}
                          description={food.description}
                          price={Number(food.price)}
                          foodTotal={foodTotal}
                          setFootTotal={(num) => setFootTotal(num)}
                          ordersServed={food?.ordersServed}
                          link="#"
                          handleAddToCart={() =>
                            handleAddToCart(
                              food.menu_id,
                              food.imageCourse,
                              food.menu_name,
                              food.price
                            )
                          }
                        />
                      ))}
                    </div>
                  </div>
                ))}
                <div className="w-100 mt-2">
                  <p className="text-center gap-1">
                    <p className="d-inline mb-0 me-1">{languageUI.Powerby}</p>
                    <p className="d-inline mb-0 text-blue text-underline">Brijack POS</p>
                  </p>

                </div>

                <div className="footer-choose-menu d-flex gap-2 align-items-center py-3 w-100 bg-white px-3 border-top">
                  {foodTotalPay > 0 && (
                    <div className="position-relative" >
                      <span onClick={() => navigate("/webcheckin/payment")} className="w-100">
                        <img src={billIcon} alt="" className="height-49px width-30px" />
                        <div className="number-cart-2">{foodTotalPay}</div>
                      </span>
                    </div>
                  )}

                  {/* <div className="position-relative" onClick={() => navigate('/webcheckin/cardInformation')}>
                          <img src={cart} alt="" />
                        </div> */}
                  <span className="w-100" onClick={() => navigate('/online/cardinformation')}>
                    <button className="custom-filter-btn border-none w-100 text-ffffff">
                      <div className="number-cart">{foodTotal}</div>
                      {languageUI.Revieworder}
                    </button>
                  </span>
                </div>

              </>
            ) : (

              <div className="d-flex flex-column align-items-center">
                <img src={NoData} alt="" />
                <p className="text-2xl text-green font-medium">{languageUI.Nodata}</p>
                {/* <div className="btn-clear-search-food mt-2 px-2" onClick={handleClearSearch}>
                        <img src={chevronRightWhite} className="rotate-180deg" alt="" />
                        Back
                        <img src={chevronRightWhite} className="rotate-180deg opacity-0" alt="" />

                      </div> */}
              </div>
            )}
          </div>
          {/* <ModalViewMore disableOnClose={dismisModalViewMore} show={showViewMore} listSelectFoodGR={listSelectFoodGR} CategoryChoosed={CategoryChoosed} handleOnchageFoodGroup={handleOnchageFoodGroup} /> */}
        </div >
      </>)
  );
}
export default MenuOnline