import { Modal } from "react-bootstrap";
import "./Styles.css";
import { handClick, leaf1, leaf2 } from "../../../../components/ImgExport";
import OtpInput from "react18-input-otp";
import { useEffect, useState } from "react";
import * as PasscodeApi from "../../../../api/apiOwner/PasscodeApi";
import ModalLoading from "../../../../components/ModalLoading";
import $ from "jquery";
import { getLanguageUI } from "../../../../Services/languageUI";
import { listNoteOptionFood } from "../../../../api/apiCheckin/chooseMenuApi";

interface ModalAddSideDishFoodProps {
  show: boolean;
  index: number;
  optionNoteFood: listNoteOptionFood[];
  handleClose: () => void;
  // setIsOwner?: (e: boolean) => void;
  addNoteOptionCart: (
    index: number,
    options: listNoteOptionFood[],
    note: string,
    priceSide: number
  ) => void;
}

const ModalAddSideDishFood = ({
  show,
  index,
  optionNoteFood,
  handleClose,
  addNoteOptionCart,
}: // setIsOwner
ModalAddSideDishFoodProps) => {
  const [listActiveSideDish, setListActiveSideDish] = useState<
    listNoteOptionFood[]
  >([]);

  const listActiveOptionNoteFood = (item: listNoteOptionFood) => {
    if (
      listActiveSideDish.some((dis) => Number(dis?.id) === Number(item?.id))
    ) {
      setListActiveSideDish(
        listActiveSideDish.filter((it) => Number(it?.id) !== Number(item?.id))
      );
    } else {
      setListActiveSideDish([...listActiveSideDish, item]);
    }
  };

  const submit = async () => {
    const note = listActiveSideDish
      .map((item) => `${item.option_name}: $${item.price}`)
      .join(", ");
    const priceSide = listActiveSideDish.reduce(
      (sum, item) => sum + Number(item.price),
      0
    );
    addNoteOptionCart(index, listActiveSideDish, note, priceSide);
    handleClose();
  };
  useEffect(() => {
    if (show) {
      setListActiveSideDish([]);
    }
  }, [show]);
  return (
    <>
      <Modal
        show={show}
        centered
        onHide={handleClose}
        className=" border-modal-16px modal-clock-in-out modal-add-side-dish-food"
      >
        <Modal.Dialog className="w-100 dialog-modal h-100">
          <Modal.Body className="p-0 h-100">
            <div className=" px-3 d-flex gap-2 flex-wrap body-modal-sidedish">
              {optionNoteFood?.map((item, i) => (
                <div
                  className={`w-fit h-fit name-food-add-to-pos-menu px-3 py-1 text-14px   rounded-4 ${
                    listActiveSideDish.some(
                      (dis) => Number(dis?.id) === Number(item?.id)
                    )
                      ? "  text-white bg-FF7B2C border-FF7B2C"
                      : "  text-4a4a6a border"
                  } `}
                  key={`not-${i}`}
                  onClick={() => listActiveOptionNoteFood(item)}
                >
                  {item?.option_name}: ${item?.price}
                </div>
              ))}
            </div>
            <div className="w-100 d-flex justify-content-center ">
              <button
                className="btn-order-menu-pos mb-3 shadow-button-default border-none bg-primary w-200px h-50px"
                onClick={submit}
              >
                Submit
              </button>
            </div>
          </Modal.Body>
        </Modal.Dialog>
      </Modal>
    </>
  );
};

export default ModalAddSideDishFood;
