import Modal from 'react-bootstrap/Modal';
import "./Styles.css"
import { FormatDolla } from '../../../../utils/format';
import { useEffect, useState } from 'react';
import { plus } from '../../../../components/ImgExport';
import * as ChooseMenu from "../../../../api/apiCheckin/chooseMenuApi";
import default_product from "../../../../assets/images/default_product.png";
import { getLanguageUI } from '../../../../Services/languageUI';
import { Form } from 'react-bootstrap';
import { FoodChild, ListSides, MenuInfo, addFoodToGroup, gListFreeSide } from '../../../../api/apiOwner/templateMenuApi';
import ModalLoading from '../../../../components/ModalLoading';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import { targetFood, targetImgage } from '../../../../api/Request';

export interface listSideActive {
  side_id: string,
  imageSide: string,
  name: string,
  quantity: number,
}

interface ModalAddSideDishProps {
  show: boolean;
  handleClose: (load?: boolean) => void;
  ownerID: string;
  maxSide: number;
  ListFreeSidesActive: listSideActive[],
  setListFreeSidesActive: (item: listSideActive[]) => void
}

const ModalAddSideDish = ({
  show,
  handleClose,
  ownerID,
  maxSide,
  ListFreeSidesActive,
  setListFreeSidesActive
}: ModalAddSideDishProps) => {
  const [showLoading, setShowLoading] = useState(false)
  const [ListFreeSides, setListFreeSides] = useState<ListSides[]>([]);

  const [maxSideOrder, setMaxSideOrder] = useState(0)
  const [maxSideOrderActive, setMaxSideOrderActive] = useState(0)


  const gListSideDish = async () => {
    if (ownerID) {
      setShowLoading(true);
      const reqMenu = await gListFreeSide(Number(ownerID));
      if (Number(reqMenu?.status) === 1) {
        setListFreeSides(reqMenu?.data ?? []);
      } else {
        setListFreeSides([]);
      }
      setShowLoading(false);
    }
  }
  useEffect(() => {
    if (show) {
      gListSideDish()
      setMaxSideOrder(maxSide)
    }
  }, [show])
  const handleAddSideDish = (side_id: string, name: string, image: string,) => {
    setShowLoading(true)
    if (maxSideOrder === maxSideOrderActive) {
      setShowLoading(false)
      return
    }
    if (ListFreeSidesActive.length > 0 && ListFreeSidesActive.some(item => item?.side_id === side_id && item?.name === name)) {
      setListFreeSidesActive(ListFreeSidesActive.map(item => { return (item?.side_id === side_id) ? { ...item, quantity: item?.quantity + 1 } : item; }));
      setShowLoading(false)
    }
    else {
      setListFreeSidesActive([...ListFreeSidesActive, { side_id: side_id, imageSide: image, name: name, quantity: 1 }])
      setShowLoading(false)
    }
  }
  const handleRemoveSideDish = (side_id: string, quantify: number) => {
    setShowLoading(true)
    if (quantify === 1) {
      setListFreeSidesActive(ListFreeSidesActive.filter(item => item?.side_id !== side_id));
      setShowLoading(false)
    }
    else {
      setListFreeSidesActive(ListFreeSidesActive.map(item => { return (item?.side_id === side_id) ? { ...item, quantity: item?.quantity - 1 } : item; }));
      setShowLoading(false)
    }
  }
  useEffect(() => {
    let total = 0
    ListFreeSidesActive.map((item) => (
      total += item.quantity
    ))
    setMaxSideOrderActive(total)
  }, [ListFreeSidesActive])
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-add-food-order"

      >
        <Modal.Body>
          <Modal.Header closeButton>
            <h2 className='m-0 text-4a4a6a text-center'>
              Choose Side Dish({maxSideOrder - maxSideOrderActive})
            </h2>
          </Modal.Header>
          <ModalLoading onHide={() => setShowLoading(false)} show={showLoading} />
          <div className='box-add-another-dish d-flex'>
            <div className='box-choose-side-dish-owner w-100 overflow-y-scroll d-flex flex-wrap gap-2'>
              {ListFreeSides.map((item, index) => (
                <div
                  className="w-100px h-150px rounded-5 shadow-card-default" key={`side-dish-${item.id}`}
                >
                  <div
                    className={`p-0 position-relative rounded-5 shadow-sm bg-white  py-1 overflow-hidden h-100 `}
                  >
                    {/* <div className=" position-relative">
                      <div className="d-flex align-items-center gap-2px h-32px ps-2">
                        <div className="text-price-food-pos-menu">{FormatDolla(12)}</div>
                        <div className="text-dollar-pos-menu mb-2">$</div>
                      </div>
                    </div> */}
                    <div className="btn-add-food-cart-pos-menu ">
                      <div className="w-100 h-100 position-relative" onClick={() => handleAddSideDish(item.id, item.name, item.imageCourse)}>
                        <img src={plus} alt="" className="btn-icon-plus-pos-menu" />
                      </div>
                    </div>
                    <div className="mt-3">
                      <img
                        src={targetFood + item.imageCourse}
                        className="img-fluid w-100 height-60px object-fit-cover rounded-3 mb-1 flex-shrink-0"
                        onError={(e) => {
                          e.currentTarget.src = default_product;
                        }}
                        alt="food-items"
                      />
                    </div>
                    <div className="px-2">
                      <div>
                        <h4 className="text-name-food-pos-menu text-two-line-name mb-1">{item.name}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

            </div>
            {ListFreeSidesActive.length > 0 ? (
              <div className='list-side-dish-active max-h-600px px-4 d-flex flex-column gap-1 flex-shrink-0'>
                {ListFreeSidesActive.map((item) => (
                  <div className='border rounded-4 shadow-card-default h-100px p-4 d-flex gap-2' key={`side-dish-active-${item.side_id}`}>
                    <div className='h-100 border aspect-ratio  rounded-4 flex-shrink-0'>
                      <img
                        src={targetFood + item.imageSide}
                        className="w-100 h-100 object-fit-cover aspect-ratio rounded-3flex-shrink-0 rounded-4"
                        onError={(e) => {
                          e.currentTarget.src = default_product;
                        }}
                        alt="food-items"
                      />
                    </div>
                    <div className='d-flex flex-column justify-content-between w-100'>
                      <p className='m-0 font-bold text-4a4a6a text-two-line-name line-height-normal text-xs'>{item.name}</p>
                      <div className='d-flex gap-2 align-items-center w-100 justify-content-end'>
                        <button className='btn-add-remove-side-dish shadow-button-default' disabled={maxSideOrderActive - maxSideOrder > 0} onClick={() => handleRemoveSideDish(item.side_id, item.quantity)}>
                          <RemoveRoundedIcon className='text-orange' />
                        </button>
                        <p className='m-0 font-bold text-4a4a6a'>{item.quantity}</p>
                        <button className='btn-add-remove-side-dish shadow-button-default' disabled={maxSideOrder - maxSideOrderActive === 0} onClick={() => handleAddSideDish(item.side_id, item.name, item.imageSide)}>
                          <AddRoundedIcon className='text-orange' />
                        </button>
                      </div>
                    </div>
                  </div>

                ))}


              </div>
            ) : ''}

          </div>

        </Modal.Body>
      </Modal>
    </>
  );
}
export default ModalAddSideDish;