import React, { useEffect, useState } from 'react'
import './styles.css'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { resetAuth } from '../../../redux/actions';
import { checkSentSmsCustomer, gBirthAndNameCustomer, gInforOwnerByCode, gLoginOrderOnline } from '../../../api/apiCheckin/checkinApi';
import ModalLoading from '../../../components/ModalLoading';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import MaskedInput from 'react-text-mask';
import { Link } from 'react-router-dom';
import * as Request from "../../../api/Request";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import Cleave from 'cleave.js/react';
import { getLanguageCheckIn } from '../../../Services/languageCheckin';


interface ownerInforType {
  name: string;
  phone: string;
  type_send_review_message: string;
  type_send_review_staff: string;
}

const LoginOnline: React.FC = () => {
  const languageUI = getLanguageCheckIn();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [ShowLoading, setShowLoading] = useState<boolean>(false);
  const [phoneValue, setPhoneValue] = useState("");
  const [checkAccept, setCheckAccept] = useState(false);
  const [checkError, setCheckError] = useState(false);
  const [checkErrorAccept, setCheckErrorAccept] = useState(false);
  const [errMess, setErMess] = useState("");
  const [ownerInFor, setOwnerInfor] = useState<ownerInforType>();
  const [errMessAccept, setErMessAccept] = useState("");
  const [firstName, setFirstName] = useState("");
  const [valueTime, setValueTime] = useState("");
  const [typeAccount, setTypeAccount] = useState(false)
  const [customerId, setCustomerId] = useState("");


  let params = useParams();
  let owner_code = params?.ownerCode;

  useEffect(() => {
    dispatch(resetAuth());
  }, [dispatch]);

  const location = useLocation();

  const onchagePhoneNumber = (e: any) => {
    let phone = e?.target.value;

    const phoneReplace = phone
      ?.replace(/[-]/g, "")
      .replace(/[()]/g, "")
      .replace(/\s+/g, "")
      .replace(/[_]/g, "");
    setPhoneValue(phoneReplace);
  };

  useEffect(() => {
    if (phoneValue.length == 10) {
      setCheckAccept(true);
    } else setCheckAccept(false);
  }, [phoneValue]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (phoneValue == "") {
      setCheckError(true);
      setErMess(languageUI.pleaseEnterPhone);
      return;
    }
    if (phoneValue.length !== 10) {
      setCheckError(true);
      setErMess(
        languageUI.phoneNumberFormatConsistsOf10Numbers
      );
      return;
    }
    if (checkAccept == false) {
      setCheckErrorAccept(true);
      setErMessAccept(languageUI.checkOurTermsPlease);
      return;
    }
    // insert phone customer
    addPhoneNumber(phoneValue, owner_code);

  };
  const sentSmsSetting = async (owner: number, id_customer: string, type: number) => {

    const req = await checkSentSmsCustomer(owner, id_customer);
    if (Number(req?.status) === 1) {
      setShowLoading(false)
      localStorage.setItem("custommerId", id_customer);
      localStorage.setItem("ownerID", owner.toString());
      if (Number(type) === 1) {
        // trường hợp đã đăng nhập
        navigate(`/online/showpoint/${id_customer}`);
      } else { // trường hợp chưa đăng nhập
        setTypeAccount(true)
      }
    } else {

      setShowLoading(false)
      localStorage.setItem("custommerId", id_customer);
      localStorage.setItem("ownerID", owner.toString());
      if (Number(type) === 1) {
        // trường hợp đã đăng nhập
        navigate(`/online/showpoint/${id_customer}`);
      } else { // trường hợp chưa đăng nhập
        setTypeAccount(true)
      }
    }
  }
  //send sms
  const sendSmsReview = async (owner: string, customerID: string) => {
    await Request.fetchAPI(`checkin/send_SMS_customer.php`, "post", {
      id_owner: owner,
      id_customer: customerID,
    })
      .then((res) => {
        if (res.status == 1) console.log(res.data);
      })
      .catch((error: any) => console.log(error));
  };
  //send sms
  const sendSmsReviewStaff = async (owner: number, customerID: string) => {
    await Request.fetchAPI(`checkin/send_SMS_customer_review_staff.php`, "post", {
      id_owner: owner,
      id_customer: customerID,
    })
      .then((res) => {
        if (res.status == 1) console.log(res.data);
      })
      .catch((error: any) => console.log(error));
  };

  const addPhoneNumber = async (phoneValue: any, id_owner: any) => {
    if (typeAccount === false) {
      setShowLoading(true)
      const res = await gLoginOrderOnline(phoneValue, id_owner)
      if (res.status == 1 && res?.data[0]?.id) {
        setCustomerId(res?.data[0]?.id.toString())
        localStorage.setItem("firstName", res?.data[0]?.name);
        localStorage.setItem("phoneNumber", res?.data[0]?.phone);
        if (Number(ownerInFor?.type_send_review_message) === 1) {
          sendSmsReview(atob(id_owner), res?.data[0]?.id)

        }
        if (Number(ownerInFor?.type_send_review_staff) === 1) {
          sendSmsReviewStaff(id_owner, res?.data[0]?.id)
        }
        sentSmsSetting(Number(atob(id_owner)), res?.data[0]?.id, Number(res.data[0].type))
      } else {
        setShowLoading(false)
      }
    } else {
      setShowLoading(true)
      const res = await gBirthAndNameCustomer(customerId, firstName, valueTime,)
      if (Number(res.status) === 1) {
        setShowLoading(false)
        localStorage.setItem("firstName", firstName);
        localStorage.setItem("phoneNumber", phoneValue);
        navigate(`/online/showpoint/${customerId}`);
      } else {
        setShowLoading(false)
        setCheckError(true)
        setErMess(res.message)
      }
    }
  };

  const getInforOwner = async () => {
    // await Request.fetchAPI(`checkin/gInfoOwner.php?id=${id_owner}`, "get").then(
    //   (res) => {
    //     if (res.status == 1) {
    //       localStorage.setItem("namestore", res.data.name);
    //       setOwnerInfor(res.data);
    //     }
    //   }
    // );
    if (owner_code) {
      const res = await gInforOwnerByCode(owner_code)
      if (Number(res.status) === 1 && res?.data?.name) {
        localStorage.setItem("namestore", res?.data?.name);
        localStorage.setItem("addressOwner", res?.data?.address);
        setOwnerInfor(res.data);
      }
    }
  };

  const handleSelect = (nextTime: string) => {
    setValueTime(nextTime);
  };




  useEffect(() => {
    localStorage.clear();
    getInforOwner();
    // localStorage.removeItem('token_custommer')

  }, []);

  return (
    <>
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
      <div className="account-pages d-flex bg-primary" style={{ minHeight: "100vh" }}>
        <Container className="m-auto">
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="bg-pattern rounded-3 shadow mb-0">
                <Card.Body className="p-4">
                  <h4 className="text-center mb-4 mt-4">{ownerInFor?.name}</h4>
                  <form className="text-center" onSubmit={handleSubmit}>
                    <h4 className="form-label">
                      {languageUI.viewOurMenu}
                    </h4>
                    <div className='w-100'>
                      {typeAccount ? (<div className=''>
                        <h5 className='text-4a4a6a text-left'>{languageUI.Fullname}:</h5>
                        <Form.Group className="mb-2 mt-2" controlId="validationCustom03">
                          <Form.Control
                            type="text"
                            placeholder={languageUI.EnterFullName}
                            required
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                          />
                          <p style={{ color: "#f1556c", textAlign: "center" }}>
                            {checkError ? errMess : ""}
                          </p>{" "}
                        </Form.Group>
                        <div className="text-start mb-2">
                          <h5 className='text-4a4a6a text-left'>{languageUI.Birthday}:</h5>
                          <Cleave
                            className="w-100 border h-40px px-2 focus-visible-gray"
                            placeholder="MM/DD"
                            inputMode="numeric"
                            options={{
                              date: true,
                              delimiter: "/",
                              datePattern: ["m", "d"],
                            }}
                            onChange={(e) => handleSelect(e.target.value)}
                          />

                        </div>
                        <h5 className='text-right text-primary mb-0' onClick={() => setTypeAccount(false)}>{languageUI.Dohaveyouaccount}</h5>
                      </div>) : (<>
                        <h5 className='text-4a4a6a text-left'>{languageUI.Phonenumber}:</h5>
                        <MaskedInput
                          mask={[
                            "(",
                            /[0-9]/,
                            /\d/,
                            /\d/,
                            ")",
                            " ",
                            /\d/,
                            /\d/,
                            /\d/,
                            "-",
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                          ]}
                          placeholder="(___) ___-____"
                          className="form-control"
                          value={phoneValue}
                          onChange={onchagePhoneNumber}
                        /></>)}
                    </div>

                    <p style={{ color: "#f1556c", textAlign: "center" }}>
                      {checkError ? errMess : ""}
                    </p>{" "}
                    <Button
                      onSubmit={handleSubmit}
                      type="submit"
                      variant="outline-primary"
                      className="waves-effect waves-light mb-4 mt-2"
                    >
                      {languageUI.go}
                    </Button>
                    <p className="text-justify text-muted">
                      {`${getLanguageCheckIn().LG === 1 ? ("By checking the box and entering your phone number. you give BriJack POS and" + ownerInFor?.name + "express written\
                      consent to contact you at number provided for any\
                      feedback, reminder or promotional purposes. Consent is not\
                      required to make a purchase. <br /> Reply STOP to stop receiving text messages") : "\
                      Bằng cách nhập số điện thoại của bạn, bạn cung cấp cho BriJack POS và cho phép chúng tôi liên hệ với bạn theo số điện thoại được cung cấp cho bất kỳ phản hồi nào, mục đích nhắc nhở hoặc quảng cáo, Không cần phải có sự đồng ý để mua hàng\
                      "}`}
                    </p>
                    <Form.Check
                      type="checkbox"
                      checked={checkAccept}
                      onChange={(e) => setCheckAccept(!checkAccept)}
                      className="cursor-pointer ps-0 text-start text-muted d-flex"
                      id="accept-term"
                      label={<div>{languageUI.Iacceptthe} <Link to={'/webcheckin/teamofuse/' + atob(owner_code ?? '')} className="text-underline">{languageUI.termsofuse}</Link> {languageUI.and} <Link to={'/webcheckin/privacypolicy/' + atob(owner_code ?? '')} className="text-underline">{languageUI.privacypolicy}</Link></div>}
                      inputMode="numeric"
                    />
                    <p style={{ color: "#f1556c", textAlign: "center" }}>
                      {checkErrorAccept ? errMessAccept : ""}
                    </p>{" "}
                  </form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
export default LoginOnline