interface Records {
    point: number;
    nameUser: string;
    phone: string;
    name: string;
    action: string;
}

const records: Records[] = [
    {
        point: 1,
        nameUser: "Jonnie",
        phone: '(402) 998 7733',
        name: 'Good',
        action: '',
    }
];

export { records };
