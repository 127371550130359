interface CustomersItems {
    id: number;
    name: string;
    phone: string;
    email: string;
    location: string;
    created_on: string;
    status: string;
    avatar: string;
}

const customers: CustomersItems[] = [
    {
        id: 1,
        name: 'Abagael Breslau',
        phone: '(461) 3636077',
        email: 'abreslau0@wiley.com',
        location: 'China',
        created_on: '7/4/2019',
        status: 'Active',
        avatar: 'https://robohash.org/laborevoluptateeaque.png?size=60x60&set=set1',
    },
    {
        id: 2,
        name: 'Veradis Taber',
        phone: '(917) 2590629',
        email: 'vtaber1@ucoz.com',
        location: 'Venezuela',
        created_on: '7/8/2019',
        status: 'Active',
        avatar: 'https://robohash.org/voluptatemeligendisint.png?size=60x60&set=set1',
    },
    {
        id: 3,
        name: 'Lindon Ceeley',
        phone: '(925) 9515307',
        email: 'lceeley2@fotki.com',
        location: 'Panama',
        created_on: '7/20/2019',
        status: 'Active',
        avatar: 'https://robohash.org/etidsapiente.png?size=60x60&set=set1',
    },
    {
        id: 4,
        name: 'Mendel Alfonzo',
        phone: '(295) 3668262',
        email: 'malfonzo3@irs.gov',
        location: 'France',
        created_on: '7/22/2019',
        status: 'Active',
        avatar: 'https://robohash.org/pariaturharumquas.png?size=60x60&set=set1',
    },
    {
        id: 5,
        name: 'Dorrie Tindley',
        phone: '(809) 2120936',
        email: 'dtindley4@so-net.ne.jp',
        location: 'Colombia',
        created_on: '7/14/2019',
        status: 'Blocked',
        avatar: 'https://robohash.org/quodfugiatquae.png?size=60x60&set=set1',
    },
    {
        id: 6,
        name: 'Kata MacKnight',
        phone: '(402) 3164595',
        email: 'kmacknight5@boston.com',
        location: 'China',
        created_on: '7/10/2019',
        status: 'Active',
        avatar: 'https://robohash.org/sapientenonomnis.png?size=60x60&set=set1',
    },
    {
        id: 7,
        name: 'Mateo Kingzeth',
        phone: '(941) 2564726',
        email: 'mkingzeth6@google.com.hk',
        location: 'China',
        created_on: '7/18/2019',
        status: 'Active',
        avatar: 'https://robohash.org/autvelitest.png?size=60x60&set=set1',
    },
    {
        id: 8,
        name: 'Jayson Creaney',
        phone: '(969) 3198297',
        email: 'jcreaney7@netvibes.com',
        location: 'Malaysia',
        created_on: '7/19/2019',
        status: 'Blocked',
        avatar: 'https://robohash.org/exsuscipitcorporis.png?size=60x60&set=set1',
    },
    {
        id: 9,
        name: 'Elita Ortmann',
        phone: '(283) 1302865',
        email: 'eortmann8@pinterest.com',
        location: 'South Korea',
        created_on: '7/2/2019',
        status: 'Blocked',
        avatar: 'https://robohash.org/consectetureiuset.png?size=60x60&set=set1',
    },
    {
        id: 10,
        name: 'Tyne Hollidge',
        phone: '(529) 2241787',
        email: 'thollidge9@ebay.co.uk',
        location: 'China',
        created_on: '7/11/2019',
        status: 'Active',
        avatar: 'https://robohash.org/aspernaturlaborumab.png?size=60x60&set=set1',
    },
    {
        id: 11,
        name: 'Ezmeralda Piggrem',
        phone: '(532) 2561412',
        email: 'epiggrema@addtoany.com',
        location: 'Cambodia',
        created_on: '7/6/2019',
        status: 'Blocked',
        avatar: 'https://robohash.org/veroinciduntfacere.png?size=60x60&set=set1',
    },
    {
        id: 12,
        name: 'Kalina Dabels',
        phone: '(953) 8150340',
        email: 'kdabelsb@typepad.com',
        location: 'Mexico',
        created_on: '7/16/2019',
        status: 'Active',
        avatar: 'https://robohash.org/cumvoluptatibusreprehenderit.png?size=60x60&set=set1',
    },
    {
        id: 13,
        name: 'Claudianus Gommes',
        phone: '(791) 6099303',
        email: 'cgommesc@berkeley.edu',
        location: 'Indonesia',
        created_on: '7/14/2019',
        status: 'Active',
        avatar: 'https://robohash.org/sitlaboreet.png?size=60x60&set=set1',
    },
    {
        id: 14,
        name: 'Ulrikaumeko Jentle',
        phone: '(478) 8204063',
        email: 'ujentled@pagesperso-orange.fr',
        location: 'Sweden',
        created_on: '7/25/2019',
        status: 'Active',
        avatar: 'https://robohash.org/placeateligendiexcepturi.png?size=60x60&set=set1',
    },
    {
        id: 15,
        name: 'Peria Richens',
        phone: '(423) 7419337',
        email: 'prichense@google.it',
        location: 'Indonesia',
        created_on: '7/20/2019',
        status: 'Blocked',
        avatar: 'https://robohash.org/impeditreprehenderiteos.png?size=60x60&set=set1',
    },
    {
        id: 16,
        name: 'Sollie Ramelet',
        phone: '(600) 7539738',
        email: 'srameletf@hubpages.com',
        location: 'Brazil',
        created_on: '7/3/2019',
        status: 'Blocked',
        avatar: 'https://robohash.org/quodeased.png?size=60x60&set=set1',
    },
    {
        id: 17,
        name: 'Hodge Diviney',
        phone: '(311) 8358001',
        email: 'hdivineyg@ycombinator.com',
        location: 'Belarus',
        created_on: '7/28/2019',
        status: 'Blocked',
        avatar: 'https://robohash.org/ipsaprovidentmaiores.png?size=60x60&set=set1',
    },
    {
        id: 18,
        name: 'Roger Lidster',
        phone: '(146) 1370107',
        email: 'rlidsterh@redcross.org',
        location: 'Indonesia',
        created_on: '7/5/2019',
        status: 'Blocked',
        avatar: 'https://robohash.org/corruptiomnisearum.png?size=60x60&set=set1',
    },
    {
        id: 19,
        name: 'Caprice Custed',
        phone: '(239) 8054555',
        email: 'ccustedi@godaddy.com',
        location: 'Russia',
        created_on: '7/14/2019',
        status: 'Active',
        avatar: 'https://robohash.org/itaquesedvoluptate.png?size=60x60&set=set1',
    },
    {
        id: 20,
        name: 'Becki Filer',
        phone: '(128) 9346017',
        email: 'bfilerj@xrea.com',
        location: 'Madagascar',
        created_on: '7/11/2019',
        status: 'Blocked',
        avatar: 'https://robohash.org/autillonam.png?size=60x60&set=set1',
    },
    {
        id: 21,
        name: 'Rube Sjostrom',
        phone: '(216) 7061924',
        email: 'rsjostromk@blogger.com',
        location: 'France',
        created_on: '7/28/2019',
        status: 'Blocked',
        avatar: 'https://robohash.org/quasiexercitationemvel.png?size=60x60&set=set1',
    },
    {
        id: 22,
        name: 'Gris Biggin',
        phone: '(269) 4756082',
        email: 'gbigginl@usa.gov',
        location: 'Indonesia',
        created_on: '7/27/2019',
        status: 'Active',
        avatar: 'https://robohash.org/quosaliquamcorrupti.png?size=60x60&set=set1',
    },
    {
        id: 23,
        name: 'Les Blain',
        phone: '(138) 1015423',
        email: 'lblainm@wsj.com',
        location: 'China',
        created_on: '7/21/2019',
        status: 'Active',
        avatar: 'https://robohash.org/velexcepturidignissimos.png?size=60x60&set=set1',
    },
    {
        id: 24,
        name: 'Adelaida MacWhan',
        phone: '(396) 7710932',
        email: 'amacwhann@abc.net.au',
        location: 'Nigeria',
        created_on: '7/27/2019',
        status: 'Blocked',
        avatar: 'https://robohash.org/nonveroprovident.png?size=60x60&set=set1',
    },
    {
        id: 25,
        name: 'Marco Capon',
        phone: '(315) 8620366',
        email: 'mcapono@ucla.edu',
        location: 'China',
        created_on: '7/1/2019',
        status: 'Active',
        avatar: 'https://robohash.org/eumevenietqui.png?size=60x60&set=set1',
    },
    {
        id: 26,
        name: 'Cordula Goude',
        phone: '(244) 8639717',
        email: 'cgoudep@indiatimes.com',
        location: 'China',
        created_on: '7/4/2019',
        status: 'Active',
        avatar: 'https://robohash.org/estvoluptatesed.png?size=60x60&set=set1',
    },
    {
        id: 27,
        name: 'Pippa Piken',
        phone: '(440) 3470724',
        email: 'ppikenq@vistaprint.com',
        location: 'Peru',
        created_on: '7/23/2019',
        status: 'Blocked',
        avatar: 'https://robohash.org/quianimiquis.png?size=60x60&set=set1',
    },
    {
        id: 28,
        name: 'Katharina Snell',
        phone: '(252) 7933179',
        email: 'ksnellr@spiegel.de',
        location: 'Sierra Leone',
        created_on: '7/7/2019',
        status: 'Blocked',
        avatar: 'https://robohash.org/reiciendisquisquamnemo.png?size=60x60&set=set1',
    },
    {
        id: 29,
        name: 'Friedrich Brickner',
        phone: '(909) 3774612',
        email: 'fbrickners@nba.com',
        location: 'Taiwan',
        created_on: '7/19/2019',
        status: 'Active',
        avatar: 'https://robohash.org/autemharummolestiae.png?size=60x60&set=set1',
    },
    {
        id: 30,
        name: 'Kilian Auden',
        phone: '(100) 2329345',
        email: 'kaudent@amazon.de',
        location: 'Russia',
        created_on: '7/9/2019',
        status: 'Active',
        avatar: 'https://robohash.org/rationenemoiste.png?size=60x60&set=set1',
    },
    {
        id: 31,
        name: 'Genevieve Rouff',
        phone: '(430) 9011662',
        email: 'grouffu@independent.co.uk',
        location: 'United Kingdom',
        created_on: '7/6/2019',
        status: 'Blocked',
        avatar: 'https://robohash.org/utvoluptatibuset.png?size=60x60&set=set1',
    },
    {
        id: 32,
        name: 'Hobard Stiles',
        phone: '(998) 7877955',
        email: 'hstilesv@bbc.co.uk',
        location: 'China',
        created_on: '7/3/2019',
        status: 'Active',
        avatar: 'https://robohash.org/rationevitaeducimus.png?size=60x60&set=set1',
    },
    {
        id: 33,
        name: 'Leoline Papps',
        phone: '(876) 9399111',
        email: 'lpappsw@xrea.com',
        location: 'Indonesia',
        created_on: '7/15/2019',
        status: 'Blocked',
        avatar: 'https://robohash.org/ametquisquamplaceat.png?size=60x60&set=set1',
    },
    {
        id: 34,
        name: 'Deloria Storre',
        phone: '(636) 5767463',
        email: 'dstorrex@hp.com',
        location: 'Ukraine',
        created_on: '7/13/2019',
        status: 'Active',
        avatar: 'https://robohash.org/magnamfugitfacere.png?size=60x60&set=set1',
    },
    {
        id: 35,
        name: 'Mickie Bolitho',
        phone: '(301) 1571490',
        email: 'mbolithoy@gizmodo.com',
        location: 'Vietnam',
        created_on: '7/24/2019',
        status: 'Blocked',
        avatar: 'https://robohash.org/autemlaborumprovident.png?size=60x60&set=set1',
    },
    {
        id: 36,
        name: 'Hope Hartil',
        phone: '(318) 4606738',
        email: 'hhartilz@gnu.org',
        location: 'China',
        created_on: '7/27/2019',
        status: 'Active',
        avatar: 'https://robohash.org/inventorenisiquo.png?size=60x60&set=set1',
    },
    {
        id: 37,
        name: 'Tadio Simmons',
        phone: '(914) 4763589',
        email: 'tsimmons10@chronoengine.com',
        location: 'Costa Rica',
        created_on: '7/21/2019',
        status: 'Active',
        avatar: 'https://robohash.org/doloremquevoluptatescommodi.png?size=60x60&set=set1',
    },
    {
        id: 38,
        name: 'Arda Bickers',
        phone: '(176) 2017597',
        email: 'abickers11@tmall.com',
        location: 'Malawi',
        created_on: '7/28/2019',
        status: 'Blocked',
        avatar: 'https://robohash.org/aliquamremet.png?size=60x60&set=set1',
    },
    {
        id: 39,
        name: 'Drucill Thurman',
        phone: '(478) 6223976',
        email: 'dthurman12@skype.com',
        location: 'Brazil',
        created_on: '7/5/2019',
        status: 'Blocked',
        avatar: 'https://robohash.org/quiaestvoluptatem.png?size=60x60&set=set1',
    },
    {
        id: 40,
        name: 'Cullin Endle',
        phone: '(960) 4792971',
        email: 'cendle13@wp.com',
        location: 'Philippines',
        created_on: '7/13/2019',
        status: 'Blocked',
        avatar: 'https://robohash.org/natusenimet.png?size=60x60&set=set1',
    },
    {
        id: 41,
        name: 'Yves Swin',
        phone: '(468) 9264240',
        email: 'yswin14@mysql.com',
        location: 'Venezuela',
        created_on: '7/20/2019',
        status: 'Active',
        avatar: 'https://robohash.org/nihilquidemdolorem.png?size=60x60&set=set1',
    },
    {
        id: 42,
        name: 'Colleen Ilieve',
        phone: '(491) 2014072',
        email: 'cilieve15@usatoday.com',
        location: 'Japan',
        created_on: '7/15/2019',
        status: 'Active',
        avatar: 'https://robohash.org/odioautemex.png?size=60x60&set=set1',
    },
    {
        id: 43,
        name: 'Mable Squires',
        phone: '(781) 2650707',
        email: 'msquires16@cafepress.com',
        location: 'Pakistan',
        created_on: '7/4/2019',
        status: 'Blocked',
        avatar: 'https://robohash.org/quaesuscipitaspernatur.png?size=60x60&set=set1',
    },
    {
        id: 44,
        name: 'Francklyn Oughtright',
        phone: '(123) 5880543',
        email: 'foughtright17@timesonline.co.uk',
        location: 'China',
        created_on: '7/1/2019',
        status: 'Blocked',
        avatar: 'https://robohash.org/etnesciunteveniet.png?size=60x60&set=set1',
    },
    {
        id: 45,
        name: 'Holly Mabley',
        phone: '(390) 8411669',
        email: 'hmabley18@smugmug.com',
        location: 'Spain',
        created_on: '7/21/2019',
        status: 'Blocked',
        avatar: 'https://robohash.org/asperioressednecessitatibus.png?size=60x60&set=set1',
    },
    {
        id: 46,
        name: 'Elvin Haistwell',
        phone: '(627) 2488531',
        email: 'ehaistwell19@ebay.com',
        location: 'Indonesia',
        created_on: '7/28/2019',
        status: 'Blocked',
        avatar: 'https://robohash.org/quiassumendacupiditate.png?size=60x60&set=set1',
    },
    {
        id: 47,
        name: 'Donaugh Simms',
        phone: '(971) 9299447',
        email: 'dsimms1a@nytimes.com',
        location: 'China',
        created_on: '7/4/2019',
        status: 'Active',
        avatar: 'https://robohash.org/perferendissedfacere.png?size=60x60&set=set1',
    },
    {
        id: 48,
        name: "Ogdan A'Barrow",
        phone: '(232) 9095847',
        email: 'oabarrow1b@princeton.edu',
        location: 'Philippines',
        created_on: '7/10/2019',
        status: 'Blocked',
        avatar: 'https://robohash.org/inventoresuntcupiditate.png?size=60x60&set=set1',
    },
    {
        id: 49,
        name: 'Lydie Sykes',
        phone: '(430) 2945766',
        email: 'lsykes1c@123-reg.co.uk',
        location: 'Bangladesh',
        created_on: '7/26/2019',
        status: 'Active',
        avatar: 'https://robohash.org/nisivoluptatesdeleniti.png?size=60x60&set=set1',
    },
    {
        id: 50,
        name: 'Justen Itscovitz',
        phone: '(182) 1712572',
        email: 'jitscovitz1d@cbslocal.com',
        location: 'Canada',
        created_on: '7/3/2019',
        status: 'Blocked',
        avatar: 'https://robohash.org/rationeconsecteturconsequatur.png?size=60x60&set=set1',
    },
    {
        id: 51,
        name: 'Ash Breche',
        phone: '(143) 9386155',
        email: 'abreche1e@hubpages.com',
        location: 'Indonesia',
        created_on: '7/19/2019',
        status: 'Active',
        avatar: 'https://robohash.org/voluptatempossimusvoluptas.png?size=60x60&set=set1',
    },
    {
        id: 52,
        name: 'Kendricks Dot',
        phone: '(316) 8004511',
        email: 'kdot1f@chicagotribune.com',
        location: 'Greece',
        created_on: '7/22/2019',
        status: 'Blocked',
        avatar: 'https://robohash.org/cumquemolestiassint.png?size=60x60&set=set1',
    },
    {
        id: 53,
        name: 'Udale Cure',
        phone: '(917) 2094519',
        email: 'ucure1g@hc360.com',
        location: 'Japan',
        created_on: '7/26/2019',
        status: 'Active',
        avatar: 'https://robohash.org/quiaaliquidad.png?size=60x60&set=set1',
    },
    {
        id: 54,
        name: 'Priscilla Durnell',
        phone: '(303) 1552401',
        email: 'pdurnell1h@cmu.edu',
        location: 'Russia',
        created_on: '7/9/2019',
        status: 'Active',
        avatar: 'https://robohash.org/porroverout.png?size=60x60&set=set1',
    },
    {
        id: 55,
        name: 'Claybourne Denniss',
        phone: '(987) 2549060',
        email: 'cdenniss1i@g.co',
        location: 'Brazil',
        created_on: '7/3/2019',
        status: 'Active',
        avatar: 'https://robohash.org/atrationeoptio.png?size=60x60&set=set1',
    },
    {
        id: 56,
        name: 'Sal Calway',
        phone: '(482) 4874156',
        email: 'scalway1j@bloglovin.com',
        location: 'Canada',
        created_on: '7/11/2019',
        status: 'Active',
        avatar: 'https://robohash.org/possimusquised.png?size=60x60&set=set1',
    },
    {
        id: 57,
        name: 'Clarine Kall',
        phone: '(534) 6135188',
        email: 'ckall1k@bbb.org',
        location: 'Pakistan',
        created_on: '7/14/2019',
        status: 'Blocked',
        avatar: 'https://robohash.org/quiperferendisut.png?size=60x60&set=set1',
    },
    {
        id: 58,
        name: 'Nowell Karran',
        phone: '(618) 4349713',
        email: 'nkarran1l@chronoengine.com',
        location: 'Russia',
        created_on: '7/24/2019',
        status: 'Active',
        avatar: 'https://robohash.org/quasifugitdolorem.png?size=60x60&set=set1',
    },
    {
        id: 59,
        name: 'Karlene Emanuelli',
        phone: '(746) 5616760',
        email: 'kemanuelli1m@boston.com',
        location: 'Thailand',
        created_on: '7/18/2019',
        status: 'Active',
        avatar: 'https://robohash.org/oditvoluptatemsapiente.png?size=60x60&set=set1',
    },
    {
        id: 60,
        name: 'Alica Afield',
        phone: '(581) 3168898',
        email: 'aafield1n@soup.io',
        location: 'Indonesia',
        created_on: '7/7/2019',
        status: 'Blocked',
        avatar: 'https://robohash.org/veroanimitotam.png?size=60x60&set=set1',
    },
    {
        id: 61,
        name: 'Ronni Lawling',
        phone: '(283) 3326740',
        email: 'rlawling1o@weebly.com',
        location: 'China',
        created_on: '7/23/2019',
        status: 'Blocked',
        avatar: 'https://robohash.org/voluptatemolestiaeomnis.png?size=60x60&set=set1',
    },
    {
        id: 62,
        name: 'Amory Sowter',
        phone: '(340) 1936161',
        email: 'asowter1p@dailymail.co.uk',
        location: 'China',
        created_on: '7/5/2019',
        status: 'Blocked',
        avatar: 'https://robohash.org/delenitiametnecessitatibus.png?size=60x60&set=set1',
    },
    {
        id: 63,
        name: 'West Clever',
        phone: '(284) 4585064',
        email: 'wclever1q@cloudflare.com',
        location: 'Tunisia',
        created_on: '7/8/2019',
        status: 'Active',
        avatar: 'https://robohash.org/etipsumvoluptate.png?size=60x60&set=set1',
    },
    {
        id: 64,
        name: 'Olivero De la Yglesia',
        phone: '(520) 2964104',
        email: 'ode1r@auda.org.au',
        location: 'United States',
        created_on: '7/12/2019',
        status: 'Blocked',
        avatar: 'https://robohash.org/ametnumquamomnis.png?size=60x60&set=set1',
    },
    {
        id: 65,
        name: 'Ottilie Morstatt',
        phone: '(588) 5873700',
        email: 'omorstatt1s@businesswire.com',
        location: 'Indonesia',
        created_on: '7/26/2019',
        status: 'Blocked',
        avatar: 'https://robohash.org/autconsequunturperferendis.png?size=60x60&set=set1',
    },
    {
        id: 66,
        name: 'Huey Fivey',
        phone: '(694) 3992261',
        email: 'hfivey1t@qq.com',
        location: 'Afghanistan',
        created_on: '7/10/2019',
        status: 'Active',
        avatar: 'https://robohash.org/animicorporisfuga.png?size=60x60&set=set1',
    },
    {
        id: 67,
        name: 'Sidonnie Schruyers',
        phone: '(621) 3204359',
        email: 'sschruyers1u@csmonitor.com',
        location: 'Indonesia',
        created_on: '7/7/2019',
        status: 'Blocked',
        avatar: 'https://robohash.org/etetea.png?size=60x60&set=set1',
    },
    {
        id: 68,
        name: 'Elbertine Kelson',
        phone: '(792) 2187445',
        email: 'ekelson1v@over-blog.com',
        location: 'Philippines',
        created_on: '7/23/2019',
        status: 'Active',
        avatar: 'https://robohash.org/numquamvoluptasvel.png?size=60x60&set=set1',
    },
    {
        id: 69,
        name: 'Teodoro Huleatt',
        phone: '(163) 2256919',
        email: 'thuleatt1w@paypal.com',
        location: 'Russia',
        created_on: '7/14/2019',
        status: 'Blocked',
        avatar: 'https://robohash.org/voluptasvitaequaerat.png?size=60x60&set=set1',
    },
    {
        id: 70,
        name: 'Kettie Teague',
        phone: '(189) 4582453',
        email: 'kteague1x@ebay.co.uk',
        location: 'Pakistan',
        created_on: '7/8/2019',
        status: 'Blocked',
        avatar: 'https://robohash.org/eaquasnam.png?size=60x60&set=set1',
    },
    {
        id: 71,
        name: 'Laetitia Hutton',
        phone: '(862) 2295100',
        email: 'lhutton1y@vinaora.com',
        location: 'China',
        created_on: '7/17/2019',
        status: 'Blocked',
        avatar: 'https://robohash.org/voluptatemetquidem.png?size=60x60&set=set1',
    },
    {
        id: 72,
        name: 'Holden Ickowicz',
        phone: '(163) 8573189',
        email: 'hickowicz1z@latimes.com',
        location: 'China',
        created_on: '7/8/2019',
        status: 'Active',
        avatar: 'https://robohash.org/nobisvoluptatemofficia.png?size=60x60&set=set1',
    },
    {
        id: 73,
        name: 'Adriano St Ange',
        phone: '(345) 3755291',
        email: 'ast20@ca.gov',
        location: 'Sweden',
        created_on: '7/21/2019',
        status: 'Active',
        avatar: 'https://robohash.org/nonculpaiure.png?size=60x60&set=set1',
    },
    {
        id: 74,
        name: 'Colleen MacAne',
        phone: '(107) 4030486',
        email: 'cmacane21@netscape.com',
        location: 'Poland',
        created_on: '7/19/2019',
        status: 'Active',
        avatar: 'https://robohash.org/harumoditpossimus.png?size=60x60&set=set1',
    },
    {
        id: 75,
        name: 'Nonna Attride',
        phone: '(279) 1946024',
        email: 'nattride22@printfriendly.com',
        location: 'China',
        created_on: '7/2/2019',
        status: 'Active',
        avatar: 'https://robohash.org/perferendisquoquos.png?size=60x60&set=set1',
    },
    {
        id: 76,
        name: 'Beverie Dewan',
        phone: '(899) 7375762',
        email: 'bdewan23@patch.com',
        location: 'China',
        created_on: '7/13/2019',
        status: 'Blocked',
        avatar: 'https://robohash.org/etreprehenderithic.png?size=60x60&set=set1',
    },
    {
        id: 77,
        name: 'Tibold Coundley',
        phone: '(787) 6790691',
        email: 'tcoundley24@samsung.com',
        location: 'Ukraine',
        created_on: '7/9/2019',
        status: 'Blocked',
        avatar: 'https://robohash.org/tenetursuntfuga.png?size=60x60&set=set1',
    },
    {
        id: 78,
        name: 'Quent Pylkynyton',
        phone: '(221) 7654738',
        email: 'qpylkynyton25@xing.com',
        location: 'Latvia',
        created_on: '7/7/2019',
        status: 'Blocked',
        avatar: 'https://robohash.org/voluptatemetvoluptas.png?size=60x60&set=set1',
    },
    {
        id: 79,
        name: 'Pooh Jakolevitch',
        phone: '(265) 5293308',
        email: 'pjakolevitch26@uiuc.edu',
        location: 'Philippines',
        created_on: '7/16/2019',
        status: 'Active',
        avatar: 'https://robohash.org/doloremollitiaamet.png?size=60x60&set=set1',
    },
    {
        id: 80,
        name: 'Jenda Cranmere',
        phone: '(552) 2051914',
        email: 'jcranmere27@wired.com',
        location: 'France',
        created_on: '7/23/2019',
        status: 'Active',
        avatar: 'https://robohash.org/veritatisrationeesse.png?size=60x60&set=set1',
    },
    {
        id: 81,
        name: "Lacee O'Ruane",
        phone: '(449) 5702030',
        email: 'loruane28@discovery.com',
        location: 'Cyprus',
        created_on: '7/4/2019',
        status: 'Active',
        avatar: 'https://robohash.org/utearem.png?size=60x60&set=set1',
    },
    {
        id: 82,
        name: 'Boris Butterfill',
        phone: '(212) 2329074',
        email: 'bbutterfill29@tripadvisor.com',
        location: 'Russia',
        created_on: '7/17/2019',
        status: 'Active',
        avatar: 'https://robohash.org/liberoutvoluptatum.png?size=60x60&set=set1',
    },
    {
        id: 83,
        name: 'Ralph Jewell',
        phone: '(995) 7684870',
        email: 'rjewell2a@mysql.com',
        location: 'Japan',
        created_on: '7/9/2019',
        status: 'Active',
        avatar: 'https://robohash.org/quiarepellatculpa.png?size=60x60&set=set1',
    },
    {
        id: 84,
        name: 'Claudina Ingry',
        phone: '(247) 3525086',
        email: 'cingry2b@twitpic.com',
        location: 'Norway',
        created_on: '7/3/2019',
        status: 'Blocked',
        avatar: 'https://robohash.org/utadid.png?size=60x60&set=set1',
    },
    {
        id: 85,
        name: 'Emile Klagge',
        phone: '(866) 3362630',
        email: 'eklagge2c@naver.com',
        location: 'Indonesia',
        created_on: '7/13/2019',
        status: 'Blocked',
        avatar: 'https://robohash.org/voluptasminimadeleniti.png?size=60x60&set=set1',
    },
    {
        id: 86,
        name: 'Hedvige Oneal',
        phone: '(736) 1305392',
        email: 'honeal2d@ehow.com',
        location: 'Bulgaria',
        created_on: '7/4/2019',
        status: 'Active',
        avatar: 'https://robohash.org/facereadquis.png?size=60x60&set=set1',
    },
    {
        id: 87,
        name: 'Jordon Morcom',
        phone: '(744) 7480359',
        email: 'jmorcom2e@gov.uk',
        location: 'China',
        created_on: '7/6/2019',
        status: 'Active',
        avatar: 'https://robohash.org/nisicorporisducimus.png?size=60x60&set=set1',
    },
    {
        id: 88,
        name: 'Ilse Ehrat',
        phone: '(105) 1189583',
        email: 'iehrat2f@rakuten.co.jp',
        location: 'Indonesia',
        created_on: '7/1/2019',
        status: 'Blocked',
        avatar: 'https://robohash.org/voluptatemsintlibero.png?size=60x60&set=set1',
    },
    {
        id: 89,
        name: 'Josephina Begwell',
        phone: '(553) 8283922',
        email: 'jbegwell2g@macromedia.com',
        location: 'Indonesia',
        created_on: '7/13/2019',
        status: 'Active',
        avatar: 'https://robohash.org/quaerathicofficiis.png?size=60x60&set=set1',
    },
    {
        id: 90,
        name: 'Nichole Conti',
        phone: '(599) 3845146',
        email: 'nconti2h@foxnews.com',
        location: 'China',
        created_on: '7/9/2019',
        status: 'Blocked',
        avatar: 'https://robohash.org/estametminus.png?size=60x60&set=set1',
    },
    {
        id: 91,
        name: 'Carmelle Lartice',
        phone: '(576) 2234738',
        email: 'clartice2i@bing.com',
        location: 'Albania',
        created_on: '7/27/2019',
        status: 'Active',
        avatar: 'https://robohash.org/etreiciendiseligendi.png?size=60x60&set=set1',
    },
    {
        id: 92,
        name: 'Micheline Bristow',
        phone: '(373) 8682504',
        email: 'mbristow2j@netvibes.com',
        location: 'Brazil',
        created_on: '7/22/2019',
        status: 'Blocked',
        avatar: 'https://robohash.org/voluptascumquesint.png?size=60x60&set=set1',
    },
    {
        id: 93,
        name: 'Fairleigh Zanazzi',
        phone: '(310) 1842198',
        email: 'fzanazzi2k@columbia.edu',
        location: 'Greece',
        created_on: '7/24/2019',
        status: 'Active',
        avatar: 'https://robohash.org/dolorummaximedolorem.png?size=60x60&set=set1',
    },
    {
        id: 94,
        name: 'Rubin Steinor',
        phone: '(121) 1146301',
        email: 'rsteinor2l@wikispaces.com',
        location: 'Indonesia',
        created_on: '7/16/2019',
        status: 'Blocked',
        avatar: 'https://robohash.org/estdolorevel.png?size=60x60&set=set1',
    },
    {
        id: 95,
        name: 'Cristionna Casley',
        phone: '(937) 8216940',
        email: 'ccasley2m@is.gd',
        location: 'Brazil',
        created_on: '7/6/2019',
        status: 'Active',
        avatar: 'https://robohash.org/eavoluptatemvoluptate.png?size=60x60&set=set1',
    },
    {
        id: 96,
        name: 'Osgood Hearst',
        phone: '(620) 7501412',
        email: 'ohearst2n@mail.ru',
        location: 'Bulgaria',
        created_on: '7/12/2019',
        status: 'Active',
        avatar: 'https://robohash.org/reprehenderitametsit.png?size=60x60&set=set1',
    },
    {
        id: 97,
        name: 'Demetre Hackelton',
        phone: '(190) 6535105',
        email: 'dhackelton2o@irs.gov',
        location: 'South Korea',
        created_on: '7/8/2019',
        status: 'Blocked',
        avatar: 'https://robohash.org/corruptihiccum.png?size=60x60&set=set1',
    },
    {
        id: 98,
        name: 'Flora Reek',
        phone: '(198) 7389568',
        email: 'freek2p@reverbnation.com',
        location: 'Philippines',
        created_on: '7/27/2019',
        status: 'Blocked',
        avatar: 'https://robohash.org/commodisequiveniam.png?size=60x60&set=set1',
    },
    {
        id: 99,
        name: 'Myrle Ramel',
        phone: '(270) 9937347',
        email: 'mramel2q@thetimes.co.uk',
        location: 'Philippines',
        created_on: '7/12/2019',
        status: 'Blocked',
        avatar: 'https://robohash.org/sittemporamollitia.png?size=60x60&set=set1',
    },
    {
        id: 100,
        name: 'Gabbie Hayers',
        phone: '(824) 3423385',
        email: 'ghayers2r@oaic.gov.au',
        location: 'Cuba',
        created_on: '7/24/2019',
        status: 'Active',
        avatar: 'https://robohash.org/expeditaeiuseaque.png?size=60x60&set=set1',
    },
];

export { customers };
