import * as Request from "../Request";
export interface addressDefaultCustomer {
  id: string
  customer_id: string
  fullname: string
  phone: string
  address: string,
  Unit: string,
  city: string,
  zipcode: string
  is_default: number
  lat: number
  lng: number
}
export interface Result<T> {
  status: number,
  message: string,
  data: T,
  idAddress: string,
}

export const addAddressCustomer = async (customer_id: string, fullname: string, phone: string, address: string, is_default: number, lat: number, lng: number) => {
  return await Request.fetchAPI(`checkin/addAddressCustomer.php`, "POST", { customer_id, fullname, phone, address, is_default, lat, lng })
    .then((res: Result<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: undefined,
        idAddress: ''
      };
    });
};
export const gAddressDefaultCustomer = async (customer_id: string) => {
  return await Request.fetchAPI(`checkin/gAddressDefaultCustomer.php`, "POST", { customer_id })
    .then((res: Result<addressDefaultCustomer>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: undefined,
      };
    });
};
export const gListAddressCustomer = async (customer_id: string) => {
  return await Request.fetchAPI(`checkin/gListAddressCustomer.php`, "POST", { customer_id })
    .then((res: Result<addressDefaultCustomer[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};
export const gAddressCustomerById = async (address_id: string) => {
  return await Request.fetchAPI(`checkin/gAddressCustomerById.php`, "POST", { address_id })
    .then((res: Result<addressDefaultCustomer>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: undefined,
      };
    });
};
export const delAddress = async (address_id: string) => {
  return await Request.fetchAPI(`checkin/delAddress.php`, "POST", { address_id })
    .then((res: Result<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: undefined,
      };
    });
};
export const setAddressDefault = async (customer_id: number, address_id: string) => {
  return await Request.fetchAPI(`checkin/setAddressDefault.php`, "POST", { customer_id, address_id })
    .then((res: Result<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: undefined,
      };
    });
};