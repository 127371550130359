import { useNavigate } from "react-router-dom";
import { arrowLeftPage, arrowRightPage, calender } from "../../components/ImgExport";
import DateRangePicker from "react-bootstrap-daterangepicker";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useEffect, useState } from "react";
import * as devicePos from "../../api/apiAdmin/devicePos";
import moment from "moment";
import Select2 from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { FormatDolla } from "../../utils/format";
import InforDeviceOrder from "./InforDeviceOrder";
import { getLanguageCheckIn } from "../../Services/languageCheckin";
import React from "react";

const DeviceCustomerDetail = () => {
  const navigate = useNavigate()
  const languageUI = getLanguageCheckIn();
  const [listBuyDevices, setListDevices] = useState<devicePos.BuyDevice[]>([])
  const [pageCurrent, setPageCurrent] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1)
  // const [totalRow, setTotalRow] = useState<number>(1)
  const [pageGoTo, setPageGoTo] = useState<number>();
  const [limitRow, setLimitRow] = useState<number>(50);
  const [searchstart, setSearchstart] = useState<any>('')//ngày bắt đầu
  const [searchend, setSearchend] = useState<any>('')//ngày kết thúc
  const [showModalInforDeviceOrder, setShowModalInforDeviceOrder] = useState(false);
  const [idOrder, setIdOrder] = useState('')

  const dismisModalInforDeviceOrder = () => {
    setShowModalInforDeviceOrder(false)
  }
  const optionPage = [
    {
      value: 50,
      page: `${languageUI.Show} 50 / ${languageUI.Page}`
    },
    {
      value: 100,
      page: `${languageUI.Show} 100 / ${languageUI.Page}`
    },
    {
      value: 0,
      page: `${languageUI.Show} ${languageUI.All}`
    },
  ]
  const gListDeviceBoughtHistory = async (page: number, limit: number, datestart: string = '', dateend: string = '') => {
    const reqCus = await devicePos.gListDeviceBoughtHistory(datestart, dateend, page, limit)
    if (Number(reqCus?.status) === 1) {
      setListDevices(reqCus?.data ?? [])
      setTotalPage(reqCus?.totalPage ? Number(reqCus?.totalPage) : 1)
      // setTotalRow(reqCus?.total ? Number(reqCus?.total) : 1)
    } else {
    }
  }
  const handleApply = (event: any, picker: any) => {
    var start = picker.startDate.format('YYYY-MM-DD')
    var end = picker.endDate.format('YYYY-MM-DD')
    setSearchstart?.(start)
    setSearchend?.(end)
    picker.element.val(
      picker.startDate.format('DD/MM/YYYY') +
      ' - ' +
      picker.endDate.format('DD/MM/YYYY')
    );

    setPageCurrent(1)
    if (pageCurrent === 1) {
      gListDeviceBoughtHistory(1, limitRow, start, end)
    }
  };
  const handleCancel = (event: any, picker: any) => {
    picker.element.val('');
    setSearchstart?.("")
    setSearchend?.("")

    setPageCurrent(1)
    if (pageCurrent === 1) {
      gListDeviceBoughtHistory(1, limitRow, '', '')
    }
  };

  useEffect(() => {
    setPageGoTo(pageCurrent)
    gListDeviceBoughtHistory(pageCurrent, limitRow);
  }, [pageCurrent, limitRow])
  const handleChangeShowRow = (value: number) => {
    setLimitRow(value)
    setPageCurrent(1)
  }
  const pagingRender = () => {

    if (totalPage <= 5) {
      let pagelist = [];
      for (let index = 1; index <= totalPage; index++) {
        pagelist.push(<button key={'page' + index} className={` ${pageCurrent === index ? "btn-page-customer-receipts-target" : "btn-page-customer-receipts"}`} onClick={() => { setPageCurrent(index) }}>
          {index}
        </button>)
      }
      return pagelist
    } else {
      let pagelist = [];
      if (pageCurrent <= 4) {
        for (let index = 1; index <= 5; index++) {
          pagelist.push(<button key={'page' + index} className={` ${pageCurrent === index ? "btn-page-customer-receipts-target" : "btn-page-customer-receipts"}`} onClick={() => { setPageCurrent(index) }}>
            {index}
          </button>)
        }
        pagelist.push(<button key={'page...'} className={`btn-page-customer-receipts`}>
          ...
        </button>)
        pagelist.push(<button key={'page' + totalPage} className={` ${pageCurrent === totalPage ? "btn-page-customer-receipts-target" : "btn-page-customer-receipts"}`} onClick={() => { setPageCurrent(totalPage) }}>
          {totalPage}
        </button>)
      } else {//p=5
        var dem = 0;
        for (let index = pageCurrent - 3; index <= (totalPage - pageCurrent > 4 ? pageCurrent + 3 : totalPage - 1); index++) {
          if (dem === 0) {
            pagelist.push(<button key={'page1'} className={` ${pageCurrent === 1 ? "btn-page-customer-receipts-target" : "btn-page-customer-receipts"}`} onClick={() => { setPageCurrent(1) }}>
              {1}
            </button>)
            pagelist.push(<button key={'page...2'} className={`btn-page-customer-receipts`}>
              ...
            </button>)
            dem++;
          }
          pagelist.push(<button key={'page' + index} className={` ${pageCurrent === index ? "btn-page-customer-receipts-target" : "btn-page-customer-receipts"}`} onClick={() => { setPageCurrent(index) }}>
            {index}
          </button>)

        }
        if (totalPage - pageCurrent > 4) {

          pagelist.push(<button key={'page...3'} className={`btn-page-customer-receipts`}>
            ...
          </button>)
          pagelist.push(<button key={'page' + totalPage} className={` ${pageCurrent === totalPage ? "btn-page-customer-receipts-target" : "btn-page-customer-receipts"}`} onClick={() => { setPageCurrent(totalPage) }}>
            {totalPage}
          </button>)
        } else {
          pagelist.push(<button key={'page' + totalPage} className={` ${pageCurrent === totalPage ? "btn-page-customer-receipts-target" : "btn-page-customer-receipts"}`} onClick={() => { setPageCurrent(totalPage) }}>
            {totalPage}
          </button>)
        }
      }

      return pagelist;
    }
    // return pagelist;

  }
  return (
    <div className="pt-70px">
      <div className="w-100 flex items-center py-4 px-4 gap-2">
        <button className="px-4 py-2 rounded-4 bg-green-light border-primary text-white font-bold w-fit" onClick={() => navigate('/web-admin/device-customer')}>
          <ArrowBackIcon className="text-212134" />
        </button>
        <span className="text-8 fw-600 font-urbansist mr-6 text-212134">{languageUI.Filter}</span>
        <div className="d-flex align-items-center border px-2 rounded-8px h-42px  w-200 gap-2 bg-white">
          <img src={calender} alt="" />
          <div className="w-100">
            <div className="form-group">
              <DateRangePicker

                initialSettings={{
                  // startDate: new Date(),
                  showDropdowns: true,
                  autoUpdateInput: false,
                  locale: {
                    cancelLabel: 'Reset',
                    applyLabel: "Apply"
                  }
                }}
                onApply={handleApply}
                onCancel={handleCancel}
              >
                <input type="text" className="form-control input-daterange-timepicker border-none w-195px" placeholder={languageUI.Total} value={searchstart !== "" && searchend !== "" ? (moment(searchstart).format('DD/MM/YYYY') + "-" + moment(searchend).format('DD/MM/YYYY')) : ""} readOnly={true} />
              </DateRangePicker>
            </div>
          </div>
        </div>
      </div>
      <div className="table-device-customer">
        <table className="table  p-0">
          <thead>
            <tr className="bg-primary">
              <th scope="col" className="w-15 bg-primary">
                <div className="flex justify-between  items-center">
                  <span className="text-sm text-white fw-600 font-urbansist mr-3">{languageUI.Contactperson}</span>
                </div>
              </th>
              <th scope="col" className="w-10 bg-primary">
                <div className="flex justify-between  items-center">
                  <span className="text-sm text-white fw-600 font-urbansist mr-3">{languageUI.Storename}</span>
                </div>
              </th>
              <th scope="col" className="w-20 bg-primary">
                <div className="flex justify-between  items-center">
                  <span className="text-sm text-white fw-600 font-urbansist mr-3">{languageUI.Shippiingaddress}</span>
                </div>
              </th>
              <th scope="col" className="w-10 bg-primary">
                <div className="flex justify-between  items-center ">
                  <span className="text-sm text-white fw-600 font-urbansist mr-3">{languageUI.Cellphone}</span>
                </div>
              </th>
              <th scope="col" className="w-10 bg-primary">
                <div className="flex justify-between  items-center">
                  <span className="text-sm text-white fw-600 font-urbansist mr-3">{languageUI.Device}</span>
                </div>
              </th>
              <th scope="col" align="center" className="w-10 bg-primary text-center">
                <span className="text-sm text-white fw-600 font-urbansist mr-3 text-center">Package</span>
              </th>
              <th scope="col" align="center" className="w-10 bg-primary text-center">
                <span className="text-sm text-white fw-600 font-urbansist mr-3 text-center">{languageUI.Total}</span>
              </th>
              <th scope="col" align="center" className="w-10 bg-primary text-center">
                <span className="text-sm text-white fw-600 font-urbansist mr-3 text-center">{languageUI.Date}</span>
              </th>
              <th scope="col" align="center" className="w-5 bg-primary text-center">
                <span className="text-sm text-white fw-600 font-urbansist mr-3 text-center"></span>
              </th>
            </tr>
          </thead>
          <tbody className=" w-100">
            {listBuyDevices.map((item, i) => (
              <tr className="w-100" key={i}>
                <td className="border-bottom-none text-sm text-4a4a6a">{item?.contact_person}</td>
                <td className="border-bottom-none text-sm text-4a4a6a">{item?.name}</td>
                <td className="border-bottom-none text-sm text-4a4a6a">{item?.suitShipping + " " + item?.shippingAddress}</td>
                <td className="border-bottom-none text-sm text-4a4a6a">{item?.sellPhone}</td>
                <td className="border-bottom-none text-sm text-4a4a6a">
                  <div>
                    {item?.list_device && item?.list_device.map((dev, j) => (
                      <p className="text-sm text-4a4a6a mb-1" key={'dev' + j}>{j + 1}.{dev.title} x{dev.quantity}</p>
                    ))}
                  </div>
                </td>
                <td className="border-bottom-none text-sm text-4a4a6a">
                  <div className="text-center">
                    <p className="text-sm text-4a4a6a mb-1 text-center">{item?.package}</p>
                    <p className="text-sm text-4a4a6a mb-1 text-center">{item?.cms} SMS/1 {languageUI.month}</p>
                  </div>
                </td>
                <td className="border-bottom-none text-sm text-4a4a6a text-center">${FormatDolla(Number(item?.price))}</td>
                <td className="border-bottom-none text-sm text-4a4a6a text-center">{item?.timestamp}</td>
                <td className="border-bottom-none text-sm text-center"><u className="text-blue" onClick={() => { setIdOrder(item?.id); setShowModalInforDeviceOrder(true) }}>{languageUI.Detail}</u></td>
              </tr>
            ))}
            {/* <tr className="w-100" >
              <td className="border-bottom-none text-sm text-4a4a6a">Tưởng Vũ</td>
              <td className="border-bottom-none text-sm text-4a4a6a">Brijack</td>
              <td className="border-bottom-none text-sm text-4a4a6a">162 tam giang, thị trấn sịa, huyện Quảng Điền, tỉnh Thừa Thiên Huế</td>
              <td className="border-bottom-none text-sm text-4a4a6a">0367393354</td>
              <td className="border-bottom-none text-sm text-4a4a6a">
                <div>
                  <p className="text-sm text-4a4a6a mb-1">1.Printer x3</p>
                  <p className="text-sm text-4a4a6a mb-1">1.Printer x3</p>
                </div>
              </td>
              <td className="border-bottom-none text-sm text-4a4a6a">
                <div className="text-center">
                  <p className="text-sm text-4a4a6a mb-1 text-center">Monthly Super</p>
                  <p className="text-sm text-4a4a6a mb-1 text-center">9000 SMS/1 month</p>
                </div>
              </td>
              <td className="border-bottom-none text-sm text-4a4a6a text-center">900$</td>
              <td className="border-bottom-none text-sm text-4a4a6a text-center">17/10/2001</td>
              <td className="border-bottom-none text-sm text-center"><u className="text-blue" onClick={() => navigate('/web-admin/infor-device-order')}>Detail</u></td>


            </tr> */}
          </tbody>
        </table>
        <div className="w-100 h-66px d-flex justify-content-center align-items-center border-top">
          <div className="d-flex h-fit d-flex  align-items-center">
            <p className="mb-0 h-fit text-page-table-bottom">{languageUI.Total} {totalPage} {languageUI.Page}</p>
            <button className="btn-page-customer-receipts" onClick={() => {
              pageCurrent > 1 ? setPageCurrent(pageCurrent - 1) : setPageCurrent(1);
            }}>
              <img src={arrowLeftPage} alt="" />
            </button>
            {pagingRender()}


            <button className="btn-page-customer-receipts" onClick={() => {
              pageCurrent !== totalPage ? setPageCurrent(pageCurrent + 1) : setPageCurrent(totalPage);
            }}>
              <img src={arrowRightPage} alt="" />
            </button>
            <div className="d-flex align-items-center border  rounded-8px h-42px mx-4">
              <Select2
                className='h-42px p-0'
                labelId="demo-simple-select-label"
                id="demo-simple-select-5"
                placeholder='Choose voucher ID'
                value={limitRow}
                onChange={(e: any) => handleChangeShowRow(Number(e.target.value))}

              >
                {optionPage.map((item, i) => (
                  < MenuItem key={i} value={item?.value} > {item.page}</MenuItem>

                ))}
              </Select2>
            </div>
            <p className="mb-0 text-page-table-bottom me-2">{languageUI.Gotopage}</p>
            <div className="input-text-page">
              <input type="number" className="w-100 text-page-table-bottom border-none px-2"
                readOnly={totalPage === 1}
                value={pageGoTo}
                onChange={(e) => {
                  if (e.target.value && Number(e.target.value) > 0) {
                    setPageGoTo(Number(e.target.value))
                  } else {
                    setPageGoTo(undefined)
                  }
                }}
                onBlur={(e) => {
                  setPageCurrent(Number(e.target.value) <= 0 ? 1 : Number(e.target.value) > totalPage ? totalPage : Number(e.target.value));
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <InforDeviceOrder handleClose={dismisModalInforDeviceOrder} show={showModalInforDeviceOrder} id={idOrder} />
    </div >
  )
};

export default DeviceCustomerDetail;
