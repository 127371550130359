import React, { useEffect, useState } from "react"
import { Modal, } from "react-bootstrap";
import './styles.css'
import { ArrowBack, LocationOnOutlined, MoreVert, Person2Outlined, PhoneInTalkOutlined } from "@mui/icons-material";
import { addressDefaultCustomer, delAddress, gListAddressCustomer, setAddressDefault } from "../../../../api/apiCheckin/addressApi";
import { Button, Popover, Snackbar } from "@mui/material";
import ModalLoading from "../../../../components/ModalLoading";
import { getLanguageCheckIn } from "../../../../Services/languageCheckin";
interface ModalChooseAddressProps {
  show: boolean;
  handleClose: () => void
  customer_id: string,
  idAddressSuccess: string,
  handleSuccess: (id: string) => void
}
const ModalChooseAddress: React.FC<ModalChooseAddressProps> = ({ handleClose, show, customer_id, idAddressSuccess, handleSuccess }) => {
  const [activeAddress, setActiveAddress] = useState('')
  const languageUI = getLanguageCheckIn();
  const [listAddress, setListAddress] = useState<addressDefaultCustomer[]>([])
  const [open, setOpen] = React.useState<string>('');
  const [openToast, setOpenToast] = React.useState<boolean>(false);
  const [showModalLoading, setShowModalLoading] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, id: string) => {
    setAnchorEl(event.currentTarget);
    setOpen(id)
  };
  const hideModalLoading = () => {
    setShowModalLoading(false)
  }
  const ListAddressCustomer = async () => {
    setShowModalLoading(true)
    const res = await gListAddressCustomer(customer_id)
    if (Number(res.status) === 1) {
      setListAddress(res.data)
      setShowModalLoading(false)
    }
  }
  const deleteAddress = async (idAddress: string) => {
    setShowModalLoading(true)
    const res = await delAddress(idAddress)
    if (Number(res.status) === 1) {
      ListAddressCustomer()
      setShowModalLoading(false)
    } else {
      setShowModalLoading(false)
      setOpenToast(true)
    }
  }
  const setDefaultInfo = async (idAddress: string) => {
    setShowModalLoading(true)
    const res = await setAddressDefault(Number(customer_id), idAddress)
    if (Number(res.status) === 1) {
      ListAddressCustomer()
      setShowModalLoading(false)
    }
  }
  useEffect(() => {
    ListAddressCustomer()
    setActiveAddress(idAddressSuccess)
  }, [show])
  useEffect(() => {
    if (openToast) {
      setTimeout(() => {
        setOpenToast(false)
      }, 3000)
    }
  }, [openToast])


  return (
    <Modal show={show} fullscreen={true} onHide={handleClose}>
      <ModalLoading onHide={hideModalLoading} show={showModalLoading} />
      <Modal.Header className="shadow-header border-bottom">
        <div className=" px-4 py-2 rounded-6 border-FF7B2C" onClick={handleClose}>
          <ArrowBack className="text-FF7B2C" />
        </div>
        <Modal.Title className="text-center text-414151f font-bold text-20px">{languageUI.ChooseAddress}</Modal.Title>
        <div className=" px-4 py-2 rounded-6 border-FF7B2C opacity-0" >
          <ArrowBack className="text-FF7B2C" />
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column gap-2">
          {listAddress.map((item) => (
            <div className={`rounded-4 bg-f0f0f0 p-3 d-flex justify-content-between align-items-start ${activeAddress === item.id ? 'border-FF7B2C' : 'border'}`} key={`address ${item.id}`} onClick={() => { setActiveAddress(item.id) }}>
              <div>
                <div className="d-flex gap-2 align-items-center">
                  <Person2Outlined className="text-4a4a6a" />
                  <p className="text-number-order m-0">{item.fullname}</p>
                </div>
                <div className="d-flex gap-2 align-items-center mt-1">
                  <PhoneInTalkOutlined className="text-4a4a6a" />
                  <p className="text-number-order m-0">{item.phone}</p>
                </div>
                <div className="d-flex gap-2 align-items-start mt-1">
                  <LocationOnOutlined className="text-4a4a6a" />
                  <p className="text-number-order m-0">{item.address}</p>
                </div>
              </div>
              <Button aria-describedby={`popover-option-${item.id}`} onClick={(e) => handleClick(e, item.id)}>
                <MoreVert />
              </Button>
              <Popover
                id={`popover-option-${item.id}`}
                anchorEl={anchorEl}
                open={open === item.id ? true : false}
                onClose={() => { setOpen('') }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <div className="p-3 d-flex flex-column gap-2">
                  <Button className="bg-primary w-100"><p className="m-0 text-white" onClick={() => { setDefaultInfo(item.id); setOpen('') }}>{languageUI.Setasdefault}</p></Button>
                  <Button className="bg-delete w-100"><p className="m-0 text-white" onClick={() => { deleteAddress(item.id); setOpen('') }}>{languageUI.Delete}</p></Button>
                </div>
              </Popover>
              {/* <Popover
                id={`popover-option-${item.id}`}
                open={openPopover}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <Typography sx={{ p: 2 }}>The content of the Popover.</Typography>
              </Popover> */}

              {/* <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title="I am tooltip"
                open={showTooltip === item.id ? true : false}
                onClose={() => setShowTooltip('')}
              >
                <Button onClick={() => { setShowTooltip(item.id) }}><MoreVert /></Button>
              </Tooltip> */}
            </div>
          ))}
          <div>


          </div>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={openToast}
            message={languageUI.Cannotdeletedefaultaddress}
            color="white"

          />
        </div>

      </Modal.Body>
      <Modal.Footer className="box-shadow-footer">
        <div className=" d-flex gap-3 align-items-center w-100">
          <button className="custom-filter-btn border-none w-100 text-ffffff" onClick={() => { handleSuccess(activeAddress) }}>
            {languageUI.confirm}
          </button>
        </div>
      </Modal.Footer>
    </Modal >
  )
}
export default ModalChooseAddress