import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DvrOutlinedIcon from "@mui/icons-material/DvrOutlined";
import "./Styles.css";
import { gListPosByOwner, ListPOS } from "../../api/apiOwner/PosAPI";
import { useSelector } from "react-redux";
import * as userSlice from "../../store/slices/userSlice";
import ModalLoading from "../../components/ModalLoading";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { gDefaultHsnByPos } from "../../api/apiOwner/accountHandheldApi";

interface PosItemProps {
  namePos: string;
  status: number;
  handleSelect: () => void;
}
const PosItem: React.FC<PosItemProps> = ({ handleSelect, namePos, status }) => {
  return (
    <div className="pos-item">
      <div className="w-100 d-flex justify-content-center">
        <DvrOutlinedIcon className="icon-pos" />
      </div>
      <div className="d-flex justify-content-between align-items-center mb-1 mt-2">
        <p className="text-name-pos">Name:</p>
        <p className="text-content-pos">{namePos}</p>
      </div>
      {status === 1 && (
        <div className="d-flex justify-content-between align-items-center important-main-pos">
          Important: The main station, which receives online orders and forwards
          them to the printer, should always be kept on to avoid disrupting the
          order printing process.
        </div>
      )}
      {/* <div className="d-flex justify-content-between align-items-center">
        <p className="text-name-pos">Status:</p>
        <p className="text-content-pos">
          {status === 1 ? "Main POS" : "Auxiliary POS"}
        </p>
      </div> */}
      <button className="button-pos-item" onClick={handleSelect}>
        Select
      </button>
    </div>
  );
};

const ChossePOS: React.FC = () => {
  const owner_id = useSelector(userSlice.selectorUserId);
  const pos_id = useSelector(userSlice.selectorPosId);
  const [listPOSOwner, setListPOSOwner] = useState<ListPOS[]>([]);
  const [ShowLoading, setShowLoading] = useState<boolean>(false);
  const [messErr, setMessErr] = useState<string>("");
  const dispatchUser = useDispatch();
  const navigate = useNavigate();

  const getListPosByOwner = async () => {
    setShowLoading(true);
    if (owner_id) {
      const req = await gListPosByOwner(owner_id);
      if (req?.status === 1) {
        if (req.data?.length === 1) {
          dispatchUser(userSlice.setPosName(req.data[0].pos_name));
          dispatchUser(userSlice.setPosStatus(req.data[0].status));
          dispatchUser(userSlice.setPosId(req.data[0].pos_id));
          gDefaultHsn(req.data[0].pos_id)

        } else {
          setListPOSOwner(req?.data ?? []);
        }
      } else {
        setMessErr(req.message ?? "");
      }
    }
    setShowLoading(false);
  };
  const gDefaultHsn = async (pos: number) => {
    if (pos && owner_id) {
      setShowLoading(true)
      const req = await gDefaultHsnByPos(owner_id, pos);
      if (req?.status === 1) {
        dispatchUser(userSlice.setHsnDefault(req?.data?.hsn_id ?? 0))
        setTimeout(() => {
          setShowLoading(false)
          navigate("/web-owner/tablepos");
        }, 1000)
      } else {
        dispatchUser(userSlice.setHsnDefault(0))
        setTimeout(() => {
          setShowLoading(false)
          navigate("/web-owner/tablepos");
        }, 1000)
      }
    }
  }
  useEffect(() => {
    if (pos_id) {
      gDefaultHsn(pos_id)
    } else {
      getListPosByOwner();
    }
  }, []);
  return (
    <div className="w-100 h-100">
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
      <div className="">
        <div className="position-relative">
          <div className={`header-kitchen-counter`}>
            <div className="d-flex align-items-center gap-1">
              <button
                onClick={() => navigate("/auth/logout")}
                className="btn-back-kitchen-counter"
              >
                <ArrowBackIcon className="me-1" />
                Back
              </button>
            </div>
          </div>
        </div>
        <div className="page-choose-pos ">
          {listPOSOwner.map((item, index) => (
            <PosItem
              handleSelect={() => {
                dispatchUser(userSlice.setPosName(item.pos_name));
                dispatchUser(userSlice.setPosStatus(item.status));
                dispatchUser(userSlice.setPosId(item?.pos_id));

                gDefaultHsn(item?.pos_id)
              }}
              namePos={item.pos_name}
              status={item.status}
              key={index}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ChossePOS;
