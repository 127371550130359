import * as Request from "../Request";

export interface Result<T> {
    status: string,
    message: string,
    data: T,
}
export interface ConfigDoordash {
    id: string,
    developer_id: string,
    key_id: string,
    signing_secret: string,
    owner_id: string
}

export const gConfigDoordash = async (owner_id: number) => {
    return await Request.fetchAPI(`owner/gConfigDoordash.php`, "POST", { owner_id })
        .then((res: Result<ConfigDoordash>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: '0',
                data: undefined,
            };
        });
};

export const AddConfigDoordash = async (owner_id: number, developer_id: string, key_id: string, signing_secret: string) => {
    return await Request.fetchAPI(`owner/addConfigDoordash.php`, "POST", { owner_id, developer_id, key_id, signing_secret })
        .then((res: Result<string>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: '0',
                data: '',
            };
        });
};