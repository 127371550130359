import { Modal } from "react-bootstrap";
import "./Styles.css";
import { handClick, leaf1, leaf2 } from "../../../../components/ImgExport";
import OtpInput from "react18-input-otp";
import { useEffect, useState } from "react";
import * as PasscodeApi from "../../../../api/apiOwner/PasscodeApi";
import ModalLoading from "../../../../components/ModalLoading";
import $ from "jquery";
import { getLanguageUI } from "../../../../Services/languageUI";
import BackspaceIcon from "@mui/icons-material/Backspace";

interface ModalOTPLogOutProps {
  show: boolean;
  UserIdStore?: number | null;
  handleClose: (status?: boolean) => void;
  handleSuccess?: (status?: boolean) => void;
  // setIsOwner?: (e: boolean) => void;
}

const ModalOTPLogOut = ({
  show,
  UserIdStore,
  handleClose,
  handleSuccess,
}: // setIsOwner
ModalOTPLogOutProps) => {
  const languageUI = getLanguageUI().layout;
  const [passCode, setPassCode] = useState("");
  const [ShowLoading, setShowLoading] = useState<boolean>(false);
  const [messageError, setMessageError] = useState("");
  const handleChange = (enteredOtp: string) => {
    setPassCode(enteredOtp);
  };
  const setClockIn = async () => {
    if (passCode.length !== 4) {
      setMessageError("Please enter passcode!");
    } else if (UserIdStore) {
      setShowLoading(true);
      const resStatus = await PasscodeApi.checkPasscode(UserIdStore, passCode);
      if (Number(resStatus.status) === 1) {
        if (Number(resStatus?.role) === 1) {
          // setIsOwner?.(true)
          localStorage.setItem("isOwner", "1");
          setShowLoading(false);
        } else {
          // setIsOwner?.(false)
          localStorage.setItem("isOwner", "0");
          setShowLoading(false);
        }
        handleClose();
        handleSuccess?.();
      } else {
        setMessageError(resStatus?.message ?? "");
        setShowLoading(false);
      }
    }
  };

  const renderElementPasscode = () => {
    const items = [];
    for (let i = 1; i < 10; i++) {
      items.push(
        <button
          className="keyboard-passcode"
          key={i}
          onClick={() => handleEnterPasscode(String(i))}
        >
          {i}
        </button>
      );
    }
    return items;
  };

  const handleEnterPasscode = (value: string) => {
    if (passCode.length < 4) {
      setPassCode(passCode + value);
    }
  };
  const deletePasscode = () => {
    setPassCode(passCode.slice(0, -1));
  };
  const deleteAllPasscode = () => {
    setPassCode("");
  };
  useEffect(() => {
    console.log(passCode);
  }, [passCode]);
  useEffect(() => {
    const lengthInput = $(".otp input");

    if (lengthInput != null) {
      lengthInput.trigger("focus");
    }
    if (show) {
      setMessageError("");
      setPassCode("");
    }
  }, [show]);
  return (
    <>
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
      <Modal
        show={show}
        onHide={handleClose}
        className=" border-modal-16px modal-clock-in-out"
      >
        <Modal.Dialog className="w-100">
          <Modal.Body className="p-0 ">
            <div className="position-relative px-3 d-flex align-items-center flex-column">
              <div className="text-center position-relative z-10">
                <p className="text-delete-modal-customer mb-1 text-center px-4">
                  {languageUI.toOwnerLogOut}
                </p>
                <p className="text-pass-code-clock px-4">
                  {languageUI.enterPasscode}
                </p>
                <p className="text-center text-danger text-italic mb-1">
                  {messageError}
                </p>
              </div>
              <div
                className={`otp-input ${
                  messageError !== "" ? "border-red" : ""
                } mb-2 mt-2 d-flex justify-content-between w-100 px-4`}
              >
                <OtpInput
                  value={passCode}
                  onChange={handleChange}
                  numInputs={4}
                  isInputNum={true}
                  className="otp"
                  shouldAutoFocus={true}
                  isDisabled
                />
              </div>
              <div className="d-flex gap-4 align-items-center">
                <div className="box-keyboard-passcode">
                  {renderElementPasscode()}
                  <button
                    className="keyboard-passcode"
                    onClick={deleteAllPasscode}
                  >
                    C
                  </button>
                  <button
                    className="keyboard-passcode"
                    onClick={() => handleEnterPasscode(String(0))}
                  >
                    0
                  </button>
                  <button
                    className="keyboard-passcode"
                    onClick={deletePasscode}
                  >
                    <BackspaceIcon />
                  </button>
                </div>
                <button
                  className="btn-check-in-out d-flex flex-column justify-content-center mt-3 align-items-center bg-btn-check-in-clock"
                  onClick={setClockIn}
                >
                  <img src={handClick} alt="" className="mb-1" />
                  {languageUI.click}
                </button>
              </div>
              <img src={leaf1} alt="" className="img-leaf1-otp-owner" />
              <img src={leaf2} alt="" className="img-leaf2-otp-owner" />
            </div>
          </Modal.Body>
        </Modal.Dialog>
      </Modal>
    </>
  );
};

export default ModalOTPLogOut;
