
import { Modal } from "react-bootstrap";
import "./Styles.css"
import { getLanguageCheckIn } from '../../Services/languageCheckin';



interface ModalCheckedSuccessProps {
  show: boolean;
  dismisImg?: boolean;
  title: string;
  img: string;
  handleClose: () => void;
  handleSuccess?: () => void;
}

const ModalCheckedSuccess = ({
  show,
  dismisImg = false,
  handleClose,
  handleSuccess,
  img,
  title
}: ModalCheckedSuccessProps) => {
  const languageUI = getLanguageCheckIn();
  return (
    <Modal show={show} onHide={handleClose} centered className="modal-alert modal-checked-success">
      <Modal.Dialog className="bg-gray-light">
        <Modal.Body className='abc'>
          <div className="d-flex justify-content-center flex-column align-items-center">
            <div className="rounded-5  p-2 d-flex flex-column align-items-center ">
              <img src={img} alt="" className={` aspect-ratio-1 rounded-circle ${dismisImg ? "d-none" : ""}`} />
              <p className="py-1  text-4a4a6a text-2xl px-3 font-bold">{title}</p>
            </div>
            <div className=" py-1 bg-primary text-white text-xl px-6 font-bold rounded-5" onClick={handleSuccess}>
              {languageUI.submit}
            </div>
          </div>
        </Modal.Body>
      </Modal.Dialog>

    </Modal>
  );
};

export default ModalCheckedSuccess;
