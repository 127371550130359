import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface StaffJoint {
  staff_id: number;
  second_allowed: number;
  count_second: number;
  type_display: number;
  staff_name: string;
}

const initialStaffJointState: StaffJoint = {
  staff_id: 0,
  second_allowed: 0,
  type_display: 1,
  count_second: 0,
  staff_name: "",
};

const StaffJointSlice = createSlice({
  name: "staffjoint",
  initialState: initialStaffJointState,
  reducers: {
    setStaffJoint: (state, action: PayloadAction<StaffJoint>) => action.payload,
    IncrementSecond: (state, action: PayloadAction<number>) => {
      state.count_second = state.count_second + 1;
    },
    setTypeDisplay: (state, action: PayloadAction<number>) => {
      state.type_display = action.payload;
    },
    setSecondAllowed: (state, action: PayloadAction<number>) => {
      state.second_allowed = action.payload;
    },
    clearStaffJoint: (state) => initialStaffJointState,
  },
});
export const selectorStaffJoint = (state: { staffjoint: StaffJoint }) =>
  state.staffjoint;
export const selectorTypeDisplay = (state: { staffjoint: StaffJoint }) =>
  state.staffjoint.type_display;
export const selectorStaffId = (state: { staffjoint: StaffJoint }) =>
  state.staffjoint.staff_id;
export const selectorStaffName = (state: { staffjoint: StaffJoint }) =>
  state.staffjoint.staff_name;
export const selectorCountSecond = (state: { staffjoint: StaffJoint }) =>
  state.staffjoint.count_second;
export const selectorSecondAllowed = (state: { staffjoint: StaffJoint }) =>
  state.staffjoint.second_allowed;

export const {
  setStaffJoint,
  clearStaffJoint,
  IncrementSecond,
  setSecondAllowed,
  setTypeDisplay,
} = StaffJointSlice.actions;
export default StaffJointSlice.reducer;
