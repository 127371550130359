import React, { useEffect, useMemo } from "react";
import { Button, Dropdown, Form } from "react-bootstrap";
import "./Styles.css";
import {
  foodDrink,
  pencilEdit,
  pencilEditMenu,
  trashRedDeleteMenu,
  window,
} from "../../../../components/ImgExport";
import { useState } from "react";
import ModalAddAnotherDish from "../ModalAddAnotherDish/ModalAddAnotherDish";
import {
  FoodChild,
  MenuInfo,
  updateSortFoodItem,
} from "../../../../api/apiOwner/templateMenuApi";
import { getLanguageUI } from "../../../../Services/languageUI";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import ControlCameraIcon from "@mui/icons-material/ControlCamera";
import MenuItem from "../MenuItem";
import { targetupload } from "../../../../api/urlImage";
import { default_product } from "../../../../components/Image";
import {
  Active,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DropAnimation,
  KeyboardSensor,
  PointerSensor,
  defaultDropAnimationSideEffects,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";

interface MenuGroupItemProps {
  id?: string;
  status?: boolean;
  foodGroupLength: number;
  foodGroupName: string;
  className?: string;
  hasFoodGroup?: boolean;
  changeIcon: boolean;
  foodGroupSTT: number;
  listFoodItem?: string[];
  ListFood?: MenuInfo[];
  handleDelFood?: () => void;
  handleEditFoodGroup: (food: number) => void;
  handleFoodMenu?: () => void;
  addFoodMenu?: () => void;
  activeFoodGroupMenu?: (id?: any) => void;
  bgColor: string;
  setShowLoading: (show: boolean) => void;
  GetListFoodGroup: () => void;
  FoodChild: FoodChild[];
  handleDeleteFood: (food_id: number, id: number) => void;
  saveActiveFood: (food_id: number) => void;
  handleEditFood: (food_id: number) => void;
}

const MenuGroupItem2 = ({
  id = "",
  status,
  className,
  foodGroupName,
  foodGroupLength,
  foodGroupSTT,
  hasFoodGroup = true,
  ListFood = [],
  handleDelFood,
  handleEditFoodGroup,
  handleFoodMenu,
  addFoodMenu,
  listFoodItem = [],
  setShowLoading,
  GetListFoodGroup,
  FoodChild,
  activeFoodGroupMenu,
  changeIcon = false,
  bgColor = "#11151f",
  handleDeleteFood,
  saveActiveFood,
  handleEditFood,
}: MenuGroupItemProps) => {
  const [listFoodChild, setListFoodChild] = useState<FoodChild[]>([]);
  const [openFood, setOpenFood] = useState(false);
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: Number(id) });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  const languageUI = getLanguageUI().menuTemplate;
  const [showModalAddAnotherDish, setShowModalAddAnotherDish] = useState(false);
  const dismisModalAddAnotherDish = (success?: boolean) => {
    setShowModalAddAnotherDish(false);
    if (success) {
      GetListFoodGroup?.();
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const [active, setActive] = useState<Active | null>(null);
  const activeItem = useMemo(
    () => listFoodChild.find((item) => item.food_id === active?.id),
    [active, listFoodChild]
  );

  useEffect(() => {
    setListFoodChild(FoodChild);
  }, [FoodChild]);
  const handleDragAndDrop = async (results: DragEndEvent) => {
    console.log("vao 111", results);
    const { active, over } = results;

    if (over && active.id !== over.id) {
      setListFoodChild((items) => {
        const oldIndex = items.findIndex((item) => item.food_id === active.id);
        const newIndex = items.findIndex((item) => item.food_id === over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
      // var parent_id_old = 0;
      // var parent_id_new = 0;

      // ListFoodGroup.map((item) => {
      //   if (item.id === over.id) {
      //     return (parent_id_new = Number(item.parent_id));
      //   }
      //   if (item.id === active.id) {
      //     return (parent_id_old = Number(item.parent_id));
      //   }
      // });

      const parent_id_old = listFoodChild.find(
        (item) => item.food_id === active.id
      );
      const parent_id_new = listFoodChild.find(
        (item) => item.food_id === over.id
      );
      const arrUpdate: { food_id: number; parent_id: number }[] = [
        {
          food_id: Number(active.id),
          parent_id: Number(parent_id_new?.parent_id) ?? 0,
        },
        {
          food_id: Number(over.id),
          parent_id: Number(parent_id_old?.parent_id) ?? 0,
        },
      ];
      const reqSwap = await updateSortFoodItem(arrUpdate);
      if (Number(reqSwap?.status) === 1) {
        setShowLoading(false);
        GetListFoodGroup();
      } else {
        setShowLoading(false);
      }
    }
    console.log(listFoodChild);

    // const { source, destination, type } = results;

    // if (!destination) return;

    // if (
    //   source.droppableId === destination.droppableId &&
    //   source.index === destination.index
    // )
  };
  return (
    <>
      <div key={Number(id)} ref={setNodeRef} style={style}>
        <div
          className={` d-flex rouned-4 mb-2 w-100 h-107px justify-content-between align-items-center bg-white ${className}`}
        >
          <div className="d-flex gap-2 h-100  w-100-707px">
            <div
              className="numberic-menu-food  h-100"
              style={{ backgroundColor: `${bgColor}` }}
            >
              <p className="mb-1 text-numberic-no">{languageUI.noNumber}</p>
              <div className="fw-bold  text-numberic">({foodGroupSTT})</div>
            </div>
            <div className="d-flex flex-column justify-content-center w-100-80px   ">
              <div className="food-name-menu mb-1 "> {foodGroupName}</div>
              <p className="mb-0 d-flex gap-1 mb-1 quanlity-product-menu">
                {languageUI.has}{" "}
                <p className="mb-0 quanlity-product-menu text-12B064">
                  {foodGroupLength}
                </p>{" "}
                {languageUI.products}
              </p>
              <div className="d-flex gap-1">
                <Form.Check
                  type="switch"
                  id="rating-allow-customer-point"
                  className="d-flex align-items-center checked-blue"
                  checked={status}
                  onChange={(e: any) => {
                    activeFoodGroupMenu?.(id);
                  }}
                />
                <p className="mb-0 active-category text-truncate">
                  {languageUI.activeCategory}
                </p>
              </div>
            </div>
          </div>

          <div
            className="d-flex flex-shrink-0"
            style={{ alignItems: "center", gap: "20px" }}
          >
            <div className="mr-5">
              <button
                className="btn-edit-delete-floor"
                {...attributes}
                {...listeners}
                onClick={() => setOpenFood(false)}
              >
                <ControlCameraIcon />
              </button>
            </div>

            <div className="mr-5">
              <button
                className="btn-edit-delete-floor"
                onClick={() => setOpenFood(!openFood)}
              >
                {openFood ? (
                  <i className="bi bi-caret-up-fill text-4A4A6A fs-5"></i>
                ) : (
                  <i className="bi bi-caret-down-fill text-4A4A6A fs-5"></i>
                )}
              </button>
            </div>

            <div className="mr-2">
              <div
                className="box-btn-function-setup-table gap-1 flex-shink-0"
                onClick={addFoodMenu}
              >
                <img src={foodDrink} alt="" />
                <p className="text-function-setup-table mb-0">
                  {languageUI.addFood}
                </p>
              </div>
              {/* <Button
              className="rounded-pill btn btn-primary"
              onClick={addFoodMenu}
            >
              Create food
            </Button>{" "} */}
            </div>
            <div className="mr-2">
              <div
                className="box-btn-function-setup-table gap-1 flex-shink-0"
                onClick={() => {
                  setShowModalAddAnotherDish(true);
                }}
              >
                {/* <img src={foodDrink} alt="" /> */}
                <p className="text-function-setup-table mb-0">
                  {languageUI.addAnotherdish}
                </p>
              </div>
              {/* <Button
              className="rounded-pill btn btn-primary"
              onClick={addFoodMenu}
            >
              Create food
            </Button>{" "} */}
            </div>

            <div className="dropdown">
              <Dropdown>
                <Dropdown.Toggle
                  className="box-btn-function-setup-table gap-1 flex-shink-0 bg-tranparent"
                  id="dropdown-basic"
                >
                  <img src={window} alt="" />
                  {/* <i className="fas fa-ellipsis-v fa-lg"></i> */}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <div className="p-3">
                    <div
                      className="d-flex gap-1 align-items-center mb-2 w-100"
                      onClick={() => {
                        handleEditFoodGroup(Number(id));
                      }}
                    >
                      <img src={pencilEditMenu} alt="" />
                      <p className="mb-0 text-function-window-menu text-4a4a6a">
                        {languageUI.edit}
                      </p>
                    </div>
                    <div
                      className="d-flex gap-1 align-items-center w-100"
                      onClick={handleDelFood}
                    >
                      <img src={trashRedDeleteMenu} alt="" />
                      <p className="mb-0 text-function-window-menu text-ff521c">
                        {languageUI.delete}
                      </p>
                    </div>{" "}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
        <div className={`${openFood === true ? "" : "d-none"}`}>
          <DndContext
            sensors={sensors}
            modifiers={[restrictToVerticalAxis]}
            onDragEnd={handleDragAndDrop}
          >
            <SortableContext
              items={listFoodChild.map((item) => item.food_id)}
              strategy={verticalListSortingStrategy}
            >
              {listFoodChild.map((item, index) => (
                <MenuItem
                  typeFood={item.typeFood}
                  noteFood={item.NoteFood}
                  optionFood={item.listNoteOptionFood}
                  key={"menu" + index}
                  foodSTT={Number(item?.parent_id)}
                  id={item?.food_id}
                  status={Number(item?.status_food) === 0 ? true : false}
                  img={
                    item?.imageCourse
                      ? targetupload + item?.imageCourse
                      : default_product
                  }
                  foodName={item?.food_name}
                  foodGroupName={""}
                  handleEditFood={() => {
                    handleEditFood(Number(item?.food_id));
                  }}
                  handleDelFood={() => {
                    handleDeleteFood(Number(item?.food_id), Number(id));
                  }}
                  activeFoodMenu={() => saveActiveFood(Number(item.food_id))}
                  priceFood={Number(item?.price)}
                  decreption={item.description}
                />
              ))}
            </SortableContext>
          </DndContext>
        </div>
      </div>

      <ModalAddAnotherDish
        handleClose={dismisModalAddAnotherDish}
        show={showModalAddAnotherDish}
        listFoodItem={listFoodItem}
        id={id}
        ListFood={ListFood}
        setShowLoading={setShowLoading}
      />
    </>
  );
};

export default MenuGroupItem2;
