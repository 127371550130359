import "./Styles.css";
import { useEffect, useState } from "react";
import ModalDialog from "@mui/joy/ModalDialog";
import DialogTitle from "@mui/joy/DialogTitle";
import DialogContent from "@mui/joy/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { Modal } from "@mui/material";
import { Transition } from "react-transition-group";
import { StaffActive } from "../../../../api/apiOwner/StaffApi";

interface ModalChooseStaffRatingProps {
  show: boolean;
  handleClose: () => void;
  listEmployeeActive: StaffActive[];
  handeSubmit: (idStaff: number, nameStaff: string) => void;
  idStaffActiveted: number;
}
const ModalChooseStaffRating = ({
  show,
  handleClose,
  listEmployeeActive,
  handeSubmit,
  idStaffActiveted,
}: ModalChooseStaffRatingProps) => {
  const [idStaffActive, setIdStaffActive] = useState<number>(0);
  const [nameStaffActive, setNameStaffActive] = useState<string>("");
  useEffect(() => {
    setIdStaffActive(idStaffActiveted);
  }, [show]);
  return (
    <>
      <Transition in={show} timeout={400}>
        {(state: string) => (
          <Modal
            className="mui-add-to-card"
            keepMounted
            open={!["exited", "exiting"].includes(state)}
            onClose={handleClose}
            slotProps={{
              backdrop: {
                sx: {
                  opacity: 0,
                  backdropFilter: "none",
                  transition: `opacity 400ms, backdrop-filter 400ms`,
                  ...{
                    entering: { opacity: 1, backdropFilter: "blur(8px)" },
                    entered: { opacity: 1, backdropFilter: "blur(8px)" },
                  }[state],
                },
              },
            }}
            sx={{
              visibility: state === "exited" ? "hidden" : "visible",
            }}
          >
            <ModalDialog
              layout="center"
              size="lg"
              sx={{
                opacity: 0,
                transition: `opacity 300ms`,
                ...{
                  entering: { opacity: 1 },
                  entered: { opacity: 1 },
                }[state],
              }}
            >
              <DialogTitle>
                <div className="w-100">
                  <div className="d-flex w-100 justify-content-between align-items-center mt-1">
                    <div className="hidden opacity-0" onClick={handleClose}>
                      <CloseIcon />
                    </div>
                    <p className="title-filter mb-0 mt-1 font-bold pe-2">
                      ChooseStaff
                    </p>
                    <div onClick={handleClose}>
                      <CloseIcon />
                    </div>
                  </div>
                </div>
              </DialogTitle>
              <DialogContent>
                <div className="px-3 overflow-y-scroll">
                  {listEmployeeActive.map((item) => (
                    <div
                      className={`h-40px  rounded-3 w-100 d-flex align-items-center px-2 mb-2 ${
                        Number(idStaffActive) === Number(item.id)
                          ? "bg-orange border-orange"
                          : "border bg-white"
                      }`}
                      onClick={() => {
                        setIdStaffActive(Number(item.id));
                        setNameStaffActive(item.name);
                      }}
                      key={`feedback-staff-${item.id}`}
                    >
                      <p
                        className={`title-filter m-0 text-truncate ${
                          Number(idStaffActive) === Number(item.id)
                            ? "text-white"
                            : "text-black"
                        }`}
                      >
                        {item.name}
                      </p>
                    </div>
                  ))}
                </div>
              </DialogContent>
              <DialogActions>
                <button
                  className="bg-primary border-none rounded-4 py-1 font-bold w-100 text-ffffff"
                  onClick={() =>
                    handeSubmit(Number(idStaffActive), nameStaffActive)
                  }
                >
                  Confirm
                </button>
              </DialogActions>
            </ModalDialog>
          </Modal>
        )}
      </Transition>
      {/* <Sheet
                isOpen={show}
                onClose={handleClose}
                snapPoints={[800]}
            >
                <Sheet.Container>
                    <Sheet.Header />
                    <Sheet.Content>
                        <div className="px-3 pb-footer overflow-y-scroll">

                            <div className="d-flex justify-content-between align-items">
                                <p className="title-filter mb-0 font-bold pe-2">{name}</p>
                                <div className="d-flex gap-2px">
                                    <div className="text-dollar">$</div>
                                    <div className="text-price-food">{FormatDolla(price)}</div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items">
                                <p className="title-filter">{description}</p>
                            </div>
                            {side_Dishes && side_Dishes.length > 0 && (
                                <>
                                    <p className="choose-a-side mb-0">Choose a side dishes?</p>
                                    <Form>
                                        {side_Dishes.map((sideItem, s) => (
                                            <Form.Check
                                                key={`side${s}`}
                                                name="choose-a-side mb-2"
                                                className="custom-radio-yellow p-0 d-flex align-items-center"
                                                label={<div className="d-flex gap-1 mt-1"><p className="mb-0 text-choose-a-side text-666687">{sideItem?.name}</p> <p className="mb-0 text-choose-a-side text-12B064">(Free x1)</p></div>}
                                                type="radio"
                                                id={sideItem?.id}
                                                checked={valueSideDishis == sideItem?.id}
                                                onClick={() => setValueSideDishis(sideItem?.id)}
                                            />
                                        ))}

                                    </Form>
                                </>
                            )}
                            {noteFood && noteFood.length > 0 && (
                                <>
                                    <div className="d-flex gap-3 align-items-center mt-2">
                                        <p className="choose-a-side mt-0 mb-0">Note</p>
                                        {messError !== '' && (
                                            <div className="text-italics text-danger text-center box-mess-error"><i className="fas fa-exclamation-triangle"></i> {messError}</div>
                                        )}
                                    </div>
                                    <div className="d-flex gap-2 flex-wrap mt-2">
                                        {noteFood && noteFood.map((items, i) => (
                                            <div key={`note${i}`} className={`d-flex justify-content-center align-items-center  ${NoteFoodId == items?.notefood_id ? "custom-choose-categories-2" : "custom-choose-categories"}`} onClick={() => { setNoteFoodId(items.notefood_id) }}>
                                                <p className={`custom-text-choose-categorie mb-0 ${NoteFoodId == items.notefood_id ? "text-ffffff" : "text-8E8EA9"}`}>{items.notefood_name}</p>
                                            </div>
                                        ))}
                                    </div>
                                </>
                            )}
                            {listOptionNote.length > 0 ? (
                                <div className='mt-2'>
                                    <p className='name-food-add-to-pos-menu mb-0 '>Note Food</p>
                                    <div className='d-flex flex-wrap gap-2 mt-2'>
                                        {listOptionNote.map((item, index) => (
                                            <div className={` w-fit name-food-add-to-pos-menu px-3 py-1 text-14px   rounded-4  ${activeOptionNoteFood.includes(item.option_name + (Number(item.price) > 0 ? `: $${item.price}` : '') ?? '') ? 'text-white bg-FF7B2C border-FF7B2C' : 'text-4a4a6a border'}`} key={`list-option-note-${item.id}`} onClick={() => listActiveOptionNoteFood(item.option_name ?? '', Number(item.price) ?? 0)}>
                                                {item.option_name} {Number(item.price) > 0 ? ': $' + item.price : ''}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : ''}
                            <p className="choose-a-side mb-0 ">More request?</p>
                            <div className={`${paddingbottomIOS == true ? "pb-250px" : ""}`}>
                                <textarea
                                    className="text-more-request"
                                    id="textarea"
                                    value={noteChef}
                                    onChange={handleChange}
                                    maxLength={maxLength}
                                    onFocus={() => setPaddingbottomIOS(true)}
                                    onBlur={() => setPaddingbottomIOS(false)}
                                ></textarea>
                                <div className="w-100 d-flex justify-content-end px-3">
                                    <small className="text-muted text-right">
                                        {noteChef.length}/{maxLength}
                                    </small>
                                </div>
                            </div>

                        </div>
                    </Sheet.Content>
                    <div className="py-3 px-3 fixed bottom-0 w-100 bg-white border-top">
                        <button className="custom-filter-btn border-none w-100 text-ffffff" onClick={AddToCart}>
                            <img src={plus} alt="" className="img-filter " />
                            Add to cart
                        </button>
                    </div>
                </Sheet.Container>
                <Sheet.Backdrop className="bg-bg-backdrop" onTap={handleClose} />
            </Sheet> */}
    </>
  );
};

export default ModalChooseStaffRating;
