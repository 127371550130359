import * as Request from "../Request";

export interface Result<T> {
  status: number;
  message?: string;
  data?: T;
}

export interface ListPOS {
  pos_id: number;
  pos_name: string;
  owner_id: number;
  status: number;
  timestamp: string;
}

export const addNewPosDevice = async (owner_id: number) => {
  return await Request.fetchAPI(`owner/addNewPosDevice.php`, "POST", {
    owner_id,
  })
    .then((res: Result<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
      };
    });
};

export const gListPosByOwner = async (owner_id: number) => {
  return await Request.fetchAPI(`owner/gListPosByOwner.php`, "POST", {
    owner_id,
  })
    .then((res: Result<ListPOS[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};

export const editNewPosDevice = async (
  owner_id: number,
  pos_name: string,
  status: number,
  pos_id: number
) => {
  return await Request.fetchAPI(`owner/editNewPosDevice.php`, "POST", {
    owner_id,
    pos_name,
    status,
    pos_id,
  })
    .then((res: Result<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
      };
    });
};

export const deletePos = async (owner_id: number, pos_id: number) => {
  return await Request.fetchAPI(`owner/deletePos.php`, "POST", {
    owner_id,
    pos_id,
  })
    .then((res: Result<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
      };
    });
};
