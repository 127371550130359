import{ useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { logoFull } from '../../components/Image';


interface AccountLayoutProps {
    helpText?: string;
    bottomLinks?: any;
    isCombineForm?: boolean;
    children?: any;
}

const AuthLayout = ({ helpText, bottomLinks, children, isCombineForm }: AccountLayoutProps) => {
    useEffect(() => {
        if (document.body) document.body.classList.add('authentication-bg', 'authentication-bg-pattern');

        return () => {
            if (document.body) document.body.classList.remove('authentication-bg', 'authentication-bg-pattern');
        };
    }, []);

    return (
        <>
            <div className="account-pages d-flex vh-100">
                <Container className="m-auto">
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={isCombineForm ? 9 : 4}>
                            <Card className="bg-pattern rounded-3 shadow mb-0">
                                <Card.Body className="p-4">
                                    <div className="text-center w-75 m-auto">
                                        <div className="auth-logo">
                                            <Link to="/" className="logo text-center">
                                                <span className="logo-lg">
                                                    <img src={logoFull} alt="" height="60" />
                                                </span>
                                            </Link>
                                        </div>
                                        <p className="text-muted mb-4 mt-3">{helpText}</p>
                                    </div>
                                    {children}
                                </Card.Body>
                            </Card>

                            {/* bottom links */}
                            {bottomLinks}
                        </Col>
                    </Row>
                </Container>
            </div>

            <footer className="footer footer-alt text-white">
                Copyright © {new Date().getFullYear()} Brijack. All rights reserved
            </footer>
        </>
    );
};

export default AuthLayout;
