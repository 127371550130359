import { Form, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "./styles.css";
import { useEffect, useState } from "react";
import {
  leaf1,
  leaf2,
  minusGreen,
  plusGreen,
} from "../../../../../components/ImgExport";
import { useSelector } from "react-redux";
import * as userSlice from "../../../../../store/slices/userSlice";
import moment from "moment-timezone";
import { addGiftVoucher } from "../../../../../api/apiOwner/giftCodeVoucher";
import { getLanguageUI } from "../../../../../Services/languageUI";
import React from "react";

interface ModalAddPosSystemProps {
  show: boolean;
  setShowLoading: (e: boolean) => void;
  HandleCreatSuccess: (posName: string, status: number) => void;
  handleClose: () => void;
  messErr: string;
}

const ModalAddPosSystem = ({
  show,
  setShowLoading,
  HandleCreatSuccess,
  handleClose,
  messErr,
}: ModalAddPosSystemProps) => {
  const languageUI = getLanguageUI().giftCard;
  const [POSName, setGiftPOSName] = useState<string>("");
  const [POStype, setPOStype] = useState<number>(1);
  const [messageError, setMessageError] = useState<string>("");
  useEffect(() => {
    if (show) {
      setGiftPOSName("");
      setPOStype(1);
    }
  }, [show]);
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="modal-delete-customer modal-import-inventory"
      >
        <Modal.Dialog className="w-100 h-100 m-0 rounded-5">
          <Modal.Body>
            <div className="px-48px">
              <p className="text-delete-modal-customer mb-0 text-center">
                Create Station
              </p>
              <div className="mt-6">
                <p className="text-add-table-name mb-1">Station</p>
                <div className="input-add-table">
                  <input
                    type="text"
                    className="border-none focus-visiable-none text-input-add-table px-2 w-100"
                    placeholder={languageUI.enterGiftCardName}
                    value={POSName}
                    onChange={(e) => setGiftPOSName(e.target.value)}
                  />
                </div>
              </div>
              <div className="mt-6">
                <p className="text-add-table-name mb-1">Status</p>
                <Form defaultValue={"1"} className="d-flex">
                  <Form.Check // prettier-ignore
                    type="radio"
                    id={"1"}
                    label={"Main Station"}
                    name="option-voucher"
                    className={`d-flex align-items-center w-fit radio-choose-option-inventory ${
                      POStype === 1 ? "text-green" : ""
                    }`}
                    checked={POStype === 1}
                    onClick={() => setPOStype(1)}
                  />
                  <Form.Check // prettier-ignore
                    type="radio"
                    id={`2`}
                    label={"Auxiliary Station"}
                    className={`d-flex align-items-center w-fit radio-choose-option-inventory ${
                      POStype === 1 ? "" : "text-green"
                    }`}
                    name="option-voucher"
                    checked={POStype === 0}
                    onClick={() => setPOStype(0)}
                  />
                </Form>
              </div>

              <div className="text-center text-danger text-italic mt-1">
                {messErr}
              </div>
              <div className="d-flex justify-content-between w-100 mt-6">
                <div
                  className="btn-import-inventory-footer "
                  onClick={handleClose}
                >
                  {languageUI.back}
                </div>
                <div
                  className="btn-import-inventory-footer border-12B064 text-white bg-12B064"
                  onClick={() => {
                    HandleCreatSuccess(POSName, POStype);
                  }}
                >
                  {languageUI.save}
                </div>
              </div>
            </div>
            <img src={leaf1} alt="" className="position-leaf1-voucher" />
            <img src={leaf2} alt="" className="position-leaf2-voucher" />
          </Modal.Body>
        </Modal.Dialog>
      </Modal>
    </>
  );
};

export default ModalAddPosSystem;
