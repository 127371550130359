import { Button, Card, Col, Container, Row } from "react-bootstrap";
import React from "react";
import { Rating } from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import ModalChooseStaffRating from "./components/ModalChooseStaffRating/ModalChooseStaffRating";
import { thankYouReview } from "../../components/Image";
import './Styles.css'

const ThankyouForReview = () => {

  return (

    <>
      <div
        className="account-pages bg-primary birthday-page d-flex"
        style={{ minHeight: "100vh" }}
      >
        <Container className="m-auto">
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="bg-pattern rounded-3 shadow mb-0">
                <Card.Body className="p-4">
                  <div className="d-flex justify-content-center w-100">
                    <img src={thankYouReview} alt="" className="w-60" />
                  </div>
                  <h1 className="font-bold text-4a4a6a text-center text-36px mt-0">For You Feedback</h1>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ThankyouForReview;
