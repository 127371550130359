import React, { Component, useEffect, useState } from "react";
import {
    withGoogleMap,
    withScriptjs,
    GoogleMap,
    Marker,
} from "react-google-maps";
declare const google: any;
const SimpleMap: React.FC<{
    locat: any;
    isMarkerShown: boolean;
}> = ({ locat, isMarkerShown }) => {
    useEffect(() => {
        console.log(locat);
    }, [isMarkerShown]);
    return (
        <GoogleMap
            defaultZoom={15}
            center={new google.maps.LatLng(locat.lat, locat.lng)}
        >
            {isMarkerShown && (
                <Marker position={{ lat: locat.lat, lng: locat.lng }} />
            )}
        </GoogleMap>
    );
};
export default withScriptjs(withGoogleMap(SimpleMap));
