
import { Row, Col, Card, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import QRCode from "react-qr-code";

import { logoFull } from "../../components/Image";


const QrCodeCheckIn = () => {

  return (
    <>
      <div className="account-pages d-flex" style={{ minHeight: "100vh" }}>
        <Container className="m-auto">
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={4}>
              <Card className="bg-pattern rounded-3 shadow mb-0">
                <Card.Body className="p-4">
                  <div className="text-center w-75 m-auto">
                    <div className="auth-logo">
                      <Link to="/" className="logo text-center">
                        <span className="logo-lg">
                          <img src={logoFull} alt="" height="60" />
                        </span>
                      </Link>
                    </div>
                  </div>
                  <h4 className="text-center mb-4 mt-4">Scan QR</h4>
                  <div className="text-center">
                    {/* <img
                      src={qrCodeDemo}
                      alt="qr-img"
                      style={{ width: "90%" }}
                    /> */}
                    <QRCode
                      id="qrcode"
                      value="https://viblo.asia/u/tranchien"
                      size={290}
                      level={"H"}
                    // includeMargin={true}
                    />
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default QrCodeCheckIn;
