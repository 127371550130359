/* eslint-disable no-lone-blocks */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from "react";

import { configureFakeBackend } from "./helpers";
import socket from "./utils/socket";
import * as userSlice from "./store/slices/userSlice";
import { useSelector } from "react-redux";

import jsPDF from "jspdf";

// Themes

// For Default import Default.scss
import "./assets/scss/Default.scss";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import "./Styles.css";
import AppLayout from "./layouts/layout/AppLayout";

import PaymentTable from "./pages/webowner/PaymentTable";
// import PaymentBillInofrmationTable from "./pages/webowner/PaymentBillInofrmation";s
import PaymentBillInofrmation from "./pages/webowner/PaymentBillInofrmation";
import QRCodeOwner from "./pages/webowner/QRCodeOwner";
import POSTable from "./pages/webowner/POSTable";

import POSMenu from "./pages/webowner/POSMenu";

import OrderStatus from "./pages/webcheckin/OrderStatus";
// import PaymentStatus from "./pages/webcheckin/PaymentStatus";
import MyBill from "./pages/webcheckin/MyBill";
import CalendarApp from "./pages/apps/Calendar";
import { Inbox, Timeline, Notifications } from "@mui/icons-material";
import Customers from "./pages/apps/CRM/Customers";
import Opportunities from "./pages/apps/CRM/Opportunities";
import ChatApp from "./pages/apps/Chat";
import Companies from "./pages/apps/Companies";
import Cart from "./pages/apps/Ecommerce/Cart";
import Checkout from "./pages/apps/Ecommerce/Checkout";
import OrderDetails from "./pages/apps/Ecommerce/OrderDetails";
import Orders from "./pages/apps/Ecommerce/Orders";
import ProductDetails from "./pages/apps/Ecommerce/ProductDetails";
import ProductEdit from "./pages/apps/Ecommerce/ProductEdit";
import Sellers from "./pages/apps/Ecommerce/Sellers";
import EmailDetail from "./pages/apps/Email/Detail";
import FileManager from "./pages/apps/FileManager";
import Projects from "./pages/apps/Projects";
import ProjectDetail from "./pages/apps/Projects/Detail";
import ProjectForm from "./pages/apps/Projects/ProjectForm";
import SocialFeed from "./pages/apps/SocialFeed";
import Kanban from "./pages/apps/Tasks/Board";
import TaskDetails from "./pages/apps/Tasks/Details";
import TaskList from "./pages/apps/Tasks/List";
import Error404Alt from "./pages/error/Error404Alt";
// import FAQ from "./pages/landing/FAQ";
// import Pricing from "./pages/landing/Pricing";
import Gallery from "./pages/other/Gallery";
import Invoice from "./pages/other/Invoice";
import SearchResults from "./pages/other/SearchResults";
import Sitemap from "./pages/other/Sitemap";
import Starter from "./pages/other/Starter";
import Avatars from "./pages/uikit/Avatars";
import Buttons from "./pages/uikit/Buttons";
import Cards from "./pages/uikit/Cards";
import Images from "./pages/uikit/Images";
import Modals from "./pages/uikit/Modals";
import Offcanvases from "./pages/uikit/Offcanvas";
import Placeholders from "./pages/uikit/Placeholders";
import Portlets from "./pages/uikit/Portlets";
import Progress from "./pages/uikit/Progress";
import Spinners from "./pages/uikit/Spinners";
import EcommerceDashboard from "./pages/apps/Ecommerce/Dashboard";
import EcommerceProducts from "./pages/apps/Ecommerce/Products";
import CRMDashboard from "./pages/apps/CRM/Dashboard";
import CRMContacts from "./pages/apps/CRM/Contacts";
import CRMLeads from "./pages/apps/CRM/Leads";
import CRMCustomers from "./pages/apps/CRM/Customers";
import EmailCompose from "./pages/apps/Email/Compose";
import ContactsList from "./pages/apps/Contacts/List";
import ContactsProfile from "./pages/apps/Contacts/Profile";
import TicketsList from "./pages/apps/Tickets/List";
import FAQ from "./pages/other/FAQ";
import TicketsDetails from "./pages/apps/Tickets/Details";
import Pricing from "./pages/other/Pricing";
import Carousels from "./pages/uikit/Carousel";
import ListGroups from "./pages/uikit/ListGroups";
import EmbedVideo from "./pages/uikit/EmbedVideo";
import Dropdowns from "./pages/uikit/Dropdowns";
import Ribbons from "./pages/uikit/Ribbons";
import TooltipsPopovers from "./pages/uikit/TooltipsPopovers";
import Typography from "./pages/uikit/Typography";
import Grid from "./pages/uikit/Grid";
import GeneralUI from "./pages/uikit/GeneralUI";
import NestableList from "./pages/uikit/NestableList";
import DragDrop from "./pages/uikit/DragDrop";
import Animation from "./pages/uikit/Animation";
import SweetAlerts from "./pages/uikit/SweetAlerts";
import TourPage from "./pages/uikit/TourPage";
import LoadingButtons from "./pages/uikit/LoadingButtons";
import TwoToneIcons from "./pages/icons/TwoToneIcons";
import FeatherIcons from "./pages/icons/FeatherIcons";
import Dripicons from "./pages/icons/Dripicons";
import MDIIcons from "./pages/icons/MDIIcons";
import FontAwesomeIcons from "./pages/icons/FontAwesomeIcons";
import ThemifyIcons from "./pages/icons/ThemifyIcons";
import SimpleLineIcons from "./pages/icons/SimpleLineIcons";
import WeatherIcons from "./pages/icons/WeatherIcons";
import BasicForms from "./pages/forms/Basic";
import FormAdvanced from "./pages/forms/Advanced";
import FormValidation from "./pages/forms/Validation";
import FormWizard from "./pages/forms/Wizard";
import FileUpload from "./pages/forms/FileUpload";
import Editors from "./pages/forms/Editors";
import BasicTables from "./pages/tables/Basic";
import AdvancedTables from "./pages/tables/Advanced";
import ApexChart from "./pages/charts/Apex";
import ChartJs from "./pages/charts/ChartJs";
import Rv from "./pages/rv";
import ShRv from "./pages/shrv";
import QrCodeCheckIn from "./pages/webcheckin/QrCodeCheckIn";
import SendMenuToPhone from "./pages/webcheckin/SendMenuToPhone";
import ErrorScanQr from "./pages/webcheckin/ErrorScanQr";
import EnterFirstName from "./pages/webcheckin/EnterFirstName";
import ChooseMenu from "./pages/webcheckin/ChooseMenu";
// import MyCard from "./pages/webcheckin/CartInformation";
import Payment from "./pages/webcheckin/Payment";
import EnterBirthday from "./pages/webcheckin/EnterBirthday";
import EnterPersonTitle from "./pages/webcheckin/EnterPersonTitle";
import ShowPointsCheckin from "./pages/webcheckin/ShowPointsCheckin";
import WaitCheckin from "./pages/webcheckin/WaitCheckin";
import CheckInReview from "./pages/webcheckin/CheckInReview";
import ShowReview from "./pages/webcheckin/ShowReview";
import LoginOwner from "./pages/webowner/LoginOwner";
import CustomerType from "./pages/webowner/CustomerType";
// import ReviewOwner from "./pages/webowner/ReviewOwner";
import SettingChangePassword from "./pages/webowner/SettingChangePassword";
import SettingInformation from "./pages/webowner/SettingInformation";
// import Subscription from "./pages/webowner/Subscription";
// import TemplateMenu from "./pages/webowner/TemplateMenu";
import AccountInfor from "./pages/webowner/AccountInfor";
import GetReviewFromCustomer from "./pages/webowner/GetReviewForCusTomer";
import AccountStaff from "./pages/webadmin/AccountStaff";
import CustomerOverviewAdmin from "./pages/webadmin/CustomerOverviewAdmin";
import GroupCustomerAdmin from "./pages/webadmin/GroupCustomerAdmin";
import CreateCustomerAdmin from "./pages/webadmin/CreateCustomerAdmin";
import SettingChangePasswordAdmin from "./pages/webadmin/SettingChangePasswordAdmin";
import SettingUpdatePhoneNumberAdmin from "./pages/webadmin/SettingUpdatePhoneNumberAdmin";
import SubscriptionAdmin from "./pages/webadmin/SubscriptionAdmin";
import AccountInforDetail from "./pages/webadmin/AccountInforDetail";
import EditSettingInformationOfCustomer from "./pages/webadmin/EditSettingInformationOfCustomer";
import ViewQRCode from "./pages/webadmin/QRCodeOwner";
import Login from "./pages/auth/Login";
// import { LoginAdmin } from "./api/apiAdmin/LoginAdmin";
import ForgetPasswordAdmin from "./pages/webadmin/ForgetPassword";
import Register from "./pages/auth/Register";
import SignInSignUp from "./pages/auth/SignInSignUp";
import LockScreen from "./pages/auth/LockScreen";
import Logout from "./pages/auth/Logout";
import Login2 from "./pages/auth2/Login2";
import Logout2 from "./pages/auth2/Logout2";
import Register2 from "./pages/auth2/Register2";
import Confirm from "./pages/auth/Confirm";
import Confirm2 from "./pages/auth2/Confirm2";
import ForgetPassword from "./pages/auth/ForgetPassword";
import ForgetPassword2 from "./pages/auth2/ForgetPassword2";
import SignInSignUp2 from "./pages/auth2/SignInSignUp2";
import LockScreen2 from "./pages/auth2/LockScreen2";
import Landing from "./pages/landing";
import Maintenance from "./pages/other/Maintenance";
import Error404 from "./pages/error/Error404";
import Error404Two from "./pages/error/Error404Two";
import Error500Two from "./pages/error/Error500Two";
import Error500 from "./pages/error/Error500";
import Upcoming from "./pages/other/Upcoming";
import CartInformation from "./pages/webcheckin/CartInformation";
import ModalNotification from "./components/ModalNotification";
import CustomerReciepts from "./pages/webowner/CustomerReciepts";
import ClockInClockOut from "./pages/webowner/ClockInClockOut";
import SetupTable from "./pages/webowner/SetupTable";
import ReviewFood from "./pages/webowner/components/ReviewFood";
import Report from "./pages/webowner/components/Report";
import IncomeDetail from "./pages/webowner/components/Report/Component/IncomeDetail";
import IncomeDateDetail from "./pages/webowner/components/Report/Component/IncomeDateDetail";
import Customer from "./pages/webowner/components/Customer";
import Inventory from "./pages/webowner/components/Inventory";
import Staff from "./pages/webowner/components/Staff";
import ReviewDetail from "./pages/webowner/components/ReviewFood/Component/ReviewDetail";
import CreateStaff from "./pages/webowner/components/Staff/Component/Create/CreateStaff";
import VoucherOwner from "./pages/webowner/components/VoucherOwner";
import SubscriptionOwner from "./pages/webowner/components/Subscription";
import FeedBack from "./pages/webowner/components/Feedback";
import EditCreateStaff from "./pages/webowner/components/Staff/Component/Create/EditCreateStaff";
import KitchenCounter from "./pages/webowner/components/KitchenCounter/KitchenCounter";
import KitchenCounterHistory from "./pages/webowner/components/KitchenCounter/KitchenCounterHistory";
import LandingPage from "./pages/webowner/components/LandingPage";
// import CreatAccountOwner from "./pages/webowner/components/CreatAccountOwner";
import CreatFormLandingPage from "./pages/webowner/components/CreatFormLandingPage";
import AppLayoutAdmin from "./layouts/layoutAdmin/AppLayoutAdmin";
import LoginAdmin from "./pages/webadmin/LoginAdmin";
import SettingConfig from "./pages/webadmin/SettingConfig";
import SalaryEachWeek from "./pages/webowner/components/Staff/Component/Content/SalaryEachWeek";
import TableSalaryEmployee from "./pages/webowner/components/Staff/Component/Content/TableSalaryEmployee";
import SalaryEach2Week from "./pages/webowner/components/Staff/Component/Content/SalaryEach2Week";
import TableSalaryEmployee2Week from "./pages/webowner/components/Staff/Component/Content/TableSalaryEmployee2Week";
import OfflineBooking from "./pages/webowner/OfflineBooking";
import SalaryEachMonth from "./pages/webowner/components/Staff/Component/Content/SalaryEachMonth";
import TableSalaryEmployeeMonth from "./pages/webowner/components/Staff/Component/Content/TableSalaryEmployeeMonth";
import { checkTokenCustomer } from "./api/apiCheckin/checkinApi";
import { getLanguageUI } from "./Services/languageUI";
import ModalTimeOutToken from "./components/ModalTimeOutToken";
import OrderListITemPrintPosMenu from "./pages/webcheckin/components/OrderListITem/OrderListITemPrintPosMenu";
import { FormatDolla } from "./utils/format";
import printJS from "print-js";
import OrderListITemPrintKitchenCounter from "./pages/webcheckin/components/OrderListITem/OrderListITemPrintKitchenCounter";
import OwnerOrderPage from "./pages/webowner/components/OwnerOrderPage";
import ModalPaymentError from "./pages/webowner/components/ModalPaymentError/ModalPaymentError";
import ModalReader from "./pages/webowner/components/ModalReader";
import PrivacyPolicy from "./pages/webcheckin/PrivacyPolicy";
import TeamOfUse from "./pages/webcheckin/TeamOfUse";
import { PrintOrderPage } from "./api/apiOwner/printerApi";
import TestPage from "./testpage";
import { font } from "./components/font/Roboto-Light-normal";
import {
  ListPrinterConnect,
  checkConnectPrinterByCatalog,
} from "./api/apiOwner/SettingPrinterApi";
import ModalToast from "./pages/webowner/components/ModalToast/ModalToastErr";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { wifiOff, wifiOn } from "./components/Image";
import YearlyPlan from "./pages/webowner/components/YearlyPlan";
import CreatFormYearly from "./pages/webowner/components/CreatFormYearlyPlan";
import MenuOrderOnline from "./pages/weborderonline/LoginOnline/LoginOnline";
import MenuOnline from "./pages/weborderonline/MenuOnline/MenuOnline";
import OrderStatusOnline from "./pages/weborderonline/OrderStatusOnline/OrderStatusOnline";
import CardInformationOnline from "./pages/weborderonline/CardInformationOnline/CardInformationOnline";
import PaymentOnline from "./pages/weborderonline/PaymentOnline/PaymentOnline";
import GoToOwner from "./pages/webowner/goToOwner";
import ShowPointOnline from "./pages/weborderonline/ShowPointOnline/ShowPointOnline";
import MyBillOnline from "./pages/weborderonline/MyBillOnline/MyBillOnline";
import LandingPageMonth from "./pages/webowner/components/LangdingPageMonth/LangdingPageMonth";
import CreatFormMonth from "./pages/webowner/components/CreatFormMonth";
import HistoryOrder from "./pages/webcheckin/HistoryOrder";
import HistoryOrderDetail from "./pages/webcheckin/HistoryOrderDetail";
import DeviceCustomer from "./pages/webadmin/DeviceCustomer";
import DeviceCustomerDetail from "./pages/webadmin/DeviceDetailCustomer";
import BookingOnline from "./pages/weborderonline/BookingOnline/BookingOnline";
import StatusOrderMethodOnline from "./pages/weborderonline/StatusOrderMethodOnline/StatusOrderMethodOnline";
import ListReservation from "./pages/weborderonline/ListReservation/ListReservation";
import ReservationDetail from "./pages/weborderonline/ListReservation/ReservationDetail";
import Reservation from "./pages/webowner/Reservation";
import RatingStaff from "./pages/webcheckin/RatingStaff";
import ThankyouForReview from "./pages/webcheckin/ThankyouForReview";
import TokenErrPage from "./pages/webcheckin/TokenErrPage";
import SnakeGame from "./components/SnakeGame/game";
import GameFlaapyBird from "./components/FlappyBird/Game";
import WhackAMole from "./pages/webcheckin/components/WhackAMole/GameBoar";
import ListGame from "./pages/webcheckin/ListGame";
import GiftCard from "./pages/webowner/GiftCard";
import GiftCardDetail from "./pages/webowner/GiftCardDetail";
import RewardPage from "./pages/webcheckin/RewardPage";
import ReviewRestaurant from "./pages/ReviewRestaurant";
import ReviewSuccess from "./pages/ReviewSuccess";
import ReviewSuccessGoToGoogle from "./pages/ReviewSuccessGoToGoogle";
import MyBillOnlineDetail from "./pages/weborderonline/MyBillOnline/MyBillOnlineDetail";
import TemplateMenu2 from "./pages/webowner/TemplateMenu2";
import FeedbackAdmin from "./pages/webadmin/FeedbackAdmin";
import ChossePOS from "./pages/webadmin/ChossePOS";
import POSSystem from "./pages/webowner/POSSystem";
import HistoryPage from "./pages/webowner/HistoryPage";
import ModalOTPOutStaff from "./pages/webowner/components/ModalOTPOutStaff/ModalOTPOutStaff";
import GiftCardBalance from "./pages/webowner/GiftCardBalance";
import PickupPage from "./pages/webowner/PickupPage";
import GiftCardHistory from "./pages/webowner/GiftCardHistory";
import HandheldPOS from "./pages/webowner/HandheldPOS";
import moment from "moment-timezone";
import { gTimeZoneDefault } from "./api/apiOwner/AccountInfor";
// import { gTimeZoneDefault } from "../api/apiOwner/AccountInfor";

configureFakeBackend();
interface cartInfo {
  id: string;
  owner_id: string;
  custommer_id: string;
  table_id: string;
  service_item_id: string;
  price: string;
  quantity: string;
  notefood_id: string;
  side_id: string;
  type: string;
  notechef: string;
  delf: string;
  timestamp: string;
  name: string;
  imageCourse: string;
  donvitinh: string;
  table_name: string;
  notefood_name: string;
  side_name: string;
  slcon: string;
  insufficient: string;
  typeFood: number;
}
export interface listSide {
  id: string;
  imageCourse: string;
  name: string;
  price: string;
  quantity: string;
  side_id: string;
  table_id: string;
  typeFood: string;
}
interface Order {
  table_id: string;
  ownerID: string;
  custommerId: string;
  customer_name: string;
  requestMealList: string;
  totalPrice: string;
  table_name: string;
  order_id: string;
  cartInfo: cartInfo[];
  listPrinterName: ListPrinterConnect[];
  listSide: listSide[];
  guestNumber: number;
}
const App = () => {
  const [showModalTimeOutToken, setShowModalTimeOutToken] = useState(false);
  const dismisModalShowTimeOutToken = () => {
    setShowModalTimeOutToken(false);
  };
  const languageUI = getLanguageUI().printPage;
  const nameStore = useSelector(userSlice.selectorNameStore);
  const addressStore = useSelector(userSlice.selectorAddress);
  const phoneOwnerStore = useSelector(userSlice.selectorPhoneNumber);

  const [showModalNotification, setShowModalNotification] = useState(false);
  const [orderInfo, setOrderInfo] = useState<Order>();
  const audioNotif = useRef(null);
  const UserIdStore = useSelector(userSlice.selectorUserId);
  const pos_status = useSelector(userSlice.selectorPosStatus) ?? 0;

  const [widthPage, setWidthPage] = useState(0);
  const [showToastError, setShowToastError] = useState<boolean>(false);
  const owner_id = useSelector(userSlice.selectorUserId);
  const [messageError, setMessageError] = useState<string>("");

  const dismisModalNotification = () => {
    setShowModalNotification(false);
  };
  const [notification, setNotification] = useState<string>("");
  const [tabActive, setTabActive] = useState(true);
  // const [dataWaiting, setDataWaiting] = useState<Order[]>([])
  const dataWaiting = useRef<Order[]>([]);
  const refpdf = useRef<any>();
  //convert html to pdf (print)
  const convertHtmlToPDF = () => {
    // const pdf = new jsPDF();
    const doc = new jsPDF({
      format: "a4",
      unit: "px",
    });
    doc.addFileToVFS("Roboto-Light.ttf", font);
    doc.addFont("Roboto-Light.ttf", "Roboto", "normal");
    doc.setFont("Roboto");
    const pdfWidth = doc.internal.pageSize.getWidth();
    console.log(pdfWidth);

    // Adding the fonts.
    doc.setLineWidth(pdfWidth);
    setWidthPage(pdfWidth);

    doc.html(refpdf.current, {
      async callback(doc) {
        await doc.save("document");
      },
    });

    // const pdfContent: any = document.getElementById('');
    // doc.html(pdfContent, {
    //   callback: function (doc) {
    //     // Hiển thị hoặc lưu file PDF (tùy chọn)
    //     // pdfRef.current.src = pdf.output('datauristring');

    //     // Gửi file PDF lên API (ví dụ: sử dụng axios)
    //     // const formData = new FormData();
    //     // formData.append('pdfFile', new Blob([pdf.output('blob')], { type: 'application/pdf' }));

    //     // axios.post('URL_API', formData, {
    //     //   headers: {
    //     //     'Content-Type': 'multipart/form-data',
    //     //   },
    //     // })
    //     //   .then(response => {
    //     //     // Xử lý kết quả từ API nếu cần
    //     //   })
    //     //   .catch(error => {
    //     //     // Xử lý lỗi nếu có
    //     //   });
    //   },
    // });
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        // Trang web đã bị ẩn (người dùng đã nhảy tab)
        setTabActive(false);
      } else {
        // Trang web đã trở lại (người dùng quay lại tab)
        setTabActive(true);

        // Thực hiện các tác vụ cần thiết khi trở lại tab, ví dụ: render lại
        // Đặt các hành động cụ thể ở đây
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Hủy đăng ký sự kiện khi component bị hủy
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const checkToken = async (token: string, custommerId: string) => {
    var pathName = window.location.pathname;
    const ownerID = localStorage.getItem("ownerID");
    if (pathName.search("/webcheckin/") > -1) {
      const req = await checkTokenCustomer(
        custommerId,
        token,
        ownerID ? Number(ownerID) : 0
      );
      // alert(custommerId + '----' + token + '----' + ownerID)
      if (Number(req?.status) === 2) {
        setShowModalTimeOutToken(true);
      } else if (Number(req?.status) === 0) {
        setShowModalTimeOutToken(true);
      }
    }
  };

  const styles = {
    fullWidth: {
      width: "100%",
    },
    textNormal: {
      fontSize: "20px",
      lineHeight: "normal",
      color: "black",
      fontFamily: "Roboto",
      fontWeight: 500,
    },
    textTitle: {
      fontSize: "28px",
      lineHeight: "normal",
      color: "black",
      fontFamily: "Roboto",
      fontWeight: 700,
    },
    borderBottomBlack: {
      borderBottom: "1px solid black",
      width: "100%",
      lineHeight: "normal",
      paddingBottom: "30px",
    },
    dflexRow: {
      display: "flex",
      justifyContent: "space-between",
      gap: "10px",
    },
    fullWidthBreakPage: {
      width: "100%",
    },
    textNormalBigWeight: {
      fontSize: "20px",
      lineHeight: "normal",
      color: "black",
      fontFamily: "Roboto",
      fontWeight: 700,
    },
  };

  useEffect(() => {
    if (!tabActive) {
      const token = localStorage.getItem("token_custommer");
      const custommerId = localStorage.getItem("custommerId");
      if (
        token &&
        custommerId &&
        window.location.pathname.search("/ratingStaff") === -1 &&
        window.location.href.search("/webcheckin") > -1
      ) {
        checkToken(token, custommerId);
      }
    }
  }, [tabActive]);
  const onPlay = () => {
    (audioNotif as any).current.audio.current.play();
  };
  const handleSpeak = (content: string) => {
    const utterance = new SpeechSynthesisUtterance(content);
    const voices = window.speechSynthesis.getVoices();
    const femaleVoice = voices.find(
      (voice) => voice.name === "Google US English Female"
    );
    // Thiết lập giọng nói là giọng nữ
    if (femaleVoice) {
      utterance.voice = femaleVoice;
    }
    window.speechSynthesis.speak(utterance);
  };

  const handleNotification = (data: any) => {
    var pathName = window.location.pathname;
    if (pathName.search("/web-owner/") > -1) {
      setShowModalNotification(false);
      setTimeout(() => {
        setNotification(data?.message);
        handleSpeak(data?.message);
        onPlay();
        setShowModalNotification(true);
        setTimeout(() => {
          dismisModalNotification();
        }, 10000);
      }, 1500);
    }
  };
  const PrintOrderSocket = (data: any) => {
    var pathName = window.location.pathname;

    if (pathName.search("/web-owner") > -1 && pos_status === 1) {
      // setOrderInfo(data);
      PrintCard(data);
    }
  };

  useEffect(() => {
    if (!UserIdStore) {
      return;
    }
    const eventName = "PushShowNotification" + UserIdStore;
    const eventPrint = "PushPrintOrder" + UserIdStore;

    socket.on(eventName, handleNotification);
    socket.on(eventPrint, PrintOrderSocket);

    return () => {
      socket.off(eventName, handleNotification);
      socket.off(eventPrint, PrintOrderSocket);
    };
  }, [UserIdStore]);

  // const showNotificaton = useCallback(async () => {
  //   console.log(UserIdStore);
  //   await socket.on("PushShowNotification" + UserIdStore, (data: any) => {
  //     console.log(UserIdStore, data);
  //     var pathName = window.location.pathname;
  //     if (pathName.search("/web-owner/") > -1) {
  //       setShowModalNotification(false);
  //       setTimeout(() => {
  //         setNotification(data?.message);
  //         handleSpeak(data?.message);
  //         onPlay();
  //         setShowModalNotification(true);
  //         setTimeout(() => {
  //           dismisModalNotification();
  //         }, 10000);
  //       }, 1500);
  //     }
  //   });
  // }, [socket]);

  // const PrintOrderSocket = async () => {
  //   await socket.on("PushPrintOrder" + UserIdStore, (data: any) => {
  //     var pathName = window.location.pathname;

  //     if (pathName.search("/web-owner") > -1 && pos_status === 1) {
  //       // setOrderInfo(data);
  //       PrintCard(data);
  //     }
  //   });
  // };
  // useEffect(() => {
  //   showNotificaton();
  //   PrintOrderSocket();
  // }, [socket]);
  const [isOnline, setIsOnline] = useState<boolean>();
  useEffect(() => {
    if (isOnline) {
      toast.dismiss();
      toast.warning("You are currently online.", {
        autoClose: 2000, // Tắt tự động
        position: toast.POSITION.BOTTOM_LEFT,
        icon: <img src={wifiOn} alt="" className="h-40px w-25px me-3" />,
      });
    } else if (isOnline === false) {
      toast.warning("You are currently offline.", {
        autoClose: false, // Tắt tự động
        position: toast.POSITION.BOTTOM_LEFT,
        icon: <img src={wifiOff} alt="" className="h-40px w-25px me-3" />,
      });
    }
  }, [isOnline]);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    // Cleanup listener khi component unmount
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);
  // useEffect(() => {
  //   if (orderInfo) {
  //     if (dataWaiting.current.length > 0) {
  //       // if(orderInfo){
  //       // setDataWaiting([...dataWaiting, orderInfo])
  //       dataWaiting.current = [...dataWaiting.current, orderInfo];
  //       // }
  //     } else {

  //       // setDataWaiting([orderInfo]);
  //       dataWaiting.current = [orderInfo]
  //       // PrintCard(orderInfo)
  //       // convertHtmlToPDF();
  //     }
  //   }

  // }, [orderInfo])
  useEffect(() => {
    const token = localStorage.getItem("token_custommer");
    const custommerId = localStorage.getItem("custommerId");
    if (
      token &&
      custommerId &&
      window.location.href.search("/webcheckin") > -1
    ) {
      checkToken(token, custommerId);
    }
  }, []);

  const printCardInfo = async (
    arr: Array<any>,
    length: number,
    cartid: string,
    nextcart: string,
    cart: cartInfo,
    table_name: string,
    order_id: string,
    customer_name: string
  ) => {
    // console.log(length);

    var text = `<div className="border-bottom-black"  style="padding-right: 12px">
<div className="w-100">
<div className="d-flex justify-content-between align-items-start w-100">
<div style="display: flex; align-items: flex-start; justify-content: space-between; margin-top: 8px; gap: 4px">
<div  style="font-weight: 600;">${cart?.name}</div>
<div  style="font-weight: 600;flex-shink: 0">x${Number(cart?.quantity)}</div>
</div>
<div className="w-100"  style="display: flex; width: 100%; justify-content: space-between; align-items: flex-start; gap: 4px">
<div className="d-flex flex-column">
${
  cart?.notefood_name
    ? `
<div>-${cart?.notefood_name}</div>
`
    : ""
}
${
  cart?.side_name
    ? `
<div>-${cart?.side_name}</div>
`
    : ""
}
${
  cart?.notechef
    ? `
<div>-${cart?.notechef}</div>
`
    : ""
}

</div>
${
  Number(cart?.type) !== 1
    ? `<div  style="font-weight: 600; flex-shink: 0 ; white-space: nowrap; ">${languageUI.takeAway}</div>`
    : ""
}

</div>

</div>
</div>
</div>`;
    var text2 = `<div className="border-bottom-black"  style="padding-right: 12px">
<div className="w-100">
<div className="d-flex justify-content-between align-items-start w-100">
<div style="display: flex; align-items: flex-start; justify-content: space-between; margin-top: 8px; gap: 4px">
<div  style="font-weight: 600;">${cart?.name}</div>
<div  style="font-weight: 600;flex-shink: 0">x${Number(cart?.quantity)}</div>
</div>
<div className="w-100"  style="display: flex; width: 100%; justify-content: space-between; align-items: flex-start; gap: 4px">
<div className="d-flex flex-column">
${
  cart?.notefood_name
    ? `
<div>-${cart?.notefood_name}</div>
`
    : ""
}
${
  cart?.side_name
    ? `
<div>-${cart?.side_name}</div>
`
    : ""
}
${
  cart?.notechef
    ? `
<div>-${cart?.notechef}</div>
`
    : ""
}
</div>
${
  Number(cart?.type) !== 1
    ? `<div  style="font-weight: 600; flex-shink: 0 ; white-space: nowrap; ">${languageUI.takeAway}</div>`
    : ""
}
</div>

</div>
</div>
</div>`;
    var TextHtml2 = `
<div className="d-flex flex-column w-100 pagebreak"  id=${cart.id} >
<div className="border-bottom-black position-relative line-height-normal abc">
<div className="text-black">
<div className="title-infor-bill-customer-print line-height-normal " style="text-decoration: underline;  font-weight: 600; font-family: "Basicdots" ; line-height: normal; margin: 0px">
${languageUI.tableName}: ${table_name} - Order#${order_id}
</div>
<div className="title-infor-bill-customer-print line-height-normal " style="text-decoration: underline;  font-weight: 600; font-family: "Basicdots" ; line-height: normal; margin: 0px">
${customer_name}
</div>
</div>
${text2}
</div>
</div>
`;
    var TextHtml = `
<div>
<div className="d-flex flex-column w-100 pagebreak" id=${cart.id}>
<div className="border-bottom-black position-relative line-height-normal abc">
<div className="text-black">
<div className="title-infor-bill-customer-print line-height-normal " style="text-decoration: underline;  font-weight: 600; font-family: "Basicdots" ; line-height: normal; margin: 0px">
Table name: ${table_name} - Order#${order_id}
</div>
<div className="title-infor-bill-customer-print line-height-normal " style="text-decoration: underline;  font-weight: 600; font-family: "Basicdots" ; line-height: normal; margin: 0px">
${customer_name}
</div>
</div>
${text}
</div>
</div>
${TextHtml2}
</div>
`;

    setTimeout(() => {
      printJS({
        printable: TextHtml,
        targetStyles: ["*"],
        targetStyle: ["*"],
        frameId: "cardprint",
        type: "raw-html",
        showModal: false,
        style: ".abc {font-size: 30px; color: red;}",
        onError: async (error: any) => {
          console.error("Error Printing: ", error);
        },
        // silent: false,3
        onPrintDialogClose: async () => {
          console.info("Print Window Closed");
        },
      });
      setTimeout(() => {
        if (length > 0) {
          printCardInfo(
            arr,
            length - 1,
            nextcart,
            arr[length - 2]?.id ?? "",
            arr[length - 1],
            table_name,
            order_id,
            customer_name
          );
        } else {
          setOrderInfo(undefined);
          var newWaiting = dataWaiting.current.filter(
            (item) => Number(item?.order_id) !== Number(order_id)
          );
          // setDataWaiting([...newWaiting]);
          dataWaiting.current = [...newWaiting];
          if (newWaiting.length > 0) {
            PrintCard(newWaiting[0]);
            console.log(newWaiting[0]);
          }
        }
      }, 1500);
    });
  };

  const PrintCard = async (data: Order) => {
    console.log(data);

    try {
      // console.log(text);
      // console.log(rawhtml);

      // await printJS({

      //   printable: rawhtml,
      //   targetStyles: ["*"],
      //   targetStyle: ["*"],
      //   frameId: "cardprint",
      //   type: "raw-html",
      //   showModal: false,
      //   css: ["./Styles.css"],
      //   style: ".abc {font-size: 30px; color: red;}",
      //   header: rawhtml,
      //   onError: async (error: any) => {
      //     console.error("Error Printing: ", error);
      //   },
      //   // silent: false,3
      //   onPrintDialogClose: async () => {
      //     console.info("Print Window Closed");

      //   }
      // });

      if (data?.listPrinterName && data?.listPrinterName.length > 0) {
        data?.listPrinterName.map(async (itempr) => {
          if (
            itempr?.printType.some((zItem) =>
              data?.cartInfo.some(
                (iItem) => Number(iItem.typeFood) === Number(zItem)
              )
            )
          ) {
            if (owner_id) {
              const timeZone = await gTimeZoneDefault(owner_id);

              const rawhtml = `
            <!DOCTYPE html>
            <html lang="en">
            
            <head>
              <meta charset="UTF-8">
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <title>Hóa đơn</title>
              <style type="text/css">
                .name-food {
                  font-weight: 600;
                }
            
                .quanlity-number {
                  font-weight: 600;
                }
            
                p, h6 {
                  margin-bottom: 0;
                  margin-top: 0;
                  line-height: normal;
                }
            
                .fw-normal{
                  font-weight: 400;
                }
              </style>
            
            </head>
            
            <body>
            
             <div style="display: table; width: 100%">
                <div style = "display: table-row; padding-top: 10px; width: 100%" >
                  <div style="display: table-cell; width: auto; text-align: left; padding-right: 30px;  padding-top: 10px; ">
                  <h6 class="fw-normal" style=" margin: 0; text-align: start;">${
                    languageUI.guest
                  }: ${data.guestNumber ?? 1}</h6>
                  </div>
                  <div style="display: table-cell; width: auto; text-align: right; padding-right: 10px;  padding-top: 10px; ">
                    <h6 style=" flex-shrink: 0; width: fit-content; white-space: nowrap; font-weight: 600;">${
                      languageUI.time
                    }: ${moment()
                .tz(timeZone?.data?.time_value ?? "America/Los_Angeles")
                .format("h:mm A")}</h6>
                  </div>
                </div>
                 <div style = "display: table-row; padding-top: 10px; width: 100%" >
                  <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 10px; ">
                  <h6 class="fw-normal" style=" margin: 0; text-align: start;">${
                    languageUI.tableName
                  } ${data.table_name ?? "To go"}</h6>
                  </div>
                  <div style="display: table-cell; width: auto; text-align: right; padding-right: 10px;  padding-top: 10px; ">
                    <h6 style=" flex-shrink: 0; width: fit-content; white-space: nowrap; font-weight: 600;">Order-id: ${
                      data.order_id
                    }</h6>
                  </div>
                </div>
              </div>
                <div style="display: table; width: 100%">
                ${data?.cartInfo
                  .map((cart, i) =>
                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    itempr?.printType?.some((ty) => ty === cart.typeFood)
                      ? `<div style = "display: table-row; padding-top: 10px; width: 100%" >
                    <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 10px">
                    <h6  style=" margin: 0; text-align: start;">${i + 1}.${
                          cart?.name
                        }</h6>
                       ${
                         cart?.notefood_name
                           ? `<p class=""  style=" flex-shrink: 0; width: fit-content; font-size: 24px">-${cart?.notefood_name}</p>`
                           : ""
                       }
                         ${
                           cart?.side_name
                             ? `<p class=""  style=" flex-shrink: 0; width: fit-content;font-size: 24px ">-${cart?.side_name}</p>`
                             : ""
                         }
                       ${
                         cart?.notechef
                           ? `<p class=""  style=" flex-shrink: 0; width: fit-content; font-size: 24px">-${cart?.notechef}</p>`
                           : ""
                       }
                    </div>
                    <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:4px;">
                      <h6 class="quanlity-number">x${Number(
                        cart?.quantity
                      )}</h6>
                       ${
                         Number(cart?.type) !== 1
                           ? `<h6 class="quanlity-number">${languageUI.takeAway}</h6>`
                           : `<h6 class="quanlity-number">${languageUI.diner}</h6>`
                       }
                    </div>
                  </div >`
                      : ``
                  )
                  .join(" ")}
                </div>
                     ${
                       data.listSide.length
                         ? `
                        <h6 style=" margin: 0; text-align: start; font-size: 24px " ><li>${
                          languageUI.freeSide
                        }</li></h6>
                        ${data.listSide
                          .map(
                            (item, index) =>
                              `
                        <p style=" margin: 0; text-align: start; font-size: 24px " >- ${
                          index + 1
                        } ${item.name} (x${item.quantity})</p>
                        `
                          )
                          .join(" ")}
                      `
                         : ""
                     }
              </div>
            </body>
            
            </html>
            `;

              const req = await PrintOrderPage(
                Number(data?.ownerID),
                itempr.printer_name,
                rawhtml.toString()
              );

              console.log(req);

              if (!req?.status) {
                // setMessageError(req?.message ?? ("Error printing on " + item.printer_name))
                // setShowToastError(true)
                // toast.warning(req?.message ?? ("Error printing on " + item.printer_name))
              }
            }
          }
        });
      } else {
        setMessageError("Please configure the printer in settings!");
        setShowToastError(true);
      }

      // const req1 = await PrintOrderPage(1, 'XP-DF', rawhtml.toString());

      // setTimeout(() => {
      //   setOrderInfo(undefined)
      //   var newWaiting = dataWaiting.current.filter(item => item?.order_id != data?.order_id);
      //   // setDataWaiting([...newWaiting]);
      //   dataWaiting.current = [...newWaiting];

      //   if (newWaiting.length > 0) {
      //     PrintCard(newWaiting[0])
      //   }
      //   // const arr = data?.cartInfo ?? [];
      //   // printCardInfo(arr, arr.length - 1, arr[arr.length - 1]?.id, arr[arr.length - 2]?.id ?? '', arr[arr.length - 1], data?.table_name ?? '', data?.order_id ?? '', data?.customer_name ?? '')
      // }, 2000);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route path="/web-owner" element={<AppLayout />}>
            <Route index element={<POSTable />} />
            <Route path="/web-owner/qrcode" element={<QRCodeOwner />} />
            <Route path="/web-owner/tablepos" element={<POSTable />} />
            <Route path="/web-owner/reservation" element={<Reservation />} />
            <Route path="/web-owner/posmenu" element={<POSMenu />} />
            <Route path="/web-owner/order" element={<OwnerOrderPage />} />
            <Route path="/web-owner/history" element={<HistoryPage />} />
            <Route path="/web-owner/pickup" element={<PickupPage />} />

            <Route
              path="/web-owner/customerreciepts"
              element={<CustomerReciepts />}
            />
            <Route
              path="/web-owner/clockinclockout"
              element={<ClockInClockOut />}
            />
            <Route path="/web-owner/pos-setting" element={<POSSystem />} />
            <Route
              path="/web-owner/settingstaffinformation"
              element={<SettingInformation />}
            />
            <Route path="/web-owner/qrcode" element={<QRCodeOwner />} />
            <Route
              path="/web-owner/settingownerinformation"
              element={<SettingInformation />}
            />
            <Route path="/web-owner/setuptable" element={<SetupTable />} />
            <Route path="/web-owner/menutemplate" element={<TemplateMenu2 />} />
            <Route path="/web-owner/customer" element={<Customer />} />
            <Route path="/web-owner/report" element={<Report />} />
            <Route
              path="/web-owner/report/incomedetail"
              element={<IncomeDetail />}
            />
            <Route
              path="/web-owner/report/incomedatedetail"
              element={<IncomeDateDetail />}
            />
            <Route path="/web-owner/review" element={<ReviewFood />} />
            <Route path="/web-owner/detailreview" element={<ReviewDetail />} />
            <Route path="/web-owner/inventory" element={<Inventory />} />
            <Route path="/web-owner/staffs" element={<Staff />} />
            <Route path="/web-owner/creatstaffs" element={<CreateStaff />} />
            <Route path="/web-owner/editstaffs" element={<EditCreateStaff />} />
            <Route path="/web-owner/setupvoucher" element={<VoucherOwner />} />
            <Route
              path="/web-owner/subscription"
              element={<SubscriptionOwner />}
            />
            <Route path="/web-owner/feedback" element={<FeedBack />} />
            <Route path="/web-owner/salaryweek" element={<SalaryEachWeek />} />
            <Route
              path="/web-owner/salary2week"
              element={<SalaryEach2Week />}
            />
            <Route
              path="/web-owner/salarymonth"
              element={<SalaryEachMonth />}
            />
            <Route
              path="/web-owner/tablesalaryweek"
              element={<TableSalaryEmployee />}
            />
            <Route
              path="/web-owner/tablesalary2week"
              element={<TableSalaryEmployee2Week />}
            />
            <Route
              path="/web-owner/tablesalarymonth"
              element={<TableSalaryEmployeeMonth />}
            />
            <Route
              path="/web-owner/offlinebooking"
              element={<OfflineBooking />}
            />
            <Route path="/web-owner/giftCard" element={<GiftCard />}></Route>
            <Route
              path="/web-owner/handheldPOS"
              element={<HandheldPOS />}
            ></Route>
            <Route
              path="/web-owner/giftCardHistory"
              element={<GiftCardHistory />}
            ></Route>

            <Route
              path="/web-owner/giftCardDetail/:voucher_id/:is_never_expire"
              element={<GiftCardDetail />}
            ></Route>
          </Route>
          <Route path="/web-owner/pos" element={<ChossePOS />}></Route>
          <Route
            path="/gift-card-balance/:voucherCode"
            element={<GiftCardBalance />}
          />
          //Order online
          <Route
            path="/online/login/:ownerCode"
            element={<MenuOrderOnline />}
          ></Route>
          <Route path="/online/menu/:owner_id" element={<MenuOnline />}></Route>
          <Route
            path="/online/orderstatus"
            element={<OrderStatusOnline />}
          ></Route>
          <Route
            path="/online/cardinformation"
            element={<CardInformationOnline />}
          ></Route>
          <Route path="/online/payment" element={<PaymentOnline />}></Route>
          <Route
            path="/online/showpoint/:id"
            element={<ShowPointOnline />}
          ></Route>
          <Route path="/online/mybill" element={<MyBillOnline />}></Route>
          <Route
            path="/online/mybilldetail/:ownerId/:orderId"
            element={<MyBillOnlineDetail />}
          ></Route>
          <Route
            path="/online/statusOrderMethodOnline/:id"
            element={<StatusOrderMethodOnline />}
          ></Route>
          <Route
            path="/online/bookingOnline"
            element={<BookingOnline />}
          ></Route>
          <Route
            path="/online/listReservation"
            element={<ListReservation />}
          ></Route>
          <Route
            path="/online/reservationDetail/:reservation_id"
            element={<ReservationDetail />}
          ></Route>
          <Route path="/apps/calendar" element={<CalendarApp />}></Route>
          <Route path="/apps/chat" element={<ChatApp />}></Route>
          <Route
            path="/apps/ecommerce/dashboard"
            element={<EcommerceDashboard />}
          ></Route>
          <Route
            path="/apps/ecommerce/products"
            element={<EcommerceProducts />}
          ></Route>
          <Route
            path="/apps/ecommerce/product-details"
            element={<ProductDetails />}
          ></Route>
          <Route
            path="/apps/ecommerce/edit-product"
            element={<ProductEdit />}
          ></Route>
          <Route
            path="/apps/ecommerce/customers"
            element={<Customers />}
          ></Route>
          <Route path="/apps/ecommerce/orders" element={<Orders />}></Route>
          <Route
            path="/apps/ecommerce/order/details"
            element={<OrderDetails />}
          ></Route>
          <Route path="/apps/ecommerce/sellers" element={<Sellers />}></Route>
          <Route
            path="/apps/ecommerce/shopping-cart"
            element={<Cart />}
          ></Route>
          <Route path="/apps/ecommerce/checkout" element={<Checkout />}></Route>
          <Route
            path="/landingpage/:owner_id"
            element={<LandingPage />}
          ></Route>
          <Route
            path="/landingpagemonth/:owner_id"
            element={<LandingPageMonth />}
          ></Route>
          <Route
            path="/createaccountowner"
            element={<CreatFormLandingPage />}
          ></Route>
          <Route path="/yearlyplan/:owner_id" element={<YearlyPlan />}></Route>
          <Route
            path="/createaccountyearly"
            element={<CreatFormYearly />}
          ></Route>
          <Route
            path="/createaccountmonth"
            element={<CreatFormMonth />}
          ></Route>
          //CRM App Routes
          <Route path="/apps/crm/dashboard" element={<CRMDashboard />}></Route>
          <Route path="/apps/crm/contacts" element={<CRMContacts />}></Route>
          <Route
            path="/apps/crm/opportunities"
            element={<Opportunities />}
          ></Route>
          <Route path="/apps/crm/leads" element={<CRMLeads />}></Route>
          <Route path="/apps/crm/customers" element={<CRMCustomers />}></Route>
          //Email App Routes
          <Route path="/apps/email/inbox" element={<Inbox />}></Route>
          <Route path="/apps/email/details" element={<EmailDetail />}></Route>
          <Route path="/apps/email/compose" element={<EmailCompose />}></Route>
          //Social App Routes
          <Route path="/apps/social-feed" element={<SocialFeed />}></Route>
          //Companies App Routes
          <Route path="/apps/companies" element={<Companies />}></Route>
          //Project App Routes
          <Route path="/apps/projects/list" element={<Projects />}></Route>
          <Route
            path="/apps/projects/:id/details"
            element={<ProjectDetail />}
          ></Route>
          <Route path="/apps/projects/create" element={<ProjectForm />}></Route>
          //Task App Routes
          <Route path="/apps/tasks/list" element={<TaskList />}></Route>
          <Route path="/apps/tasks/details" element={<TaskDetails />}></Route>
          <Route path="/apps/tasks/kanban" element={<Kanban />}></Route>
          //Contacts Routes
          <Route path="/apps/contacts/list" element={<ContactsList />}></Route>
          <Route
            path="/apps/contacts/profile"
            element={<ContactsProfile />}
          ></Route>
          //Tickets Routes
          <Route path="/apps/tickets/list" element={<TicketsList />}></Route>
          <Route
            path="/apps/tickets/details"
            element={<TicketsDetails />}
          ></Route>
          //File App Routes
          <Route path="/apps/file-manager" element={<FileManager />}></Route>
          //Extra Pages Routes
          <Route path="/pages/starter" element={<Starter />}></Route>
          <Route path="/pages/timeline" element={<Timeline />}></Route>
          <Route path="/pages/sitemap" element={<Sitemap />}></Route>
          <Route path="/pages/invoice" element={<Invoice />}></Route>
          <Route path="/pages/faq" element={<FAQ />}></Route>
          <Route
            path="/pages/serach-results"
            element={<SearchResults />}
          ></Route>
          <Route path="/pages/pricing" element={<Pricing />}></Route>
          <Route path="/pages/gallery" element={<Gallery />}></Route>
          <Route path="/pages/error-404-alt" element={<Error404Alt />}></Route>
          <Route path="/kitchencounter" element={<KitchenCounter />}></Route>
          <Route
            path="/kitchencounterhistory"
            element={<KitchenCounterHistory />}
          ></Route>
          //UI Routes
          <Route path="/ui/buttons" element={<Buttons />}></Route>
          <Route path="/ui/cards" element={<Cards />}></Route>
          <Route path="/ui/avatars" element={<Avatars />}></Route>
          <Route path="/ui/portlets" element={<Portlets />}></Route>
          <Route path="/ui/progress" element={<Progress />}></Route>
          <Route path="/ui/modals" element={<Modals />}></Route>
          <Route path="/ui/notifications" element={<Notifications />}></Route>
          <Route path="/ui/offcanvas" element={<Offcanvases />}></Route>
          <Route path="/ui/placeholders" element={<Placeholders />}></Route>
          <Route path="/ui/spinners" element={<Spinners />}></Route>
          <Route path="/ui/images" element={<Images />}></Route>
          <Route path="/ui/carousel" element={<Carousels />}></Route>
          <Route path="/ui/listgroups" element={<ListGroups />}></Route>
          <Route path="/ui/embedvideo" element={<EmbedVideo />}></Route>
          <Route path="/ui/dropdowns" element={<Dropdowns />}></Route>
          <Route path="/ui/ribbons" element={<Ribbons />}></Route>
          <Route
            path="/ui/tooltips-popovers"
            element={<TooltipsPopovers />}
          ></Route>
          <Route path="/ui/typography" element={<Typography />}></Route>
          <Route path="/ui/grid" element={<Grid />}></Route>
          <Route path="/ui/general" element={<GeneralUI />}></Route>
          //Extend UI
          <Route
            path="/extended-ui/nestable"
            element={<NestableList />}
          ></Route>
          <Route path="/extended-ui/dragdrop" element={<DragDrop />}></Route>
          <Route path="/extended-ui/animation" element={<Animation />}></Route>
          <Route
            path="/extended-ui/sweet-alert"
            element={<SweetAlerts />}
          ></Route>
          <Route path="/extended-ui/tour" element={<TourPage />}></Route>
          <Route
            path="/extended-ui/loading-buttons"
            element={<LoadingButtons />}
          ></Route>
          //Extend Widgets
          <Route path='/ui/icons/two-tone"' element={<TwoToneIcons />}></Route>
          <Route path='/ui/icons/feather"' element={<FeatherIcons />}></Route>
          <Route path='/ui/icons/dripicons"' element={<Dripicons />}></Route>
          <Route path="/ui/icons/mdi" element={<MDIIcons />}></Route>
          <Route
            path="/ui/icons/font-awesome"
            element={<FontAwesomeIcons />}
          ></Route>
          <Route path="/ui/icons/themify" element={<ThemifyIcons />}></Route>
          <Route
            path="/ui/icons/simple-line"
            element={<SimpleLineIcons />}
          ></Route>
          <Route path="/ui/icons/weather" element={<WeatherIcons />}></Route>
          //UI Forms
          <Route path="/ui/forms/basic" element={<BasicForms />}></Route>
          <Route path="/ui/forms/advanced" element={<FormAdvanced />}></Route>
          <Route
            path="/ui/forms/validation"
            element={<FormValidation />}
          ></Route>
          <Route path="/ui/forms/wizard" element={<FormWizard />}></Route>
          <Route path="/ui/forms/upload" element={<FileUpload />}></Route>
          <Route path="/ui/forms/editors" element={<Editors />}></Route>
          //UI Tables
          <Route path="/ui/tables/basic" element={<BasicTables />}></Route>
          <Route
            path="/ui/tables/advanced"
            element={<AdvancedTables />}
          ></Route>
          //UI Chart
          <Route path="/ui/charts/apex" element={<ApexChart />}></Route>
          <Route path="/ui/charts/chartjs" element={<ChartJs />}></Route>
          //--------------Web Check-in------------ //Check-in review
          <Route path="/rv/:idSendSMS" element={<Rv />}></Route>
          <Route
            path="/review-restaurant/:idSendSMS"
            element={<ReviewRestaurant />}
          ></Route>
          <Route path="/review-success" element={<ReviewSuccess />}></Route>
          <Route path="/shrv/:id_review" element={<ShRv />}></Route>
          //web check-in
          <Route path="/webcheckin/scanqr" element={<QrCodeCheckIn />}></Route>
          <Route
            path="/webcheckin/sendmenu/:id/:table_id"
            element={<SendMenuToPhone />}
          ></Route>
          <Route
            path="/webcheckin/privacypolicy/:owner_id"
            element={<PrivacyPolicy />}
          ></Route>
          <Route
            path="/webcheckin/teamofuse/:owner_id"
            element={<TeamOfUse />}
          ></Route>
          <Route path="/webcheckin/sendmenu" element={<ErrorScanQr />}></Route>
          <Route
            path="/webcheckin/enterfirstname/:id"
            element={<EnterFirstName />}
          ></Route>
          <Route
            path="/webcheckin/choosemenu/:id"
            element={<ChooseMenu />}
          ></Route>
          <Route
            path="/webcheckin/OrderStatus"
            element={<OrderStatus />}
          ></Route>
          <Route path="/webcheckin/Payment" element={<Payment />}></Route>
          <Route
            path="/webcheckin/enterbirthday/:id"
            element={<EnterBirthday />}
          ></Route>
          <Route
            path="/webcheckin/enterpersontitle/:id"
            element={<EnterPersonTitle />}
          ></Route>
          <Route
            path="/webcheckin/showpointcheckin/:id"
            element={<ShowPointsCheckin />}
          ></Route>
          <Route
            path="/webcheckin/waittcheckin/:id"
            element={<WaitCheckin />}
          ></Route>
          {/* <Route path='/webcheckin/paymentstatus' element={<PaymentStatus/>}></Route> */}
          <Route path="/webcheckin/mybill" element={<MyBill />}></Route>
          <Route
            path="/webcheckin/checkinreview/:idowner/:idcustomer/:idSendSMS"
            element={<CheckInReview />}
          ></Route>
          <Route
            path="/webcheckin/showreview/:id_review"
            element={<ShowReview />}
          ></Route>
          <Route
            path="/webcheckin/cardInformation"
            element={<CartInformation />}
          ></Route>
          <Route
            path="/webcheckin/historyOrder"
            element={<HistoryOrder />}
          ></Route>
          <Route
            path="/webcheckin/historyOrderDetail"
            element={<HistoryOrderDetail />}
          ></Route>
          <Route
            path="/webcheckin/ratingStaff/:review_token"
            element={<RatingStaff />}
          ></Route>
          <Route
            path="/webcheckin/thankyouForReview"
            element={<ThankyouForReview />}
          ></Route>
          <Route
            path="/webcheckin/tokenNotFound"
            element={<TokenErrPage />}
          ></Route>
          <Route path="/webcheckin/snakeGame" element={<SnakeGame />}></Route>
          <Route
            path="/webcheckin/flappyBird"
            element={<GameFlaapyBird />}
          ></Route>
          <Route path="/webcheckin/whackamole" element={<WhackAMole />}></Route>
          <Route path="/webcheckin/listGame" element={<ListGame />}></Route>
          <Route path="/webcheckin/rewardPage" element={<RewardPage />}></Route>
          // web owner route
          <Route path="/web-owner/login" element={<LoginOwner />}></Route>
          <Route
            path="/web-owner/gotoowner/:ownerCode"
            element={<GoToOwner />}
          ></Route>
          <Route
            path="/web-owner/customer-type"
            element={<CustomerType />}
          ></Route>
          {/* <Route path='/web-owner/qrcode' element={<QRCodeOwner/>}></Route> */}
          {/* <Route path='/web-owner/review' element={<ReviewOwner />}></Route> */}
          <Route
            path="/web-owner/setting-change-password"
            element={<SettingChangePassword />}
          ></Route>
          {/* <Route path='/web-owner/setting-information' element={<SettingInformation />}></Route> */}
          <Route
            path="/web-owner/subscription"
            element={<SubscriptionOwner />}
          ></Route>
          <Route path="/web-owner/POSTable" element={<POSTable />}></Route>
          <Route path="/web-owner/POSMenu" element={<POSMenu />}></Route>
          <Route
            path="/web-owner/PaymentTable"
            element={<PaymentTable />}
          ></Route>
          <Route
            path="/web-owner/PaymentBillInofrmation"
            element={<PaymentBillInofrmation />}
          ></Route>
          <Route
            path="/web-owner/template-menu"
            element={<TemplateMenu2 />}
          ></Route>
          {/* <Route
            path="/web-owner/template-menu2"
            element={<TemplateMenu />}
          ></Route> */}
          <Route
            path="/web-owner/owner-infor"
            element={<AccountInfor />}
          ></Route>
          <Route
            path="/web-owner/review-from-customer/:customerId"
            element={<GetReviewFromCustomer />}
          ></Route>
          // Admin
          <Route path="/web-admin" element={<AppLayoutAdmin />}>
            <Route index element={<AccountStaff />} />
            <Route
              path="/web-admin/customer-overview-admin"
              element={<CustomerOverviewAdmin />}
            ></Route>
            <Route
              path="/web-admin/group-customer-admin"
              element={<GroupCustomerAdmin />}
            ></Route>
            <Route
              path="/web-admin/create-customer-admin"
              element={<CreateCustomerAdmin />}
            ></Route>
            <Route
              path="/web-admin/feedback"
              element={<FeedbackAdmin />}
            ></Route>
            <Route
              path="/web-admin/device-customer"
              element={<DeviceCustomer />}
            ></Route>
            <Route
              path="/web-admin/customer-device-detail"
              element={<DeviceCustomerDetail />}
            ></Route>
            <Route
              path="/web-admin/setting-change-password"
              element={<SettingChangePasswordAdmin />}
            ></Route>
            <Route
              path="/web-admin/setting-update-phone-number"
              element={<SettingUpdatePhoneNumberAdmin />}
            ></Route>
            <Route
              path="/web-admin/setting-config"
              element={<SettingConfig />}
            ></Route>
            <Route
              path="/web-admin/subscription-admin"
              element={<SubscriptionAdmin />}
            ></Route>
            <Route
              path="/web-admin/acccount-infor"
              element={<AccountInforDetail />}
            ></Route>
            <Route
              path="/web-admin/edit-setting-customer-infor/:idcustomer"
              element={<EditSettingInformationOfCustomer />}
            ></Route>
            <Route
              path="/web-admin/qrcode/:id_customer"
              element={<ViewQRCode />}
            ></Route>
          </Route>
          <Route path="/web-admin/login" element={<LoginAdmin />}></Route>
          <Route
            path="/web-admin/forget-password-admin"
            element={<ForgetPasswordAdmin />}
          ></Route>
          // auth
          <Route path="/" element={<Login />}></Route>
          <Route path="/auth/login" element={<Login />}></Route>
          <Route path="/auth/register" element={<Register />}></Route>
          <Route path="/auth/register2" element={<Register2 />}></Route>
          <Route path="/auth/signin-signup" element={<SignInSignUp />}></Route>
          <Route
            path="/auth/signin-signup2"
            element={<SignInSignUp2 />}
          ></Route>
          <Route path="/auth/lock-screen" element={<LockScreen />}></Route>
          <Route path="/auth/lock-screen2" element={<LockScreen2 />}></Route>
          <Route path="/auth/logout" element={<Logout />}></Route>
          <Route path="/auth/logout2" element={<Logout2 />}></Route>
          <Route path="/auth/login2" element={<Login2 />}></Route>
          <Route path="/auth/confirm2" element={<Confirm2 />}></Route>
          <Route path="/auth/confirm" element={<Confirm />}></Route>
          <Route
            path="/auth/forget-password"
            element={<ForgetPassword />}
          ></Route>
          <Route
            path="/auth/forget-password2"
            element={<ForgetPassword2 />}
          ></Route>
          //TESTPAGE
          <Route path="/testpage" element={<TestPage />}></Route>
          // Public routes
          <Route path="/Landing" element={<Landing />}></Route>
          <Route path="/maintenance" element={<Maintenance />}></Route>
          <Route path="/error-404" element={<Error404 />}></Route>
          <Route path="/error-404-two" element={<Error404Two />}></Route>
          <Route path="/error-500" element={<Error500 />}></Route>
          <Route path="/error-500-two" element={<Error500Two />}></Route>
          <Route path="/upcoming" element={<Upcoming />}></Route>
        </Routes>
      </BrowserRouter>
      <ModalNotification
        handleClose={dismisModalNotification}
        show={showModalNotification}
        content={notification}
        onPlay={onPlay}
        audioNotif={audioNotif}
      />
      <ModalTimeOutToken
        show={showModalTimeOutToken}
        handleClose={dismisModalShowTimeOutToken}
      />
      <ModalToast
        show={showToastError}
        handleClose={() => setShowToastError(false)}
        content={messageError}
      />

      {/* <AudioPlayer
        //autoPlay
        ref={audioNotif}
        src="http://brijack.bdata.link/public/notification.mp3"
        className="recored-audio-player d-none"
        onPlay={() => onPlay()}
        onPause={() => onPause()} 
        onError={(e) => onError(e)}
      /> */}
    </>
  );
};

export default App;
