import { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as Request from "../../api/Request";

// components
import { useParams } from 'react-router';
import { gPointPageNote } from "../../api/apiOwner/AccountInfor";
import { addToken, checkSentSmsCustomer, conversionPointCustomer } from "../../api/apiCheckin/checkinApi";
import Swal from "sweetalert2";
import ModalLoading from "../../components/ModalLoading";
import { getLanguageCheckIn } from "../../Services/languageCheckin";

const ShowPointsCheckin = () => {
  const languageUI = getLanguageCheckIn();
  const navigate = useNavigate();
  const ownerID = Number(localStorage.getItem("ownerID"));
  const params: any = useParams();
  let id_customer = params.id;
  const [ShowLoading, setShowLoading] = useState<boolean>(false);
  const [points, setPoints] = useState<any>('');
  const [pointPage, setPointPage] = useState<number>(0);
  const [pointDiscount, setPointDiscount] = useState<string>('');

  const gInfoCustomer = async (id_customer: any) => {
    await Request.fetchAPI(
      `checkin/gInfoCustomer.php?id=${id_customer}`,
      "get"
    ).then((res) => {
      if (Number(res.status) === 1) {
        setPoints(res?.data?.points);
      }
    })
      .catch((error: any) => {
        console.log(error);
      });
  };


  let bodyEl = document?.querySelector("body");
  bodyEl?.classList.remove("pb-0", "m-0", "birthday-page");
  const gPoint = async () => {
    if (ownerID) {
      const req = await gPointPageNote(ownerID);
      if (Number(req?.status) === 1) {
        setPointPage(Number(req?.data?.point_page))
        setPointDiscount(req?.data?.discount_page ?? '')
      }
    }
  }

  const AddToken = async () => {
    const req = await addToken(id_customer, ownerID);
    if (Number(req?.status) === 1 && req?.data) {
      localStorage.setItem("token_custommer", req.data);
      setShowLoading(false)
      navigate(`/webcheckin/choosemenu/${id_customer}`)

    } else {
      setShowLoading(false)
      Swal.fire({
        position: "center",
        icon: "error",
        title: req?.message,
        showConfirmButton: false,
        timer: 3000,
      });
    }
  }

  const GoToMenu = async () => {
    setShowLoading(true)
    const req = await conversionPointCustomer(ownerID, id_customer);
    if (Number(req?.status) === 1) {
      AddToken()
    } else {
      setShowLoading(false)
      Swal.fire({
        position: "center",
        icon: "error",
        title: req?.message,
        showConfirmButton: false,
        timer: 3000,
      });
    }

  }



  useEffect(() => {
    gPoint();
    gInfoCustomer(id_customer);
  }, [id_customer]);
  return (
    <>
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
      <div className="account-pages d-flex" style={{ minHeight: "100vh" }}>
        <Container className="m-auto">
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="bg-pattern rounded-3 shadow mb-0">
                <Card.Body className="p-4">
                  <h4 className="text-center mb-4 mt-4">{languageUI.yourPoints}</h4>
                  <div
                    style={{ width: 100, height: 100, border: "3px solid" }}
                    className="rounded-circle border-primary d-flex mx-auto"
                  >
                    <h2 className="m-auto text-primary">{points}</h2>
                  </div>
                  <div className="px-10 mt-3">
                    <p className="mb-0 text-center">{languageUI.ForEveryDoolarYouSpend}</p>
                    {getLanguageCheckIn().LG === 1 ? (<p className="d-flex gap-1 mb-0 text-center flex-wrap justify-content-center">Once you accumulate <p className="mb-0 text-danger">{pointPage}</p> points, we will <p className="mb-0">provide you with a discount of</p> <p className="mb-0 text-danger">{pointDiscount}</p> off</p>) : (
                      <p className="d-flex gap-1 mb-0 text-center flex-wrap justify-content-center">Một khi bạn tích lũy <p className="mb-0 text-danger">{pointPage}</p> điểm, chúng tôi sẽ <p className="mb-0">cung cấp cho bạn giảm giá</p> <p className="mb-0 text-danger">{pointDiscount}</p></p>
                    )}

                  </div>
                  <div className="text-center">
                    <div
                      className="d-inline-block"
                      onClick={GoToMenu}
                    >
                      <Button
                        variant="outline-primary"
                        className="waves-effect waves-light mb-2 mt-4"
                      >
                        {languageUI.go}
                      </Button>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ShowPointsCheckin;
