import * as Request from "../Request";
export interface ResultMenu<T> {
  status: number,
  message?: string,
  data: T
}
export interface isConfigUpsale {
  is_show_upsale: string
}
export interface InfoComboFood {
  id: string;
  service_id: string;
  service_item_code: string;
  name: string;
  description: string;
  price: string;
  imageCourse: string;
  service_code: string;
  owner_id: string;
  name_foodgroup: string;
  checked: number
}
export interface FoodSaleInfo {
  food_id: 1,
  service_item_id: string,
  owner_id: string,
  service_id: string,
  service_item_code: string,
  name: string,
  description: string,
  price: string,
  imageCourse: string
}
export interface DrinkSaleInfo {
  drink_id: 1,
  service_item_id: string,
  owner_id: string,
  service_id: string,
  service_item_code: string,
  name: string,
  description: string,
  price: string,
  imageCourse: string
}
export const gComboFoodsUpSale = async (owner_id: string) => {
  return await Request.fetchAPI(`owner/gComboFoods.php`, "POST", { owner_id })
    .then((res: ResultMenu<InfoComboFood[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: []
      };
    });
}
export const gComboDrinksUpSale = async (owner_id: string) => {
  return await Request.fetchAPI(`/owner/gComboDrinks.php`, "POST", { owner_id })
    .then((res: ResultMenu<InfoComboFood[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: []
      };
    });
}
export const gConfigUpsale = async (owner_id: string) => {
  return await Request.fetchAPI(`/owner/gConfigUpsale.php`, "POST", { owner_id })
    .then((res: ResultMenu<isConfigUpsale>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: undefined
      };
    });
}
export const upConfigUpsale = async (owner_id: string, is_show_upsale: number) => {
  return await Request.fetchAPI(`/owner/upConfigUpsale.php`, "POST", { owner_id, is_show_upsale })
    .then((res: ResultMenu<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: ''
      };
    });
}
export const addUpsaleDrinks = async (owner_id: number, listDrinks: string[]) => {
  return await Request.fetchAPI(`/owner/addUpsaleDrinks.php`, "POST", { owner_id, listDrinks: JSON.stringify(listDrinks) })
    .then((res: ResultMenu<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: ''
      };
    });
}
export const addUpsaleFoods = async (owner_id: number, listFoods: string[]) => {
  return await Request.fetchAPI(`/owner/addUpsaleFoods.php`, "POST", { owner_id, listFoods: JSON.stringify(listFoods) })
    .then((res: ResultMenu<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: ''
      };
    });
}

export const gListFoodSaleChoosed = async (owner_id: number) => {
  return await Request.fetchAPI(`owner/gListFoodSaleChoosed.php`, "POST", { owner_id })
    .then((res: ResultMenu<FoodSaleInfo[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: []
      };
    });
}
export const gListDrinkSaleChoosed = async (owner_id: number) => {
  return await Request.fetchAPI(`owner/gListDrinkSaleChoosed.php`, "POST", { owner_id })
    .then((res: ResultMenu<DrinkSaleInfo[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: []
      };
    });
}