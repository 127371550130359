import "./Styles.css";
import * as userSlice from "../../../../../../store/slices/userSlice";
import { useSelector } from "react-redux";
import ModalLoading from "../../../../../../components/ModalLoading";
import React, { useEffect, useState } from "react";
import Select2 from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {
  arrowLeftPage,
  arrowRightPage,
} from "../../../../../../components/ImgExport";
import {
  gStaffReviewByOwner,
  listStaffReview,
} from "../../../../../../api/apiOwner/StaffApi";
import { getLanguageUI } from "../../../../../../Services/languageUI";
import { Rating } from "@mui/material";

interface StaffReviewProp {}
const StaffReview: React.FC<StaffReviewProp> = () => {
  const languageUI = getLanguageUI().staffPage;
  const UserIdStore = useSelector(userSlice.selectorUserId);
  const [chooseOptionEmployee, setChooseOptionEmployee] = useState<number>(0);
  const [ShowLoading, setShowLoading] = useState<boolean>(false);
  const [pageCurrent, setPageCurrent] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [totalRow, setTotalRow] = useState<number>(1);
  const [pageGoTo, setPageGoTo] = useState<number>();
  const [limitRow, setLimitRow] = useState<number>(50);
  const [listArrStaffReview, setListArrStaffReview] = useState<
    listStaffReview[]
  >([]);

  const optionPage = [
    {
      value: 50,
      page: ` ${languageUI.show} 50 / ${languageUI.page}`,
    },
    {
      value: 100,
      page: ` ${languageUI.show} 100 / ${languageUI.page}`,
    },
    {
      value: 0,
      page: languageUI.showAll,
    },
  ];

  const handleChangeShowRow = (value: number) => {
    setLimitRow(value);
    setPageCurrent(1);
  };
  const pagingRender = () => {
    if (totalPage <= 5) {
      let pagelist = [];
      for (let index = 1; index <= totalPage; index++) {
        pagelist.push(
          <button
            key={"page" + index}
            className={` ${
              pageCurrent === index
                ? "btn-page-customer-receipts-target"
                : "btn-page-customer-receipts"
            }`}
            onClick={() => {
              setPageCurrent(index);
            }}
          >
            {index}
          </button>
        );
      }
      return pagelist;
    } else {
      let pagelist = [];
      if (pageCurrent <= 4) {
        for (let index = 1; index <= 5; index++) {
          pagelist.push(
            <button
              key={"page" + index}
              className={` ${
                pageCurrent === index
                  ? "btn-page-customer-receipts-target"
                  : "btn-page-customer-receipts"
              }`}
              onClick={() => {
                setPageCurrent(index);
              }}
            >
              {index}
            </button>
          );
        }
        pagelist.push(
          <button key={"page..."} className={`btn-page-customer-receipts`}>
            ...
          </button>
        );
        pagelist.push(
          <button
            key={"page" + totalPage}
            className={` ${
              pageCurrent === totalPage
                ? "btn-page-customer-receipts-target"
                : "btn-page-customer-receipts"
            }`}
            onClick={() => {
              setPageCurrent(totalPage);
            }}
          >
            {totalPage}
          </button>
        );
      } else {
        //p=5
        var dem = 0;
        for (
          let index = pageCurrent - 3;
          index <=
          (totalPage - pageCurrent > 4 ? pageCurrent + 3 : totalPage - 1);
          index++
        ) {
          if (dem === 0) {
            pagelist.push(
              <button
                key={"page1"}
                className={` ${
                  pageCurrent === 1
                    ? "btn-page-customer-receipts-target"
                    : "btn-page-customer-receipts"
                }`}
                onClick={() => {
                  setPageCurrent(1);
                }}
              >
                {1}
              </button>
            );
            pagelist.push(
              <button key={"page...2"} className={`btn-page-customer-receipts`}>
                ...
              </button>
            );
            dem++;
          }
          pagelist.push(
            <button
              key={"page" + index}
              className={` ${
                pageCurrent === index
                  ? "btn-page-customer-receipts-target"
                  : "btn-page-customer-receipts"
              }`}
              onClick={() => {
                setPageCurrent(index);
              }}
            >
              {index}
            </button>
          );
        }
        if (totalPage - pageCurrent > 4) {
          pagelist.push(
            <button key={"page...3"} className={`btn-page-customer-receipts`}>
              ...
            </button>
          );
          pagelist.push(
            <button
              key={"page" + totalPage}
              className={` ${
                pageCurrent === totalPage
                  ? "btn-page-customer-receipts-target"
                  : "btn-page-customer-receipts"
              }`}
              onClick={() => {
                setPageCurrent(totalPage);
              }}
            >
              {totalPage}
            </button>
          );
        } else {
          pagelist.push(
            <button
              key={"page" + totalPage}
              className={` ${
                pageCurrent === totalPage
                  ? "btn-page-customer-receipts-target"
                  : "btn-page-customer-receipts"
              }`}
              onClick={() => {
                setPageCurrent(totalPage);
              }}
            >
              {totalPage}
            </button>
          );
        }
      }

      return pagelist;
    }
    // return pagelist;
  };

  const getStaffReviewByOwner = async (reviewTypeId: number) => {
    setShowLoading(true);
    if (UserIdStore) {
      const req = await gStaffReviewByOwner(
        Number(UserIdStore),
        pageCurrent,
        reviewTypeId,
        limitRow
      );
      if (Number(req.status) === 1) {
        setListArrStaffReview(req.data ?? []);
        setTotalPage(req.totalPage ? Number(req.totalPage) : 1);
        setTotalRow(req.total ? Number(req.total) : 1);
        setShowLoading(false);
      }
    }
  };

  useEffect(() => {
    setPageGoTo(pageCurrent);
    getStaffReviewByOwner(chooseOptionEmployee);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageCurrent, limitRow, chooseOptionEmployee]);

  useEffect(() => {
    setPageGoTo(pageCurrent);
  }, [pageCurrent]);
  return (
    <>
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
      <div className="">
        <div className="bg-white radius-18px w-100 ">
          {/* <div className="d-flex w-100 justify-content-between align-items-center">
            <div className="h-70px custom-radius-table  bg-white px-4 d-flex align-items-center border-bottom container-fluid justify-content-between">
              <div className="d-flex align-items-center gap-3">
                <button
                  className={` h-48px w-100px d-flex justify-content-center align-items-center px-4 radius-2  text-12px fw-600 font-urbansist ${
                    chooseOptionEmployee === 0
                      ? "bg-0FA54A border-none text-white"
                      : "border-DEE2E6 text-4A4A6A bg-white"
                  }`}
                  onClick={() => {
                    setPageCurrent(1);
                    setChooseOptionEmployee(0);
                  }}
                >
                  {languageUI.all}
                </button>
                <button
                  className={` h-48px w-100px d-flex justify-content-center align-items-center px-4 radius-2  text-12px fw-600 font-urbansist ${
                    chooseOptionEmployee === 1
                      ? "bg-0FA54A border-none text-white"
                      : "border-DEE2E6 text-4A4A6A bg-white"
                  }`}
                  onClick={() => {
                    setPageCurrent(1);
                    setChooseOptionEmployee(1);
                  }}
                >
                  {languageUI.good}
                </button>
                <button
                  className={` h-48px w-100px d-flex justify-content-center align-items-center px-4 radius-2  text-12px fw-600 font-urbansist ${
                    chooseOptionEmployee === 2
                      ? "bg-0FA54A border-none text-white"
                      : "border-DEE2E6 text-4A4A6A bg-white"
                  }`}
                  onClick={() => {
                    setPageCurrent(1);
                    setChooseOptionEmployee(2);
                  }}
                >
                  {languageUI.justOK}
                </button>
                <button
                  className={` h-48px w-100px d-flex justify-content-center align-items-center px-4 radius-2  text-12px fw-600 font-urbansist ${
                    chooseOptionEmployee === 3
                      ? "bg-0FA54A border-none text-white"
                      : "border-DEE2E6 text-4A4A6A bg-white"
                  }`}
                  onClick={() => {
                    setPageCurrent(1);
                    setChooseOptionEmployee(3);
                  }}
                >
                  {languageUI.bad}
                </button>
              </div>
            </div>
          </div> */}
          <table className="table">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="w-20 text-18px text-12B064 fw-600 font-urbansist"
                >
                  {languageUI.dateTime}
                </th>
                {/* <th
                  scope="col"
                  className="w-30 text-18px text-12B064 fw-600 font-urbansist"
                >
                  {languageUI.customerNamePhone}{" "}
                </th> */}
                {/* <th
                  scope="col"
                  className="w-15 text-18px text-12B064 fw-600 font-urbansist"
                >
                  {languageUI.rating}
                </th> */}
                <th
                  scope="col"
                  className="w-30 text-18px text-12B064 fw-600 font-urbansist"
                >
                  {languageUI.staff}
                </th>
                <th
                  scope="col"
                  className="w-50 text-18px text-12B064 fw-600 font-urbansist"
                >
                  {languageUI.feedBack}
                </th>
              </tr>
            </thead>
            <tbody className="h-body-table-staff-review">
              {listArrStaffReview.map((item) => (
                <tr className="d-flex align-items-center border-bottom">
                  <td className=" w-20 ps-2 border-bottom-none  d-flex align-items-center text-4 fw-600 text-666687 font-urbansist">
                    <div>
                      <p className="m-0">{item.date_review}</p>
                      <p className="m-0">{item.time_review}</p>
                    </div>
                  </td>
                  {/* <td className="w-15 border-bottom-none d-flex align-items-center text-4 fw-600 text-666687 font-urbansist">
                    <div>
                      <p className="m-0">{item.customer_name}</p>
                      <p className="m-0">{item.phone}</p>
                    </div>
                  </td> */}
                  {/* <td className="border-bottom-none w-15 d-flex align-items-center text-4 fw-600 text-666687 font-urbansist">
                    <div
                      className={`rounded-4 h-40px ${
                        Number(item.review_type_id) === 1
                          ? "bg-primary"
                          : Number(item.review_type_id) === 2
                          ? "bg-orange"
                          : "bg-danger"
                      } text-white w-100px d-flex justify-content-center align-items-center`}
                    >
                      {item.type_name}
                    </div>
                  </td> */}
                  <td className="border-bottom-none w-30 d-flex flex-column gap-1  text-4 fw-600 text-666687 font-urbansist">
                    {item.staff_name}
                    <Rating
                      size="large"
                      sx={{
                        fontSize: "1rem",
                      }}
                      max={5}
                      value={item.star_num}
                      // disabled={true}
                      readOnly={true}
                    />
                  </td>
                  <td className="border-bottom-none w-50 d-flex align-items-center text-4 fw-600 text-666687 font-urbansist">
                    {item.content_feedback}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="w-100 h-66px d-flex justify-content-center align-items-center border-top">
            <div className="d-flex h-fit d-flex  align-items-center">
              <p className="mb-0 h-fit text-page-table-bottom">
                {languageUI.total} {totalPage} {languageUI.pages}
              </p>
              <button
                className="btn-page-customer-receipts"
                onClick={() => {
                  pageCurrent > 1
                    ? setPageCurrent(pageCurrent - 1)
                    : setPageCurrent(1);
                }}
              >
                <img src={arrowLeftPage} alt="" />
              </button>
              {pagingRender()}

              <button
                className="btn-page-customer-receipts"
                onClick={() => {
                  pageCurrent !== totalPage
                    ? setPageCurrent(pageCurrent + 1)
                    : setPageCurrent(totalPage);
                }}
              >
                <img src={arrowRightPage} alt="" />
              </button>
              <div className="d-flex align-items-center border  rounded-8px h-42px mx-4">
                {/* <Select
                                menuPlacement="top"
                                className="react-select react-select-container w-150px  border-select-none "
                                classNamePrefix="react-select"
                                defaultValue={[""]}
                                placeholder="Show 50 / page"
                                isClearable={true}
                                options={optionPage.map((item) => {
                                    return {
                                        value: item.value,
                                        label: item.page,
                                    };
                                })}
                                onChange={(e) => { handleChangeShowRow(e?.value) }}
                            /> */}
                <Select2
                  className="h-42px p-0"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select-5"
                  placeholder="Choose voucher ID"
                  value={limitRow}
                  onChange={(e: any) =>
                    handleChangeShowRow(Number(e.target.value))
                  }
                >
                  {optionPage.map((item, i) => (
                    <MenuItem key={i} value={item?.value}>
                      {" "}
                      {item.page}
                    </MenuItem>
                  ))}
                </Select2>
              </div>
              <p className="mb-0 text-page-table-bottom me-2">
                {languageUI.Goto}
              </p>
              <div className="input-text-page">
                <input
                  type="number"
                  className="w-100 text-page-table-bottom border-none px-2 text-11151f"
                  readOnly={totalPage === 1}
                  value={pageGoTo}
                  onChange={(e) => {
                    if (e.target.value) {
                      setPageGoTo(Number(e.target.value));
                    } else {
                      setPageGoTo(undefined);
                    }
                  }}
                  onBlur={(e) => {
                    setPageCurrent(
                      Number(e.target.value) < 1
                        ? 1
                        : Number(e.target.value) > totalPage
                        ? totalPage
                        : Number(e.target.value)
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default StaffReview;
