interface FeatherIconType {
    name: string;
}

export const FEATHERICONLIST: FeatherIconType[] = [
    {
        name: 'fe-alert-octagon',
    },
    {
        name: 'fe-alert-circle',
    },
    {
        name: 'fe-activity',
    },
    {
        name: 'fe-alert-triangle',
    },
    {
        name: 'fe-align-center',
    },
    {
        name: 'fe-airplay',
    },
    {
        name: 'fe-align-justify',
    },
    {
        name: 'fe-align-left',
    },
    {
        name: 'fe-align-right',
    },
    {
        name: 'fe-arrow-down-left',
    },
    {
        name: 'fe-arrow-down-right',
    },
    {
        name: 'fe-anchor',
    },
    {
        name: 'fe-aperture',
    },
    {
        name: 'fe-arrow-left',
    },
    {
        name: 'fe-arrow-right',
    },
    {
        name: 'fe-arrow-down',
    },
    {
        name: 'fe-arrow-up-left',
    },
    {
        name: 'fe-arrow-up-right',
    },
    {
        name: 'fe-arrow-up',
    },
    {
        name: 'fe-award',
    },
    {
        name: 'fe-bar-chart',
    },
    {
        name: 'fe-at-sign',
    },
    {
        name: 'fe-bar-chart-2',
    },
    {
        name: 'fe-battery-charging',
    },
    {
        name: 'fe-bell-off',
    },
    {
        name: 'fe-battery',
    },
    {
        name: 'fe-bluetooth',
    },
    {
        name: 'fe-bell',
    },
    {
        name: 'fe-book',
    },
    {
        name: 'fe-briefcase',
    },
    {
        name: 'fe-camera-off',
    },
    {
        name: 'fe-calendar',
    },
    {
        name: 'fe-bookmark',
    },
    {
        name: 'fe-box',
    },
    {
        name: 'fe-camera',
    },
    {
        name: 'fe-check-circle',
    },
    {
        name: 'fe-check',
    },
    {
        name: 'fe-check-square',
    },
    {
        name: 'fe-cast',
    },
    {
        name: 'fe-chevron-down',
    },
    {
        name: 'fe-chevron-left',
    },
    {
        name: 'fe-chevron-right',
    },
    {
        name: 'fe-chevron-up',
    },
    {
        name: 'fe-chevrons-down',
    },
    {
        name: 'fe-chevrons-right',
    },
    {
        name: 'fe-chevrons-up',
    },
    {
        name: 'fe-chevrons-left',
    },
    {
        name: 'fe-circle',
    },
    {
        name: 'fe-clipboard',
    },
    {
        name: 'fe-chrome',
    },
    {
        name: 'fe-clock',
    },
    {
        name: 'fe-cloud-lightning',
    },
    {
        name: 'fe-cloud-drizzle',
    },
    {
        name: 'fe-cloud-rain',
    },
    {
        name: 'fe-cloud-off',
    },
    {
        name: 'fe-codepen',
    },
    {
        name: 'fe-cloud-snow',
    },
    {
        name: 'fe-compass',
    },
    {
        name: 'fe-copy',
    },
    {
        name: 'fe-corner-down-right',
    },
    {
        name: 'fe-corner-down-left',
    },
    {
        name: 'fe-corner-left-down',
    },
    {
        name: 'fe-corner-left-up',
    },
    {
        name: 'fe-corner-up-left',
    },
    {
        name: 'fe-corner-up-right',
    },
    {
        name: 'fe-corner-right-down',
    },
    {
        name: 'fe-corner-right-up',
    },
    {
        name: 'fe-cpu',
    },
    {
        name: 'fe-credit-card',
    },
    {
        name: 'fe-crosshair',
    },
    {
        name: 'fe-disc',
    },
    {
        name: 'fe-delete',
    },
    {
        name: 'fe-download-cloud',
    },
    {
        name: 'fe-download',
    },
    {
        name: 'fe-droplet',
    },
    {
        name: 'fe-edit-2',
    },
    {
        name: 'fe-edit',
    },
    {
        name: 'fe-edit-1',
    },
    {
        name: 'fe-external-link',
    },
    {
        name: 'fe-eye',
    },
    {
        name: 'fe-feather',
    },
    {
        name: 'fe-facebook',
    },
    {
        name: 'fe-file-minus',
    },
    {
        name: 'fe-eye-off',
    },
    {
        name: 'fe-fast-forward',
    },
    {
        name: 'fe-file-text',
    },
    {
        name: 'fe-film',
    },
    {
        name: 'fe-file',
    },
    {
        name: 'fe-file-plus',
    },
    {
        name: 'fe-folder',
    },
    {
        name: 'fe-filter',
    },
    {
        name: 'fe-flag',
    },
    {
        name: 'fe-globe',
    },
    {
        name: 'fe-grid',
    },
    {
        name: 'fe-heart',
    },
    {
        name: 'fe-home',
    },
    {
        name: 'fe-github',
    },
    {
        name: 'fe-image',
    },
    {
        name: 'fe-inbox',
    },
    {
        name: 'fe-layers',
    },
    {
        name: 'fe-info',
    },
    {
        name: 'fe-instagram',
    },
    {
        name: 'fe-layout',
    },
    {
        name: 'fe-link-2',
    },
    {
        name: 'fe-life-buoy',
    },
    {
        name: 'fe-link',
    },
    {
        name: 'fe-log-in',
    },
    {
        name: 'fe-list',
    },
    {
        name: 'fe-lock',
    },
    {
        name: 'fe-log-out',
    },
    {
        name: 'fe-loader',
    },
    {
        name: 'fe-mail',
    },
    {
        name: 'fe-maximize-2',
    },
    {
        name: 'fe-map',
    },
    {
        name: 'fe-maximize',
    },
    {
        name: 'fe-map-pin',
    },
    {
        name: 'fe-menu',
    },
    {
        name: 'fe-message-circle',
    },
    {
        name: 'fe-message-square',
    },
    {
        name: 'fe-minimize-2',
    },
    {
        name: 'fe-minimize',
    },
    {
        name: 'fe-mic-off',
    },
    {
        name: 'fe-minus-circle',
    },
    {
        name: 'fe-mic',
    },
    {
        name: 'fe-minus-square',
    },
    {
        name: 'fe-minus',
    },
    {
        name: 'fe-moon',
    },
    {
        name: 'fe-monitor',
    },
    {
        name: 'fe-more-vertical',
    },
    {
        name: 'fe-more-horizontal',
    },
    {
        name: 'fe-move',
    },
    {
        name: 'fe-music',
    },
    {
        name: 'fe-navigation-2',
    },
    {
        name: 'fe-navigation',
    },
    {
        name: 'fe-octagon',
    },
    {
        name: 'fe-package',
    },
    {
        name: 'fe-pause-circle',
    },
    {
        name: 'fe-pause',
    },
    {
        name: 'fe-percent',
    },
    {
        name: 'fe-phone-call',
    },
    {
        name: 'fe-phone-forwarded',
    },
    {
        name: 'fe-phone-missed',
    },
    {
        name: 'fe-phone-off',
    },
    {
        name: 'fe-phone-incoming',
    },
    {
        name: 'fe-phone',
    },
    {
        name: 'fe-phone-outgoing',
    },
    {
        name: 'fe-pie-chart',
    },
    {
        name: 'fe-play-circle',
    },
    {
        name: 'fe-play',
    },
    {
        name: 'fe-plus-square',
    },
    {
        name: 'fe-plus-circle',
    },
    {
        name: 'fe-plus',
    },
    {
        name: 'fe-pocket',
    },
    {
        name: 'fe-printer',
    },
    {
        name: 'fe-power',
    },
    {
        name: 'fe-radio',
    },
    {
        name: 'fe-repeat',
    },
    {
        name: 'fe-refresh-ccw',
    },
    {
        name: 'fe-rewind',
    },
    {
        name: 'fe-rotate-ccw',
    },
    {
        name: 'fe-refresh-cw',
    },
    {
        name: 'fe-rotate-cw',
    },
    {
        name: 'fe-save',
    },
    {
        name: 'fe-search',
    },
    {
        name: 'fe-server',
    },
    {
        name: 'fe-scissors',
    },
    {
        name: 'fe-share-2',
    },
    {
        name: 'fe-share',
    },
    {
        name: 'fe-shield',
    },
    {
        name: 'fe-settings',
    },
    {
        name: 'fe-skip-back',
    },
    {
        name: 'fe-shuffle',
    },
    {
        name: 'fe-sidebar',
    },
    {
        name: 'fe-skip-forward',
    },
    {
        name: 'fe-slack',
    },
    {
        name: 'fe-slash',
    },
    {
        name: 'fe-smartphone',
    },
    {
        name: 'fe-square',
    },
    {
        name: 'fe-speaker',
    },
    {
        name: 'fe-star',
    },
    {
        name: 'fe-stop-circle',
    },
    {
        name: 'fe-sun',
    },
    {
        name: 'fe-sunrise',
    },
    {
        name: 'fe-tablet',
    },
    {
        name: 'fe-tag',
    },
    {
        name: 'fe-sunset',
    },
    {
        name: 'fe-target',
    },
    {
        name: 'fe-thermometer',
    },
    {
        name: 'fe-thumbs-up',
    },
    {
        name: 'fe-thumbs-down',
    },
    {
        name: 'fe-toggle-left',
    },
    {
        name: 'fe-toggle-right',
    },
    {
        name: 'fe-trash-2',
    },
    {
        name: 'fe-trash',
    },
    {
        name: 'fe-trending-up',
    },
    {
        name: 'fe-trending-down',
    },
    {
        name: 'fe-triangle',
    },
    {
        name: 'fe-type',
    },
    {
        name: 'fe-twitter',
    },
    {
        name: 'fe-upload',
    },
    {
        name: 'fe-umbrella',
    },
    {
        name: 'fe-upload-cloud',
    },
    {
        name: 'fe-unlock',
    },
    {
        name: 'fe-user-check',
    },
    {
        name: 'fe-user-minus',
    },
    {
        name: 'fe-user-plus',
    },
    {
        name: 'fe-user-x',
    },
    {
        name: 'fe-user',
    },
    {
        name: 'fe-users',
    },
    {
        name: 'fe-video-off',
    },
    {
        name: 'fe-video',
    },
    {
        name: 'fe-voicemail',
    },
    {
        name: 'fe-volume-x',
    },
    {
        name: 'fe-volume-1',
    },
    {
        name: 'fe-volume-2',
    },
    {
        name: 'fe-volume',
    },
    {
        name: 'fe-watch',
    },
    {
        name: 'fe-wifi',
    },
    {
        name: 'fe-x-square',
    },
    {
        name: 'fe-wind',
    },
    {
        name: 'fe-x',
    },
    {
        name: 'fe-x-circle',
    },
    {
        name: 'fe-zap',
    },
    {
        name: 'fe-zoom-in',
    },
    {
        name: 'fe-zoom-out',
    },
    {
        name: 'fe-command',
    },
    {
        name: 'fe-cloud',
    },
    {
        name: 'fe-hash',
    },
    {
        name: 'fe-headphones',
    },
    {
        name: 'fe-underline',
    },
    {
        name: 'fe-italic',
    },
    {
        name: 'fe-bold',
    },
    {
        name: 'fe-crop',
    },
    {
        name: 'fe-help-circle',
    },
    {
        name: 'fe-paperclip',
    },
    {
        name: 'fe-shopping-cart',
    },
    {
        name: 'fe-tv',
    },
    {
        name: 'fe-wifi-off',
    },
    {
        name: 'fe-gitlab',
    },
    {
        name: 'fe-sliders',
    },
    {
        name: 'fe-star-on',
    },
    {
        name: 'fe-heart-on',
    },
    {
        name: 'fe-archive',
    },
    {
        name: 'fe-arrow-down-circle',
    },
    {
        name: 'fe-arrow-up-circle',
    },
    {
        name: 'fe-arrow-left-circle',
    },
    {
        name: 'fe-arrow-right-circle',
    },
    {
        name: 'fe-bar-chart-line-',
    },
    {
        name: 'fe-bar-chart-line',
    },
    {
        name: 'fe-book-open',
    },
    {
        name: 'fe-code',
    },
    {
        name: 'fe-database',
    },
    {
        name: 'fe-dollar-sign',
    },
    {
        name: 'fe-folder-plus',
    },
    {
        name: 'fe-gift',
    },
    {
        name: 'fe-folder-minus',
    },
    {
        name: 'fe-git-commit',
    },
    {
        name: 'fe-git-branch',
    },
    {
        name: 'fe-git-pull-request',
    },
    {
        name: 'fe-git-merge',
    },
    {
        name: 'fe-linkedin',
    },
    {
        name: 'fe-hard-drive',
    },
    {
        name: 'fe-more-vertical-',
    },
    {
        name: 'fe-more-horizontal-',
    },
    {
        name: 'fe-rss',
    },
    {
        name: 'fe-send',
    },
    {
        name: 'fe-shield-off',
    },
    {
        name: 'fe-shopping-bag',
    },
    {
        name: 'fe-terminal',
    },
    {
        name: 'fe-truck',
    },
    {
        name: 'fe-zap-off',
    },
    {
        name: 'fe-youtube',
    },
];
