import Modal from 'react-bootstrap/Modal';
import "./Styles.css"
import { getLanguageUI } from '../../../../Services/languageUI';

interface ModalShowTipRuleProps {
    show: boolean;

    handleClose: (load?: boolean) => void;

}
const ModalShowTipRule = ({
    show,
    handleClose,

}: ModalShowTipRuleProps) => {

    const languageUI = getLanguageUI().staffPage
    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="modal-add-food-order"
            >
                <Modal.Body className='bg-D9D9D9 p-4 rounded-5'>
                    <div className='container-box-add-dish-group h-400px overflow-y-scroll bg-D9D9D9 rounded-5'>
                        <div className='w-100 bg-white rounded-5 p-4'>
                            <h4 className='font-bold text-11151f m-0'>{languageUI.note}</h4>
                            <h5 className='font-bold text-11151f m-0'>{languageUI.ifThereAreTwoPeople}</h5>
                            <h5 className='font-bold text-11151f m-0'>{languageUI.theSamePrinciple}</h5>
                        </div>
                        <div className='w-100 bg-white rounded-5 p-4 mt-3 '>
                            <div className='py-2 w-100 border-bottom'>
                                <h4 className='font-bold text-11151f m-0'>{languageUI.exampleHowToInput}</h4>
                                <div className='p-5'>
                                    <div className='d-flex align-items-center  mt-1 '>
                                        <div className='w-20'>
                                            <p className='text-11151f font-medium'>{languageUI.foodServer}</p>
                                        </div>
                                        <div className='w-50'>
                                            <p className='text-11151f font-medium'>{languageUI.plusOff} <p className='text-primary font-medium d-inline'>{languageUI.hisTip}</p> {languageUI.enterNumberOnly}</p>
                                        </div>
                                        <div className='w-30 d-flex gap-2 align-items-center '>
                                            <div className='border rounded-4 py-2 px-4'>
                                                <input type="number" inputMode='numeric' className='border-none focus-visiable-none  w-150px font-bold' readOnly value={20} />
                                            </div>
                                            <p className='m-0 text-11151f'>(%)</p>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center  mt-1'>
                                        <div className='w-20'>
                                            <p className='text-11151f font-medium'>{languageUI.chef}</p>
                                        </div>
                                        <div className='w-50'>
                                            <p className='text-11151f font-medium'>{languageUI.plusOff} <p className='text-danger font-medium d-inline'>{languageUI.allTotalTip} </p> {languageUI.enterNumberOnly}</p>
                                        </div>
                                        <div className='w-30 d-flex gap-2 align-items-center'>
                                            <div className='border rounded-4 py-2 px-4'>
                                                <input type="number" inputMode='numeric' className='border-none focus-visiable-none  w-150px font-bold' readOnly value={10} />
                                            </div>
                                            <p className='m-0 text-11151f'>(%)</p>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center  mt-1'>
                                        <div className='w-20'>
                                            <p className='text-11151f font-medium'>{languageUI.manager}</p>
                                        </div>
                                        <div className='w-50'>
                                            <p className='text-11151f font-medium'>{languageUI.plusOff} <p className='text-danger font-medium d-inline'>{languageUI.allTotalTip}</p> {languageUI.enterNumberOnly}</p>
                                        </div>
                                        <div className='w-30 d-flex gap-2 align-items-center'>
                                            <div className='border rounded-4 py-2 px-4'>
                                                <input type="number" inputMode='numeric' className='border-none focus-visiable-none  w-150px font-bold' readOnly value={30} />
                                            </div>
                                            <p className='m-0 text-11151f'>(%)</p>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center  mt-1'>
                                        <div className='w-20'>
                                            <p className='text-11151f font-medium'>{languageUI.beverageServer}</p>
                                        </div>
                                        <div className='w-50'>
                                            <p className='text-11151f font-medium'>{languageUI.plusOff} <p className='text-danger font-medium d-inline'>{languageUI.allTotalTip}</p> {languageUI.enterNumberOnly}</p>
                                        </div>
                                        <div className='w-30 d-flex gap-2 align-items-center'>
                                            <div className='border rounded-4 py-2 px-4'>
                                                <input type="number" inputMode='numeric' className='border-none focus-visiable-none  w-150px font-bold' readOnly value={20} />
                                            </div>
                                            <p className='m-0 text-11151f'>(%)</p>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center  mt-1'>
                                        <div className='w-20'>
                                            <p className='text-11151f font-medium'>{languageUI.cleanTable}</p>
                                        </div>
                                        <div className='w-50'>
                                            <p className='text-11151f font-medium'>{languageUI.plusOff} <p className='text-danger font-medium d-inline'>{languageUI.allTotalTip}</p> {languageUI.enterNumberOnly}</p>
                                        </div>
                                        <div className='w-30 d-flex gap-2 align-items-center'>
                                            <div className='border rounded-4 py-2 px-4'>
                                                <input type="number" inputMode='numeric' className='border-none focus-visiable-none  w-150px font-bold' readOnly value={20} />
                                            </div>
                                            <p className='m-0 text-11151f'>(%)</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex align-items-center justify-content-between p-4 w-100'>
                                <div className='w-70'> <h4 className='font-bold text-11151f'>{languageUI.total}</h4></div>
                                <div className='d-flex align-items-center gap-4 w-30'>
                                    <div className='d-flex align-items-center gap-2'>
                                        <div className='w-100px h-40px rounded-4 border text-11151f font-bold d-flex justify-content-center align-items-center'>
                                            100
                                        </div>
                                        <p className='m-0 text-11151f'>(%)</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal.Footer className='bg-white rounded-5 mt-2'>
                        <div className='w-100 d-flex justify-content-end'>
                            <div className='btn-footer-add-dish-group ' onClick={() => handleClose()}>
                                {languageUI.close}
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal.Body>
            </Modal>
        </>
    );
}
export default ModalShowTipRule;