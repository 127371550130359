import { useEffect, useState } from "react"
import { arowRightPriceSubsription, checkCircleSubscription, logoMonthSubscripCardBasic, starOutline } from "../../../../components/ImgExport"
import "./Styles.css"
import * as SubscriptionAPI from "../../../../api/apiOwner/Subscription";
import { useNavigate, useParams } from "react-router-dom"
import { CardSubscription } from "../../../../components/SVGChangeColor/SVGChangeColor";
import ModalLoading from "../../../../components/ModalLoading";
import { Devices, gComboDevices } from "../../../../api/apiAdmin/devicePos";
import { FormatDolla } from "../../../../utils/format";
import { Add, Remove } from "@mui/icons-material";
import { Checkbox } from "@mui/material";
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { targetImgadmin } from "../../../../api/Request";
import default_product from "../../../../assets/images/default_product.png";
import { getLanguageCheckIn } from "../../../../Services/languageCheckin";
import React from "react";

interface SubscriotionCardItemsProps {
    fill: string;
    cardExpiration: string;
    typeCard: string;
    contentCard: string;
    priceCard: number;
    priceSaveCard: number;
    numberSMS: number;
    bytime: string;
    logoSubsription: string;
    statusText: string;
    handleClick: () => void;
    id: number;
    handleChooseCard: (id: number) => void;
    cardItemIndex: number
}
const languageUI = getLanguageCheckIn();
const SubscriotionCardItems = ({
    bytime, cardExpiration, contentCard, fill = '#0D7C46', numberSMS, priceCard, priceSaveCard = 0, typeCard, logoSubsription, statusText = 'Upgrade',
    handleClick, id, handleChooseCard, cardItemIndex }: SubscriotionCardItemsProps) => {
    return (
        <div className={`box-subscription-card-ladding-page w-fit ${cardItemIndex === id ? 'border-choose-item-laneding' : ''}`} onClick={() => handleChooseCard(id)}>
            <CardSubscription fill={fill} />
            <div className="content-card-subsctiption">
                <div className="d-flex align-items-center gap-2">
                    <div className="box-img-subscription">
                        <img src={logoSubsription} alt="" className="img-subscription" />
                    </div>
                    <div>
                        <p className="text-name-card-subscription mb-0 text-white">{cardExpiration}</p>
                        <p className="text-type-card">{typeCard}</p>
                    </div>
                </div>
                <p className="mb-0 mt-2 text-content">{contentCard}</p>
                <div className="d-flex gap-1 align-items-start mt-2">
                    <p className="text-price-subscription-card mb-0 mt-2">${priceCard}</p>
                    <img src={arowRightPriceSubsription} alt="" className={`mt-2 ${priceSaveCard <= 0 ? 'd-none' : ''} `} />
                    <p className={`text-price-hand ${priceSaveCard <= 0 ? 'd-none' : ''}`}>{languageUI.Save} ${priceSaveCard}</p>
                </div>
                <p className="mb-0 mt-2 text-what-included">{languageUI.Whatsincluded}</p>
                <div className="mt-2 d-flex align-items-center gap-2">
                    <img src={checkCircleSubscription} alt="" />
                    <p className="text-sms-have-in-buy mb-0">{numberSMS} SMS / {bytime} {languageUI.month}</p>
                </div>
                <div className="box-btn-upgrade " onClick={handleClick}>
                    <p className="text-upgrade mb-0">
                        {statusText}
                    </p>
                    <img src={starOutline} alt="" />
                </div>
            </div>
        </div>
    )
}
const YearlyPlan = () => {
    const navigate = useNavigate();

    const { owner_id } = useParams();
    const [ListPacked, setListPacked] = useState<SubscriptionAPI.IListPackageData[]>([]);
    const [ShowLoading, setShowLoading] = useState<boolean>(false);
    const [optionSMS, setOptionSMS] = useState<number>(2)
    const [cardItemIndex, setCardItemIndex] = useState<number>(0)
    const [comboDevices, setComboDevices] = useState<Devices[]>([])
    const [priceDev, setPriceDev] = useState<number>(0);
    const [packName, setPackName] = useState<string>('');
    const [idPacked, setIdPacked] = useState<number>(0);
    const [totalEquipment, setTotalEqipment] = useState<number>(0)


    const handleChooseCard = (id: number, package_name: string, price: number) => {
        setCardItemIndex(id)
        setPriceDev(price)
        setPackName(package_name)
        setIdPacked(id)
    }

    // gListPacked.php
    const GetListPacked = async () => {
        setShowLoading(true);
        const ListPackage = await SubscriptionAPI.ListPackage();
        if (Number(ListPackage?.status) === 1) {
            setListPacked(ListPackage?.data ? ListPackage?.data : []);
        } else {
            setListPacked([]);
        }
        setShowLoading(false);
    };
    const gComboDevicesPos = async () => {
        const req = await gComboDevices();
        if (Number(req?.status) === 1) {
            setComboDevices(req?.data ?? [])
        }
    }
    const ReloadForm = () => {
        setListPacked([]);
        setShowLoading(false);
        setOptionSMS(2)
    };

    useEffect(() => {
        if (owner_id) {
            ReloadForm();
            gComboDevicesPos()
            GetListPacked();
        } else {
            navigate('/')
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleChangeQuantity = (type: number, idDev: string) => {
        setComboDevices(comboDevices.map(item => {
            return Number(item.id) === Number(idDev) ? { ...item, quantity: type === 1 ? Number(item.quantity) + 1 : Number(item.quantity) - 1 } : item
        }))
    }
    const handleChecked = (status: boolean, idDev: string) => {
        setComboDevices(comboDevices.map(item => {
            return Number(item.id) === Number(idDev) ? { ...item, checked: status } : item
        }))
    }

    useEffect(() => {
        let total = 0;
        // eslint-disable-next-line array-callback-return
        comboDevices.map((item) => {
            if (item?.checked)
                total += Number(item?.price) * Number(item.quantity)
        })
        setTotalEqipment(total)
    }, [comboDevices])

    const handlePayment = async () => {
        if (idPacked) {
            const ListDevices = comboDevices.filter(item => item?.checked)
            navigate('/createaccountyearly', { state: { id: idPacked, price: totalEquipment + priceDev, owner_id, ListDevices, packName } })
        }

    }

    const handlePopup = (id: number, price: number) => {
        // navigate('/createaccountyearly', { state: { id, price, owner_id } })
    };
    return (
        <>
            <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
            <div className="w-100 p-6 height-card-subscription">
                <div className="d-flex align-items-center justify-content-center gap-6  flex-wrap">
                    <p className="mb-0 text-best-choice-subscription">{languageUI.Pleasechooseyourpackage}</p>

                </div>
                <div className="w-100 d-flex justify-content-center">
                    <div
                        className={`mt-6 d-flex gap-3 flex-wrap h-100  optionSMS
                                ${optionSMS === 1 ? '' : 'd-none'}`
                        }
                    >
                        {ListPacked.map((item, i) => Number(item?.package_expiry) === 1 && Number(item?.package_type) === 2 && (
                            <SubscriotionCardItems key={`month${i}`} logoSubsription={logoMonthSubscripCardBasic}
                                bytime="1"
                                cardExpiration={item?.package_name}
                                contentCard={item?.description ?? ''}
                                fill={item?.corlor}
                                numberSMS={item?.cms ?? 0}
                                priceCard={item?.price ?? 0}
                                priceSaveCard={item?.save ?? 0}
                                typeCard={item?.typeCard}
                                statusText={languageUI.Buy}
                                handleClick={() => handlePopup(item?.id ?? 0, item?.price ?? 0)}
                                handleChooseCard={() => handleChooseCard(Number(item.id), item?.package_name + ' ' + item.typeCard, Number(item?.price) ?? 0)}
                                cardItemIndex={cardItemIndex}
                                id={Number(item.id)}
                            />
                        ))}


                    </div>
                    <div className={`mt-6 d-flex gap-3 flex-wrap h-100  optionSMS ${optionSMS === 2 ? '' : 'd-none'}`}>
                        {ListPacked.map((item, i) => Number(item?.package_expiry) === 2 && (
                            <SubscriotionCardItems id={Number(item.id)}
                                handleChooseCard={() => handleChooseCard(Number(item.id), item?.package_name + ' ' + item.typeCard, item?.price ?? 0)}
                                cardItemIndex={cardItemIndex} key={`month${i}`} logoSubsription={logoMonthSubscripCardBasic} bytime="1" cardExpiration={item?.package_name} contentCard={item?.description ?? ''} fill={item?.corlor} numberSMS={item?.cms ?? 0} priceCard={item?.price ?? 0} priceSaveCard={item?.save ?? 0} typeCard={item?.typeCard} statusText={languageUI.Buy} handleClick={() => handlePopup(item?.id ?? 0, item?.cms ?? 0)} />
                        ))}
                    </div>
                    <div className={`mt-6 d-flex gap-3 flex-wrap h-100  optionSMS ${optionSMS === 3 ? '' : 'd-none'}`}>
                        {ListPacked.map((item, i) => Number(item?.package_expiry) === 3 && (
                            <SubscriotionCardItems id={Number(item.id)}
                                handleChooseCard={() => handleChooseCard(Number(item.id), item?.package_name + ' ' + item.typeCard, item?.price ?? 0)}
                                cardItemIndex={cardItemIndex} key={`month${i}`} logoSubsription={logoMonthSubscripCardBasic} bytime="1" cardExpiration={item?.package_name} contentCard={item?.description ?? ''} fill={item?.corlor} numberSMS={item?.cms ?? 0} priceCard={item?.price ?? 0} priceSaveCard={item?.save ?? 0} typeCard={item?.typeCard} statusText={languageUI.Buy} handleClick={() => handlePopup(item?.id ?? 0, item?.cms ?? 0)} />
                        ))}
                    </div>
                </div>
                <div className=" w-80 h-400px m-auto mt-3 pb-4">
                    <p className="m-0 text-4a4a6a font-bold text-xl">{languageUI.equipment}:</p>
                    <div className="px-4">
                        {comboDevices.length > 0 && (
                            <>
                                {comboDevices.map((item, i) => (
                                    <div className="mt-3 d-flex justify-content-between gap-4 shadow-items-equiment p-3 rounded-4" key={`eqip${i}`}>
                                        <div className="w-200px h-120px rounded-4 flex-shrink-0">
                                            <img src={targetImgadmin + item?.imgurl} alt="" className="w-100 h-100 rounded-4" onError={(e) => {
                                                e.currentTarget.src = default_product;
                                            }} />
                                        </div>
                                        <div className="w-100 py-2">
                                            <div className="w-100 d-flex justify-content-between align-items-center">
                                                <p className="font-medium text-base text-4a4a6a mb-1">{item?.title}</p>
                                                <p className="font-medium text-base text-4a4a6a mb-1">{languageUI.Price}: ${FormatDolla(Number(item?.price))}</p>
                                            </div>
                                            <p className="font-normal text-base text-4a4a6a mb-1">{item?.description}</p>
                                        </div>
                                        <div className="flex-shrink-0 d-flex align-items-center gap-4 ">
                                            <div className="d-flex flex-column align-items-center gap-1">
                                                <div className="h-40px w-40px rounded-circle d-flex justify-content-center align-items-center bg-orange-light" onClick={() => {
                                                    handleChangeQuantity(1, item?.id)
                                                }}>
                                                    <Add className="text-fd7e14" />
                                                </div>
                                                <p className="font-medium text-base text-4a4a6a m-0">{item?.quantity}</p>
                                                <div className="h-40px w-40px rounded-circle d-flex justify-content-center align-items-center bg-orange-light" onClick={() => {
                                                    if (Number(item?.quantity) !== 1) {
                                                        handleChangeQuantity(2, item?.id)
                                                    }
                                                }}>
                                                    <Remove className="text-fd7e14" />
                                                </div>
                                            </div>
                                            <div>
                                                <Checkbox
                                                    icon={<RadioButtonUncheckedIcon />}
                                                    checkedIcon={<RadioButtonCheckedIcon className="text-primary" />}
                                                    checked={item?.checked}
                                                    onClick={(e: any) => {
                                                        handleChecked(e.target.checked, item.id);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </>
                        )}
                    </div>
                    {idPacked !== 0 && (
                        <div className="px-4 mt-2 py-4">
                            <div className="d-flex justify-content-between align-items-center">
                                <p className="m-0 text-4a4a6a font-bold text-xl">{packName}:</p>
                                <p className="m-0 text-4a4a6a font-bold text-xl">${FormatDolla(Number(priceDev))}</p>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <p className="m-0 text-4a4a6a font-bold text-xl">{languageUI.Alltheequipment}:</p>
                                <p className="m-0 text-4a4a6a font-bold text-xl">${FormatDolla(Number(totalEquipment))}</p>
                            </div>
                            <div className="d-flex justify-content-between align-items-center mt-2">
                                <p className="m-0 text-4a4a6a font-bold text-xl ps-2">{languageUI.Total}:</p>
                                <p className="m-0 text-4a4a6a font-bold text-xl">${FormatDolla(Number(totalEquipment) + Number(priceDev))}</p>
                            </div>
                            <div className="d-flex justify-content-end mt-2" onClick={handlePayment}>
                                <div className="px-4 py-2 rounded-3 bg-primary font-bold text-white w-fit ">
                                    {languageUI.Payment}
                                </div>
                            </div>
                        </div>
                    )}


                </div>
            </div>
        </>
    )
}
export default YearlyPlan
