import { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as Request from "../../api/Request";

// actions
import { resetAuth } from "../../redux/actions";

// store
import { AppDispatch } from "../../redux/store";

// components
import MaskedInput from "react-text-mask";
import { checkSentSmsCustomer } from "../../api/apiCheckin/checkinApi";
import ModalLoading from "../../components/ModalLoading";
import { Link } from "react-router-dom";
import { getLanguageCheckIn } from "../../Services/languageCheckin";

interface ownerInforType {
  name: string;
  phone: string;
  type_send_review_message: string;
  type_send_review_staff: string;
}

const SendMenuToPhone = () => {
  const languageUI = getLanguageCheckIn();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [ShowLoading, setShowLoading] = useState<boolean>(false);
  const [phoneValue, setPhoneValue] = useState("");
  const [checkAccept, setCheckAccept] = useState(false);
  const [checkError, setCheckError] = useState(false);
  const [checkErrorAccept, setCheckErrorAccept] = useState(false);
  const [errMess, setErMess] = useState("");
  const [ownerInFor, setOwnerInfor] = useState<ownerInforType>();
  const [errMessAccept, setErMessAccept] = useState("");
  let params = useParams();
  let id_owner = params?.id;
  let table_id = params?.table_id;

  useEffect(() => {
    dispatch(resetAuth());
  }, [dispatch]);

  const location = useLocation();

  const onchagePhoneNumber = (e: any) => {
    let phone = e?.target.value;

    const phoneReplace = phone
      ?.replace(/[-]/g, "")
      .replace(/[()]/g, "")
      .replace(/\s+/g, "")
      .replace(/[_]/g, "");
    setPhoneValue(phoneReplace);
  };

  useEffect(() => {
    if (phoneValue.length === 10) {
      setCheckAccept(true);
    } else setCheckAccept(false);
  }, [phoneValue]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (phoneValue === "") {
      setCheckError(true);
      setErMess(languageUI.pleaseEnterPhone);
      return;
    }
    if (phoneValue.length !== 10) {
      setCheckError(true);
      setErMess(languageUI.phoneNumberFormatConsistsOf10Numbers);
      return;
    }
    if (checkAccept === false) {
      setCheckErrorAccept(true);
      setErMessAccept(languageUI.checkOurTermsPlease);
      return;
    }
    // insert phone customer
    addPhoneNumber(phoneValue, id_owner);
  };
  const sentSmsSetting = async (
    owner: number,
    id_customer: string,
    type: number
  ) => {
    const req = await checkSentSmsCustomer(owner, id_customer);
    if (Number(req?.status) === 1) {
      setShowLoading(false);
      localStorage.setItem("custommerId", id_customer);
      localStorage.setItem("ownerID", owner.toString());
      if (Number(type) === 1) {
        navigate(`/webcheckin/showpointcheckin/${id_customer}`);
      } else {
        navigate(`/webcheckin/enterfirstname/${id_customer}`);
      }
    } else {
      setShowLoading(false);
      localStorage.setItem("custommerId", id_customer);
      localStorage.setItem("ownerID", owner.toString());
      if (Number(type) === 1) {
        navigate(`/webcheckin/showpointcheckin/${id_customer}`);
      } else {
        navigate(`/webcheckin/enterfirstname/${id_customer}`);
      }
    }
  };
  //send sms
  const sendSmsReview = async (owner: number, customerID: string) => {
    await Request.fetchAPI(`checkin/send_SMS_customer.php`, "post", {
      id_owner: owner,
      id_customer: customerID,
    })
      .then((res) => {
        if (Number(res.status) === 1) console.log(res.data);
      })
      .catch((error: any) => console.log(error));
  };
  //send sms
  const sendSmsReviewStaff = async (owner: number, customerID: string) => {
    await Request.fetchAPI(
      `checkin/send_SMS_customer_review_staff.php`,
      "post",
      {
        id_owner: owner,
        id_customer: customerID,
      }
    )
      .then((res) => {
        if (Number(res.status) === 1) console.log(res.data);
      })
      .catch((error: any) => console.log(error));
  };

  const addPhoneNumber = async (phoneValue: any, id_owner: any) => {
    setShowLoading(true);
    await Request.fetchAPI(`checkin/addPhoneCustomer.php`, "post", {
      phone: phoneValue,
      admin_id: id_owner,
    })
      .then((res) => {
        if (Number(res.status) === 1) {
          if (Number(ownerInFor?.type_send_review_message) === 1) {
            sendSmsReview(id_owner, res?.data[0]?.id);
          }
          if (Number(ownerInFor?.type_send_review_staff) === 1) {
            sendSmsReviewStaff(id_owner, res?.data[0]?.id);
          }
          if (
            Number(ownerInFor?.type_send_review_message) === 1 ||
            Number(ownerInFor?.type_send_review_staff) === 1
          ) {
            setTimeout(() => {
              sentSmsSetting(id_owner, res?.data[0]?.id, res.data[0].type);
            }, 3000);
          } else {
            sentSmsSetting(id_owner, res?.data[0]?.id, res.data[0].type);
          }
        } else {
          setShowLoading(false);
        }
      })
      .catch((error: any) => {
        console.log(error);
        setShowLoading(false);
      });
  };

  const getInforOwner = async () => {
    await Request.fetchAPI(`checkin/gInfoOwner.php?id=${id_owner}`, "get").then(
      (res) => {
        if (Number(res.status) === 1) {
          localStorage.setItem("namestore", res.data.name);
          setOwnerInfor(res.data);
        }
      }
    );
  };

  useEffect(() => {
    getInforOwner();
    localStorage.setItem("table_id", table_id ?? "");
    localStorage.removeItem("token_custommer");
  }, [location.pathname, id_owner, table_id]);

  return (
    <>
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
      <div className="account-pages d-flex" style={{ minHeight: "100vh" }}>
        <Container className="m-auto">
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="bg-pattern rounded-3 shadow mb-0">
                <Card.Body className="p-4">
                  <h4 className="text-center mb-4 mt-4">{ownerInFor?.name}</h4>
                  <form className="text-center" onSubmit={handleSubmit}>
                    <label className="form-label">
                      {languageUI.viewOurMenu}
                    </label>{" "}
                    <br />
                    <MaskedInput
                      mask={[
                        "(",
                        /[0-9]/,
                        /\d/,
                        /\d/,
                        ")",
                        " ",
                        /\d/,
                        /\d/,
                        /\d/,
                        "-",
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                      placeholder="(___) ___-____"
                      className="form-control"
                      value={phoneValue}
                      onChange={onchagePhoneNumber}
                    />
                    <p style={{ color: "#f1556c", textAlign: "center" }}>
                      {checkError ? errMess : ""}
                    </p>{" "}
                    <Button
                      onSubmit={handleSubmit}
                      type="submit"
                      variant="outline-primary"
                      className="waves-effect waves-light mb-4 mt-2"
                    >
                      {languageUI?.go}
                    </Button>
                    <p className="text-justify text-muted">
                      {`${
                        getLanguageCheckIn().LG === 1
                          ? "By checking the box and entering your phone number. you give BriJack POS and" +
                            ownerInFor?.name +
                            "express written\
                      consent to contact you at number provided for any\
                      feedback, reminder or promotional purposes. Consent is not\
                      required to make a purchase. <br /> Reply STOP to stop receiving text messages"
                          : "\
                      Bằng cách nhập số điện thoại của bạn, bạn cung cấp cho BriJack POS và cho phép chúng tôi liên hệ với bạn theo số điện thoại được cung cấp cho bất kỳ phản hồi nào, mục đích nhắc nhở hoặc quảng cáo, Không cần phải có sự đồng ý để mua hàng\
                      "
                      }`}
                    </p>
                    <Form.Check
                      type="checkbox"
                      checked={checkAccept}
                      onChange={(e) => setCheckAccept(!checkAccept)}
                      className="cursor-pointer ps-0 text-start text-muted d-flex"
                      id="accept-term"
                      label={
                        getLanguageCheckIn().LG === 1 ? (
                          <div>
                            I accept the{" "}
                            <Link
                              to={"/webcheckin/teamofuse/" + id_owner}
                              className="text-underline"
                            >
                              terms of use
                            </Link>{" "}
                            and{" "}
                            <Link
                              to={"/webcheckin/privacypolicy/" + id_owner}
                              className="text-underline"
                            >
                              privacy policy
                            </Link>
                          </div>
                        ) : (
                          <div>
                            Tôi chấp nhận{" "}
                            <Link
                              to={"/webcheckin/teamofuse/" + id_owner}
                              className="text-underline"
                            >
                              Điều khoản sử dụng
                            </Link>{" "}
                            và{" "}
                            <Link
                              to={"/webcheckin/privacypolicy/" + id_owner}
                              className="text-underline"
                            >
                              Chính sách bảo mật
                            </Link>
                          </div>
                        )
                      }
                      inputMode="numeric"
                    />
                    <p style={{ color: "#f1556c", textAlign: "center" }}>
                      {checkErrorAccept ? errMessAccept : ""}
                    </p>{" "}
                  </form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default SendMenuToPhone;
