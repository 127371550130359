import { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import * as Request from "../../api/Request";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const PrivacyPolicy = () => {
  let params = useParams();
  let id_owner = params?.owner_id;
  const navigate = useNavigate();
  const [nameStore, setNameStore] = useState<string>('');

  const getInforOwner = async () => {
    await Request.fetchAPI(`checkin/gInfoOwner.php?id=${id_owner}`, "get").then(
      (res) => {
        if (Number(res.status) === 1) {
          setNameStore(res?.data?.name ?? '');
        }
      }
    );
  };

  useEffect(() => {
    getInforOwner();
  }, [])

  return (
    <>
      <div className="account-pages d-flex" style={{ minHeight: "100vh" }}>
        <Container className="m-auto">
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="bg-pattern rounded-3 shadow mb-0">
                <Card.Body className="p-4">
                  <div className="d-flex  justify-content-between mb-2 mt-4">
                    <div className="box-btn-back-privacy-policy" onClick={() => navigate(-1)}>
                      <KeyboardBackspaceIcon className="btn-back-privacy-policy" />
                    </div>
                    <h4 className="text-center font-bold">Privacy Policy</h4>
                    <div className="box-btn-back-privacy-policy opacity-0">
                      <KeyboardBackspaceIcon />
                    </div>
                  </div>
                  <p className="text-center text-gray">Last Updated: 1/1/2024</p>
                  <div>
                    <div>
                      <p className="font-bold mb-0">
                        1. Introduction
                      </p>
                      <p className="mb-1">
                        Welcome to BriJack POS and {nameStore}. This privacy policy outlines how we collect, use, and protect your personal information when you use our services and visit our website.
                      </p>
                    </div>
                    <div>
                      <p className="font-bold mb-0">
                        2. Consent
                      </p>
                      <p className="mb-1">
                        By using our services and providing us with your information, you consent to the collection, use, and sharing of your information as described in this policy.
                      </p>
                    </div>
                    <div>
                      <p className="font-bold mb-0">
                        3. Information Collection
                      </p>
                      <p className="mb-1">
                        We collect information you provide directly to us, such as when you enter your phone number for service registration, or use a credit card for payments. This information may include, but is not limited to, your name, phone number, and credit card details.
                      </p>
                    </div>
                    <div>
                      <p className="font-bold mb-0">
                        4. Use of Information
                      </p>
                      <p className="mb-0">
                        Your information is used to:
                      </p>
                      <div className="mb-1">
                        <p className="mb-0">•	Process transactions and provide our services.</p>
                        <p className="mb-0">•	Send marketing communications and updates about our services.</p>
                      </div>
                    </div>
                    <div>
                      <p className="font-bold mb-0">
                        5. Third-Party Service Providers
                      </p>
                      <p className="mb-1">
                        We may share your information with third-party service providers to facilitate our services, process payments, or perform other related business activities.
                      </p>
                    </div>
                    <div>
                      <p className="font-bold mb-0">
                        6. Data Security
                      </p>
                      <p className="mb-1">
                        We implement reasonable measures to protect your personal information, but we cannot guarantee its absolute security. Please be aware that no method of transmission over the Internet or electronic storage is 100% secure.
                      </p>
                    </div>
                    <div>
                      <p className="font-bold mb-0">
                        7. User Responsibilities
                      </p>
                      <p className="mb-1">
                        It is your responsibility to protect your personal information and credit card details. We are not responsible for any loss or damage arising from your failure to safeguard your information.
                      </p>
                    </div>
                    <div>
                      <p className="font-bold mb-0">
                        8. Limitation of Liability
                      </p>
                      <p className="mb-0">
                        We will not be liable for any indirect, incidental, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from:
                      </p>
                      <div className="mb-1">
                        <p className="mb-0">•	Process transactions and provide our services.</p>
                        <p className="mb-0">•	Send marketing communications and updates about our services.</p>
                      </div>
                    </div>
                    <div>
                      <p className="font-bold mb-0">
                        9. Changes to This Privacy Policy
                      </p>
                      <p className="mb-1">
                        We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
                      </p>
                    </div>
                    <div>
                      <p className="font-bold mb-0">
                        10. Contact Us
                      </p>
                      <p className="mb-1">
                        If you have any questions about this Privacy Policy, please contact us at support@brijack.com
                      </p>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default PrivacyPolicy;
