import "../../Styles.css";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import {
  gInfoLinkeOwner,
  updateLinkOwner,
} from "../../../../api/apiOwner/SettingInformation";
import { useSelector } from "react-redux";
import * as userSlice from "../../../../store/slices/userSlice";
import ModalLoading from "../../../../components/ModalLoading";
import { useDispatch } from "react-redux";
import * as flagSlice from "../../../../store/slices/FlagSettingSlice";
import Swal from "sweetalert2";
import {
  facebookIcon,
  facebookIconPng,
  instagramIcon,
} from "../../../../components/ImgExport";
interface ConectStripeProp {
  ChangeSetting: () => void;
}
const UpdateLink: React.FC<ConectStripeProp> = ({ ChangeSetting }) => {
  const dispatch = useDispatch();
  const [showModalLoading, setShowModalLoading] = useState<boolean>(false);
  const [linkFacebook, setLinkFacebook] = useState<string>("");
  const [linkInstagram, setLinkInstagram] = useState<string>("");
  const UserIdStore = useSelector(userSlice.selectorUserId);
  const gLink = async () => {
    if (UserIdStore) {
      const req = await gInfoLinkeOwner(UserIdStore);
      if (Number(req?.status) === 1) {
        setLinkFacebook(req?.data?.facebook_link ?? "");
        setLinkInstagram(req?.data?.instagram_link ?? "");
      } else {
        setLinkFacebook("");
        setLinkInstagram("");
      }
    }
  };

  const saveConfigLink = async () => {
    if (UserIdStore) {
      setShowModalLoading(true);
      const res = await updateLinkOwner(
        UserIdStore,
        linkInstagram,
        linkFacebook
      );
      if (Number(res?.status) === 1) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Update success!",
          showConfirmButton: false,
          timer: 2000,
        });
        dispatch(flagSlice.clearSetting());
        setShowModalLoading(false);
      } else {
        Swal.fire({
          title: "Cancelled",
          text: res?.message
            ? res?.message
            : "Something went wrong. Please try again later!",
          icon: "error",
          confirmButtonColor: "#4a4fea",
          timer: 3000,
        });
        setShowModalLoading(false);
      }
    }
  };

  useEffect(() => {
    gLink();
  }, []);
  return (
    <div>
      <ModalLoading
        show={showModalLoading}
        onHide={() => setShowModalLoading(false)}
      />
      <div className="box-connect-stripe px-5 box-happy-hour">
        <div className="mt-3 w-80">
          <div className="d-flex align-items-center w-100 mt-2">
            <div className="w-200px flex-shink-0">
              <p className="m-0 font-bold text-4a4a6a whitespace-nowrap flex-shỉnk-0">
                Enter Facebook Link
              </p>
            </div>
            <div className="w-100 d-flex gap-2 align-items-center">
              <img src={facebookIconPng} alt="" style={{ width: "50px" }} />
              <input
                type="text"
                className="input-setting-info w-100"
                value={linkFacebook}
                placeholder="Please enter facebook link"
                onChange={(e) => {
                  setLinkFacebook(e.target.value);
                  ChangeSetting();
                }}
              />
            </div>
          </div>
          <div className="d-flex align-items-center w-100 mt-2">
            <div className="w-200px flex-shink-0">
              <p className="m-0 font-bold text-4a4a6a whitespace-nowrap flex-shỉnk-0">
                Enter Instagram Link
              </p>
            </div>
            <div className="w-100 d-flex gap-2 align-items-center">
              <img src={instagramIcon} alt="" style={{ width: "55px" }} />
              <input
                type="text"
                className="input-setting-info w-100"
                value={linkInstagram}
                placeholder="Please enter instagram link"
                onChange={(e) => {
                  setLinkInstagram(e.target.value);
                  ChangeSetting();
                }}
              />
            </div>
          </div>
          <div className="d-flex justify-content-end mt-4">
            <Button variant="primary" className="ms-2" onClick={saveConfigLink}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UpdateLink;
