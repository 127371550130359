import * as Request from "../Request";
export interface ListReservation {
  id: string
  owner_id: string
  customer_id: string
  fullname: string
  phone: string
  booking_date: string
  date_value: string
  booking_time: string
  seats: string
  note: string
  status: string
}
export interface SeatOwner {
  id: string
  tax: string
  fee_stripe: string
  show_rank: string
  seat_reservation: string
  owner_id: string
  delf: string
  timestamp: string
}

export interface Reservation<T> {
  status: number,
  message?: string,
  data: T,
}
export const gRevervationListPOS = async (owner_id: string) => {
  return await Request.fetchAPI(`/owner/gRevervationListPOS.php`, "POST", { owner_id })
    .then((res: Reservation<ListReservation[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};
export const RevervationDetailCustomer = async (owner_id: string, id: string) => {
  return await Request.fetchAPI(`/owner/gRevervationDetailCustomer.php`, "POST", { owner_id, id })
    .then((res: Reservation<ListReservation>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: undefined,
      };
    });
};
export const UpStatusRevervation = async (owner_id: string, id: string, status: number) => {
  return await Request.fetchAPI(`/owner/upStatusRevervation.php`, "POST", { owner_id, id, status })
    .then((res: Reservation<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: '',
      };
    });
};
export const UpRevervationInfo = async (owner_id: string, id: string, booking_time: string, seats: string, note: string) => {
  return await Request.fetchAPI(`/owner/upRevervationInfo.php`, "POST", { owner_id, id, booking_time, seats, note })
    .then((res: Reservation<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: '',
      };
    });
};
// lấy số lượng đơn đặt bàn
export const gSettingSeatOwner = async (owner_id: string) => {
  return await Request.fetchAPI(`/owner/gSettingSeatOwner.php`, "POST", { owner_id })
    .then((res: Reservation<SeatOwner>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: undefined,
      };
    });
};

// setting số lượng đơn đặt bàn
export const UpSettingSeatOwner = async (owner_id: string, seat_reservation: string) => {
  return await Request.fetchAPI(`/owner/upSettingSeatOwner.php`, "POST", { owner_id, seat_reservation })
    .then((res: Reservation<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: '',
      };
    });
};