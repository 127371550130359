import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  pencilEditMenu,
  plusWhite,
  strashRed,
} from "../../components/ImgExport";
import ModalLoading from "../../components/ModalLoading";
import "./Styles.css";
import { useSelector } from "react-redux";
import * as userSlice from "../../store/slices/userSlice";
import { getLanguageUI } from "../../Services/languageUI";
import { useNavigate } from "react-router-dom";
import ModalDeleteHandheld from "./components/ModalDeleteHandheld/ModalDeleteHandheld";
import ModalCreateAccountHandheld from "./components/ModalCreateAccountHandheld/ModalCreateAccountHandheld";
import ModalEditAccountHandheld from "./components/ModalEditAccountHandheld/ModalEditAccountHandheld";
import ModalCreateHandheld from "./components/ModalCreateHandheld/ModalCreateHandheld";
import ModalEditHandheld from "./components/ModalEditHandheld/ModalEditHandheld";
import * as accountHandheldApi from "../../api/apiOwner/accountHandheldApi";
import { decrypt } from "../../utils/format";
import { secretKeyTin } from "../../api/Request";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
const HandheldPOS: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const pos_id = useSelector(userSlice.selectorPosId) ?? 0;
  const languageUI = getLanguageUI().handheldPOS;
  const owner_id = useSelector(userSlice.selectorUserId) ?? 0;
  const [ShowLoading, setShowLoading] = useState<boolean>(false);
  const [showModalDeleteHandheld, setShowModalDeleteHandheld] = useState(false);

  const [merchantId, setMerchantId] = useState("");
  const [haId, setHaId] = useState<number>(0);
  const [authorization, setAuthorization] = useState("");
  const [modalCreateAccountHandheld, setModalCreateAccountHandheld] =
    useState(false);
  const [modalEditAccountHandheld, setModalEditacountHandheld] =
    useState(false);
  const [modalCreateHandheld, setModalCreateHandheld] = useState(false);
  const [modalEditHandheld, setModalEditHandheld] = useState(false);
  const [listDevices, setlistDevices] = useState<accountHandheldApi.HandheldDevice[]>([]);
  const [hsnIdEdit, setHsnIdEdit] = useState<number>(0);
  const [hsnNameEdit, setHsnNameEdit] = useState<string>("");
  const [hsnKeyEdit, setHsnKeyEdit] = useState<string>("");
  const [hsnDefault, sethsnDefault] = useState<number>(0);

  function maskExceptLastFourDigits(input: string): string {
    // Kiểm tra độ dài chuỗi
    if (input.length <= 4) {
      return input; // Nếu chuỗi có ít hơn hoặc bằng 4 ký tự thì trả về chuỗi ban đầu
    }
    // Thay thế các ký tự trước 4 số cuối bằng dấu *
    const maskedPart = "*".repeat(input.length - 4);
    return maskedPart + input.slice(-4);
  }


  const handleCreateAccountSuccess = () => {
    gInfoAccountHandHeld();
    setModalCreateAccountHandheld(false)
  }
  const handleEditAccountSuccess = () => {
    gInfoAccountHandHeld();
    setModalEditacountHandheld(false)
  }
  const handleCreateDeviceSuccess = () => {
    setModalEditHandheld(false)
    gListHandhelDevices(haId);
    gDefaultHsn();
    setModalCreateHandheld(false)
  }
  const gInfoAccountHandHeld = async () => {
    if (owner_id) {
      setShowLoading(true)
      const res = await accountHandheldApi.gAcountUATHandheld(owner_id);
      if (Number(res?.status) === 1 && res?.data) {
        setShowLoading(false)
        setMerchantId(decrypt(res?.data?.merchant_id, secretKeyTin))
        setAuthorization(decrypt(res?.data?.authorization, secretKeyTin))
        setHaId(res?.data?.ha_id)
        gListHandhelDevices(res?.data?.ha_id)
      } else {
        setModalCreateAccountHandheld(true)
        setShowLoading(false)
      }
    }
  }

  const gListHandhelDevices = async (ha_id: number) => {
    if (owner_id) {
      setShowLoading(true)
      const res = await accountHandheldApi.gListHandheldDevice(owner_id, ha_id);
      if (Number(res?.status) === 1 && res?.data) {
        setShowLoading(false)
        setlistDevices(res?.data)

      } else {
        setlistDevices([])
        setShowLoading(false)
      }
    }
  }

  const gDefaultHsn = async () => {
    if (pos_id && owner_id) {
      const req = await accountHandheldApi.gDefaultHsnByPos(owner_id, pos_id);
      if (req?.status === 1) {
        sethsnDefault(req?.data?.hsn_id ?? 0)
        dispatch(userSlice.setHsnDefault(req?.data?.hsn_id ?? 0))
      } else {
        sethsnDefault(0)
        dispatch(userSlice.setHsnDefault(0))
      }
    }
  }

  const HandleChangHsnDefault = async (hsn_id: number) => {
    sethsnDefault(hsn_id)
    const req = await accountHandheldApi.setDefaultHsnByPos(owner_id, pos_id, hsn_id);
    if (req?.status === 1) {
      dispatch(userSlice.setHsnDefault(hsn_id))
    }
  }
  const handleEditDevice = (devices: accountHandheldApi.HandheldDevice) => {
    setHsnIdEdit(devices?.hsn_id)
    setHsnNameEdit(devices?.hsn_name)
    setHsnKeyEdit(devices?.hsn_key)
    setModalEditHandheld(true)
  }
  const deleteDevicePos = (devices: accountHandheldApi.HandheldDevice) => {
    setHsnIdEdit(devices?.hsn_id)

    setShowModalDeleteHandheld(true)
  }
  useEffect(() => {
    gInfoAccountHandHeld()
    gDefaultHsn()
  }, [])


  return (
    <>
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />

      <div className="px-3 pt-90px  h-100vh pb-5  w-100">
        <div className="bg-white  h-100 w-100 rounded-18px box-shadow-inventory ">
          <div className="d-flex justify-content-between align-items-center py-7 px-6">
            <div className="">
              <p className="mb-0 text-inventory">Handheld POS</p>
              <div className="m-w-200px  d-flex flex-column align-items-center justify-content-between mt-2">
                <div className="m-w-200px w-100 flex align-items-center gap-3">
                  <p className="m-0 font-bold text-4a4a6a text-16px w-100px">
                    MerchantId:
                  </p>
                  <p className="m-0 font-bold text-4a4a6a text-16px ">
                    {maskExceptLastFourDigits(merchantId)}
                  </p>
                </div>
                <div className="m-w-200px flex align-items-center gap-3 mt-1 w-100">
                  <p className="m-0 font-bold text-4a4a6a text-16px w-100px">
                    Authorization:
                  </p>
                  <p className="m-0 font-bold text-4a4a6a text-16px">
                    {" "}
                    {maskExceptLastFourDigits(authorization)}
                  </p>
                </div>
                <button
                  className="w-150px rounded-3 border-none bg-primary text-white font-bold mt-2 h-30px"
                  onClick={() => {
                    setModalEditacountHandheld(true);
                  }}
                >
                  Edit
                </button>
              </div>
            </div>
            <div
              className="btn-create-voucher"
              onClick={() => setModalCreateHandheld(true)}
            >
              <img src={plusWhite} alt="" />
              {languageUI.createHandheldPOS}
            </div>
          </div>
          <div>
            <TableContainer className="border-top">
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className="text-12B064 text-4 fw-600 font-urbansist w-10">
                      {languageUI.noNumber}
                    </TableCell>
                    <TableCell
                      align="left"
                      className="text-12B064 text-4 fw-600 font-urbansist w-30"
                    >
                      {languageUI.nameHandheldPOS}
                    </TableCell>
                    <TableCell
                      align="left"
                      className="text-12B064 text-4 fw-600 font-urbansist w-15"
                    >
                      HSN
                    </TableCell>

                    <TableCell
                      align="left"
                      className="text-12B064 text-4 fw-600 font-urbansist w-15"
                    >
                      {languageUI.creationDate}
                    </TableCell>
                    <TableCell
                      align="left"
                      className="text-12B064 text-4 fw-600 font-urbansist w-10"
                    >
                      Default
                    </TableCell>
                    <TableCell
                      align="left"
                      className="text-12B064 text-4 fw-600 font-urbansist w-20"
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="body-table-voucher">
                  {listDevices.map((items, i) => (
                    <TableRow className="d-flex align-items-center" key={`dev${i}`}>
                      <TableCell className="text-269AFF text-14px fw-500  font-urbansist w-10 border-none">
                        {i + 1}
                      </TableCell>
                      <TableCell className="text-32324D text-14px fw-500  font-urbansist w-30 border-none">
                        {items?.hsn_name}
                      </TableCell>
                      <TableCell className="text-269AFF text-14px fw-500  font-urbansist w-15 border-none">
                        {items?.hsn_key}
                      </TableCell>

                      <TableCell
                        align="left"
                        className="w-15 text-32324D text-14px fw-500 font-urbansist  border-none"
                      >
                        {items?.date_add}
                      </TableCell>
                      <TableCell align="center" className="text-269AFF text-14px fw-500  font-urbansist w-10 border-none">
                        <Form.Check
                          inline
                          name="checkDefaultPos"
                          type={"checkbox"}
                          id={"1"}

                          checked={hsnDefault === items?.hsn_id}
                          onClick={(e: any) =>
                            HandleChangHsnDefault(items?.hsn_id)
                          }
                          className="mt-2px checkbox-handle-pos"
                          size={50}
                        />
                      </TableCell>
                      <TableCell
                        align="left"
                        className="text-32324D text-14px fw-600 font-urbansist w-20 border-none"
                      >
                        <div className="d-flex gap-2 justify-content-end">
                          <div
                            className="btn-delete-edit-staff"
                            onClick={() => handleEditDevice(items)}
                          >
                            <img src={pencilEditMenu} alt="" />
                          </div>
                          <div
                            className="btn-delete-edit-staff"
                            onClick={() => deleteDevicePos(items)}
                          >
                            <img src={strashRed} alt="" />
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}

                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
      <ModalDeleteHandheld
        handleClose={() => {
          setShowModalDeleteHandheld(false);
        }}
        show={showModalDeleteHandheld}
        hsnIdEdit={hsnIdEdit}
        setShowLoading={setShowLoading}
        handleSuccess={() => { if (hsnIdEdit === hsnDefault) { sethsnDefault(0); dispatch(userSlice.setHsnDefault(0)) } gListHandhelDevices(haId) }}
      />
      {haId && (
        <ModalCreateHandheld
          haId={haId}
          handleClose={() => setModalCreateHandheld(false)}
          show={modalCreateHandheld}
          handleSuccess={handleCreateDeviceSuccess}
          setShowLoading={setShowLoading}
          hsnDefault={hsnDefault}
          pos_id={pos_id}
        />
      )}

      <ModalEditHandheld
        handleClose={() => setModalEditHandheld(false)}
        show={modalEditHandheld}
        haId={haId}
        setShowLoading={setShowLoading}
        handleSuccess={handleCreateDeviceSuccess}
        hsnIdEdit={hsnIdEdit}
        hsnNameEdit={hsnNameEdit}
        hsnKeyEdit={hsnKeyEdit}

      />
      <ModalCreateAccountHandheld show={modalCreateAccountHandheld} setShowLoading={setShowLoading} handleSuccess={handleCreateAccountSuccess} />
      <ModalEditAccountHandheld
        show={modalEditAccountHandheld}
        merchanIdEdit={merchantId}
        authorizationEdit={authorization}
        ha_id={haId}
        setShowLoading={setShowLoading}
        handleClose={() => {
          setModalEditacountHandheld(false);
        }}
        handleSuccess={handleEditAccountSuccess}
      />
    </>
  );
};

export default HandheldPOS;
