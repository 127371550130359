import React, { useEffect, useState } from 'react';
import './styles.css';
import Snake from '../Snake/Snake';
import Food from '../Food/Food';

const Board: React.FC<{ gameOver: boolean; setScore: (score: number) => void }> = ({ gameOver, setScore }) => {
  const [snakeDots, setSnakeDots] = useState([{ x: 0, y: 0 }]);
  const [foodDot, setFoodDot] = useState({ x: 10, y: 10 });
  const [direction, setDirection] = useState('RIGHT');
  const [speed, setSpeed] = useState(200);

  useEffect(() => {
    const gameInterval = setInterval(moveSnake, speed);

    // Xử lý sự kiện khi kết thúc trò chơi
    if (gameOver) {
      clearInterval(gameInterval);
      document.dispatchEvent(new Event('gameOver'));
    }

    return () => {
      clearInterval(gameInterval);
    };
  }, [snakeDots, direction, gameOver, speed]);

  const moveSnake = () => {
    const dots = [...snakeDots];
    let head = dots[dots.length - 1];

    switch (direction) {
      case 'RIGHT':
        head = { x: head.x + 2, y: head.y };
        break;
      case 'LEFT':
        head = { x: head.x - 2, y: head.y };
        break;
      case 'UP':
        head = { x: head.x, y: head.y - 2 };
        break;
      case 'DOWN':
        head = { x: head.x, y: head.y + 2 };
        break;
      default:
        break;
    }

    dots.push(head);
    dots.shift();
    setSnakeDots(dots);

    checkSnakeCollision();
    checkFoodCollision();
  };

  const checkSnakeCollision = () => {
    const head = snakeDots[snakeDots.length - 1];
    const body = [...snakeDots];
    body.pop();

    body.forEach(dot => {
      if (head.x === dot.x && head.y === dot.y) {
        setSpeed(0);
      }
    });
  };

  const checkFoodCollision = () => {
    const head = snakeDots[snakeDots.length - 1];
    const food = foodDot;

    if (head.x === food.x && head.y === food.y) {
      setFoodDot({
        x: Math.floor(Math.random() * 20) * 2,
        y: Math.floor(Math.random() * 20) * 2
      });
      // setScore();
      enlargeSnake();
    }
  };

  const enlargeSnake = () => {
    const newSnake = [...snakeDots];
    newSnake.unshift({
      x: 0,
      y: 0
    });
    setSnakeDots(newSnake);
  };

  const onKeyDown = (e: KeyboardEvent) => {
    switch (e.key) {
      case 'ArrowRight':
        setDirection('RIGHT');
        break;
      case 'ArrowLeft':
        setDirection('LEFT');
        break;
      case 'ArrowUp':
        setDirection('UP');
        break;
      case 'ArrowDown':
        setDirection('DOWN');
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    return () => document.removeEventListener('keydown', onKeyDown);
  }, []);

  return (
    <div className="board">
      <Snake snakeDots={snakeDots} />
      <Food foodDot={foodDot} />
    </div>
  );
};

export default Board;