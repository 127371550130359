import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as userSlice from "../../store/slices/userSlice";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";

// actions
import { loginUser } from "../../redux/actions";

// store
import { AppDispatch } from "../../redux/store";

// components
import { VerticalForm } from "../../components/";

import AuthLayout from "./AuthLayout";
import ModalLoading from "../../components/ModalLoading";
import * as LoginAdminAPI from "../../api/apiAdmin/LoginAdmin";
import { getLanguageCheckIn } from "../../Services/languageCheckin";


interface UserData {
  username: string;
  password: string;
}

/* bottom links */
// const BottomLink = () => {
//     const { t } = useTranslation();

//     return (
//         <Row className="mt-3">
//             <Col className="text-center">
//                 <p>
//                     <Link to={'/auth/forget-password'} className="text-white-50 ms-1">
//                         {t('Forgot your password?')}
//                     </Link>
//                 </p>
//                 <p className="text-white-50">
//                     {t("Don't have an account?")}{' '}
//                     <Link to={'/auth/register'} className="text-white ms-1">
//                         <b>{t('Sign Up')}</b>
//                     </Link>
//                 </p>
//             </Col>
//         </Row>
//     );
// };

/* social links */
// const SocialLinks = () => {
//     const socialLinks = [
//         {
//             variant: 'primary',
//             icon: 'facebook',
//         },
//         {
//             variant: 'danger',
//             icon: 'google',
//         },
//         {
//             variant: 'info',
//             icon: 'twitter',
//         },
//         {
//             variant: 'secondary',
//             icon: 'github',
//         },
//     ];
//     return (
//         <>
//             <ul className="social-list list-inline mt-3 mb-0">
//                 {(socialLinks || []).map((item, index) => {
//                     return (
//                         <li key={index} className="list-inline-item">
//                             <Link
//                                 to="#"
//                                 className={classNames(
//                                     'social-list-item',
//                                     'border-' + item.variant,
//                                     'text-' + item.variant
//                                 )}
//                             >
//                                 <i className={classNames('mdi', 'mdi-' + item.icon)}></i>
//                             </Link>
//                         </li>
//                     );
//                 })}
//             </ul>
//         </>
//     );
// };

const LoginAdmin: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const languageUI = getLanguageCheckIn();

  // const { user, userLoggedIn, loading, error } = useSelector((state: RootState) => ({
  //     user: state.Auth.user,
  //     loading: state.Auth.loading,
  //     error: state.Auth.error,
  //     userLoggedIn: state.Auth.userLoggedIn,
  // }));

  // useEffect(() => {
  //     dispatch(resetAuth());
  // }, [dispatch]);
  // useEffect(() => {
  //   console.log(location.pathname);
  // }, [history]);

  const [UserName, setUserName] = useState<any>("");
  const [Password, setPassword] = useState<any>("");
  const [Showpass, setShowpass] = useState<boolean>(false);
  const [message, setMessage] = useState<any>("");
  const [ShowLoading, setShowLoading] = useState<boolean>(false);

  const userIdStore = useSelector(userSlice.selectorUserId);
  const navigate = useNavigate();


  const ReloadForm = () => {
    setUserName("");
    setPassword("");
    setMessage("");
    setShowpass(false);
    setShowLoading(false);
  };

  const dispatchUser = useDispatch();
  const dispatchPermission = useDispatch();

  const ReduxStoreUser = async (id: number, permission: any, role: number) => {
    await dispatchUser(userSlice.setUserId(id));
    await dispatchPermission(userSlice.setPermission(permission));
    await dispatchPermission(userSlice.setRole(role));
  };

  const LoginByAccount = async () => {
    if (
      !UserName ||
      UserName.trim() === "" ||
      !Password ||
      Password.trim() === ""
    ) {
      if (!Password || Password.trim() === "") {
        setMessage(languageUI.Passwordisrequired);
      } else {
        setMessage("");
      }
      if (!UserName || UserName.trim() === "") {
        setMessage(languageUI.Usernameisrequired);
      } else {
        setMessage("");
      }
    } else {
      // let formData = new FormData();
      // formData.append("username", UserName);
      // formData.append("password", Password);
      setShowLoading(true);
      const LoginAdmin = await LoginAdminAPI.LoginAdmin(UserName, Password);
      if (Number(LoginAdmin.status) === 1) {
        setMessage("");
        // console.log(LoginAdmin?.data[0]?.id);
        ReduxStoreUser(
          LoginAdmin?.data[0]?.id,
          LoginAdmin?.data[0]?.permission,
          LoginAdmin?.data[0]?.role
        );
        // ReduxStoreUser(LoginAdmin?.data[0]?.id);
      } else {
        setMessage(LoginAdmin?.message);
      }
      setShowLoading(false);
    }
  };
  /*
    form validation schema
    */
  const schemaResolver = yupResolver(
    yup.object().shape({
      username: yup.string().required(t(languageUI.pleaseEnterUsername)),
      password: yup.string().required(t(languageUI.pleaseEnterYourPassword)),
    })
  );

  /*
    handle form submission
    */
  const onSubmit = (formData: UserData) => {
    dispatch(loginUser(formData["username"], formData["password"]));
    // alert("dsdssd");
  };

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/web-admin/login") {
      ReloadForm();
    }
  }, [location.pathname]);

  useEffect(() => {
    if (userIdStore && userIdStore != null && userIdStore > 0) {
      navigate("/web-admin");
    }
  }, [location.pathname, userIdStore]);

  return (
    <>
      {/* {(userLoggedIn || user) && <Redirect to={redirectUrl}></Redirect>} */}

      <AuthLayout helpText={t(languageUI.Logintosystem)} bottomLinks={""}>
        <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
        {/* {error && (
                    <Alert variant="danger" className="my-2">
                        {error}
                    </Alert>
                )} */}

        <VerticalForm
          onSubmit={onSubmit}
          resolver={schemaResolver}
          defaultValues={{ username: UserName, password: Password }}
        >
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>{languageUI.Username}</Form.Label>
            <Form.Control
              type="text"
              placeholder={languageUI.EnteryourUsernameEmail}
              onChange={(e: any) => {
                setUserName(e.target.value);
              }}
            />
          </Form.Group>
          {/* <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type={`${Showpass ? "text" : "password"}`} placeholder="Enter your Password"  onChange={(e: any)=>{setPassword(e.target.value)}}/>
                    </Form.Group> */}
          <Form.Label>{languageUI.Password}</Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              type={`${Showpass ? "text" : "password"}`}
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
              placeholder={languageUI.EnteryourPassword}
              onChange={(e: any) => {
                setPassword(e.target.value);
              }}
            />
            <InputGroup.Text
              className={`input-group-password ${Showpass ? "show-password" : ""
                }`}
              id="inputGroup-sizing-sm"
              onClick={() => {
                setShowpass(!Showpass);
              }}
            >
              <span className="password-eye"></span>
            </InputGroup.Text>
          </InputGroup>

          <Form.Label className="text-danger">{message}</Form.Label>
          <div className="mb-3 text-end">
            <Link
              to="/web-admin/forget-password-admin"
              className="text-primary"
            >
              {languageUI.ForgetYourPassword}
            </Link>
          </div>
          <div className="text-center d-grid">
            <Button
              variant="primary"
              type="submit"
              // disabled={loading}
              onClick={() => {
                LoginByAccount();
              }}
            >
              {t(languageUI.Login)}
            </Button>
            {/* <Link to="/web-owner/qrcode">
                            <Button variant="primary" className="d-block w-100" disabled={loading}>
                                {t('Login')}
                            </Button>
                        </Link> */}
          </div>
        </VerticalForm>
      </AuthLayout>
    </>
  );
};

export default LoginAdmin;
