import product1 from '../../../assets/images/products/product-1.png';
import product2 from '../../../assets/images/products/product-2.png';
import product3 from '../../../assets/images/products/product-3.png';
import product4 from '../../../assets/images/products/product-4.png';
import product5 from '../../../assets/images/products/product-5.png';
import product6 from '../../../assets/images/products/product-6.png';
import product7 from '../../../assets/images/products/product-7.png';
import product8 from '../../../assets/images/products/product-8.png';

export interface ProductItemTypes {
    id: number;
    name: string;
    image: string;
    rating: number;
    price: number;
    quantity: number;
    status: boolean;
}

export interface OrdersItemTypes {
    id: number;
    product_img: Array<string>;
    order_id: string;
    order_date: string;
    order_time: string;
    payment_status: string;
    total: string;
    payment_method: string;
    order_status: string;
}

interface CustomersItemTypes {
    id: number;
    name: string;
    phone: string;
    balance: string;
    orders: number;
    last_order: {
        date: string;
        time: string;
    };
    avatar: string;
    status: string;
}

interface SellersItemTypes {
    id: number;
    name: string;
    store: string;
    ratings: number;
    products: number;
    balance: string;
    created_on: string;
    revenue: string;
    image: string;
}

export interface CartItemTypes {
    id: number;
    image: string;
    name: string;
    size: string;
    color: string;
    price: number;
    qty: number;
    total: number;
}

const products: ProductItemTypes[] = [
    {
        id: 1,
        name: "Men's sport T-shirt",
        image: product1,
        rating: 3,
        price: 39,
        quantity: 98,
        status: true,
    },
    {
        id: 2,
        name: "Jack & Jones Men's T-shirt (Red)",
        image: product2,
        rating: 2,
        price: 98,
        quantity: 23,
        status: true,
    },
    {
        id: 3,
        name: "Women's Marron Tshirt",
        image: product3,
        rating: 5,
        price: 49,
        quantity: 235,
        status: true,
    },
    {
        id: 4,
        name: "Jack & Jones Men's T-shirt (Sky Blue)",
        image: product4,
        rating: 3,
        price: 29,
        quantity: 385,
        status: false,
    },
    {
        id: 5,
        name: 'Soft Green Tshirt',
        image: product5,
        rating: 4,
        price: 49,
        quantity: 25,
        status: false,
    },
    {
        id: 6,
        name: "Jack & Jones Men's Jacket",
        image: product6,
        rating: 5,
        price: 19,
        quantity: 19,
        status: true,
    },
    {
        id: 7,
        name: "Women's Soft Green Tshirt",
        image: product7,
        rating: 5,
        price: 99,
        quantity: 36,
        status: false,
    },
    {
        id: 8,
        name: 'Roadster Long Sleeves T-shirt',
        image: product8,
        rating: 1,
        price: 29,
        quantity: 128,
        status: true,
    },
];

const orders: OrdersItemTypes[] = [
    {
        id: 1,
        product_img: [product1, product2],
        order_id: '31',
        order_date: '23-May-2019',
        order_time: '1:45 PM',
        payment_status: 'Payment Failed',
        total: '$8361.93',
        payment_method: 'Visa',
        order_status: 'Processing',
    },
    {
        id: 2,
        product_img: [product3, product4, product5],
        order_id: '060',
        order_date: '01-Feb-2019',
        order_time: '12:10 PM',
        payment_status: 'Unpaid',
        total: '$6219.67',
        payment_method: 'Credit Card',
        order_status: 'Shipped',
    },
    {
        id: 3,
        product_img: [product5],
        order_id: '76961',
        order_date: '13-Mar-2019',
        order_time: '2:53 AM',
        payment_status: 'Payment Failed',
        total: '$6695.83',
        payment_method: 'Paypal',
        order_status: 'Shipped',
    },
    {
        id: 4,
        product_img: [product3, product8],
        order_id: '59',
        order_date: '02-Feb-2019',
        order_time: '2:53 AM',
        payment_status: 'Paid',
        total: '$8616.73',
        payment_method: 'Visa',
        order_status: 'Delivered',
    },
    {
        id: 5,
        product_img: [product5, product7],
        order_id: '93',
        order_date: '15-May-2019',
        order_time: '1:52 PM',
        payment_status: 'Awaiting Authorization',
        total: '$1808.61',
        payment_method: 'Paypal',
        order_status: 'Shipped',
    },
    {
        id: 6,
        product_img: [product2],
        order_id: '2164',
        order_date: '22-Feb-2019',
        order_time: '3:59 AM',
        payment_status: 'Paid',
        total: '$9813.57',
        payment_method: 'Paypal',
        order_status: 'Delivered',
    },
    {
        id: 7,
        product_img: [product4, product6],
        order_id: '22410',
        order_date: '16-Jan-2019',
        order_time: '7:29 AM',
        payment_status: 'Awaiting Authorization',
        total: '$9457.23',
        payment_method: 'Mastercard',
        order_status: 'Delivered',
    },
    {
        id: 8,
        product_img: [product6, product8, product3],
        order_id: '04260',
        order_date: '06-Feb-2019',
        order_time: '5:14 AM',
        payment_status: 'Unpaid',
        total: '$1054.76',
        payment_method: 'Payoneer',
        order_status: 'Processing',
    },
    {
        id: 9,
        product_img: [product2, product5],
        order_id: '77',
        order_date: '18-Jan-2019',
        order_time: '9:34 AM',
        payment_status: 'Payment Failed',
        total: '$3526.87',
        payment_method: 'Payoneer',
        order_status: 'Shipped',
    },
    {
        id: 10,
        product_img: [product5, product8],
        order_id: '938',
        order_date: '27-Apr-2019',
        order_time: '6:16 PM',
        payment_status: 'Paid',
        total: '$8201.67',
        payment_method: 'Paypal',
        order_status: 'Delivered',
    },
    {
        id: 11,
        product_img: [product1],
        order_id: '99',
        order_date: '03-Mar-2019',
        order_time: '11:23 PM',
        payment_status: 'Payment Failed',
        total: '$9557.76',
        payment_method: 'Paypal',
        order_status: 'Processing',
    },
    {
        id: 12,
        product_img: [product3, product4, product5],
        order_id: '397',
        order_date: '12-May-2019',
        order_time: '11:18 PM',
        payment_status: 'Unpaid',
        total: '$8391.95',
        payment_method: 'Visa',
        order_status: 'Processing',
    },
    {
        id: 13,
        product_img: [product5],
        order_id: '4874',
        order_date: '01-Jan-2019',
        order_time: '2:15 PM',
        payment_status: 'Unpaid',
        total: '$3558.36',
        payment_method: 'Paypal',
        order_status: 'Cancelled',
    },
    {
        id: 14,
        product_img: [product3, product8],
        order_id: '496',
        order_date: '17-Apr-2019',
        order_time: '7:56 PM',
        payment_status: 'Payment Failed',
        total: '$2871.99',
        payment_method: 'Credit Card',
        order_status: 'Delivered',
    },
    {
        id: 15,
        product_img: [product5, product7],
        order_id: '982',
        order_date: '07-May-2019',
        order_time: '7:54 PM',
        payment_status: 'Awaiting Authorization',
        total: '$415.59',
        payment_method: 'Mastercard',
        order_status: 'Processing',
    },
    {
        id: 16,
        product_img: [product2],
        order_id: '66303',
        order_date: '14-Mar-2019',
        order_time: '10:47 AM',
        payment_status: 'Paid',
        total: '$9554.21',
        payment_method: 'Credit Card',
        order_status: 'Shipped',
    },
    {
        id: 17,
        product_img: [product4, product6],
        order_id: '73',
        order_date: '20-Feb-2019',
        order_time: '4:24 PM',
        payment_status: 'Payment Failed',
        total: '$9766.71',
        payment_method: 'Mastercard',
        order_status: 'Delivered',
    },
    {
        id: 18,
        product_img: [product6, product8, product3],
        order_id: '90804',
        order_date: '03-Jun-2019',
        order_time: '5:42 PM',
        payment_status: 'Payment Failed',
        total: '$1194.25',
        payment_method: 'Mastercard',
        order_status: 'Cancelled',
    },
    {
        id: 19,
        product_img: [product3, product8],
        order_id: '97489',
        order_date: '09-May-2019',
        order_time: '11:47 PM',
        payment_status: 'Paid',
        total: '$8715.09',
        payment_method: 'Visa',
        order_status: 'Processing',
    },
    {
        id: 20,
        product_img: [product5, product7],
        order_id: '53577',
        order_date: '14-Jun-2019',
        order_time: '8:47 PM',
        payment_status: 'Unpaid',
        total: '$2836.42',
        payment_method: 'Mastercard',
        order_status: 'Delivered',
    },
    {
        id: 21,
        product_img: [product2],
        order_id: '8940',
        order_date: '08-Jun-2019',
        order_time: '6:14 PM',
        payment_status: 'Paid',
        total: '$3552.93',
        payment_method: 'Credit Card',
        order_status: 'Delivered',
    },
    {
        id: 22,
        product_img: [product4, product6],
        order_id: '34334',
        order_date: '20-Apr-2019',
        order_time: '7:19 PM',
        payment_status: 'Payment Failed',
        total: '$7487.68',
        payment_method: 'Payoneer',
        order_status: 'Delivered',
    },
    {
        id: 23,
        product_img: [product6, product8, product3],
        order_id: '2',
        order_date: '07-Jan-2019',
        order_time: '4:29 AM',
        payment_status: 'Paid',
        total: '$2900.64',
        payment_method: 'Mastercard',
        order_status: 'Delivered',
    },
    {
        id: 24,
        product_img: [product5],
        order_id: '478',
        order_date: '19-Jun-2019',
        order_time: '9:29 AM',
        payment_status: 'Unpaid',
        total: '$7589.76',
        payment_method: 'Visa',
        order_status: 'Delivered',
    },
    {
        id: 25,
        product_img: [product3, product8],
        order_id: '95',
        order_date: '30-Mar-2019',
        order_time: '9:47 AM',
        payment_status: 'Unpaid',
        total: '$3774.83',
        payment_method: 'Mastercard',
        order_status: 'Delivered',
    },
    {
        id: 26,
        product_img: [product5, product7],
        order_id: '160',
        order_date: '05-Jun-2019',
        order_time: '1:39 PM',
        payment_status: 'Paid',
        total: '$2696.43',
        payment_method: 'Paypal',
        order_status: 'Cancelled',
    },
    {
        id: 27,
        product_img: [product2],
        order_id: '81338',
        order_date: '19-Jun-2019',
        order_time: '4:59 PM',
        payment_status: 'Unpaid',
        total: '$4720.65',
        payment_method: 'Credit Card',
        order_status: 'Processing',
    },
    {
        id: 28,
        product_img: [product4, product6],
        order_id: '75',
        order_date: '31-Jan-2019',
        order_time: '10:24 AM',
        payment_status: 'Unpaid',
        total: '$7674.74',
        payment_method: 'Visa',
        order_status: 'Delivered',
    },
    {
        id: 29,
        product_img: [product6, product8, product3],
        order_id: '8',
        order_date: '16-Feb-2019',
        order_time: '5:08 AM',
        payment_status: 'Paid',
        total: '$2399.90',
        payment_method: 'Paypal',
        order_status: 'Delivered',
    },
    {
        id: 30,
        product_img: [product1],
        order_id: '99',
        order_date: '19-Jun-2019',
        order_time: '1:33 AM',
        payment_status: 'Payment Failed',
        total: '$2471.31',
        payment_method: 'Visa',
        order_status: 'Cancelled',
    },
    {
        id: 31,
        product_img: [product3, product8],
        order_id: '976',
        order_date: '17-Jun-2019',
        order_time: '8:21 AM',
        payment_status: 'Unpaid',
        total: '$8073.28',
        payment_method: 'Visa',
        order_status: 'Processing',
    },
    {
        id: 32,
        product_img: [product5, product7],
        order_id: '13',
        order_date: '17-Feb-2019',
        order_time: '5:41 AM',
        payment_status: 'Unpaid',
        total: '$4393.62',
        payment_method: 'Payoneer',
        order_status: 'Cancelled',
    },
    {
        id: 33,
        product_img: [product2],
        order_id: '8333',
        order_date: '14-Feb-2019',
        order_time: '4:42 AM',
        payment_status: 'Paid',
        total: '$6077.15',
        payment_method: 'Paypal',
        order_status: 'Cancelled',
    },
    {
        id: 34,
        product_img: [product4, product6],
        order_id: '21547',
        order_date: '22-Jul-2019',
        order_time: '8:42 PM',
        payment_status: 'Unpaid',
        total: '$5792.53',
        payment_method: 'Mastercard',
        order_status: 'Processing',
    },
    {
        id: 35,
        product_img: [product6, product8, product3],
        order_id: '25',
        order_date: '11-Jul-2019',
        order_time: '10:38 PM',
        payment_status: 'Payment Failed',
        total: '$8661.65',
        payment_method: 'Credit Card',
        order_status: 'Cancelled',
    },
    {
        id: 36,
        product_img: [product1],
        order_id: '3201',
        order_date: '13-Jun-2019',
        order_time: '9:57 PM',
        payment_status: 'Payment Failed',
        total: '$7795.53',
        payment_method: 'Visa',
        order_status: 'Processing',
    },
    {
        id: 37,
        product_img: [product6, product8, product3],
        order_id: '797',
        order_date: '09-Mar-2019',
        order_time: '9:53 PM',
        payment_status: 'Paid',
        total: '$9755.20',
        payment_method: 'Mastercard',
        order_status: 'Cancelled',
    },
    {
        id: 38,
        product_img: [product5],
        order_id: '7078',
        order_date: '04-Jul-2019',
        order_time: '9:09 AM',
        payment_status: 'Unpaid',
        total: '$2335.70',
        payment_method: 'Mastercard',
        order_status: 'Processing',
    },
    {
        id: 39,
        product_img: [product3, product8],
        order_id: '4380',
        order_date: '11-Apr-2019',
        order_time: '5:33 AM',
        payment_status: 'Awaiting Authorization',
        total: '$1342.74',
        payment_method: 'Paypal',
        order_status: 'Shipped',
    },
    {
        id: 40,
        product_img: [product5, product7],
        order_id: '4',
        order_date: '23-May-2019',
        order_time: '11:35 AM',
        payment_status: 'Paid',
        total: '$626.28',
        payment_method: 'Payoneer',
        order_status: 'Delivered',
    },
    {
        id: 41,
        product_img: [product2],
        order_id: '54',
        order_date: '11-Apr-2019',
        order_time: '1:51 AM',
        payment_status: 'Unpaid',
        total: '$1903.55',
        payment_method: 'Visa',
        order_status: 'Processing',
    },
    {
        id: 42,
        product_img: [product4, product6],
        order_id: '6084',
        order_date: '01-Jan-2019',
        order_time: '11:08 AM',
        payment_status: 'Unpaid',
        total: '$1671.64',
        payment_method: 'Paypal',
        order_status: 'Delivered',
    },
    {
        id: 43,
        product_img: [product6, product8, product3],
        order_id: '7004',
        order_date: '12-Mar-2019',
        order_time: '6:18 AM',
        payment_status: 'Payment Failed',
        total: '$7471.41',
        payment_method: 'Payoneer',
        order_status: 'Shipped',
    },
    {
        id: 44,
        product_img: [product1, product5, product3],
        order_id: '00890',
        order_date: '10-Jan-2019',
        order_time: '5:36 AM',
        payment_status: 'Payment Failed',
        total: '$7395.11',
        payment_method: 'Paypal',
        order_status: 'Processing',
    },
    {
        id: 45,
        product_img: [product5, product8, product4],
        order_id: '60931',
        order_date: '27-Jan-2019',
        order_time: '11:31 PM',
        payment_status: 'Awaiting Authorization',
        total: '$4022.17',
        payment_method: 'Payoneer',
        order_status: 'Delivered',
    },
    {
        id: 46,
        product_img: [product1],
        order_id: '2',
        order_date: '12-Jan-2019',
        order_time: '1:01 PM',
        payment_status: 'Payment Failed',
        total: '$7455.30',
        payment_method: 'Payoneer',
        order_status: 'Shipped',
    },
    {
        id: 47,
        product_img: [product1, product2],
        order_id: '92',
        order_date: '20-Jul-2019',
        order_time: '2:48 PM',
        payment_status: 'Unpaid',
        total: '$192.21',
        payment_method: 'Visa',
        order_status: 'Delivered',
    },
    {
        id: 48,
        product_img: [product3, product4, product5],
        order_id: '937',
        order_date: '05-Apr-2019',
        order_time: '6:06 PM',
        payment_status: 'Payment Failed',
        total: '$4070.53',
        payment_method: 'Visa',
        order_status: 'Shipped',
    },
    {
        id: 49,
        product_img: [product5],
        order_id: '3474',
        order_date: '27-Jan-2019',
        order_time: '8:18 PM',
        payment_status: 'Unpaid',
        total: '$2957.29',
        payment_method: 'Visa',
        order_status: 'Cancelled',
    },
    {
        id: 50,
        product_img: [product3, product8],
        order_id: '1419',
        order_date: '10-Feb-2019',
        order_time: '12:58 AM',
        payment_status: 'Unpaid',
        total: '$8052.46',
        payment_method: 'Mastercard',
        order_status: 'Shipped',
    },
    {
        id: 51,
        product_img: [product5, product7],
        order_id: '763',
        order_date: '16-Feb-2019',
        order_time: '5:36 AM',
        payment_status: 'Unpaid',
        total: '$1424.77',
        payment_method: 'Visa',
        order_status: 'Delivered',
    },
    {
        id: 52,
        product_img: [product2],
        order_id: '2',
        order_date: '12-May-2019',
        order_time: '8:32 PM',
        payment_status: 'Unpaid',
        total: '$2126.47',
        payment_method: 'Visa',
        order_status: 'Processing',
    },
    {
        id: 53,
        product_img: [product4, product6],
        order_id: '06',
        order_date: '07-Mar-2019',
        order_time: '3:10 AM',
        payment_status: 'Payment Failed',
        total: '$3626.53',
        payment_method: 'Paypal',
        order_status: 'Cancelled',
    },
    {
        id: 54,
        product_img: [product6, product8, product3],
        order_id: '29608',
        order_date: '18-Jul-2019',
        order_time: '10:03 PM',
        payment_status: 'Awaiting Authorization',
        total: '$4291.42',
        payment_method: 'Mastercard',
        order_status: 'Cancelled',
    },
    {
        id: 55,
        product_img: [product2, product5],
        order_id: '109',
        order_date: '05-Apr-2019',
        order_time: '8:14 AM',
        payment_status: 'Paid',
        total: '$417.32',
        payment_method: 'Mastercard',
        order_status: 'Cancelled',
    },
    {
        id: 56,
        product_img: [product5, product8],
        order_id: '700',
        order_date: '08-Apr-2019',
        order_time: '9:49 AM',
        payment_status: 'Payment Failed',
        total: '$1046.92',
        payment_method: 'Visa',
        order_status: 'Cancelled',
    },
    {
        id: 57,
        product_img: [product1],
        order_id: '59297',
        order_date: '06-Feb-2019',
        order_time: '8:49 PM',
        payment_status: 'Paid',
        total: '$8722.68',
        payment_method: 'Credit Card',
        order_status: 'Shipped',
    },
    {
        id: 58,
        product_img: [product3, product4, product5],
        order_id: '74',
        order_date: '02-Feb-2019',
        order_time: '10:59 PM',
        payment_status: 'Payment Failed',
        total: '$9032.25',
        payment_method: 'Mastercard',
        order_status: 'Processing',
    },
    {
        id: 59,
        product_img: [product5],
        order_id: '2',
        order_date: '08-Jun-2019',
        order_time: '10:38 PM',
        payment_status: 'Unpaid',
        total: '$6220.01',
        payment_method: 'Paypal',
        order_status: 'Delivered',
    },
    {
        id: 60,
        product_img: [product3, product8],
        order_id: '1375',
        order_date: '12-Jan-2019',
        order_time: '4:07 PM',
        payment_status: 'Unpaid',
        total: '$8482.80',
        payment_method: 'Credit Card',
        order_status: 'Delivered',
    },
    {
        id: 61,
        product_img: [product5, product7],
        order_id: '65',
        order_date: '26-Jan-2019',
        order_time: '3:23 PM',
        payment_status: 'Awaiting Authorization',
        total: '$1429.24',
        payment_method: 'Visa',
        order_status: 'Processing',
    },
    {
        id: 62,
        product_img: [product2],
        order_id: '6158',
        order_date: '24-Jan-2019',
        order_time: '4:33 PM',
        payment_status: 'Awaiting Authorization',
        total: '$6894.72',
        payment_method: 'Visa',
        order_status: 'Cancelled',
    },
    {
        id: 63,
        product_img: [product4, product6],
        order_id: '47797',
        order_date: '29-May-2019',
        order_time: '7:05 PM',
        payment_status: 'Paid',
        total: '$6970.59',
        payment_method: 'Mastercard',
        order_status: 'Cancelled',
    },
    {
        id: 64,
        product_img: [product6, product8, product3],
        order_id: '7727',
        order_date: '12-Jun-2019',
        order_time: '4:52 AM',
        payment_status: 'Awaiting Authorization',
        total: '$8793.72',
        payment_method: 'Visa',
        order_status: 'Delivered',
    },
    {
        id: 65,
        product_img: [product3, product8],
        order_id: '69091',
        order_date: '15-Apr-2019',
        order_time: '2:13 AM',
        payment_status: 'Awaiting Authorization',
        total: '$799.08',
        payment_method: 'Mastercard',
        order_status: 'Processing',
    },
    {
        id: 66,
        product_img: [product5, product7],
        order_id: '55320',
        order_date: '29-Jan-2019',
        order_time: '7:47 AM',
        payment_status: 'Payment Failed',
        total: '$8411.65',
        payment_method: 'Mastercard',
        order_status: 'Cancelled',
    },
    {
        id: 67,
        product_img: [product2],
        order_id: '334',
        order_date: '15-Jan-2019',
        order_time: '5:15 PM',
        payment_status: 'Payment Failed',
        total: '$885.00',
        payment_method: 'Credit Card',
        order_status: 'Delivered',
    },
    {
        id: 68,
        product_img: [product4, product6],
        order_id: '782',
        order_date: '13-Feb-2019',
        order_time: '2:57 PM',
        payment_status: 'Unpaid',
        total: '$8856.16',
        payment_method: 'Mastercard',
        order_status: 'Shipped',
    },
    {
        id: 69,
        product_img: [product6, product8, product3],
        order_id: '6036',
        order_date: '30-Apr-2019',
        order_time: '1:29 AM',
        payment_status: 'Unpaid',
        total: '$904.92',
        payment_method: 'Mastercard',
        order_status: 'Shipped',
    },
    {
        id: 70,
        product_img: [product5],
        order_id: '2028',
        order_date: '04-Jan-2019',
        order_time: '2:05 PM',
        payment_status: 'Unpaid',
        total: '$4966.59',
        payment_method: 'Visa',
        order_status: 'Processing',
    },
    {
        id: 71,
        product_img: [product3, product8],
        order_id: '603',
        order_date: '14-Jan-2019',
        order_time: '4:21 AM',
        payment_status: 'Unpaid',
        total: '$2899.05',
        payment_method: 'Mastercard',
        order_status: 'Processing',
    },
    {
        id: 72,
        product_img: [product5, product7],
        order_id: '0497',
        order_date: '08-Jun-2019',
        order_time: '2:27 AM',
        payment_status: 'Unpaid',
        total: '$8717.70',
        payment_method: 'Payoneer',
        order_status: 'Shipped',
    },
    {
        id: 73,
        product_img: [product2],
        order_id: '635',
        order_date: '15-Jul-2019',
        order_time: '2:51 AM',
        payment_status: 'Paid',
        total: '$5238.61',
        payment_method: 'Mastercard',
        order_status: 'Processing',
    },
    {
        id: 74,
        product_img: [product4, product6],
        order_id: '2185',
        order_date: '13-Jan-2019',
        order_time: '8:06 PM',
        payment_status: 'Unpaid',
        total: '$620.06',
        payment_method: 'Visa',
        order_status: 'Shipped',
    },
    {
        id: 75,
        product_img: [product6, product8, product3],
        order_id: '235',
        order_date: '28-Jun-2019',
        order_time: '8:22 AM',
        payment_status: 'Paid',
        total: '$8280.55',
        payment_method: 'Paypal',
        order_status: 'Processing',
    },
    {
        id: 76,
        product_img: [product1],
        order_id: '121',
        order_date: '09-Apr-2019',
        order_time: '4:41 PM',
        payment_status: 'Payment Failed',
        total: '$8483.16',
        payment_method: 'Payoneer',
        order_status: 'Cancelled',
    },
    {
        id: 77,
        product_img: [product3, product8],
        order_id: '9288',
        order_date: '17-Jun-2019',
        order_time: '6:17 PM',
        payment_status: 'Paid',
        total: '$5638.00',
        payment_method: 'Credit Card',
        order_status: 'Delivered',
    },
    {
        id: 78,
        product_img: [product5, product7],
        order_id: '4',
        order_date: '27-May-2019',
        order_time: '8:31 PM',
        payment_status: 'Paid',
        total: '$3600.90',
        payment_method: 'Credit Card',
        order_status: 'Cancelled',
    },
    {
        id: 79,
        product_img: [product2],
        order_id: '98',
        order_date: '18-Jul-2019',
        order_time: '6:25 AM',
        payment_status: 'Awaiting Authorization',
        total: '$7017.32',
        payment_method: 'Visa',
        order_status: 'Cancelled',
    },
    {
        id: 80,
        product_img: [product4, product6],
        order_id: '2',
        order_date: '21-Jul-2019',
        order_time: '11:32 AM',
        payment_status: 'Payment Failed',
        total: '$4923.75',
        payment_method: 'Visa',
        order_status: 'Processing',
    },
    {
        id: 81,
        product_img: [product6, product8, product3],
        order_id: '18652',
        order_date: '17-Mar-2019',
        order_time: '5:16 AM',
        payment_status: 'Awaiting Authorization',
        total: '$7781.30',
        payment_method: 'Paypal',
        order_status: 'Delivered',
    },
    {
        id: 82,
        product_img: [product1],
        order_id: '2154',
        order_date: '16-Jul-2019',
        order_time: '8:49 PM',
        payment_status: 'Unpaid',
        total: '$7772.35',
        payment_method: 'Credit Card',
        order_status: 'Cancelled',
    },
    {
        id: 83,
        product_img: [product6, product8, product3],
        order_id: '127',
        order_date: '18-Apr-2019',
        order_time: '8:00 PM',
        payment_status: 'Unpaid',
        total: '$8074.16',
        payment_method: 'Credit Card',
        order_status: 'Shipped',
    },
    {
        id: 84,
        product_img: [product5],
        order_id: '8866',
        order_date: '13-May-2019',
        order_time: '3:53 PM',
        payment_status: 'Unpaid',
        total: '$1769.78',
        payment_method: 'Mastercard',
        order_status: 'Cancelled',
    },
    {
        id: 85,
        product_img: [product3, product8],
        order_id: '3',
        order_date: '28-Mar-2019',
        order_time: '9:37 PM',
        payment_status: 'Awaiting Authorization',
        total: '$8387.41',
        payment_method: 'Payoneer',
        order_status: 'Processing',
    },
    {
        id: 86,
        product_img: [product5, product7],
        order_id: '9499',
        order_date: '25-Jan-2019',
        order_time: '5:56 PM',
        payment_status: 'Unpaid',
        total: '$1575.43',
        payment_method: 'Credit Card',
        order_status: 'Shipped',
    },
    {
        id: 87,
        product_img: [product2],
        order_id: '905',
        order_date: '08-Jun-2019',
        order_time: '1:47 PM',
        payment_status: 'Payment Failed',
        total: '$6732.83',
        payment_method: 'Paypal',
        order_status: 'Delivered',
    },
    {
        id: 88,
        product_img: [product4, product6],
        order_id: '3',
        order_date: '11-Jun-2019',
        order_time: '2:29 PM',
        payment_status: 'Unpaid',
        total: '$8606.47',
        payment_method: 'Paypal',
        order_status: 'Cancelled',
    },
    {
        id: 89,
        product_img: [product6, product8, product3],
        order_id: '23556',
        order_date: '21-Jan-2019',
        order_time: '5:00 PM',
        payment_status: 'Awaiting Authorization',
        total: '$7765.50',
        payment_method: 'Payoneer',
        order_status: 'Cancelled',
    },
    {
        id: 90,
        product_img: [product1, product5, product3],
        order_id: '625',
        order_date: '27-Jan-2019',
        order_time: '12:37 PM',
        payment_status: 'Paid',
        total: '$3732.00',
        payment_method: 'Mastercard',
        order_status: 'Shipped',
    },
    {
        id: 91,
        product_img: [product5, product8, product4],
        order_id: '6',
        order_date: '23-Jul-2019',
        order_time: '6:04 PM',
        payment_status: 'Payment Failed',
        total: '$7194.17',
        payment_method: 'Mastercard',
        order_status: 'Delivered',
    },
    {
        id: 92,
        product_img: [product1],
        order_id: '2808',
        order_date: '06-Jun-2019',
        order_time: '10:41 PM',
        payment_status: 'Paid',
        total: '$1181.92',
        payment_method: 'Paypal',
        order_status: 'Delivered',
    },
    {
        id: 93,
        product_img: [product5, product7],
        order_id: '9313',
        order_date: '08-May-2019',
        order_time: '8:10 AM',
        payment_status: 'Paid',
        total: '$370.72',
        payment_method: 'Paypal',
        order_status: 'Shipped',
    },
    {
        id: 94,
        product_img: [product2],
        order_id: '66',
        order_date: '24-Jan-2019',
        order_time: '12:30 AM',
        payment_status: 'Unpaid',
        total: '$3398.52',
        payment_method: 'Paypal',
        order_status: 'Shipped',
    },
    {
        id: 95,
        product_img: [product4, product6],
        order_id: '5',
        order_date: '04-Jun-2019',
        order_time: '7:23 PM',
        payment_status: 'Paid',
        total: '$8872.94',
        payment_method: 'Paypal',
        order_status: 'Delivered',
    },
    {
        id: 96,
        product_img: [product6, product8, product3],
        order_id: '4',
        order_date: '21-May-2019',
        order_time: '6:13 AM',
        payment_status: 'Payment Failed',
        total: '$6897.83',
        payment_method: 'Mastercard',
        order_status: 'Processing',
    },
    {
        id: 97,
        product_img: [product1],
        order_id: '7160',
        order_date: '08-Apr-2019',
        order_time: '10:49 PM',
        payment_status: 'Unpaid',
        total: '$4432.29',
        payment_method: 'Credit Card',
        order_status: 'Cancelled',
    },
    {
        id: 98,
        product_img: [product6, product8, product3],
        order_id: '10509',
        order_date: '05-Jun-2019',
        order_time: '7:54 PM',
        payment_status: 'Unpaid',
        total: '$6381.03',
        payment_method: 'Credit Card',
        order_status: 'Shipped',
    },
    {
        id: 99,
        product_img: [product5],
        order_id: '025',
        order_date: '31-May-2019',
        order_time: '8:50 PM',
        payment_status: 'Awaiting Authorization',
        total: '$197.45',
        payment_method: 'Payoneer',
        order_status: 'Processing',
    },
    {
        id: 100,
        product_img: [product3, product8],
        order_id: '16529',
        order_date: '01-Jul-2019',
        order_time: '6:04 AM',
        payment_status: 'Payment Failed',
        total: '$3337.15',
        payment_method: 'Credit Card',
        order_status: 'Cancelled',
    },
];

const customers: CustomersItemTypes[] = [
    {
        id: 1,
        name: 'Paul J. Friend',
        phone: '050 414 8778',
        balance: '$12,874.82',
        orders: 43,
        last_order: {
            date: 'August 05 2019',
            time: '10:29 PM',
        },
        avatar: 'https://robohash.org/laborevoluptateeaque.png?size=60x60&set=set1',
        status: 'Active',
    },
    {
        id: 2,
        name: 'Bryan J. Luellen',
        phone: '215-302-3376',
        balance: '$874.25',
        orders: 220,
        last_order: {
            date: 'August 04 2019',
            time: '08:18 AM',
        },
        avatar: 'https://robohash.org/voluptatemeligendisint.png?size=60x60&set=set1',
        status: 'Active',
    },
    {
        id: 3,
        name: 'Kathryn S. Collier',
        phone: '828-216-2190',
        balance: '$125.78',
        orders: 841,
        last_order: {
            date: 'November 04 2019',
            time: '10:29 PM',
        },
        avatar: 'https://robohash.org/etidsapiente.png?size=60x60&set=set1',
        status: 'Blocked',
    },
    {
        id: 4,
        name: 'Timothy Kauper',
        phone: '(216) 75 612 706',
        balance: '$561.25',
        orders: 62,
        last_order: {
            date: 'February 01 2019',
            time: '07:22 AM',
        },
        avatar: 'https://robohash.org/pariaturharumquas.png?size=60x60&set=set1',
        status: 'Blocked',
    },
    {
        id: 5,
        name: 'Zara Raws',
        phone: '(02) 75 150 655',
        balance: '$2147.84',
        orders: 9,
        last_order: {
            date: 'February 01 2019',
            time: '07:22 AM',
        },
        avatar: 'https://robohash.org/quodfugiatquae.png?size=60x60&set=set1',
        status: 'Active',
    },
    {
        id: 6,
        name: 'Annette P. Kelsch',
        phone: '(+15) 73 483 758',
        balance: '$451.28',
        orders: 25,
        last_order: {
            date: 'September 07 2019',
            time: '07:22 AM',
        },
        avatar: 'https://robohash.org/sapientenonomnis.png?size=60x60&set=set1',
        status: 'Active',
    },
    {
        id: 7,
        name: 'Jenny C. Gero',
        phone: '078 7173 9261',
        balance: '$965.20',
        orders: 214,
        last_order: {
            date: 'November 14 2019',
            time: '07:22 AM',
        },
        avatar: 'https://robohash.org/autvelitest.png?size=60x60&set=set1',
        status: 'Blocked',
    },
    {
        id: 8,
        name: 'Edward Roseby',
        phone: '078 6013 3854',
        balance: '$71584.2',
        orders: 365,
        last_order: {
            date: 'February 09 2019',
            time: '07:22 AM',
        },
        avatar: 'https://robohash.org/exsuscipitcorporis.png?size=60x60&set=set1',
        status: 'Active',
    },
    {
        id: 9,
        name: 'Anna Ciantar',
        phone: '(216) 76 298 896',
        balance: '$5482.00',
        orders: 921,
        last_order: {
            date: 'September 12 2019',
            time: '07:22 AM',
        },
        avatar: 'https://robohash.org/consectetureiuset.png?size=60x60&set=set1',
        status: 'Active',
    },
    {
        id: 10,
        name: 'Dean Smithies',
        phone: '077 6157 4248',
        balance: '$482.15',
        orders: 68,
        last_order: {
            date: 'October 09 2019',
            time: '07:22 AM',
        },
        avatar: 'https://robohash.org/aspernaturlaborumab.png?size=60x60&set=set1',
        status: 'Active',
    },
    {
        id: 11,
        name: 'Labeeb Ghali',
        phone: '050 414 8778',
        balance: '$7852.3',
        orders: 475,
        last_order: {
            date: 'October 27 2019',
            time: '07:22 AM',
        },
        avatar: 'https://robohash.org/veroinciduntfacere.png?size=60x60&set=set1',
        status: 'Active',
    },
    {
        id: 12,
        name: 'Rory Seekamp',
        phone: '078 5054 8877',
        balance: '$3365.12',
        orders: 25,
        last_order: {
            date: 'April 21 2019',
            time: '07:22 AM',
        },
        avatar: 'https://robohash.org/cumvoluptatibusreprehenderit.png?size=60x60&set=set1',
        status: 'Blocked',
    },
];

const sellers: SellersItemTypes[] = [
    {
        id: 1,
        name: 'Hayward Billo',
        store: 'Boehm-Cremin',
        ratings: 4.9,
        products: 747,
        balance: '$1621.73',
        created_on: '07/18/2019',
        revenue: '$258.26k',
        image: 'https://robohash.org/voluptateesseaut.png?size=100x100&set=set1',
    },
    {
        id: 2,
        name: 'Adler Villa',
        store: 'Hettinger, Shields and Pollich',
        ratings: 3.5,
        products: 883,
        balance: '$20343.72',
        created_on: '07/03/2019',
        revenue: '$152.3k',
        image: 'https://robohash.org/pariaturfacilisrem.png?size=100x100&set=set1',
    },
    {
        id: 3,
        name: 'Janna MacFayden',
        store: "Fay, Batz and O'Conner",
        ratings: 4.1,
        products: 579,
        balance: '$29121.64',
        created_on: '07/11/2019',
        revenue: '$178.6k',
        image: 'https://robohash.org/laborehicvitae.png?size=100x100&set=set1',
    },
    {
        id: 4,
        name: 'Ludovika Ryle',
        store: 'Adams, Schuppe and Daugherty',
        ratings: 4.9,
        products: 353,
        balance: '$49595.04',
        created_on: '07/12/2019',
        revenue: '$368.2k',
        image: 'https://robohash.org/laboriosamvoluptatemet.png?size=100x100&set=set1',
    },
    {
        id: 5,
        name: 'Jamal Stopper',
        store: 'Dooley-Koss',
        ratings: 5.0,
        products: 662,
        balance: '$42698.10',
        created_on: '07/02/2019',
        revenue: '$89.5k',
        image: 'https://robohash.org/repellatdelenitinon.png?size=100x100&set=set1',
    },
    {
        id: 6,
        name: 'Ralf Orcott',
        store: 'Renner Group',
        ratings: 4.0,
        products: 453,
        balance: '$9943.45',
        created_on: '07/24/2019',
        revenue: '$597.8k',
        image: 'https://robohash.org/delectusquodquo.png?size=100x100&set=set1',
    },
    {
        id: 7,
        name: 'Bevin Weatherhogg',
        store: 'Feil-McDermott',
        ratings: 2.6,
        products: 801,
        balance: '$8067.89',
        created_on: '07/25/2019',
        revenue: '$29.3k',
        image: 'https://robohash.org/ipsaestad.png?size=100x100&set=set1',
    },
    {
        id: 8,
        name: 'Emalia Oglevie',
        store: 'Schaefer and Sons',
        ratings: 4.0,
        products: 280,
        balance: '$43724.29',
        created_on: '07/28/2019',
        revenue: '$48.6k',
        image: 'https://robohash.org/reprehenderitofficiisqui.png?size=100x100&set=set1',
    },
    {
        id: 9,
        name: 'Shelley Tyrer',
        store: 'Dooley-Lind',
        ratings: 4.3,
        products: 619,
        balance: '$3242.86',
        created_on: '07/16/2019',
        revenue: '$12.1k',
        image: 'https://robohash.org/expeditadictaitaque.png?size=100x100&set=set1',
    },
    {
        id: 10,
        name: 'Faustine Swine',
        store: 'Ledner Inc',
        ratings: 5.0,
        products: 829,
        balance: '$32115.41',
        created_on: '07/16/2019',
        revenue: '$78.2k',
        image: 'https://robohash.org/laboreetnisi.png?size=100x100&set=set1',
    },
    {
        id: 11,
        name: 'Jilly Gladstone',
        store: 'Kuphal, Bartell and Marquardt',
        ratings: 2.7,
        products: 546,
        balance: '$10625.78',
        created_on: '07/05/2019',
        revenue: '$121',
        image: 'https://robohash.org/velnecessitatibusofficiis.png?size=100x100&set=set1',
    },
    {
        id: 12,
        name: 'Erwin Malin',
        store: 'Marquardt, Littel and Kuhn',
        ratings: 4.9,
        products: 668,
        balance: '$24499.65',
        created_on: '07/29/2019',
        revenue: '$78.2k',
        image: 'https://robohash.org/quiperspiciatismollitia.png?size=100x100&set=set1',
    },
    {
        id: 13,
        name: 'Nancey Whelan',
        store: 'Shields, Wunsch and Becker',
        ratings: 4.8,
        products: 579,
        balance: '$36498.86',
        created_on: '07/09/2019',
        revenue: '$258.26k',
        image: 'https://robohash.org/sequicumqueeos.png?size=100x100&set=set1',
    },
    {
        id: 14,
        name: 'Riva Henighan',
        store: 'Hayes-Nicolas',
        ratings: 3.7,
        products: 719,
        balance: '$11551.96',
        created_on: '07/08/2019',
        revenue: '$152.3k',
        image: 'https://robohash.org/etadfuga.png?size=100x100&set=set1',
    },
    {
        id: 15,
        name: 'Patience Topling',
        store: 'Gerlach, Mayert and Hirthe',
        ratings: 4.6,
        products: 501,
        balance: '$30146.93',
        created_on: '07/26/2019',
        revenue: '$178.6k',
        image: 'https://robohash.org/fugiataperferendis.png?size=100x100&set=set1',
    },
    {
        id: 16,
        name: 'Rahal Syseland',
        store: 'Zieme, Boehm and Kshlerin',
        ratings: 4.9,
        products: 693,
        balance: '$7211.03',
        created_on: '07/24/2019',
        revenue: '$368.2k',
        image: 'https://robohash.org/minusquoeos.png?size=100x100&set=set1',
    },
    {
        id: 17,
        name: 'Isabel Seamen',
        store: 'Dickens-Orn',
        ratings: 3.5,
        products: 153,
        balance: '$34067.95',
        created_on: '07/27/2019',
        revenue: '$89.5k',
        image: 'https://robohash.org/enimnequeexplicabo.png?size=100x100&set=set1',
    },
    {
        id: 18,
        name: 'Shermy Meas',
        store: 'Roob, Jerde and Lynch',
        ratings: 4.1,
        products: 805,
        balance: '$20601.07',
        created_on: '07/04/2019',
        revenue: '$597.8k',
        image: 'https://robohash.org/corporisetrecusandae.png?size=100x100&set=set1',
    },
    {
        id: 19,
        name: 'Eartha Giotto',
        store: 'Rodriguez, Hyatt and Sanford',
        ratings: 2.8,
        products: 426,
        balance: '$25690.56',
        created_on: '07/15/2019',
        revenue: '$29.3k',
        image: 'https://robohash.org/omnisessenon.png?size=100x100&set=set1',
    },
    {
        id: 20,
        name: 'Wenona Lightowlers',
        store: 'Goodwin, Rempel and MacGyver',
        ratings: 5.0,
        products: 706,
        balance: '$22481.32',
        created_on: '07/09/2019',
        revenue: '$48.6k',
        image: 'https://robohash.org/impeditaiste.png?size=100x100&set=set1',
    },
    {
        id: 21,
        name: 'Silvano Milne',
        store: 'Kshlerin-Tillman',
        ratings: 4.0,
        products: 461,
        balance: '$45809.60',
        created_on: '07/14/2019',
        revenue: '$12.1k',
        image: 'https://robohash.org/voluptasperspiciatisrem.png?size=100x100&set=set1',
    },
    {
        id: 22,
        name: 'Mal Stendell',
        store: 'Kunze-Fritsch',
        ratings: 5.0,
        products: 361,
        balance: '$1277.01',
        created_on: '07/02/2019',
        revenue: '$78.2k',
        image: 'https://robohash.org/autdolorvero.png?size=100x100&set=set1',
    },
    {
        id: 23,
        name: 'Annora McGilleghole',
        store: 'Toy, Stamm and Crona',
        ratings: 4.0,
        products: 326,
        balance: '$37087.49',
        created_on: '07/20/2019',
        revenue: '$121',
        image: 'https://robohash.org/sunteligendiamet.png?size=100x100&set=set1',
    },
    {
        id: 24,
        name: 'Odey Lemanu',
        store: 'Parker Inc',
        ratings: 4.3,
        products: 134,
        balance: '$35535.66',
        created_on: '07/09/2019',
        revenue: '$78.2k',
        image: 'https://robohash.org/dolorumrepellatvelit.png?size=100x100&set=set1',
    },
    {
        id: 25,
        name: 'Maximo Vear',
        store: 'Hintz, Schumm and Gleichner',
        ratings: 5.0,
        products: 481,
        balance: '$12509.21',
        created_on: '07/13/2019',
        revenue: '$258.26k',
        image: 'https://robohash.org/etdoloremconsequatur.png?size=100x100&set=set1',
    },
    {
        id: 26,
        name: 'Lila Blazy',
        store: 'Wintheiser, Kulas and Waelchi',
        ratings: 2.7,
        products: 126,
        balance: '$33284.96',
        created_on: '07/08/2019',
        revenue: '$152.3k',
        image: 'https://robohash.org/etdoloremad.png?size=100x100&set=set1',
    },
    {
        id: 27,
        name: 'Felic Bake',
        store: 'Stehr Inc',
        ratings: 4.9,
        products: 544,
        balance: '$7772.79',
        created_on: '07/29/2019',
        revenue: '$178.6k',
        image: 'https://robohash.org/voluptatemestat.png?size=100x100&set=set1',
    },
    {
        id: 28,
        name: 'Brietta Gogarty',
        store: 'Bartell, Gislason and Shields',
        ratings: 4.8,
        products: 381,
        balance: '$40363.11',
        created_on: '07/05/2019',
        revenue: '$368.2k',
        image: 'https://robohash.org/nonexplicaboveniam.png?size=100x100&set=set1',
    },
    {
        id: 29,
        name: 'Courtney Dellenbrook',
        store: 'Vandervort-Ritchie',
        ratings: 3.7,
        products: 611,
        balance: '$45479.53',
        created_on: '07/23/2019',
        revenue: '$89.5k',
        image: 'https://robohash.org/illumautnam.png?size=100x100&set=set1',
    },
    {
        id: 30,
        name: 'Lauryn Foucar',
        store: 'Treutel, Heidenreich and Boehm',
        ratings: 4.6,
        products: 227,
        balance: '$47753.18',
        created_on: '07/08/2019',
        revenue: '$597.8k',
        image: 'https://robohash.org/suntillodolores.png?size=100x100&set=set1',
    },
    {
        id: 31,
        name: 'Deeann Jennemann',
        store: 'Will-Dicki',
        ratings: 4.9,
        products: 501,
        balance: '$3064.35',
        created_on: '07/06/2019',
        revenue: '$29.3k',
        image: 'https://robohash.org/remplaceatreiciendis.png?size=100x100&set=set1',
    },
    {
        id: 32,
        name: 'Nixie Merrilees',
        store: 'Nolan-Marvin',
        ratings: 3.5,
        products: 185,
        balance: '$37305.56',
        created_on: '07/19/2019',
        revenue: '$48.6k',
        image: 'https://robohash.org/temporedoloresenim.png?size=100x100&set=set1',
    },
    {
        id: 33,
        name: 'Daisi Corss',
        store: 'Monahan, Nikolaus and Lueilwitz',
        ratings: 4.1,
        products: 610,
        balance: '$22613.61',
        created_on: '07/25/2019',
        revenue: '$12.1k',
        image: 'https://robohash.org/aperiamrerumest.png?size=100x100&set=set1',
    },
    {
        id: 34,
        name: 'Reynard Pledge',
        store: 'Marquardt Group',
        ratings: 4.9,
        products: 213,
        balance: '$15564.63',
        created_on: '07/26/2019',
        revenue: '$78.2k',
        image: 'https://robohash.org/doloribusrationeodit.png?size=100x100&set=set1',
    },
    {
        id: 35,
        name: 'Peirce MacTimpany',
        store: 'Zieme and Sons',
        ratings: 5.0,
        products: 574,
        balance: '$2227.09',
        created_on: '07/12/2019',
        revenue: '$121',
        image: 'https://robohash.org/solutaautnam.png?size=100x100&set=set1',
    },
    {
        id: 36,
        name: 'Ingaborg Grelka',
        store: 'Bergnaum, Miller and Grimes',
        ratings: 4.0,
        products: 225,
        balance: '$11645.39',
        created_on: '07/08/2019',
        revenue: '$78.2k',
        image: 'https://robohash.org/possimusdoloremducimus.png?size=100x100&set=set1',
    },
    {
        id: 37,
        name: 'Johan Yeeles',
        store: 'Kovacek-Bogan',
        ratings: 5.0,
        products: 535,
        balance: '$7310.28',
        created_on: '07/29/2019',
        revenue: '$258.26k',
        image: 'https://robohash.org/sitassumendaaliquam.png?size=100x100&set=set1',
    },
    {
        id: 38,
        name: 'Tarrance Kulver',
        store: 'Smith, Roob and Schultz',
        ratings: 4.0,
        products: 798,
        balance: '$13193.06',
        created_on: '07/23/2019',
        revenue: '$152.3k',
        image: 'https://robohash.org/aspernaturvoluptasaut.png?size=100x100&set=set1',
    },
    {
        id: 39,
        name: 'Sharleen Tustin',
        store: 'Leuschke, Willms and Simonis',
        ratings: 4.3,
        products: 447,
        balance: '$2827.36',
        created_on: '07/29/2019',
        revenue: '$178.6k',
        image: 'https://robohash.org/maioresmollitiaiure.png?size=100x100&set=set1',
    },
    {
        id: 40,
        name: 'Natal Jacobowits',
        store: 'Rodriguez-Heathcote',
        ratings: 5.0,
        products: 216,
        balance: '$31236.57',
        created_on: '07/25/2019',
        revenue: '$368.2k',
        image: 'https://robohash.org/etrepellendusquia.png?size=100x100&set=set1',
    },
    {
        id: 41,
        name: 'Loraine De Moreno',
        store: 'Schuppe-MacGyver',
        ratings: 2.7,
        products: 257,
        balance: '$40410.89',
        created_on: '07/01/2019',
        revenue: '$89.5k',
        image: 'https://robohash.org/nostrumesseunde.png?size=100x100&set=set1',
    },
    {
        id: 42,
        name: 'Hermann Quin',
        store: "O'Reilly, Berge and Goyette",
        ratings: 4.9,
        products: 616,
        balance: '$22091.40',
        created_on: '07/24/2019',
        revenue: '$597.8k',
        image: 'https://robohash.org/temporedoloreoccaecati.png?size=100x100&set=set1',
    },
    {
        id: 43,
        name: 'Skippie Hawton',
        store: 'Ruecker-Jenkins',
        ratings: 4.8,
        products: 648,
        balance: '$22037.81',
        created_on: '07/11/2019',
        revenue: '$29.3k',
        image: 'https://robohash.org/doloremqueistealias.png?size=100x100&set=set1',
    },
    {
        id: 44,
        name: 'Charlena Filipponi',
        store: 'Dietrich-Treutel',
        ratings: 3.7,
        products: 896,
        balance: '$1560.22',
        created_on: '07/03/2019',
        revenue: '$48.6k',
        image: 'https://robohash.org/molestiaevelanimi.png?size=100x100&set=set1',
    },
    {
        id: 45,
        name: 'Titus Whorf',
        store: 'Beahan and Sons',
        ratings: 4.6,
        products: 458,
        balance: '$47518.74',
        created_on: '07/24/2019',
        revenue: '$12.1k',
        image: 'https://robohash.org/nihiltemporesoluta.png?size=100x100&set=set1',
    },
    {
        id: 46,
        name: 'Phillipp Vicker',
        store: 'Nikolaus-Wiegand',
        ratings: 4.9,
        products: 184,
        balance: '$26574.25',
        created_on: '07/18/2019',
        revenue: '$78.2k',
        image: 'https://robohash.org/fugaeumex.png?size=100x100&set=set1',
    },
    {
        id: 47,
        name: 'Yves Trattles',
        store: 'Lakin Inc',
        ratings: 3.5,
        products: 583,
        balance: '$43339.94',
        created_on: '07/19/2019',
        revenue: '$121',
        image: 'https://robohash.org/harumdolorofficiis.png?size=100x100&set=set1',
    },
    {
        id: 48,
        name: 'Valli Puttick',
        store: 'Ward Group',
        ratings: 4.1,
        products: 243,
        balance: '$30841.74',
        created_on: '07/24/2019',
        revenue: '$78.2k',
        image: 'https://robohash.org/etdoloruminventore.png?size=100x100&set=set1',
    },
    {
        id: 49,
        name: 'Harley Moralas',
        store: 'Dibbert-Koch',
        ratings: 4.9,
        products: 387,
        balance: '$24170.76',
        created_on: '07/22/2019',
        revenue: '$258.26k',
        image: 'https://robohash.org/temporibusdoloresexpedita.png?size=100x100&set=set1',
    },
    {
        id: 50,
        name: 'Dane Poor',
        store: 'Schaefer, Baumbach and Rogahn',
        ratings: 5.0,
        products: 700,
        balance: '$43686.45',
        created_on: '07/26/2019',
        revenue: '$152.3k',
        image: 'https://robohash.org/asequiaccusamus.png?size=100x100&set=set1',
    },
    {
        id: 51,
        name: 'Byran Temple',
        store: 'Mann-Farrell',
        ratings: 4.0,
        products: 716,
        balance: '$4893.32',
        created_on: '07/18/2019',
        revenue: '$178.6k',
        image: 'https://robohash.org/etnequerepudiandae.png?size=100x100&set=set1',
    },
    {
        id: 52,
        name: 'Merralee Lefridge',
        store: 'Gutmann-Bednar',
        ratings: 5.0,
        products: 474,
        balance: '$23137.50',
        created_on: '07/07/2019',
        revenue: '$368.2k',
        image: 'https://robohash.org/suntoptioet.png?size=100x100&set=set1',
    },
    {
        id: 53,
        name: 'Becki Sambals',
        store: 'Pacocha, Beier and Koch',
        ratings: 4.0,
        products: 176,
        balance: '$29240.97',
        created_on: '07/26/2019',
        revenue: '$89.5k',
        image: 'https://robohash.org/etquiaccusantium.png?size=100x100&set=set1',
    },
    {
        id: 54,
        name: 'L;urette Chastanet',
        store: 'Veum LLC',
        ratings: 4.3,
        products: 788,
        balance: '$45908.04',
        created_on: '07/24/2019',
        revenue: '$597.8k',
        image: 'https://robohash.org/estexut.png?size=100x100&set=set1',
    },
    {
        id: 55,
        name: 'Gerri Methley',
        store: 'Champlin, Jones and Bosco',
        ratings: 5.0,
        products: 301,
        balance: '$25934.40',
        created_on: '07/11/2019',
        revenue: '$29.3k',
        image: 'https://robohash.org/autemquaeratnumquam.png?size=100x100&set=set1',
    },
    {
        id: 56,
        name: 'Etienne Aldrick',
        store: 'Dare Inc',
        ratings: 2.7,
        products: 197,
        balance: '$23307.73',
        created_on: '07/02/2019',
        revenue: '$48.6k',
        image: 'https://robohash.org/temporeexpeditabeatae.png?size=100x100&set=set1',
    },
    {
        id: 57,
        name: 'Lida Garrigan',
        store: 'Streich-Langworth',
        ratings: 4.9,
        products: 523,
        balance: '$3418.92',
        created_on: '07/09/2019',
        revenue: '$12.1k',
        image: 'https://robohash.org/doloribusoditvitae.png?size=100x100&set=set1',
    },
    {
        id: 58,
        name: 'Gerald Vlasenko',
        store: 'Towne, Nader and Emard',
        ratings: 4.8,
        products: 549,
        balance: '$39865.90',
        created_on: '07/29/2019',
        revenue: '$78.2k',
        image: 'https://robohash.org/doloribusvoluptatumet.png?size=100x100&set=set1',
    },
    {
        id: 59,
        name: 'Atalanta Linford',
        store: 'Franecki, Harris and VonRueden',
        ratings: 3.7,
        products: 263,
        balance: '$10741.61',
        created_on: '07/14/2019',
        revenue: '$121',
        image: 'https://robohash.org/illoomnisvoluptas.png?size=100x100&set=set1',
    },
    {
        id: 60,
        name: 'Norrie Drayn',
        store: 'Stokes-Runolfsdottir',
        ratings: 4.6,
        products: 109,
        balance: '$26179.10',
        created_on: '07/11/2019',
        revenue: '$78.2k',
        image: 'https://robohash.org/dolorconsectetureligendi.png?size=100x100&set=set1',
    },
    {
        id: 61,
        name: 'Del Hutcheons',
        store: 'Kilback-Crona',
        ratings: 4.9,
        products: 769,
        balance: '$41994.30',
        created_on: '07/22/2019',
        revenue: '$89.5k',
        image: 'https://robohash.org/laborumpraesentiumquibusdam.png?size=100x100&set=set1',
    },
    {
        id: 62,
        name: 'Toinette Mate',
        store: "Jenkins, O'Kon and Erdman",
        ratings: 3.5,
        products: 321,
        balance: '$5173.07',
        created_on: '07/12/2019',
        revenue: '$597.8k',
        image: 'https://robohash.org/autemblanditiisquaerat.png?size=100x100&set=set1',
    },
    {
        id: 63,
        name: 'Cheslie Bamber',
        store: 'Krajcik Group',
        ratings: 4.1,
        products: 852,
        balance: '$48425.48',
        created_on: '07/12/2019',
        revenue: '$29.3k',
        image: 'https://robohash.org/similiqueplaceatexcepturi.png?size=100x100&set=set1',
    },
    {
        id: 64,
        name: 'Emma Frusher',
        store: 'Ziemann-Toy',
        ratings: 4.9,
        products: 883,
        balance: '$22458.90',
        created_on: '07/29/2019',
        revenue: '$48.6k',
        image: 'https://robohash.org/etrerumminima.png?size=100x100&set=set1',
    },
    {
        id: 65,
        name: 'Ardella Dummer',
        store: 'Block Group',
        ratings: 5.0,
        products: 325,
        balance: '$1809.30',
        created_on: '07/06/2019',
        revenue: '$12.1k',
        image: 'https://robohash.org/nihilharumqui.png?size=100x100&set=set1',
    },
    {
        id: 66,
        name: 'Adore Sidwick',
        store: 'Kulas LLC',
        ratings: 4.0,
        products: 756,
        balance: '$2309.66',
        created_on: '07/10/2019',
        revenue: '$78.2k',
        image: 'https://robohash.org/insolutanumquam.png?size=100x100&set=set1',
    },
    {
        id: 67,
        name: 'Tiler Veevers',
        store: 'Cassin, Feest and Abernathy',
        ratings: 5.0,
        products: 506,
        balance: '$21046.26',
        created_on: '07/19/2019',
        revenue: '$121',
        image: 'https://robohash.org/sintinventorereiciendis.png?size=100x100&set=set1',
    },
    {
        id: 68,
        name: 'Marylin Hurdiss',
        store: 'Yost Inc',
        ratings: 4.0,
        products: 644,
        balance: '$19214.19',
        created_on: '07/10/2019',
        revenue: '$78.2k',
        image: 'https://robohash.org/etexplicaboitaque.png?size=100x100&set=set1',
    },
    {
        id: 69,
        name: 'Gunther Atyea',
        store: 'Fahey and Sons',
        ratings: 4.3,
        products: 372,
        balance: '$8185.24',
        created_on: '07/29/2019',
        revenue: '$258.26k',
        image: 'https://robohash.org/possimusdolorevero.png?size=100x100&set=set1',
    },
    {
        id: 70,
        name: 'Giovanna Roskelly',
        store: 'Mitchell-Stiedemann',
        ratings: 5.0,
        products: 593,
        balance: '$39492.73',
        created_on: '07/20/2019',
        revenue: '$152.3k',
        image: 'https://robohash.org/atiditaque.png?size=100x100&set=set1',
    },
    {
        id: 71,
        name: 'Martina Martina',
        store: 'Bruen and Sons',
        ratings: 2.7,
        products: 270,
        balance: '$15813.98',
        created_on: '07/23/2019',
        revenue: '$89.5k',
        image: 'https://robohash.org/saepeestrem.png?size=100x100&set=set1',
    },
    {
        id: 72,
        name: 'Emmit Tolcharde',
        store: 'Roob and Sons',
        ratings: 4.9,
        products: 786,
        balance: '$4310.84',
        created_on: '07/21/2019',
        revenue: '$597.8k',
        image: 'https://robohash.org/occaecatisedrem.png?size=100x100&set=set1',
    },
    {
        id: 73,
        name: 'Freeman Hoopper',
        store: 'Reynolds-Wuckert',
        ratings: 4.8,
        products: 615,
        balance: '$27106.74',
        created_on: '07/24/2019',
        revenue: '$29.3k',
        image: 'https://robohash.org/molestiasvelitdebitis.png?size=100x100&set=set1',
    },
    {
        id: 74,
        name: 'Gustavo Yellowlees',
        store: 'Anderson-Hickle',
        ratings: 3.7,
        products: 884,
        balance: '$28237.31',
        created_on: '07/13/2019',
        revenue: '$48.6k',
        image: 'https://robohash.org/porroquidemut.png?size=100x100&set=set1',
    },
    {
        id: 75,
        name: 'Zach Piel',
        store: "Bode, O'Kon and Murphy",
        ratings: 4.6,
        products: 338,
        balance: '$45261.76',
        created_on: '07/15/2019',
        revenue: '$12.1k',
        image: 'https://robohash.org/blanditiisautdolorem.png?size=100x100&set=set1',
    },
    {
        id: 76,
        name: 'Reube Bratton',
        store: 'Bartoletti and Sons',
        ratings: 4.6,
        products: 387,
        balance: '$14127.50',
        created_on: '07/29/2019',
        revenue: '$78.2k',
        image: 'https://robohash.org/idomnisab.png?size=100x100&set=set1',
    },
    {
        id: 77,
        name: 'Jerrie Levee',
        store: 'Hauck-Heidenreich',
        ratings: 4.9,
        products: 483,
        balance: '$29745.67',
        created_on: '07/07/2019',
        revenue: '$121',
        image: 'https://robohash.org/temporibusdoloremquecupiditate.png?size=100x100&set=set1',
    },
    {
        id: 78,
        name: 'Brooks Preshaw',
        store: 'Hudson-Rowe',
        ratings: 3.5,
        products: 778,
        balance: '$6157.00',
        created_on: '07/23/2019',
        revenue: '$78.2k',
        image: 'https://robohash.org/evenietveroat.png?size=100x100&set=set1',
    },
    {
        id: 79,
        name: 'Aaren Studholme',
        store: 'Koepp-Barrows',
        ratings: 4.1,
        products: 573,
        balance: '$27603.34',
        created_on: '07/20/2019',
        revenue: '$258.26k',
        image: 'https://robohash.org/doloremquismaiores.png?size=100x100&set=set1',
    },
    {
        id: 80,
        name: 'Edythe Filipson',
        store: 'Murazik Inc',
        ratings: 4.9,
        products: 153,
        balance: '$30666.98',
        created_on: '07/29/2019',
        revenue: '$152.3k',
        image: 'https://robohash.org/mollitiarepellatperferendis.png?size=100x100&set=set1',
    },
    {
        id: 81,
        name: 'Sigrid Bartozzi',
        store: 'Crist Group',
        ratings: 5.0,
        products: 533,
        balance: '$12180.60',
        created_on: '07/24/2019',
        revenue: '$89.5k',
        image: 'https://robohash.org/suntsitofficia.png?size=100x100&set=set1',
    },
    {
        id: 82,
        name: 'Lorain French',
        store: 'Langworth, Reichel and Labadie',
        ratings: 4.0,
        products: 587,
        balance: '$2267.88',
        created_on: '07/08/2019',
        revenue: '$597.8k',
        image: 'https://robohash.org/nobisvoluptatesdolorem.png?size=100x100&set=set1',
    },
    {
        id: 83,
        name: 'Court Gulley',
        store: 'Hickle, Runolfsdottir and Bosco',
        ratings: 5.0,
        products: 275,
        balance: '$16333.17',
        created_on: '07/17/2019',
        revenue: '$29.3k',
        image: 'https://robohash.org/maioreseteos.png?size=100x100&set=set1',
    },
    {
        id: 84,
        name: 'Pacorro Sarfatti',
        store: 'Hegmann, Fahey and Williamson',
        ratings: 4.0,
        products: 370,
        balance: '$41618.98',
        created_on: '07/21/2019',
        revenue: '$48.6k',
        image: 'https://robohash.org/quosfugaquod.png?size=100x100&set=set1',
    },
    {
        id: 85,
        name: 'Jaime Calendar',
        store: 'Kulas-Klocko',
        ratings: 4.3,
        products: 704,
        balance: '$38722.94',
        created_on: '07/10/2019',
        revenue: '$12.1k',
        image: 'https://robohash.org/aliasesseiusto.png?size=100x100&set=set1',
    },
    {
        id: 86,
        name: 'Leslie Strognell',
        store: 'Cruickshank-Gibson',
        ratings: 5.0,
        products: 253,
        balance: '$47561.04',
        created_on: '07/28/2019',
        revenue: '$78.2k',
        image: 'https://robohash.org/sedpossimuset.png?size=100x100&set=set1',
    },
    {
        id: 87,
        name: 'Mattheus Covil',
        store: 'Runolfsson, Swaniawski and Bauch',
        ratings: 4.6,
        products: 799,
        balance: '$39170.91',
        created_on: '07/05/2019',
        revenue: '$121',
        image: 'https://robohash.org/voluptatempraesentiumrem.png?size=100x100&set=set1',
    },
    {
        id: 88,
        name: 'Roxi Briereton',
        store: "Donnelly-O'Connell",
        ratings: 4.9,
        products: 407,
        balance: '$39625.51',
        created_on: '07/08/2019',
        revenue: '$78.2k',
        image: 'https://robohash.org/voluptasipsamiste.png?size=100x100&set=set1',
    },
    {
        id: 89,
        name: 'Ricca Hanning',
        store: 'Kulas, Doyle and Bradtke',
        ratings: 3.5,
        products: 757,
        balance: '$47445.38',
        created_on: '07/05/2019',
        revenue: '$258.26k',
        image: 'https://robohash.org/cumquemodiut.png?size=100x100&set=set1',
    },
    {
        id: 90,
        name: 'Minnie Doggett',
        store: 'Hammes Group',
        ratings: 4.1,
        products: 609,
        balance: '$2969.51',
        created_on: '07/16/2019',
        revenue: '$152.3k',
        image: 'https://robohash.org/etvoluptateslibero.png?size=100x100&set=set1',
    },
    {
        id: 91,
        name: 'Roch Heyball',
        store: 'Nicolas, Funk and Brown',
        ratings: 4.9,
        products: 701,
        balance: '$40481.67',
        created_on: '07/01/2019',
        revenue: '$89.5k',
        image: 'https://robohash.org/temporanesciuntdolor.png?size=100x100&set=set1',
    },
    {
        id: 92,
        name: 'Nate Buddell',
        store: "O'Keefe and Sons",
        ratings: 5.0,
        products: 161,
        balance: '$13665.71',
        created_on: '07/15/2019',
        revenue: '$597.8k',
        image: 'https://robohash.org/etarchitectoaccusantium.png?size=100x100&set=set1',
    },
    {
        id: 93,
        name: 'Jammie Silveston',
        store: 'Klocko-Okuneva',
        ratings: 4.0,
        products: 860,
        balance: '$30771.86',
        created_on: '07/03/2019',
        revenue: '$29.3k',
        image: 'https://robohash.org/veniamofficiisad.png?size=100x100&set=set1',
    },
    {
        id: 94,
        name: 'Valentine Eddoes',
        store: 'Hettinger, Stracke and Cronin',
        ratings: 5.0,
        products: 217,
        balance: '$16216.20',
        created_on: '07/08/2019',
        revenue: '$48.6k',
        image: 'https://robohash.org/eumquibusdaminventore.png?size=100x100&set=set1',
    },
    {
        id: 95,
        name: 'Eryn Mobberley',
        store: 'Raynor Group',
        ratings: 4.0,
        products: 419,
        balance: '$24074.71',
        created_on: '07/28/2019',
        revenue: '$12.1k',
        image: 'https://robohash.org/quaedolorembeatae.png?size=100x100&set=set1',
    },
    {
        id: 96,
        name: 'Randall MacCracken',
        store: 'Windler, Blanda and Tremblay',
        ratings: 4.3,
        products: 462,
        balance: '$43974.13',
        created_on: '07/18/2019',
        revenue: '$78.2k',
        image: 'https://robohash.org/explicaborecusandaeet.png?size=100x100&set=set1',
    },
    {
        id: 97,
        name: 'Loraine Filipowicz',
        store: 'Collier-Gleason',
        ratings: 5.0,
        products: 567,
        balance: '$41706.66',
        created_on: '07/21/2019',
        revenue: '$121',
        image: 'https://robohash.org/facerenecessitatibusquis.png?size=100x100&set=set1',
    },
    {
        id: 98,
        name: 'Kylen Fosse',
        store: 'Zulauf Inc',
        ratings: 4.7,
        products: 294,
        balance: '$26964.69',
        created_on: '07/24/2019',
        revenue: '$78.2k',
        image: 'https://robohash.org/maximelaudantiumaut.png?size=100x100&set=set1',
    },
    {
        id: 99,
        name: 'Rochell Blythe',
        store: "Hauck, O'Keefe and Walker",
        ratings: 4.0,
        products: 885,
        balance: '$20913.92',
        created_on: '07/18/2019',
        revenue: '$258.26k',
        image: 'https://robohash.org/iustoetut.png?size=100x100&set=set1',
    },
    {
        id: 100,
        name: 'Lavena Cleife',
        store: 'Ward-Orevenue',
        ratings: 4.1,
        products: 642,
        balance: '$29126.84',
        created_on: '07/18/2019',
        revenue: '$78.2k',
        image: 'https://robohash.org/evenietquaeconsequuntur.png?size=100x100&set=set1',
    },
];

const cartItems: CartItemTypes[] = [
    {
        id: 1,
        image: product1,
        name: 'Polo Navy blue T-shirt',
        size: 'Large',
        color: 'Light Green',
        price: 148.66,
        qty: 5,
        total: 743.3,
    },
    {
        id: 2,
        image: product2,
        name: 'Brown Hoodie for men',
        size: 'Small',
        color: 'Brown',
        price: 99,
        qty: 2,
        total: 198.0,
    },
    {
        id: 3,
        image: product3,
        name: 'Designer Awesome T-Shirt',
        size: 'Medium',
        color: 'Light pink',
        price: 49.99,
        qty: 3,
        total: 499.9,
    },
    {
        id: 4,
        image: product5,
        name: 'Half Sleeves Tshirt',
        size: 'Large',
        color: 'Green',
        price: 129.99,
        qty: 1,
        total: 129.99,
    },
];

export { products, orders, customers, sellers, cartItems };
