import "./Styles.css";

import { download, storeWhite } from "../../../components/Image";
import { cartBagOrange, plusOrange } from "../../../components/ImgExport";
import { useEffect, useState } from "react";
import { gInfoBill } from "../../../api/apiCheckin/paymentApi";
import * as orderConfilmApi from "../../../api/apiCheckin/orderConfilmApi";
import { FormatDolla } from "../../../utils/format";
import html2canvas from "html2canvas";
import ModalLoading from "../../../components/ModalLoading";
import { useLocation, useNavigate } from "react-router-dom";
import { getLanguageCheckIn } from "../../../Services/languageCheckin";
import { ClickAwayListener, Tooltip } from "@mui/material";
import HelpOutlineSharpIcon from "@mui/icons-material/HelpOutlineSharp";

const MyBillOnline = () => {
  const location = useLocation();
  const params: any = location.state;
  const order_id = params?.order_id;
  const custommerId = params?.custommerId;
  const totalPrice = params?.totalPrice;
  const tax_price = params?.tax_price;
  const TotalPay = params?.TotalPay;
  const cardNumber = params?.cardNumber;
  const tip_amount = params?.tip_amount;
  const discount = params?.discount ?? 0;
  const convenience_fee = params?.fee ?? 0;
  const paymentMethodNameShowBill = params?.paymentMethodNameShowBill ?? "";
  const orderInfo: orderConfilmApi.InfoOrder[] = params?.orderInfo
    ? params?.orderInfo
    : [];
  const languageUI = getLanguageCheckIn();
  const [ShowLoading, setShowLoading] = useState<boolean>(false);
  const nameStore = localStorage.getItem("namestore");
  const [invoice_number, setInvoice_number] = useState<string>();
  const [custommer_Name, setCustommer_Name] = useState<string>();
  const [timePay, setTimePay] = useState<string>();
  const [openToolTip, setOpenToolTip] = useState(false);
  const navigate = useNavigate();
  const gBill = async () => {
    console.log(custommerId, order_id);
    if (order_id && custommerId) {
      const BillInfo = await gInfoBill(order_id, custommerId);
      if (BillInfo.status == 1) {
        setInvoice_number(BillInfo?.invoice_number);
        setCustommer_Name(BillInfo?.custommer_name);
        setTimePay(BillInfo?.timepay);
      }
    }
  };
  const handleTooltipClose = () => {
    setOpenToolTip(false);
  };
  function downloadURI(uri: string, name: string) {
    var link = document.createElement("a");

    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    // clearDynamicLink(link);
    setShowLoading(false);
  }

  function DownloadAsImage(element: any, name: string) {
    html2canvas(element).then(function (canvas) {
      var myImage = canvas.toDataURL();
      downloadURI(myImage, name);
    });
  }
  const down = () => {
    setShowLoading(true);
    const e: any = document.querySelector(".billInfomation");
    // for (let i = 0; i < e.length; i++) {
    DownloadAsImage(e, "bill_" + invoice_number + ".png");
    // }
  };
  const Order_More = () => {
    navigate("/online/menu/" + custommerId);
  };
  useEffect(() => {
    gBill();
  }, []);
  return (
    <>
      {/* Menubody */}
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
      <div className="billInfomation">
        <header className="w-100 custom-header-choose-menu d-flex align-items-center justify-content-between">
          {/* <img src={logoPrijack} alt="" /> */}
          <p className="title-name-store mb-0 text-truncate">{nameStore}</p>
          <div
            className="d-flex align-items-center gap-1 bg-white rounded-4 py-1 px-2"
            onClick={() => Order_More()}
          >
            <img src={plusOrange} alt="" />
            <p className="mb-0 text-order-more">{languageUI.Ordermore}</p>
          </div>
        </header>

        <div
          className="account-pages d-flex  custom-pading-header bg-white "
          style={{ minHeight: "100vh" }}
        >
          <div className=" rounded-3 overflow-hidden w-100">
            <div className="p-3 custom-h-my-bill">
              <div className="pb-2 border-bottom">
                <div className="d-flex justify-content-between mb-1">
                  <span className="text-title">{languageUI.Invoicenumber}</span>
                  <span className="text-content text-4A4A6A ">
                    {invoice_number}
                  </span>
                </div>
                <div className="d-flex justify-content-between mb-1 ">
                  <span className="text-title">{languageUI.Printdatetime}</span>
                  <span className="text-content text-4A4A6A">{timePay}</span>
                </div>
                <div className="d-flex justify-content-between mb-1 ">
                  <span className="text-title">{languageUI.Customer}</span>
                  <span className="text-content text-4A4A6A">
                    {custommer_Name}
                  </span>
                </div>
                <div className="d-flex justify-content-between mb-1 ">
                  <span className="text-title">{languageUI.PaymentMethod}</span>
                  <span className="text-content text-4A4A6A">
                    {paymentMethodNameShowBill}
                  </span>
                </div>
              </div>
              {orderInfo.map((item, i) => (
                <div className="pb-2 border-bottom" key={i}>
                  <div className="d-flex justify-content-between pt-2 gap-2">
                    <span className="name-food ">{item?.name}</span>
                    <div className="d-flex align-items-center gap-1">
                      <div className="d-flex align-items-end gap-2px ">
                        <div className="text-price-food-2 mb-2px">
                          {item?.quantity}
                        </div>
                        <div className="text-x">x</div>
                        <div className="text-price-food-2 mb-2px">
                          {FormatDolla(Number(item?.price))}$
                        </div>
                      </div>
                      {/* <div className="d-flex gap-2px">
                                            <div className="text-dollar-2">$</div>
                                            <div className="text-price-food-2">{FormatDolla(Number(item?.price))}</div>
                                        </div> */}
                    </div>
                  </div>
                  {item?.notefood_name && (
                    <div className="d-flex align-items-center gap-2">
                      <div className="bg-F6F9F8 mt-1">
                        <p className="mb-0 text-12B064">
                          {item?.notefood_name}
                        </p>
                      </div>
                    </div>
                  )}
                  {item?.side_name && (
                    <div className="mb-2">
                      <div className="d-flex gap-1 mt-1">
                        <p className="mb-0 text-choose-a-side text-666687">
                          + {item?.side_name}
                        </p>{" "}
                        <p className="mb-0 text-choose-a-side text-12B064">
                          (Free x1)
                        </p>
                      </div>
                    </div>
                  )}

                  {item?.note && (
                    <p className="text-note-information-card-items mb-0">
                      {item?.note}
                    </p>
                  )}
                </div>
              ))}
              <div className="py-3">
                <div className="d-flex justify-content-between mb-2">
                  <span className="text-subtitle">{languageUI.ItemsTotal}</span>
                  <div className="d-flex gap-2px">
                    <div className="text-dollar-2">$</div>
                    <div className="text-price-food-2">
                      {FormatDolla(Number(totalPrice))}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between mb-2">
                  <span className="text-subtitle">{languageUI.Discount}</span>
                  <div className="d-flex gap-2px">
                    <div className="text-dollar-2">$</div>
                    <div className="text-price-food-2">
                      {FormatDolla(Number(discount))}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between mb-2">
                  <span className="text-subtitle">Tip</span>
                  <div className="d-flex gap-2px">
                    <div className="text-dollar-2">$</div>
                    <div className="text-price-food-2">
                      {FormatDolla(Number(tip_amount))}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between mb-2">
                  <span className="text-subtitle">Tax</span>
                  <div className="d-flex gap-2px">
                    <div className="text-dollar-2">$</div>
                    <div className="text-price-food-2">
                      {FormatDolla(Number(tax_price))}
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-between align-items-center">
                  <ClickAwayListener onClickAway={handleTooltipClose}>
                    <div className="tooltip-payment-web-checkin">
                      <Tooltip
                        className=""
                        PopperProps={{
                          disablePortal: true,
                        }}
                        onClose={handleTooltipClose}
                        open={openToolTip}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        placement="right"
                        title={
                          languageUI.TheconveniencefeeischargedpertransactionbyathirdpartyvendorWedonotretainanyportionofthisfee
                        }
                      >
                        <div
                          className="d-flex gap-1 align-items-end w-fit"
                          onClick={() => setOpenToolTip(true)}
                        >
                          <div className="name-title-price">
                            {languageUI.Conveniencefee}
                          </div>
                          <HelpOutlineSharpIcon className="" />
                        </div>
                      </Tooltip>
                    </div>
                  </ClickAwayListener>
                  <div className="d-flex gap-2px">
                    <div className="text-dollar-3">$</div>
                    <div className="text-price-food-3">
                      {FormatDolla(convenience_fee)}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <span className="text-content text-4A4A6A font-bold">
                    {languageUI.Total}
                  </span>
                  <div className="d-flex gap-2px ">
                    <div className="text-FFB080 ">$</div>
                    <div className=" text-content text-4A4A6A font-bold">
                      {FormatDolla(Number(TotalPay))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-choose-menu d-flex gap-2 align-items-center py-3 w-100 bg-white px-3 border-top">
        <div className="w-100">
          <button
            className="custom-btn-payment-status  text-8E8EA9 border-1-8E8EA9 w-100 "
            onClick={down}
          >
            <img src={download} alt="" />
            {languageUI.Download}
          </button>
        </div>
        {/* <a href={`/webcheckin/choosemenu/${custommerId}`} className="w-100">
                    <button className="custom-filter-btn border-none w-100 text-ffffff">
                        <img src={storeWhite} alt="" />
                        Home
                    </button>
                </a> */}
      </div>
    </>
  );
};

export default MyBillOnline;
