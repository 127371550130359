import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  Link, useNavigate,
} from "react-router-dom";
import * as Request from "../../api/Request";
import Swal from "sweetalert2";

// components
import { VerticalForm } from "../../components";
import AuthLayout from "./AuthLayout";
import { getLanguageUI } from "../../Services/languageUI";

const ForgetPassword = () => {
  const languageUI = getLanguageUI().forgotPassword;

  const [email, setEmail] = useState("");
  const [checkCode, setCheckCode] = useState("");
  const [showCodeInput, setShowCodeInput] = useState(false);
  const [codeForgotPw, setCodeForgotPw] = useState("");
  const [checkError, setCheckError] = useState(false);
  const [errMess, setErMess] = useState("");
  const [checkCodeErr, setCheckCodeErr] = useState(false);
  const [checkCodeErrMess, setCheckCodeErrMess] = useState("");
  const { t } = useTranslation();
  const navigate = useNavigate();



  const mailformat = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  const getCode = async () => {
    if (email === "") {
      setCheckError(true);
      setErMess(languageUI.pleaseEnterEmail);
      return;
    }
    if (mailformat.test(email) === false) {
      setCheckError(true);
      setErMess(languageUI.emailNotFormat);
      return;
    }
    setCheckError(false);
    setErMess("");
    setShowCodeInput(true);

    await Request.fetchAPI(`owner/forget_password_owner.php`, "post", {
      username: email,
    })
      .then((res) => {
        if (res.status == 1) {
          console.log("okee", res);
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  const checkCodeForgotPW = async () => {
    if (email === "") {
      setCheckError(true);
      setErMess(languageUI.pleaseEnterEmail);
      return;
    }
    if (mailformat.test(email) === false) {
      setCheckError(true);
      setErMess(languageUI.emailNotFormat);
      return;
    }
    if (checkCode === "") {
      setCheckCodeErr(true);
      setCheckCodeErrMess(languageUI.pleaseEnterCode);
      return;
    }

    await Request.fetchAPI(`/owner/check_code.php`, "post", {
      username: email,
      code: checkCode,
    })
      .then((res) => {
        if (res.status === 1) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: languageUI.changePasswordSuccess,
            showConfirmButton: false,
            timer: 1500,
          });
          navigate("/auth/login");
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: languageUI.somethingWentWrong,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((error: any) => {
        console.log(error);
        Swal.fire({
          position: "center",
          icon: "error",
          title: languageUI.somethingWentWrong,
          showConfirmButton: false,
          timer: 1500,
        });
      });
    setCheckError(false);
    setErMess("");
    setCheckCodeErr(false);
    setCheckCodeErrMess("");
  };

  return (
    <>
      <AuthLayout helpText={t(languageUI.forgotPassword)} bottomLinks={""}>
        <VerticalForm onSubmit={checkCodeForgotPW}>
          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              name="email"
              type="email"
              placeholder={languageUI.enterYourEmail}
              value={email}
              onChange={(e: any) => setEmail(e.target.value)}
            />
            <p style={{ color: "#f1556c", textAlign: "center" }}>
              {checkError ? errMess : ""}
            </p>{" "}
            {showCodeInput ? (
              <>
                <Form.Label>Code</Form.Label>
                <Form.Control
                  className="mb-2"
                  name={languageUI.code}
                  type="text"
                  placeholder={languageUI.enterCode}
                  value={checkCode}
                  onChange={(e: any) => setCheckCode(e.target.value)}
                />
                <p style={{ color: "#f1556c", textAlign: "center" }}>
                  {checkCodeErr ? checkCodeErrMess : ""}
                </p>{" "}
                <p>{languageUI.weHaveSentTheCode}</p>
              </>
            ) : null}
            <Button onClick={getCode}>{languageUI.getCode}</Button>
          </Form.Group>

          <div className="text-center d-grid">
            <Button variant="primary" type="submit" onClick={checkCodeForgotPW}>
              {t(languageUI.forgotPassword)}
            </Button>
            <Link to="/auth/login" className="mt-2">
              {languageUI.login}
            </Link>
          </div>
        </VerticalForm>
      </AuthLayout>
    </>
  );
};

export default ForgetPassword;
