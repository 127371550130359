/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Styles.css"
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { flappyBirdGame, whackAMole } from "../../components/ImgExport";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { gConfigGame, gGameRoundCustomer, resetRoundGameCustomer } from "../../api/apiCheckin/gameApi";
import { getLanguageCheckIn } from "../../Services/languageCheckin";
import ModalOutDateGameRound from "../../components/ModalOutDateGameRound";



const ListGame = () => {
  const navigate = useNavigate();

  const languageUI = getLanguageCheckIn();
  const nameStore = localStorage.getItem("namestore");
  const custommerId = localStorage.getItem("custommerId");
  const ownerID = localStorage.getItem("ownerID");
  const [checkPoints, setCheckPoints] = useState(false)
  const [playFlappyBird, setPlayFlappyBird] = useState('0')
  const [playFlappyWhackAMole, setPlayWhackAMole] = useState('0')
  const [showModalOutGameRound, setShowModalOutGameRound] = useState(false)

  const dismisModalOutGameRound = () => {
    setShowModalOutGameRound(false)
  }

  const gConfig = async () => {
    if (ownerID) {

      const req = await gConfigGame(ownerID)
      if (Number(req?.status) === 1 && Number(req?.data?.is_show_redemption_game) === 1) {
        setCheckPoints(true)
      } else {
        setCheckPoints(false)
      }
    } else {
      setCheckPoints(false)
    }

  }

  const gRoundGameCustomer = async () => {
    if (ownerID && custommerId) {
      const req = await resetRoundGameCustomer(ownerID, custommerId)
    }
  }
  const getGameRoundCustomer = async () => {
    if (custommerId) {
      const req = await gGameRoundCustomer(custommerId)
      if (Number(req.status) === 1) {
        setPlayWhackAMole(req.data?.plays_whackamole ?? '0')
        setPlayFlappyBird(req.data?.plays_flappybird ?? '0')

      }
    }
  }
  useEffect(() => {
    gConfig()
    gRoundGameCustomer()
    getGameRoundCustomer()
  }, [])
  return (

    <>

      {/* Menubody */}
      <header className="w-100 custom-header-choose-menu d-flex justify-content-between align-items-center">
        <div className=" px-4 py-1 bg-white d-flex  rounded-4 border" onClick={() => navigate('/webcheckin/choosemenu/' + custommerId)}>
          <ArrowBackRoundedIcon className="text-orange" />
        </div>
        {/* <img src={logoPrijack} alt="" /> */}
        <p className="title-name-store mb-0">{nameStore}</p>
      </header>
      <div className="account-pages box-menu-food-order  custom-pading-header bg-white h-100vh">
        {checkPoints && (
          <div className="px-4 d-flex justify-content-end">
            <button className="px-6 py-2 rounded-4 border-none focus-visiable-none mt-2 text-xl text-white font-bold bg-orange shadow-button-default" onClick={() => { navigate('/webcheckin/rewardPage') }}>
              <EmojiEventsIcon className="text-2xl text-white me-2" />
              {languageUI.Rewards}
            </button>
          </div>
        )}

        <div className="px-0 px-sm-0 px-md-3 px-lg-4  position-relative pt-0 bg-white  d-flex">
          <div className="px-2 px-sm-2 px-md-3 px-lg-4 pb-footer">
            <div className="box-list-game " >
              <div className="rounded-4 position-relative" onClick={() => Number(playFlappyWhackAMole) > 0 ? navigate('/webcheckin/whackamole') : setShowModalOutGameRound(true)}>
                <img src={whackAMole} alt="" className="w-100 aspect-square rounded-4" />
                <div className="font-bold text-4a4a6a d-flex justify-content-center gap-1 align-items-center  w-fit px-1 h-30px rounded-6 bg-white position-absolute border-orange-2" style={{ top: '8px', right: '8px' }}>
                  <p className="m-0">Game round:</p>
                  <p className="m-0 text-orange d-inline">{playFlappyWhackAMole}</p>
                </div>
              </div>
              <a className="rounded-4 position-relative" href={Number(playFlappyBird) > 0 ? '/webcheckin/flappyBird' : '#'} onClick={() => Number(playFlappyBird) > 0 ? '' : setShowModalOutGameRound(true)}>
                <img src={flappyBirdGame} alt="" className="w-100 aspect-square rounded-4" />
                <div className="font-bold text-4a4a6a d-flex justify-content-center gap-1 align-items-center  w-fit h-30px px-1 rounded-6 bg-white position-absolute border-orange-2" style={{ top: '8px', right: '8px' }}>
                  <p className="m-0">Game round:</p>
                  <p className="m-0 text-orange d-inline">{playFlappyBird}</p>
                </div>
              </a>
            </div>
          </div>
        </div>
        <ModalOutDateGameRound handleClose={dismisModalOutGameRound} show={showModalOutGameRound} />
      </div>
    </>
  );
};

export default ListGame;
