import React from "react";
import { plus } from "../../../../components/ImgExport";
import { FormatDolla } from "../../../../utils/format";
import default_product from "../../../../assets/images/default_product.png";

interface SideDishItemProps {
  img: string,
  id: string,
  name: string,
  price: number;
  description: string;
  disabled: boolean;
  AddSide: (side_id: string) => void
}
const SideDishItem = ({ description, img, name, price, id, disabled = false, AddSide }: SideDishItemProps) => {
  const add = () => {
    if (!disabled) {
      AddSide(id)
    }

  }
  return (
    <div
      className={`p-0 p-md-2 pb-1 p-lg-3 bg-tranparent rounded-5 shadow-sm bg-F6F6F9 px-2 py-2 overflow-hidden  h-100`}
    >
      <div className=" position-relative mb-2">
        {/* <div className="d-flex gap-2px">
          <div className="text-dollar">$</div>
          <div className="text-price-food">{FormatDolla(price)}</div>
        </div> */}
        <div className="btn-add-food-cart " onClick={add} >
          <div className="w-100 h-100 position-relative">
            <img src={plus} alt="" className="btn-icon-plus" />
          </div>
        </div>
      </div>
      <div className="d-block">
        <img
          src={img}
          className="img-fluid width-100 height-120px object-fit-cover aspect-ratio-16-11 rounded-5 mb-1 mb-sm-1 mb-md-2 mb-lg-3 flex-shrink-0"
          onError={(e) => {
            e.currentTarget.src = default_product;
          }}
          alt="food-item"
        />
      </div>
      <div className="flex justify-between  food-item-info row h-100-130px pb-1 position-relative">
        {/* <div className="d-flex align-items-center food-item-info flex-wrap flex-sm-wrap flex-md-nowrap flex-lg-nowrap"> */}
        <div className="d-flex justify-content-between flex-column px-2">
          <div>
            <h4 className="text-name-food text-two-line-name mb-1">{name}</h4>
            <div className="text-describe">{description}</div>
          </div>


        </div>
        {/* <div className="col-6 col-425-100 d-flex align-items-end pl-0 justify-content-end">
              <Button
                variant="primary"
                className="d-flex ms-auto btn-add-food flex-shrink-0 padding-1 flex-shrink-0 rounded-5  h-fit"
                // onClick={handleAddToCart}
                onClick={gInfoFood}
              >
                Add to cart
              </Button>
            </div> */}

      </div>
    </div>
  )
}
export default SideDishItem