import { useState } from "react";
import { checkedCircle } from "../components/Image";
import "./styles.css";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ThumbUpRoundedIcon from "@mui/icons-material/ThumbUpRounded";
import ThumbDownRoundedIcon from "@mui/icons-material/ThumbDownRounded";

interface ReviewSuccessGoToGoogleProps {
  show: boolean;
  linkURLGG: string;
  dismis: () => void;
}

const ReviewSuccessGoToGoogle = ({
  show,
  linkURLGG,
  dismis,
}: ReviewSuccessGoToGoogleProps) => {
  const [googleBusinessID, setGoogleBusinessID] = useState<any>("");
  const mobile_link =
    "/https://search.google.com/local/writereview?placeid=" +
    `${googleBusinessID}`;

  const desktop_link =
    "/https://search.google.com/local/writereview?placeid=" +
    `${googleBusinessID}`;
  const isMobile = window.innerWidth <= 600;
  const linkURL = isMobile ? mobile_link : desktop_link;
  const navigate = useNavigate();
  return (
    <Modal show={show} fullscreen={true}>
      <div
        className="review-restaurant-page align-items-center d-flex"
        style={{ minHeight: `${window.innerHeight}vh"` }}
      >
        <div className="review-introduce w-100">
          <div className="d-flex gap-2 align-items-center">
            <p className="text-primary text-successs-rv mb-0 text-center">
              Were you satisfied with <br /> our service today?
            </p>
          </div>
          <button
            className="btn-review-staff"
            onClick={() =>
              window.open(linkURLGG, "_blank", "rel=noopener noreferrer")
            }
          >
            <ThumbUpRoundedIcon />
            Good
          </button>
          <button className="btn-review-staff" onClick={dismis}>
            <ThumbDownRoundedIcon />
            Bad
          </button>
          <button className="btn-review-staff" onClick={dismis}>
            Just OK
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ReviewSuccessGoToGoogle;
