
import * as Request from "../Request";
export interface Result<T> {
    status: number,
    message: string,
    data: T,
    total: number | string
}

export interface Reservation {
    booking_date: string,
    booking_time: string,
    customer_id: string,
    fullname: string,
    id: string,
    note: string,
    owner_id: string,
    phone: string,
    seats: string,
    status: string,
    date_value: string
}

export interface ReservationDetailId {
    id: string,
        owner_id: string,
        customer_id: string,
        fullname: string,
        phone: string,
        booking_date: string,
        booking_time: string,
        seats: string,
        note: string,
        status: string,
        customer_name: string,
        customer_phone: string,
        owner_name: string
}

export const addRevervation = async (customer_id: string, owner_id: string, phone_number: string, booking_date: string, booking_time: string, seats: number, fullname: string = '', note: string = '') => {
    return await Request.fetchAPI(`checkin/addRevervation.php`, "POST", { customer_id, owner_id, booking_date, phone_number, booking_time, seats, note, fullname })
        .then((res: Result<string>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: '',
            };
        });
};

export const checkRevervationCustomer = async (customer_id: string, owner_id: string) => {
    return await Request.fetchAPI(`checkin/checkRevervationCustomer.php`, "POST", { customer_id, owner_id })
        .then((res: Result<string>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: '',
                total: 0
            };
        });
};

export const gRevervationDetailCustomer = async (customer_id: string, owner_id: string) => {
    return await Request.fetchAPI(`checkin/gRevervationDetailCustomer.php`, "POST", { customer_id, owner_id })
        .then((res: Result<Reservation>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: undefined,
                total: 0
            };
        });
};
export const gRevervationDetailById = async (reservation_id: string) => {
    return await Request.fetchAPI(`checkin/gRevervationDetailById.php`, "POST", {reservation_id })
        .then((res: Result<ReservationDetailId>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: undefined,
                total: 0
            };
        });
};
export const CancelRevervationCustomer = async (id: string) => {
    return await Request.fetchAPI(`checkin/CancelRevervationCustomer.php`, "POST", { id })
        .then((res: Result<Reservation>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: undefined,
                total: 0
            };
        });
};