import { Checkbox } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as devicePos from "../../api/apiAdmin/devicePos";
import ModalLoading from "../../components/ModalLoading";
import { FormatDolla } from "../../utils/format";
import Swal from "sweetalert2";
import { getLanguageCheckIn } from "../../Services/languageCheckin";

const DeviceCustomer = () => {
  const languageUI = getLanguageCheckIn();
  const navigate = useNavigate()
  const [ShowLoading, setShowLoading] = useState(false);
  const [listBuyDevices, setListDevices] = useState<devicePos.BuyDevice[]>([])
  const [listOrderChoosed, setListOrderChoosed] = useState<string[]>([])

  const gListDeviceBought = async () => {
    setShowLoading(true)
    const req = await devicePos.gListDeviceBought();
    if (Number(req?.status) === 1) {
      setListDevices(req?.data ?? []);
      setShowLoading(false)
    } else {
      setShowLoading(false)
    }
  }

  const handleChooseOrder = (checked: boolean, id: string) => {
    if (checked) {
      setListOrderChoosed([...listOrderChoosed, id])
    } else {
      setListOrderChoosed(listOrderChoosed.filter(item => Number(item) !== Number(id)))
    }
  }

  const HandelSubmit = async () => {
    if (listOrderChoosed.length === 0) {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: languageUI.PleaseselectInvoice,
        showConfirmButton: false,
        timer: 3500,
      });
    } else {
      setShowLoading(true)
      const req = await devicePos.upStatusDeviceBought(listOrderChoosed)
      if (Number(req?.status) === 1) {
        gListDeviceBought()
      } else {
        setShowLoading(false)
        Swal.fire({
          position: "center",
          icon: "warning",
          title: req?.message,
          showConfirmButton: false,
          timer: 3500,
        });
      }
    }
  }
  useEffect(() => {
    gListDeviceBought()
  }, [])
  return (
    <div className="pt-70px">
      <div className="d-flex justify-content-end w-100 px-6 mb-4">
        <button className="bg-green-light px-4 py-2 rounded-4 border-primary text-4a4a6a font-bold w-fit mt-3" onClick={() => navigate('/web-admin/customer-device-detail')}>
          {languageUI.CustomerDetail}
        </button>
      </div>
      <div className="table-device-customer">
        <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
        <table className="table  p-0">
          <thead>
            <tr className="bg-primary">
              <th scope="col" className="w-5 bg-primary text-sm text-white fw-600 font-urbansist"></th>
              <th scope="col" className="w-15 bg-primary">
                <div className="flex justify-between  items-center">
                  <span className="text-sm text-white fw-600 font-urbansist mr-3">{languageUI.Contactperson}</span>
                </div>
              </th>
              <th scope="col" className="w-10 bg-primary">
                <div className="flex justify-between  items-center">
                  <span className="text-sm text-white fw-600 font-urbansist mr-3">{languageUI.Storename}</span>
                </div>
              </th>
              <th scope="col" className="w-20 bg-primary">
                <div className="flex justify-between  items-center">
                  <span className="text-sm text-white fw-600 font-urbansist mr-3">{languageUI.Shippiingaddress}</span>
                </div>
              </th>
              <th scope="col" className="w-10 bg-primary">
                <div className="flex justify-between  items-center ">
                  <span className="text-sm text-white fw-600 font-urbansist mr-3">{languageUI.Cellphone}</span>
                </div>
              </th>
              <th scope="col" className="w-10 bg-primary">
                <div className="flex justify-between  items-center">
                  <span className="text-sm text-white fw-600 font-urbansist mr-3">{languageUI.Device}</span>
                </div>
              </th>
              <th scope="col" align="center" className="w-15 bg-primary text-center">
                <span className="text-sm text-white fw-600 font-urbansist mr-3 text-center">Package</span>
              </th>
              <th scope="col" align="center" className="w-10 bg-primary text-center">
                <span className="text-sm text-white fw-600 font-urbansist mr-3 text-center">{languageUI.Total}</span>
              </th>
            </tr>
          </thead>
          <tbody className=" w-100">
            {listBuyDevices.map((item, i) => (
              <tr className="w-100" key={i}>
                <td className="border-bottom-none text-sm text-4A4A6A"><Checkbox className="p-0" size="small" checked={listOrderChoosed.some(ord => Number(ord) === Number(item?.id))} onClick={(e: any) => handleChooseOrder(e.target.checked, item?.id)} /></td>
                <td className="border-bottom-none text-sm text-4a4a6a">{item?.contact_person}</td>
                <td className="border-bottom-none text-sm text-4a4a6a">{item?.name}</td>
                <td className="border-bottom-none text-sm text-4a4a6a">{item?.suitShipping + " " + item?.shippingAddress}</td>
                <td className="border-bottom-none text-sm text-4a4a6a">{item?.sellPhone}</td>
                <td className="border-bottom-none text-sm text-4a4a6a">
                  <div>
                    {item?.list_device && item?.list_device.map((dev, j) => (
                      <p className="text-sm text-4a4a6a mb-1" key={'dev' + j}>{j + 1}.{dev.title} x{dev.quantity}</p>
                    ))}
                  </div>
                </td>
                <td className="border-bottom-none text-sm text-4a4a6a">
                  <div className="text-center">
                    <p className="text-sm text-4a4a6a mb-1 text-center">{item?.package}</p>
                    <p className="text-sm text-4a4a6a mb-1 text-center">{item?.cms} SMS/1 {languageUI.month}</p>
                  </div>
                </td>
                <td className="border-bottom-none text-sm text-4a4a6a text-center">${FormatDolla(Number(item?.price))}</td>
              </tr>
            ))}

          </tbody>
        </table>
      </div>
      {listOrderChoosed.length > 0 && (
        <div className="d-flex justify-content-end w-100 px-6">
          <button className="px-4 py-2 rounded-4 bg-primary text-white font-bold w-fit mt-3 border-0" onClick={HandelSubmit}>
            {languageUI.Processed}
          </button>
        </div>
      )}

    </div >
  )
};

export default DeviceCustomer;
