import React, { useState, useEffect } from 'react';
import './styles.css';
import Board from './components/Board/Board';

const SnakeGame: React.FC = () => {
  const [score, setScore] = useState(0);
  const [gameOver, setGameOver] = useState(false);

  useEffect(() => {
    const handleGameOver = () => {
      setGameOver(true);
    };

    // Thêm sự kiện lắng nghe sự kiện kết thúc trò chơi
    document.addEventListener('gameOver', handleGameOver);

    return () => {
      document.removeEventListener('gameOver', handleGameOver);
    };
  }, []);

  const restartGame = () => {
    setScore(0);
    setGameOver(false);
  };

  return (
    <div>
      <div>
        <div>
          <div>Snake Game</div>
        </div>
      </div>
      <div>
        <Board gameOver={gameOver} setScore={setScore} />
        <div className="score">Score: {score}</div>
        {gameOver && (
          <div className="game-over">
            <p>Game Over!</p>
            <button onClick={restartGame}>Restart</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SnakeGame;