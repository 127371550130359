import { Accordion, Form, Modal } from "react-bootstrap";
import "./Styles.css";
import OtpInput from "react18-input-otp";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getLanguageUI } from "../../../../../Services/languageUI";
import {
  leaf1,
  leaf2,
  trashBlack,
  trashRed,
} from "../../../../../components/ImgExport";
import * as userSlice from "../../../../../store/slices/userSlice";

interface ModalDeletePOSProps {
  show: boolean;
  HandleDelSuccess: () => void;
  handleClose: () => void;
  setShowLoading: (e: boolean) => void;
  messErr: string;
}

const ModalDeletePOS = ({
  show,
  HandleDelSuccess,
  handleClose,
  setShowLoading,
  messErr,
}: ModalDeletePOSProps) => {
  const languageUI = getLanguageUI().voucherPage;
  const UserIdStore = useSelector(userSlice.selectorUserId);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="modal-delete-customer modal-delete-voucher"
      >
        <Modal.Dialog className="w-100">
          <Modal.Body>
            <div className="position-relative px-3">
              <div className="d-flex align-items-center gap-2px">
                <img src={trashBlack} alt="" />
                <p className="text-delete-modal-customer mb-0">
                  {languageUI.delete}
                </p>
              </div>
              <p className="text-content-modal-delete-customer mt-2">
                {languageUI.areYouWantDelete}
              </p>
              <div>
                <p className="mess-err">{messErr}</p>
              </div>
              <div className="d-flex justify-content-between">
                <div
                  className="btn-cancel-modal-delete-customer bg-white"
                  onClick={handleClose}
                >
                  {languageUI.cancel}
                </div>
                <div
                  className="btn-cancel-modal-delete-customer border-delete bg-white"
                  onClick={HandleDelSuccess}
                >
                  <img src={trashRed} alt="" className="" />
                  <p className="mb-0 text-delete-modal-customer-btn">
                    {languageUI.delete}
                  </p>
                </div>
              </div>
              <img src={leaf1} alt="" className="img-leaf1" />
              <img src={leaf2} alt="" className="img-leaf2" />
            </div>
          </Modal.Body>
        </Modal.Dialog>
      </Modal>
    </>
  );
};

export default ModalDeletePOS;
