import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import classNames from 'classnames';
import "./Styles.css"

interface ProfileMenuItem {
    label: string;
    icon: string;
    redirectTo: string;
}

interface ProfileDropdownProps {
    menuItems: Array<ProfileMenuItem>;
    profilePic?: string;
    username: string;
    userTitle?: string;
}

const ProfileDropdown = (props: ProfileDropdownProps) => {
    const profilePic = props['profilePic'] || null;
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

    /*
     * toggle profile-dropdown
     */
    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    return (
        <div className='h-100 d-flex align-items-center gap-2'>
            <div className=' h-40px w-40px position-relative rounded-circle'>
                <img src={profilePic!} className="rounded-circle h-40px w-40px flex-shink-0" alt="" />
                <div className='status-owner'></div>
            </div>
            <div>
                <span className="text-name-header">
                    {props['username']}
                </span>
                <p className='mb-0 text-staff-header'>{props['userTitle']}</p>
            </div>
        </div>
        // <Dropdown show={dropdownOpen} onToggle={toggleDropdown}>
        //     <Dropdown.Toggle
        //         id="dropdown-profile"
        //         as="a"
        //         onClick={toggleDropdown}
        //         className={classNames('nav-link nav-user me-0 waves-effect waves-light', { show: dropdownOpen })}
        //     >
        //         <img src={profilePic!} className="rounded-circle" alt="" />
        //         <span className="pro-user-name ms-1">
        //             {props['username']} <i className="mdi mdi-chevron-down"></i>
        //         </span>
        //     </Dropdown.Toggle>
        //     <Dropdown.Menu className="dropdown-menu dropdown-menu-end profile-dropdown">
        //         <div onClick={toggleDropdown}>
        //             {(props.menuItems || []).map((item, i) => {
        //                 return (
        //                     <React.Fragment key={i}>
        //                         {i === props['menuItems'].length - 1 && <div className="dropdown-divider"></div>}
        //                         <Link
        //                             to={item.redirectTo}
        //                             className="dropdown-item notify-item"
        //                             key={i + '-profile-menu'}
        //                         >
        //                             <i className={`${item.icon} me-1`}></i>
        //                             <span>{item.label}</span>
        //                         </Link>
        //                     </React.Fragment>
        //                 );
        //             })}
        //         </div>
        //     </Dropdown.Menu>
        // </Dropdown>
    );
};

export default ProfileDropdown;
