import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Nav,
  Row,
  Tab,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

// actions
import { resetAuth } from "../../redux/actions";

// store
import { AppDispatch } from "../../redux/store";

// components
import * as Request from "../../api/Request";
import { useParams } from 'react-router';




const EnterPersonTitle = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [activeTab, setActiveTab] = useState("0");
  const navigate = useNavigate();
  const [input_first_name, setInput_first_name] = useState<number>(0);
  const [input_day_of_birth, setInput_day_of_birth] = useState<number>(0);
  const [show_customer_point, setShow_customer_point] = useState<number>(0);

  const params: any = useParams();
  let id_customer = params.id;

  // Get info customer
  const gInfoCustomer = async (id_customer: any) => {
    await Request.fetchAPI(
      `checkin/gInfoCustomer.php?id=${id_customer}`,
      "get"
    ).then((res) => {
      if (Number(res.status) === 1) {
        getInforOwner(res?.data?.admin_id);
      }
    })
      .catch((error: any) => {
        console.log(error);
      });
  };


  //Get info owner
  const getInforOwner = async (id_owner: any) => {
    await Request.fetchAPI(
      `checkin/gInfoOwner.php?id=${id_owner}`,
      "get"
    ).then((res) => {
      if (Number(res.status) === 1) {
        setInput_day_of_birth(res?.data?.input_day_of_birth);
        setInput_first_name(res?.data?.input_first_name);
        setShow_customer_point(res?.data?.show_customer_point);
      }
    });
  };

  const location = useLocation();

  const handleClick = (id_customer: any, activeTab: any) => {
    addGender(activeTab, id_customer);
  };

  const addGender = async (gender: any, id_customer: any) => {
    await Request.fetchAPI(`/checkin/uGenderCustomer.php`, "post", {
      gender: gender,
      customer_id: id_customer,
    })
      .then((res) => {
        if (Number(res.status) === 1) {
          if (Number(input_first_name) === 0) {
            navigate(`/webcheckin/enterfirstname/${id_customer}`);
          } else if (Number(input_first_name) === 1 && Number(input_day_of_birth) === 1 && Number(show_customer_point) === 1) {
            navigate(`/webcheckin/choosemenu/${id_customer}`);
          } else if (Number(input_first_name) === 1 && Number(input_day_of_birth) === 1 && Number(show_customer_point) === 0) {
            navigate(`/webcheckin/showpointcheckin/${id_customer}`);
          } else {
            navigate(`/webcheckin/enterbirthday/${id_customer}`);
          }
          // localStorage.setItem("vocative", activeTab);
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  };
  // 
  useEffect(() => {
    dispatch(resetAuth());
  }, [dispatch]);

  useEffect(() => {
    gInfoCustomer(id_customer);
  }, [location.pathname, id_customer]);


  return (
    <>
      <div className="account-pages d-flex" style={{ minHeight: "100vh" }}>
        <Container className="m-auto">
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="bg-pattern rounded-3 shadow mb-0">
                <Card.Body className="p-4">
                  <h4 className="text-center mb-4 mt-4">
                    What should we call you?
                  </h4>
                  <Tab.Container
                    defaultActiveKey={activeTab}
                    onSelect={(tab: any) => setActiveTab(tab)}
                  >
                    <Row className="mb-5">
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div>
                          <Nav
                            as="ul"
                            variant="pills"
                            className="navtab-bg justify-content-center"
                          >
                            <Nav.Item as="li">
                              <Nav.Link
                                className="cursor-pointer ms-0"
                                eventKey="0"
                              >
                                Sir
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li">
                              <Nav.Link className="cursor-pointer" eventKey="1">
                                Madam
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li">
                              <Nav.Link className="cursor-pointer" eventKey="2">
                                Friend
                              </Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </div>
                      </Col>
                    </Row>
                    <Tab.Content className="pt-0"></Tab.Content>
                  </Tab.Container>
                  <div className="text-center">
                    {/* <Link to="/webcheckin/enterfirstname" className='d-inline-block'> */}
                    <Button
                      onClick={() => handleClick(id_customer, activeTab)}
                      variant="outline-primary"
                      className="waves-effect waves-light"
                    >
                      Go
                    </Button>
                    {/* </Link> */}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default EnterPersonTitle;
