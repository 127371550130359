import { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Nav, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as Request from "../../api/Request";
import Swal from "sweetalert2";

// actions
import { resetAuth } from "../../redux/actions";

// store
import { AppDispatch } from "../../redux/store";



const CheckInReview = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [customerInfor, setCustomerInfor] = useState<any>("");
  const [ownerInfor, setOwnerInfor] = useState<any>("");

  const [activeTab, setActiveTab] = useState("1");
  const [checkError, setCheckError] = useState(false);
  const [errMess, setErMess] = useState("");

  const [reviewText, setReviewText] = useState("");
  const params = useParams();

  const [showFeedbackForm, setShowFeedbackForm] = useState(false);
  useEffect(() => {
    dispatch(resetAuth());
  }, [dispatch]);

  const getInforCustomer = async () => {
    await Request.fetchAPI(
      `/checkin/gInfoCustomerCry.php?id=${params?.idcustomer}`,
      "get"
    )
      .then((res) => {
        if (Number(res.status) === 1) setCustomerInfor(res?.data);
      })
      .catch((error: any) => console.log(error));
  };

  const getInforOwner = async () => {
    await Request.fetchAPI(
      `/checkin/gInfoOwnerCry.php?id=${params?.idowner}`,
      "get"
    )
      .then((res) => {
        if (Number(res.status) === 1) {
          setOwnerInfor(res?.data);
        }
      })
      .catch((error: any) => console.log(error));
  };

  const sendSmsReview = async (
    id_owner: any,
    id_customer: any,
    id_review: any
  ) => {
    await Request.fetchAPI(`checkin/send_SMS_owner.php`, "post", {
      id_owner: id_owner,
      id_customer: id_customer,
      id_review: id_review,
    })
      .then((res) => {
        if (Number(res.status) === 1) console.log("sms", res.data);
        else console.log("send sms k t thanh cong");
      })
      .catch((error: any) => console.log(error));
  };

  const handleSubmitFeelBack = async () => {
    if (reviewText === "" || null || undefined) {
      setCheckError(true);
      setErMess("Please enter your comment");
      return;
    }
    await Request.fetchAPI(`checkin/iReview.php`, "post", {
      customer_id: params?.idcustomer,
      owner_id: params?.idowner,
      sms_send_id: params?.idSendSMS,
      review_type_id: activeTab,
      message: reviewText,
    })
      .then((res) => {
        if (Number(res?.status) === 1) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Submit success!",
            showConfirmButton: false,
            timer: 1500,
          });
          if (Number(activeTab) === 3) {
            sendSmsReview(params?.idowner, params?.idcustomer, res.data.id_review);
          }
          setReviewText("");
          navigate(`/webcheckin/sendmenu/${ownerInfor?.id}`);
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            // title: "Submit error, please try again!",
            title: res?.message,
            showConfirmButton: false,
            timer: 3000,
          });
          setReviewText("");
        }
      })
      .catch((error: any) => {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Submit error, please try again!",
          showConfirmButton: false,
          timer: 3000,
        });
        console.log(error);
      });
  };

  const GoodReview = async () => {
    await Request.fetchAPI(`checkin/iReview.php`, "post", {
      customer_id: params?.idcustomer,
      owner_id: params?.idowner,
      sms_send_id: params?.idSendSMS,
      // customer_id: param?.idcustomer,
      // owner_id: param?.idowner,
      // sms_send_id: param?.idSendSMS,
      review_type_id: 1,
      message: "Good",
    })
      .then((res) => {
        if (Number(res?.status) === 1) {
          // sendSmsReview();
          setReviewText("");
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: res?.message,
            showConfirmButton: false,
            timer: 3000,
          });
          setReviewText("");
          return;
        }
      })
      .catch((error: any) => {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "please try again!",
          showConfirmButton: false,
          timer: 3000,
        });
        console.log(error);
      });
  };

  useEffect(() => {
    getInforCustomer();
    getInforOwner();
  }, []);
  return (
    <>
      <div className="account-pages d-flex" style={{ minHeight: "100vh" }}>
        <Container className="m-auto">
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="bg-pattern rounded-3 shadow mb-0">
                <Card.Body className="p-4">
                  {showFeedbackForm ? (
                    <div>
                      <h4 className="text-center text-uppercase fw-bold">
                        {ownerInfor?.name} need your feedback
                      </h4>
                      <div className="d-flex align-items-center justify-content-center">
                        <i
                          className="fas fa-star fs-1 me-2 text-warning"
                          style={{ cursor: "pointer" }}
                        ></i>
                        <i
                          className="fas fa-star fs-1 me-2 text-warning"
                          style={{ cursor: "pointer" }}
                        ></i>
                        <i
                          className="fas fa-star fs-1 me-2 text-warning"
                          style={{ cursor: "pointer" }}
                        ></i>
                        <i
                          className="fas fa-star fs-1 me-2"
                          style={{ cursor: "pointer" }}
                        ></i>
                        <i
                          className="fas fa-star fs-1 me-2"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </div>
                      <Form.Control
                        className="my-3"
                        as="textarea"
                        rows={6}
                        value={reviewText}
                        onChange={(e: any) => setReviewText(e.target.value)}
                        placeholder={`Dear ${customerInfor.name}, as owners of Invogue Nails & Spa, we value the quality of our service and would like to hear true feedbacks from you in order to serve you better`}
                      />
                      <p style={{ color: "#f1556c", textAlign: "center" }}>
                        {checkError ? errMess : ""}
                      </p>
                      <div className="text-end">
                        <Button
                          variant="outline-primary"
                          className="me-2"
                          onClick={() => setShowFeedbackForm(false)}
                        >
                          Back
                        </Button>
                        <Button
                          variant="primary"
                          onClick={handleSubmitFeelBack}
                        >
                          Submit feedback
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <>
                      <h4 className="mb-3 mt-4">{ownerInfor?.name}</h4>
                      <p className="mb-3 mt-3">
                        ~ Hi {customerInfor.name}, how would you rate{" "}
                      </p>
                      <Nav
                        variant="pills"
                        className="flex-column review-tab"
                        defaultActiveKey={activeTab}
                        onSelect={(tab: any) => setActiveTab(tab)}
                      >
                        <Nav.Item>
                          <Nav.Link
                            className="border fw-bold my-1"
                            eventKey="1"
                            href="https://www.google.com/search?q=invogue+nails+%26+spa.+(%231+rating+cocoa+beach)&rlz=1C1CHBD_viVN1014VN1014&oq=invogue+nails+%26+Spa&aqs=chrome.1.69i57j0i22i30l6.133736j1j7&sourceid=chrome&ie=UTF-8#lrd=0x88e0a793f5ca3991:0xbb0d4771885ee4ed,3,,,,"
                            target="blank"
                            onClick={GoodReview}
                          >
                            <i className="me-2 far fa-laugh fs-4"></i>Great
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            className="border fw-bold my-1"
                            eventKey="2"
                            onClick={() => setShowFeedbackForm(true)}
                          >
                            <i className="me-2 far fa-meh fs-4"></i> Just OK
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            className="border fw-bold my-1"
                            eventKey="3"
                            onClick={() => setShowFeedbackForm(true)}
                          >
                            <i className="me-2 far fa-frown-open fs-4"></i>Bad
                          </Nav.Link>
                        </Nav.Item>
                        {/* <Nav.Item>
                          <Nav.Link
                            className="border fw-bold  my-1"
                            eventKey="4"
                            onClick={notReview}
                          >
                            <i className="me-2 far fa-frown-open fs-4"></i>
                            Haven't give review yet
                          </Nav.Link>
                        </Nav.Item> */}
                      </Nav>
                    </>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default CheckInReview;
