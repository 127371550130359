import { Form, Modal } from "react-bootstrap";
import "./Styles.css"
import { uploadImage } from "../../../../components/ImgExport";
import { useEffect, useRef, useState } from "react";
import ItemsChooseRaw from "./ItemsChooseRaw";
import { foodPlaceholder } from "../../../../components/Image";
import { useSelector } from "react-redux";
import * as userSlice from "../../../../store/slices/userSlice";
import { useNavigate } from "react-router-dom";
import ModalRawMaterial from "./ModalRawMaterial";
import ModalCustomerSide from "./ModalCustomerSide";
import { ListIngredient, addNewSideDish } from "../../../../api/apiOwner/templateMenuApi";
import * as comboApi from "../../../../api/apiOwner/comboApi";
import ModalLoading from "../../../../components/ModalLoading";
import React from 'react'
import { getLanguageUI } from "../../../../Services/languageUI";
import default_product from "../../../../assets/images/default_product.png";

interface ModalAddNewSideDishProps {
  show: boolean;
  FoodNumericalOrderMax: number;
  ChoooseFoodGroupAdd: number;
  comboUnit: comboApi.ComboUnit[],
  comboSideDish: comboApi.ComboSideDish[],
  comboIngredient: comboApi.ComboIngredient[],
  handleClose: () => void;
  HandleAddFoodSuccess: () => void;
}
interface SideChoosed {
  id: string, name: string
}
const ModalAddNewSideDish = ({
  show,
  FoodNumericalOrderMax,
  comboUnit = [],
  comboIngredient = [],
  comboSideDish = [],
  ChoooseFoodGroupAdd = 0,
  HandleAddFoodSuccess,
  handleClose,
}: ModalAddNewSideDishProps) => {
  const languageUI = getLanguageUI().menuTemplate
  const navigate = useNavigate();
  const UserIdStore = useSelector(userSlice.selectorUserId);
  const [FoodNameAdd, setFoodNameAdd] = useState<string>("");
  const [PhotoTmp, setPhotoTmp] = useState<string>(""); //ảnh tạm tải lên
  const avtRef = useRef<HTMLInputElement>(null);
  const [MessageToast, setMessageToast] = useState<string>("");
  const [File, setFile] = useState<string>(""); //ảnh tạm tải lên
  const [FoodNumericalOrder, setFoodNumericalOrder] = useState<number | string>("");
  const [FoodPriceAdd, setFoodPriceAdd] = useState<number | string>('');
  const [ShowLoading, setShowLoading] = useState<boolean>(false);
  const [descriptionFoodEdit, setDescriptionFoodEdit] = useState<string>("");
  const [showModalRawMaterial, setShowModalRawMaterial] = useState<boolean>(false);
  const [showModalCustomerSide, setShowModalCustomerSide] = useState<boolean>(false);
  const [listNoteFood, setListNoteFood] = useState<string[]>([]);//tên các node
  const [listIngredient, setListIngredient] = useState<ListIngredient[]>([]);//list các nguyên liệu
  const [listSide, setListSide] = useState<SideChoosed[]>([]);//list các món ăn kèm
  const [noteText, setNoteText] = useState<string>('')
  const [maxSide, setMaxSide] = useState<string | number>('')
  const [typeFood, setTypeFood] = useState<number>(0)
  const dismisModalCustomerSide = () => {
    setShowModalCustomerSide(false)
  }
  const dismisModalRawMaterial = () => {
    setShowModalRawMaterial(false)
  }




  const ChangeAVT = () => {
    (avtRef as any).current.click();
  };
  const maxLength = 200

  const onSelectFile = async (picture: any) => {
    var file = picture[0];
    if (!file.type.match("image")) {
      setMessageToast("The file you selected is not in the correct format!");
    } else {
      setMessageToast("");
      var picReader = new FileReader();
      picReader.readAsDataURL(file);
      picReader.onload = function () {
        setFile(file);

        setPhotoTmp(picReader.result?.toString() ?? '');
      };
      picReader.onerror = function (error) {
        console.log("Error: ", error);
      };
    }
  };

  const SaveRawMaterial = (NewIngredient: ListIngredient[]) => {
    setListIngredient([...NewIngredient]);
  }
  const SaveSideDish = (NewSide: SideChoosed[], max: number = 0) => {
    setListSide([...NewSide])
    if (!max || max <= 0) {
      setMaxSide(1)
    } else {
      setMaxSide(max)
    }
  }
  const SaveNoteFood = () => {
    if (noteText.trim() !== '' && !listNoteFood.some(item => item === noteText)) {
      setListNoteFood([...listNoteFood, noteText])
    }
    setNoteText('')
  }
  const RemoveIngredientItem = (index: number) => {

    setListIngredient([...listIngredient.filter((item, i) => i !== index)])
  }
  const RemoveNoteItem = (index: number) => {

    setListNoteFood([...listNoteFood.filter((item, i) => i !== index)])
  }
  const RemoveSideItem = (index: number) => {

    setListSide([...listSide.filter((item, i) => i !== index)])
  }
  const saveNewFood = async () => {

    // if (File == "") {
    //     setMessageToast("Please enter food image!");
    //     return;
    // }
    if (FoodNameAdd.trim() === "") {
      setMessageToast("Please enter food name!");
      return;
    }
    if (typeFood === 0) {
      setMessageToast("Please select food or beverage!");
      return;
    }
    if (FoodNumericalOrderMax < Number(FoodNumericalOrder)) {
      setMessageToast("Ordinal number is too big");
      setFoodNumericalOrder(FoodNumericalOrderMax);
      return;
    }
    if (!FoodPriceAdd || Number(FoodPriceAdd) < 0) {
      setMessageToast("Please enter food price!");
      return;
    }
    setMessageToast('')
    if (UserIdStore) {
      setShowLoading(true);
      const Side = listSide.map(item => { return item?.id });
      const reqAdd = await addNewSideDish(UserIdStore, ChoooseFoodGroupAdd, FoodNameAdd, Number(FoodPriceAdd), Number(FoodNumericalOrder), listIngredient, typeFood, Side, Number(maxSide), listNoteFood, File, '', descriptionFoodEdit);
      if (Number(reqAdd?.status) === 1) {
        setMessageToast("");
        handleClose();
        HandleAddFoodSuccess()
        setShowLoading(false)
      } else {
        setMessageToast(
          reqAdd?.message
            ? reqAdd?.message
            : "Something went wrong. Please try again later!"
        );
        setShowLoading(false)
        return;
      }
    } else {
      navigate("/auth/login");
    }
  };
  const resetForm = () => {
    setNoteText('')
    setPhotoTmp('')
    setFile('')
    setFoodPriceAdd('')
    setDescriptionFoodEdit('')
    setListNoteFood([])
    setListIngredient([])
    setListSide([])
  }
  useEffect(() => {
    if (show) {
      setFoodNumericalOrder(FoodNumericalOrderMax)
      resetForm()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  return (
    <>
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
      <Modal show={show} onHide={handleClose} className="add-new-food">
        <Modal.Dialog className="bg-gray-light">
          <Modal.Body>
            <div className="h-100vh position-relative">
              <div className="px-68px pt-48px h-100vh overflow-y-scroll pb-120px">
                <p className="mb-1 create-new-food">{languageUI.createNewSideDish}</p>
                <p className="text-detail-title">{languageUI.itWillShowInFoodMenu}</p>
                <div className="text-danger text-center">{MessageToast}</div >
                <div className="w-100 d-flex justify-content-center">
                  <img
                    className={`img-fluid mx-auto rounded-5 object-cover border ${PhotoTmp === "" ? "d-none" : ""}`}
                    src={PhotoTmp && PhotoTmp !== "" ? PhotoTmp : foodPlaceholder}
                    alt=""
                    style={{ width: 100, height: 100 }}
                    onClick={(e) => {
                      ChangeAVT();
                    }}
                    onError={(e) => {
                      e.currentTarget.src = default_product;
                    }}
                  />
                </div>
                <div className={`box-upload-image d-flex gap-2 justify-content-start position-relative ${PhotoTmp !== "" ? "d-none" : ""}`}>
                  <input
                    type="file"
                    ref={avtRef}
                    accept="image/*"
                    onChange={(e) => {
                      onSelectFile(e.target.files);
                    }}
                    className="position-absolute w-100 h-100px opacity-0 top-0 start-0"
                    onClick={(e: any) => (e.target.value = null)}
                  />
                  <img src={uploadImage} alt="" className="h-fit" />
                  <div>
                    <p className="upload-food-image mb-1">{languageUI.uploadFoodImg}</p>
                    <p className="text-content-upload-image d-flex gap-2px flex-wrap mb-0 h-fit">{languageUI.recommendedImageFormats}: <p className="h-fit mb-0 text-12B064">JPG, PNG</p>, {languageUI.atLeast} <p className="h-fit mb-0 text-12B064">800 pixels</p> {languageUI.forOptimalDisplay}</p>
                  </div>
                </div>
                <div className="mt-2">
                  <p className="text-add-table-name mb-1">{languageUI.sideDishName}</p>
                  <div className="input-add-table bg-white">
                    <input type="text" className=" bg-tranparent w-100 border-none focus-visiable-none text-input-add-table px-2 " placeholder="Name" value={FoodNameAdd} onChange={(e) => {
                      setFoodNameAdd(e.target.value);
                    }} />
                  </div>
                </div>
                <div className="mt-2">
                  <p className="text-add-table-name mb-1">{languageUI.selectFoodOrBeverage}</p>
                  <Form>
                    <Form.Check // prettier-ignore
                      className='d-flex gap-2 align-items-center ps-2'
                      name="choose-food-beverage"
                      type='radio'
                      id={`beverage`}
                      label={languageUI.beverage}
                      checked={typeFood === 1}
                      onClick={() => setTypeFood(1)}
                    />
                    <Form.Check // prettier-ignore
                      className='d-flex gap-2 align-items-center ps-2'
                      name="choose-food-beverage"
                      type='radio'
                      id={`appetizer`}
                      label={languageUI.appetizer}
                      checked={typeFood === 2}
                      onClick={() => setTypeFood(2)}
                    />
                    <Form.Check // prettier-ignore
                      className='d-flex gap-2 align-items-center ps-2'
                      name="choose-food-beverage"
                      type='radio'
                      id={`entree`}
                      label={languageUI.entree}
                      checked={typeFood === 3}
                      onClick={() => setTypeFood(3)}
                    />
                  </Form>
                </div>
                <div className="d-flex justify-content-between mt-2">
                  <div className="w-48">
                    <p className="text-add-table-name mb-1">{languageUI.orderNo}</p>
                    <div className="input-add-table bg-white">
                      <input type="number" max={FoodNumericalOrderMax} className=" bg-tranparent w-100 border-none focus-visiable-none text-input-add-table px-2 " placeholder="Numerical order" value={FoodNumericalOrder} onChange={(e) => {
                        if (e.target.value && Number(e.target.value) > FoodNumericalOrderMax) {
                          return
                        }
                        setFoodNumericalOrder(e.target.value);
                      }} />
                    </div>
                  </div>
                  <div className="w-48">
                    <p className="text-add-table-name mb-1">{languageUI.price}</p>
                    <div className="input-add-table d-flex gap-2 align-items-center p-2 ">
                      <input type="number" className=" bg-tranparent w-100 border-none focus-visiable-none text-input-add-table px-2 " placeholder={languageUI.enterPrice} value={FoodPriceAdd} onChange={(e) => {
                        if (e.target.value && Number(e.target.value) < 0) {
                          return;
                        }
                        setFoodPriceAdd(Number(e.target.value));
                      }} />
                    </div>
                  </div>
                </div>
                <div className="mt-2">
                  <p className="text-add-table-name mb-1">{languageUI.description}</p>
                  <div>
                    <textarea
                      className="text-more-request"
                      id="textarea"
                      value={descriptionFoodEdit}
                      placeholder={languageUI.descriptionText}
                      onChange={(e) => {
                        setDescriptionFoodEdit(e.target.value);
                      }}
                      maxLength={maxLength}
                    ></textarea>
                    <div className="w-100 d-flex justify-content-end px-3">
                      <small className="text-muted text-right">
                        {descriptionFoodEdit.length}/{maxLength}
                      </small>
                    </div>
                  </div>
                </div>
                <p className="mb-0 mt-2 text-select-material">{languageUI.selectMaterialToConnect}</p>
                <div className="box-choose-raw mt-2">
                  <p className="mb-0 text-truncate w-100 text-choose-number-side" onClick={() => { setShowModalRawMaterial(true) }}>{languageUI.rawMaterialInWarehouse}</p>
                </div>
                <div className="d-flex gap-2 flex-wrap mt-3">
                  {listIngredient.map((item, i) => (
                    <ItemsChooseRaw mass={item?.unit_name} name={item?.ingredient_name} unit={item?.quantity} key={`ing${i}`} index={i} onRemove={RemoveIngredientItem} />
                  ))}

                </div>
                {/* <div className="box-choose-raw mt-2 d-flex justify-content-between align-items-center">
                  <p className="mb-0 text-choose-number-side" onClick={() => setShowModalCustomerSide(true)}>Choose number of side dish</p>
                  <img src={arrowRightPage} alt="" />
                </div> */}
                {/* <div className="d-flex gap-2 items-center mt-2">
                  <p className="mb-0 text-select-material" onClick={() => setShowModalCustomerSide(true)}>How many free side customers can choose for this food</p>
                  <div className="d-flex align-items-center border  px-2  rounded-8px h-42px  w-150px bg-white flex-shrink-0">
                    <Select
                      className="react-select react-select-container w-100 border-select-none"
                      classNamePrefix="react-select"
                      placeholder=""
                      defaultValue={["0"]}
                      isClearable={false}
                      options={optionsSelectSide}
                    // onChange={(e) => setPaymentStatus(e?.value)}
                    // value={listTimeZone.filter(item => item?.value == timeZone)}
                    // onChange={(e: any) => {
                    //   if (e == null) {
                    //     setTimeZone(0);
                    //   } else {
                    //     setTimeZone(e.value);
                    //   }
                    // }}
                    />
                  </div>
                </div> */}
                <div className="d-flex gap-2 flex-wrap mt-3">
                  {listSide.map((item, i) => (
                    <ItemsChooseRaw name={item?.name} key={`side${i}`} index={i} onRemove={RemoveSideItem} />
                  ))}
                </div>
                <p className="mb-0 mt-2 text-select-material">{languageUI.optionsForThis}</p>
                <p className="mb-0 mt-2 text-select-material">{languageUI.exampleMedium}</p>
                <p className="mb-0 mt-2 text-select-material">(Press 'Enter' after you type)</p>
                <div className="input-add-table d-flex gap-2 align-items-center p-2 mt-2 ">
                  <input type="text" className=" bg-tranparent w-100 border-none focus-visiable-none text-input-add-something ps-2 " placeholder={languageUI.exampleSpice} value={noteText}
                    onChange={(e) => setNoteText(e.target.value)}
                    onKeyPress={event => {
                      if (event.key === 'Enter') {
                        SaveNoteFood();
                      }
                    }} />
                </div>
                <div className="d-flex gap-2 flex-wrap mt-3">
                  {listNoteFood.map((item, i) => (
                    <ItemsChooseRaw name={item} key={`note${i}`} index={i} onRemove={RemoveNoteItem} />
                  ))}
                </div>
              </div>
              <div className="box-btn-add-new-food">
                <div className="d-flex justify-content-between align-items-center px-68px w-100">
                  <div className="btn-save-cancel-floor text-4a4a6a border" onClick={handleClose}>{languageUI.cancel}</div>
                  <div className=" btn-save-cancel-floor text-white bg-0FA54A border-0FA54A" onClick={saveNewFood}>{languageUI.save}</div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal.Dialog>

      </Modal>
      <ModalRawMaterial handleClose={dismisModalRawMaterial} show={showModalRawMaterial} comboIngredient={comboIngredient} comboUnit={comboUnit} listIngredient={listIngredient} onSave={SaveRawMaterial} />
      <ModalCustomerSide handleClose={dismisModalCustomerSide} show={showModalCustomerSide} comboSideDish={comboSideDish} listSide={listSide} maxSide={maxSide} onSave={SaveSideDish} />
    </>

  );
};

export default ModalAddNewSideDish;
