export const THEMIFYICONS_LIST = [
    {
        name: 'ti-arrow-up',
        category: 'arrows-direction',
    },
    {
        name: 'ti-arrow-right',
        category: 'arrows-direction',
    },
    {
        name: 'ti-arrow-left',
        category: 'arrows-direction',
    },
    {
        name: 'ti-arrow-down',
        category: 'arrows-direction',
    },
    {
        name: 'ti-arrows-vertical',
        category: 'arrows-direction',
    },
    {
        name: 'ti-arrows-horizontal',
        category: 'arrows-direction',
    },
    {
        name: 'ti-angle-up',
        category: 'arrows-direction',
    },
    {
        name: 'ti-angle-right',
        category: 'arrows-direction',
    },
    {
        name: 'ti-angle-left',
        category: 'arrows-direction',
    },
    {
        name: 'ti-angle-down',
        category: 'arrows-direction',
    },
    {
        name: 'ti-angle-double-up',
        category: 'arrows-direction',
    },
    {
        name: 'ti-angle-double-right',
        category: 'arrows-direction',
    },
    {
        name: 'ti-angle-double-left',
        category: 'arrows-direction',
    },
    {
        name: 'ti-angle-double-down',
        category: 'arrows-direction',
    },
    {
        name: 'ti-move',
        category: 'arrows-direction',
    },
    {
        name: 'ti-fullscreen',
        category: 'arrows-direction',
    },
    {
        name: 'ti-arrow-top-right',
        category: 'arrows-direction',
    },
    {
        name: 'ti-arrow-top-left',
        category: 'arrows-direction',
    },
    {
        name: 'ti-arrow-circle-up',
        category: 'arrows-direction',
    },
    {
        name: 'ti-arrow-circle-right',
        category: 'arrows-direction',
    },
    {
        name: 'ti-arrow-circle-left',
        category: 'arrows-direction',
    },
    {
        name: 'ti-arrow-circle-down',
        category: 'arrows-direction',
    },
    {
        name: 'ti-arrows-corner',
        category: 'arrows-direction',
    },
    {
        name: 'ti-split-v',
        category: 'arrows-direction',
    },
    {
        name: 'ti-split-v-alt',
        category: 'arrows-direction',
    },
    {
        name: 'ti-split-h',
        category: 'arrows-direction',
    },
    {
        name: 'ti-hand-point-up',
        category: 'arrows-direction',
    },
    {
        name: 'ti-hand-point-right',
        category: 'arrows-direction',
    },
    {
        name: 'ti-hand-point-left',
        category: 'arrows-direction',
    },
    {
        name: 'ti-hand-point-down',
        category: 'arrows-direction',
    },
    {
        name: 'ti-back-right',
        category: 'arrows-direction',
    },
    {
        name: 'ti-back-left',
        category: 'arrows-direction',
    },
    {
        name: 'ti-exchange-vertical',
        category: 'arrows-direction',
    },
    {
        name: 'ti-wand',
        category: 'web-app',
    },
    {
        name: 'ti-save',
        category: 'web-app',
    },
    {
        name: 'ti-save-alt',
        category: 'web-app',
    },
    {
        name: 'ti-direction',
        category: 'web-app',
    },
    {
        name: 'ti-direction-alt',
        category: 'web-app',
    },
    {
        name: 'ti-user',
        category: 'web-app',
    },
    {
        name: 'ti-link',
        category: 'web-app',
    },
    {
        name: 'ti-unlink',
        category: 'web-app',
    },
    {
        name: 'ti-trash',
        category: 'web-app',
    },
    {
        name: 'ti-target',
        category: 'web-app',
    },
    {
        name: 'ti-tag',
        category: 'web-app',
    },
    {
        name: 'ti-desktop',
        category: 'web-app',
    },
    {
        name: 'ti-tablet',
        category: 'web-app',
    },
    {
        name: 'ti-mobile',
        category: 'web-app',
    },
    {
        name: 'ti-email',
        category: 'web-app',
    },
    {
        name: 'ti-star',
        category: 'web-app',
    },
    {
        name: 'ti-spray',
        category: 'web-app',
    },
    {
        name: 'ti-signal',
        category: 'web-app',
    },
    {
        name: 'ti-shopping-cart',
        category: 'web-app',
    },
    {
        name: 'ti-shopping-cart-full',
        category: 'web-app',
    },
    {
        name: 'ti-settings',
        category: 'web-app',
    },
    {
        name: 'ti-search',
        category: 'web-app',
    },
    {
        name: 'ti-zoom-in',
        category: 'web-app',
    },
    {
        name: 'ti-zoom-out',
        category: 'web-app',
    },
    {
        name: 'ti-cut',
        category: 'web-app',
    },
    {
        name: 'ti-ruler',
        category: 'web-app',
    },
    {
        name: 'ti-ruler-alt-2',
        category: 'web-app',
    },
    {
        name: 'ti-ruler-pencil',
        category: 'web-app',
    },
    {
        name: 'ti-ruler-alt',
        category: 'web-app',
    },
    {
        name: 'ti-bookmark',
        category: 'web-app',
    },
    {
        name: 'ti-bookmark-alt',
        category: 'web-app',
    },
    {
        name: 'ti-reload',
        category: 'web-app',
    },
    {
        name: 'ti-plus',
        category: 'web-app',
    },
    {
        name: 'ti-minus',
        category: 'web-app',
    },
    {
        name: 'ti-close',
        category: 'web-app',
    },
    {
        name: 'ti-pin',
        category: 'web-app',
    },
    {
        name: 'ti-pencil',
        category: 'web-app',
    },
    {
        name: 'ti-pencil-alt',
        category: 'web-app',
    },
    {
        name: 'ti-paint-roller',
        category: 'web-app',
    },
    {
        name: 'ti-paint-bucket',
        category: 'web-app',
    },
    {
        name: 'ti-na',
        category: 'web-app',
    },
    {
        name: 'ti-medall',
        category: 'web-app',
    },
    {
        name: 'ti-medall-alt',
        category: 'web-app',
    },
    {
        name: 'ti-marker',
        category: 'web-app',
    },
    {
        name: 'ti-marker-alt',
        category: 'web-app',
    },
    {
        name: 'ti-lock',
        category: 'web-app',
    },
    {
        name: 'ti-unlock',
        category: 'web-app',
    },
    {
        name: 'ti-location-arrow',
        category: 'web-app',
    },
    {
        name: 'ti-layout',
        category: 'web-app',
    },
    {
        name: 'ti-layers',
        category: 'web-app',
    },
    {
        name: 'ti-layers-alt',
        category: 'web-app',
    },
    {
        name: 'ti-key',
        category: 'web-app',
    },
    {
        name: 'ti-image',
        category: 'web-app',
    },
    {
        name: 'ti-heart',
        category: 'web-app',
    },
    {
        name: 'ti-heart-broken',
        category: 'web-app',
    },
    {
        name: 'ti-hand-stop',
        category: 'web-app',
    },
    {
        name: 'ti-hand-open',
        category: 'web-app',
    },
    {
        name: 'ti-hand-drag',
        category: 'web-app',
    },
    {
        name: 'ti-flag',
        category: 'web-app',
    },
    {
        name: 'ti-flag-alt',
        category: 'web-app',
    },
    {
        name: 'ti-flag-alt-2',
        category: 'web-app',
    },
    {
        name: 'ti-eye',
        category: 'web-app',
    },
    {
        name: 'ti-import',
        category: 'web-app',
    },
    {
        name: 'ti-export',
        category: 'web-app',
    },
    {
        name: 'ti-cup',
        category: 'web-app',
    },
    {
        name: 'ti-crown',
        category: 'web-app',
    },
    {
        name: 'ti-comments',
        category: 'web-app',
    },
    {
        name: 'ti-comment',
        category: 'web-app',
    },
    {
        name: 'ti-comment-alt',
        category: 'web-app',
    },
    {
        name: 'ti-thought',
        category: 'web-app',
    },
    {
        name: 'ti-clip',
        category: 'web-app',
    },
    {
        name: 'ti-check',
        category: 'web-app',
    },
    {
        name: 'ti-check-box',
        category: 'web-app',
    },
    {
        name: 'ti-camera',
        category: 'web-app',
    },
    {
        name: 'ti-announcement',
        category: 'web-app',
    },
    {
        name: 'ti-brush',
        category: 'web-app',
    },
    {
        name: 'ti-brush-alt',
        category: 'web-app',
    },
    {
        name: 'ti-palette',
        category: 'web-app',
    },
    {
        name: 'ti-briefcase',
        category: 'web-app',
    },
    {
        name: 'ti-bolt',
        category: 'web-app',
    },
    {
        name: 'ti-bolt-alt',
        category: 'web-app',
    },
    {
        name: 'ti-blackboard',
        category: 'web-app',
    },
    {
        name: 'ti-bag',
        category: 'web-app',
    },
    {
        name: 'ti-world',
        category: 'web-app',
    },
    {
        name: 'ti-wheelchair',
        category: 'web-app',
    },
    {
        name: 'ti-car',
        category: 'web-app',
    },
    {
        name: 'ti-truck',
        category: 'web-app',
    },
    {
        name: 'ti-timer',
        category: 'web-app',
    },
    {
        name: 'ti-ticket',
        category: 'web-app',
    },
    {
        name: 'ti-thumb-up',
        category: 'web-app',
    },
    {
        name: 'ti-thumb-down',
        category: 'web-app',
    },
    {
        name: 'ti-stats-up',
        category: 'web-app',
    },
    {
        name: 'ti-stats-down',
        category: 'web-app',
    },
    {
        name: 'ti-shine',
        category: 'web-app',
    },
    {
        name: 'ti-shift-right',
        category: 'web-app',
    },
    {
        name: 'ti-shift-left',
        category: 'web-app',
    },
    {
        name: 'ti-shift-right-alt',
        category: 'web-app',
    },
    {
        name: 'ti-shift-left-alt',
        category: 'web-app',
    },
    {
        name: 'ti-shield',
        category: 'web-app',
    },
    {
        name: 'ti-notepad',
        category: 'web-app',
    },
    {
        name: 'ti-server',
        category: 'web-app',
    },
    {
        name: 'ti-pulse',
        category: 'web-app',
    },
    {
        name: 'ti-printer',
        category: 'web-app',
    },
    {
        name: 'ti-power-off',
        category: 'web-app',
    },
    {
        name: 'ti-plug',
        category: 'web-app',
    },
    {
        name: 'ti-pie-chart',
        category: 'web-app',
    },
    {
        name: 'ti-panel',
        category: 'web-app',
    },
    {
        name: 'ti-package',
        category: 'web-app',
    },
    {
        name: 'ti-music',
        category: 'web-app',
    },
    {
        name: 'ti-music-alt',
        category: 'web-app',
    },
    {
        name: 'ti-mouse',
        category: 'web-app',
    },
    {
        name: 'ti-mouse-alt',
        category: 'web-app',
    },
    {
        name: 'ti-money',
        category: 'web-app',
    },
    {
        name: 'ti-microphone',
        category: 'web-app',
    },
    {
        name: 'ti-menu',
        category: 'web-app',
    },
    {
        name: 'ti-menu-alt',
        category: 'web-app',
    },
    {
        name: 'ti-map',
        category: 'web-app',
    },
    {
        name: 'ti-map-alt',
        category: 'web-app',
    },
    {
        name: 'ti-location-pin',
        category: 'web-app',
    },
    {
        name: 'ti-light-bulb',
        category: 'web-app',
    },
    {
        name: 'ti-info',
        category: 'web-app',
    },
    {
        name: 'ti-infinite',
        category: 'web-app',
    },
    {
        name: 'ti-id-badge',
        category: 'web-app',
    },
    {
        name: 'ti-hummer',
        category: 'web-app',
    },
    {
        name: 'ti-home',
        category: 'web-app',
    },
    {
        name: 'ti-help',
        category: 'web-app',
    },
    {
        name: 'ti-headphone',
        category: 'web-app',
    },
    {
        name: 'ti-harddrives',
        category: 'web-app',
    },
    {
        name: 'ti-harddrive',
        category: 'web-app',
    },
    {
        name: 'ti-gift',
        category: 'web-app',
    },
    {
        name: 'ti-game',
        category: 'web-app',
    },
    {
        name: 'ti-filter',
        category: 'web-app',
    },
    {
        name: 'ti-files',
        category: 'web-app',
    },
    {
        name: 'ti-file',
        category: 'web-app',
    },
    {
        name: 'ti-zip',
        category: 'web-app',
    },
    {
        name: 'ti-folder',
        category: 'web-app',
    },
    {
        name: 'ti-envelope',
        category: 'web-app',
    },
    {
        name: 'ti-dashboard',
        category: 'web-app',
    },
    {
        name: 'ti-cloud',
        category: 'web-app',
    },
    {
        name: 'ti-cloud-up',
        category: 'web-app',
    },
    {
        name: 'ti-cloud-down',
        category: 'web-app',
    },
    {
        name: 'ti-clipboard',
        category: 'web-app',
    },
    {
        name: 'ti-calendar',
        category: 'web-app',
    },
    {
        name: 'ti-book',
        category: 'web-app',
    },
    {
        name: 'ti-bell',
        category: 'web-app',
    },
    {
        name: 'ti-basketball',
        category: 'web-app',
    },
    {
        name: 'ti-bar-chart',
        category: 'web-app',
    },
    {
        name: 'ti-bar-chart-alt',
        category: 'web-app',
    },
    {
        name: 'ti-archive',
        category: 'web-app',
    },
    {
        name: 'ti-anchor',
        category: 'web-app',
    },
    {
        name: 'ti-alert',
        category: 'web-app',
    },
    {
        name: 'ti-alarm-clock',
        category: 'web-app',
    },
    {
        name: 'ti-agenda',
        category: 'web-app',
    },
    {
        name: 'ti-write',
        category: 'web-app',
    },
    {
        name: 'ti-wallet',
        category: 'web-app',
    },
    {
        name: 'ti-video-clapper',
        category: 'web-app',
    },
    {
        name: 'ti-video-camera',
        category: 'web-app',
    },
    {
        name: 'ti-vector',
        category: 'web-app',
    },
    {
        name: 'ti-support',
        category: 'web-app',
    },
    {
        name: 'ti-stamp',
        category: 'web-app',
    },
    {
        name: 'ti-slice',
        category: 'web-app',
    },
    {
        name: 'ti-shortcode',
        category: 'web-app',
    },
    {
        name: 'ti-receipt',
        category: 'web-app',
    },
    {
        name: 'ti-pin2',
        category: 'web-app',
    },
    {
        name: 'ti-pin-alt',
        category: 'web-app',
    },
    {
        name: 'ti-pencil-alt2',
        category: 'web-app',
    },
    {
        name: 'ti-eraser',
        category: 'web-app',
    },
    {
        name: 'ti-more',
        category: 'web-app',
    },
    {
        name: 'ti-more-alt',
        category: 'web-app',
    },
    {
        name: 'ti-microphone-alt',
        category: 'web-app',
    },
    {
        name: 'ti-magnet',
        category: 'web-app',
    },
    {
        name: 'ti-line-double',
        category: 'web-app',
    },
    {
        name: 'ti-line-dotted',
        category: 'web-app',
    },
    {
        name: 'ti-line-dashed',
        category: 'web-app',
    },
    {
        name: 'ti-ink-pen',
        category: 'web-app',
    },
    {
        name: 'ti-info-alt',
        category: 'web-app',
    },
    {
        name: 'ti-help-alt',
        category: 'web-app',
    },
    {
        name: 'ti-headphone-alt',
        category: 'web-app',
    },
    {
        name: 'ti-gallery',
        category: 'web-app',
    },
    {
        name: 'ti-face-smile',
        category: 'web-app',
    },
    {
        name: 'ti-face-sad',
        category: 'web-app',
    },
    {
        name: 'ti-credit-card',
        category: 'web-app',
    },
    {
        name: 'ti-comments-smiley',
        category: 'web-app',
    },
    {
        name: 'ti-time',
        category: 'web-app',
    },
    {
        name: 'ti-share',
        category: 'web-app',
    },
    {
        name: 'ti-share-alt',
        category: 'web-app',
    },
    {
        name: 'ti-rocket',
        category: 'web-app',
    },
    {
        name: 'ti-new-window',
        category: 'web-app',
    },
    {
        name: 'ti-rss',
        category: 'web-app',
    },
    {
        name: 'ti-rss-alt',
        category: 'web-app',
    },
    {
        name: 'ti-control-stop',
        category: 'control',
    },
    {
        name: 'ti-control-shuffle',
        category: 'control',
    },
    {
        name: 'ti-control-play',
        category: 'control',
    },
    {
        name: 'ti-control-pause',
        category: 'control',
    },
    {
        name: 'ti-control-forward',
        category: 'control',
    },
    {
        name: 'ti-control-backward',
        category: 'control',
    },
    {
        name: 'ti-volume',
        category: 'control',
    },
    {
        name: 'ti-control-skip-forward',
        category: 'control',
    },
    {
        name: 'ti-control-skip-backward',
        category: 'control',
    },
    {
        name: 'ti-control-record',
        category: 'control',
    },
    {
        name: 'ti-control-eject',
        category: 'control',
    },
    {
        name: 'ti-paragraph',
        category: 'text-editor',
    },
    {
        name: 'ti-uppercase',
        category: 'text-editor',
    },
    {
        name: 'ti-underline',
        category: 'text-editor',
    },
    {
        name: 'ti-text',
        category: 'text-editor',
    },
    {
        name: 'ti-Italic',
        category: 'text-editor',
    },
    {
        name: 'ti-smallcap',
        category: 'text-editor',
    },
    {
        name: 'ti-list',
        category: 'text-editor',
    },
    {
        name: 'ti-list-ol',
        category: 'text-editor',
    },
    {
        name: 'ti-align-right',
        category: 'text-editor',
    },
    {
        name: 'ti-align-left',
        category: 'text-editor',
    },
    {
        name: 'ti-align-justify',
        category: 'text-editor',
    },
    {
        name: 'ti-align-center',
        category: 'text-editor',
    },
    {
        name: 'ti-quote-right',
        category: 'text-editor',
    },
    {
        name: 'ti-quote-left',
        category: 'text-editor',
    },
    {
        name: 'ti-layout-width-full',
        category: 'layout',
    },
    {
        name: 'ti-layout-width-default',
        category: 'layout',
    },
    {
        name: 'ti-layout-width-default-alt',
        category: 'layout',
    },
    {
        name: 'ti-layout-tab',
        category: 'layout',
    },
    {
        name: 'ti-layout-tab-window',
        category: 'layout',
    },
    {
        name: 'ti-layout-tab-v',
        category: 'layout',
    },
    {
        name: 'ti-layout-tab-min',
        category: 'layout',
    },
    {
        name: 'ti-layout-slider',
        category: 'layout',
    },
    {
        name: 'ti-layout-slider-alt',
        category: 'layout',
    },
    {
        name: 'ti-layout-sidebar-right',
        category: 'layout',
    },
    {
        name: 'ti-layout-sidebar-none',
        category: 'layout',
    },
    {
        name: 'ti-layout-sidebar-left',
        category: 'layout',
    },
    {
        name: 'ti-layout-placeholder',
        category: 'layout',
    },
    {
        name: 'ti-layout-menu',
        category: 'layout',
    },
    {
        name: 'ti-layout-menu-v',
        category: 'layout',
    },
    {
        name: 'ti-layout-menu-separated',
        category: 'layout',
    },
    {
        name: 'ti-layout-menu-full',
        category: 'layout',
    },
    {
        name: 'ti-layout-media-right',
        category: 'layout',
    },
    {
        name: 'ti-layout-media-right-alt',
        category: 'layout',
    },
    {
        name: 'ti-layout-media-overlay',
        category: 'layout',
    },
    {
        name: 'ti-layout-media-overlay-alt',
        category: 'layout',
    },
    {
        name: 'ti-layout-media-overlay-alt-2',
        category: 'layout',
    },
    {
        name: 'ti-layout-media-left',
        category: 'layout',
    },
    {
        name: 'ti-layout-media-left-alt',
        category: 'layout',
    },
    {
        name: 'ti-layout-media-center',
        category: 'layout',
    },
    {
        name: 'ti-layout-media-center-alt',
        category: 'layout',
    },
    {
        name: 'ti-layout-list-thumb',
        category: 'layout',
    },
    {
        name: 'ti-layout-list-thumb-alt',
        category: 'layout',
    },
    {
        name: 'ti-layout-list-post',
        category: 'layout',
    },
    {
        name: 'ti-layout-list-large-image',
        category: 'layout',
    },
    {
        name: 'ti-layout-line-solid',
        category: 'layout',
    },
    {
        name: 'ti-layout-grid4',
        category: 'layout',
    },
    {
        name: 'ti-layout-grid3',
        category: 'layout',
    },
    {
        name: 'ti-layout-grid2',
        category: 'layout',
    },
    {
        name: 'ti-layout-grid2-thumb',
        category: 'layout',
    },
    {
        name: 'ti-layout-cta-right',
        category: 'layout',
    },
    {
        name: 'ti-layout-cta-left',
        category: 'layout',
    },
    {
        name: 'ti-layout-cta-center',
        category: 'layout',
    },
    {
        name: 'ti-layout-cta-btn-right',
        category: 'layout',
    },
    {
        name: 'ti-layout-cta-btn-left',
        category: 'layout',
    },
    {
        name: 'ti-layout-column4',
        category: 'layout',
    },
    {
        name: 'ti-layout-column3',
        category: 'layout',
    },
    {
        name: 'ti-layout-column2',
        category: 'layout',
    },
    {
        name: 'ti-layout-accordion-separated',
        category: 'layout',
    },
    {
        name: 'ti-layout-accordion-merged',
        category: 'layout',
    },
    {
        name: 'ti-layout-accordion-list',
        category: 'layout',
    },
    {
        name: 'ti-widgetized',
        category: 'layout',
    },
    {
        name: 'ti-widget',
        category: 'layout',
    },
    {
        name: 'ti-widget-alt',
        category: 'layout',
    },
    {
        name: 'ti-view-list',
        category: 'layout',
    },
    {
        name: 'ti-view-list-alt',
        category: 'layout',
    },
    {
        name: 'ti-view-grid',
        category: 'layout',
    },
    {
        name: 'ti-upload',
        category: 'layout',
    },
    {
        name: 'ti-download',
        category: 'layout',
    },
    {
        name: 'ti-loop',
        category: 'layou,t',
    },
    {
        name: 'ti-layout-sidebar-2',
        category: 'layout',
    },
    {
        name: 'ti-layout-grid4-alt',
        category: 'layout',
    },
    {
        name: 'ti-layout-grid3-alt',
        category: 'layout',
    },
    {
        name: 'ti-layout-grid2-alt',
        category: 'layout',
    },
    {
        name: 'ti-layout-column4-alt',
        category: 'layout',
    },
    {
        name: 'ti-layout-column3-alt',
        category: 'layout',
    },
    {
        name: 'ti-layout-column2-alt',
        category: 'layout',
    },
    {
        name: 'ti-flickr',
        category: 'brand',
    },
    {
        name: 'ti-flickr-alt',
        category: 'brand',
    },
    {
        name: 'ti-instagram',
        category: 'brand',
    },
    {
        name: 'ti-google',
        category: 'brand',
    },
    {
        name: 'ti-github',
        category: 'brand',
    },
    {
        name: 'ti-facebook',
        category: 'brand',
    },
    {
        name: 'ti-dropbox',
        category: 'brand',
    },
    {
        name: 'ti-dropbox-alt',
        category: 'brand',
    },
    {
        name: 'ti-dribbble',
        category: 'brand',
    },
    {
        name: 'ti-apple',
        category: 'brand',
    },
    {
        name: 'ti-android',
        category: 'brand',
    },
    {
        name: 'ti-yahoo',
        category: 'brand',
    },
    {
        name: 'ti-trello',
        category: 'brand',
    },
    {
        name: 'ti-stack-overflow',
        category: 'brand',
    },
    {
        name: 'ti-soundcloud',
        category: 'brand',
    },
    {
        name: 'ti-sharethis',
        category: 'brand',
    },
    {
        name: 'ti-sharethis-alt',
        category: 'brand',
    },
    {
        name: 'ti-reddit',
        category: 'brand',
    },
    {
        name: 'ti-microsoft',
        category: 'brand',
    },
    {
        name: 'ti-microsoft-alt',
        category: 'brand',
    },
    {
        name: 'ti-linux',
        category: 'brand',
    },
    {
        name: 'ti-jsfiddle',
        category: 'brand',
    },
    {
        name: 'ti-joomla',
        category: 'brand',
    },
    {
        name: 'ti-html5',
        category: 'brand',
    },
    {
        name: 'ti-css3',
        category: 'brand',
    },
    {
        name: 'ti-drupal',
        category: 'brand',
    },
    {
        name: 'ti-wordpress',
        category: 'brand',
    },
    {
        name: 'ti-tumblr',
        category: 'brand',
    },
    {
        name: 'ti-tumblr-alt',
        category: 'brand',
    },
    {
        name: 'ti-skype',
        category: 'brand',
    },
    {
        name: 'ti-youtube',
        category: 'brand',
    },
    {
        name: 'ti-vimeo',
        category: 'brand',
    },
    {
        name: 'ti-vimeo-alt',
        category: 'brand',
    },
    {
        name: 'ti-twitter',
        category: 'brand',
    },
    {
        name: 'ti-twitter-alt',
        category: 'brand',
    },
    {
        name: 'ti-linkedin',
        category: 'brand',
    },
    {
        name: 'ti-pinterest',
        category: 'brand',
    },
    {
        name: 'ti-pinterest-alt',
        category: 'brand',
    },
    {
        name: 'ti-themify-logo',
        category: 'brand',
    },
    {
        name: 'ti-themify-favicon',
        category: 'brand',
    },
    {
        name: 'ti-themify-favicon-alt',
        category: 'brand',
    },
];
