import { Rating } from "@mui/material";
import "./styles.css";
import { useEffect, useState } from "react";
interface ReviewFoodItemProps {
  foodName: string;
  idFoodReview: number;
  handleChangeReviewFood: (
    id: number,
    starNumber: number,
    contentFeedback: string
  ) => void;
}

const ReviewFoodItem = ({
  foodName,
  idFoodReview,
  handleChangeReviewFood,
}: ReviewFoodItemProps) => {
  const [valueRating, setValueRating] = useState<number | null>(10);
  const [contentFeedBackFood, setContentFeedbackFood] = useState<string>("");
  useEffect(() => {
    handleChangeReviewFood(idFoodReview, valueRating ?? 0, contentFeedBackFood);
  }, [valueRating, contentFeedBackFood]);
  return (
    <>
      <div className="mt-2 w-100">
        <p className="review-food-name mb-1">{foodName}</p>
        <div className="w-100 d-flex justify-content-center">
          <Rating
            size="large"
            sx={{
              fontSize: "2rem",
            }}
            max={10}
            value={valueRating}
            onChange={(event, newValue) => {
              setValueRating(newValue);
            }}
          />
        </div>
        <div className="mt-2">
          <textarea
            className="note-feedback-food focus-visiable-none"
            placeholder="Please tell us your feedback!"
            id="textarea"
            value={contentFeedBackFood}
            onChange={(e) => {
              setContentFeedbackFood(e.target.value);
            }}
          ></textarea>
        </div>
      </div>
    </>
  );
};

export default ReviewFoodItem;
