import * as Request from "../Request";
export interface ResultHappyHour<T> {
  status: number,
  message?: string,
  data?: T,
  listFood: ListFood[],
  listDay: ListDay[],
  repeat_id: string,
}
export interface HappyDay {
  id: string,
  name: string,
  value: string
}
export interface HappyRepeat {
  id: string,
  name: string,
}
export interface ListDay {
  id: string,
}
export interface ListFood {
  id: string,
  name: string,
  discount: number,
  checked: number,
}
export interface InforHappyHour {
  id: string,
  owner_id: string,
  timestart: string,
  timeend: string,
  status: string,
  discount: string,
  repeat_id: string,
  name_repeat: string,
}
export const gComboHappyDay = async () => {
  return await Request.fetchAPI(`/owner/gComboHappyDay.php`, "POST", {})
    .then((res: ResultHappyHour<HappyDay[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],

      };
    });
};
export const gComboHappyRepeat = async () => {
  return await Request.fetchAPI(`/owner/gComboHappyRepeat.php`, "POST", {})
    .then((res: ResultHappyHour<HappyRepeat[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};
//lấy thông tin happy hour setting
export const gInfosettingHappyHour = async (owner_id: number) => {
  return await Request.fetchAPI(`/owner/gInfosettingHappyHour.php`, "POST", { owner_id })
    .then((res: ResultHappyHour<InforHappyHour>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        listFood: [],
        listDay: [],
        data: undefined,
        repeat_id: undefined,
      };
    });
};
//lưu setting happy hour

export const settingHappyHour = async (owner_id: number) => {
  return await Request.fetchAPI(`/owner/settingHappyHour.php`, "POST", { owner_id })
    .then((res: ResultHappyHour<InforHappyHour>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        listFood: [],
        listDay: [],
        data: undefined,
      };
    });
};
export const saveSettingHappyHour = async (owner_id: number, repeatId: number, timeStart: string, timeEnd: string, status: number, discount: number, listDayId: string[], listFoodId: ListFood[]) => {
  return await Request.fetchAPI(`/owner/settingHappyHour.php`, "POST", { owner_id, repeatId, timeStart, timeEnd, status, discount, listDayId: JSON.stringify(listDayId), listFoodId: JSON.stringify(listFoodId) })
    .then((res: ResultHappyHour<InforHappyHour>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        listFood: [],
        listDay: [],
        data: undefined,
      };
    });
};