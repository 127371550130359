
import { Button, Modal } from "react-bootstrap";
import { getLanguageUI } from "../../../../Services/languageUI";
// import "./Styles.css"

interface ModalConfirmProps {
    show: boolean;
    content: string;
    messageError?: string;
    handleClose: () => void;
    submit?: () => void
}

const ModalConfirm = ({
    show,
    handleClose,
    content = 'Are you sure?',
    messageError = '',
    submit
}: ModalConfirmProps) => {
    const languageUI = getLanguageUI().setUpTable
    return (
        <Modal
            show={show}
            onHide={handleClose}
            centered
            className='modal-add-printer'
        >
            <Modal.Body>
                <h1 className="text-center">{content}</h1>
                {messageError != '' && (
                    <div className="text-danger text-center ">{messageError}</div>
                )}
                <div className="mt-2 flex justify-content-between mt-4">
                    <div className="text-white font-bold h-40px w-48 bg-primary w-fit d-flex justify-content-center align-items-center rounded-3" onClick={() => handleClose()}>{languageUI.no}</div>
                    <div className="text-white font-bold h-40px w-48  w-fit d-flex justify-content-center align-items-center rounded-3 bg-delete" onClick={submit}>{languageUI.yes}</div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ModalConfirm;
