import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ModalLoading from "../../components/ModalLoading";
import "./Styles.css";
import { getLanguageUI } from "../../Services/languageUI";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import * as userSlice from "../../store/slices/userSlice";
import * as giftCodeVoucher from "../../api/apiOwner/giftCodeVoucher";
const GiftCardHistory: React.FC = () => {
  const owner_id = useSelector(userSlice.selectorUserId);
  const [ShowLoading, setShowLoading] = useState(false);
  const [listGiftVoucher, setListGiftVoucher] = useState<
    giftCodeVoucher.GiftVoucherPosSale[]
  >([]);
  const languageUI = getLanguageUI().giftCard;
  const navigate = useNavigate();

  const gListGiftVoucher = async () => {
    if (owner_id) {
      setShowLoading(true);
      const req = await giftCodeVoucher.getHistoryGiftSale(owner_id);
      if (Number(req?.status) === 1) {
        setListGiftVoucher(req?.data ?? []);
        setShowLoading(false);
      } else {
        setShowLoading(false);
        setListGiftVoucher([]);
      }
    }
  };
  useEffect(() => {
    gListGiftVoucher();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />

      <div className="px-3 pt-90px  h-100vh pb-5  w-100">
        <div className="bg-white  h-100 w-100 rounded-18px box-shadow-inventory ">
          <div className="d-flex justify-content-between align-items-center py-7 px-6">
            <div className="d-flex gap-4 align-items-center">
              <button
                className="h-40px w-80px rounded-3 bg-primary border-none text-white"
                onClick={() => {
                  navigate("/web-owner/giftCard");
                }}
              >
                <ArrowBackIosIcon />
              </button>
              <p className="mb-0 text-inventory">
                {languageUI.giftCardHistory}
              </p>
            </div>
          </div>
          <div>
            <TableContainer className="border-top">
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className="text-12B064 text-4 fw-600 font-urbansist w-15">
                      {languageUI.urchasedDateAndTime}
                    </TableCell>
                    <TableCell
                      align="left"
                      className="text-12B064 text-4 fw-600 font-urbansist w-20"
                    >
                      {languageUI.seller}
                    </TableCell>
                    <TableCell
                      align="left"
                      className="text-12B064 text-4 fw-600 font-urbansist w-20"
                    >
                      {languageUI.personSell}
                    </TableCell>

                    <TableCell
                      align="left"
                      className="text-12B064 text-4 fw-600 font-urbansist w-15"
                    >
                      {languageUI.sellTo}
                    </TableCell>
                    <TableCell
                      align="left"
                      className="text-12B064 text-4 fw-600 font-urbansist w-20"
                    >
                      {languageUI.contactPhoneEmail}
                    </TableCell>
                    <TableCell
                      align="left"
                      className="text-12B064 text-4 fw-600 font-urbansist w-10"
                    >
                      {languageUI.amount}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="body-table-voucher">
                  {listGiftVoucher.map((item, i) => (
                    <TableRow className="d-flex align-items-center" key={i}>
                      <TableCell className="text-32324D text-14px fw-500  font-urbansist w-15 border-none">
                        {item?.createat}
                      </TableCell>
                      <TableCell className="text-32324D text-14px fw-500  font-urbansist w-20 border-none">
                        {item?.send_from}
                      </TableCell>
                      <TableCell className="text-32324D text-14px fw-500  font-urbansist w-20 border-none">
                        {item?.staff_name}
                      </TableCell>
                      <TableCell
                        align="left"
                        className="w-15 text-32324D text-14px fw-500 font-urbansist  border-none"
                      >
                        {item?.send_to}
                      </TableCell>

                      <TableCell
                        align="left"
                        className="text-32324D text-14px fw-600 font-urbansist w-20 border-none"
                      >
                        {item?.contact}
                      </TableCell>
                      <TableCell
                        align="left"
                        className="text-32324D text-14px fw-600 font-urbansist w-10 border-none"
                      >
                        {item?.discount_value}
                      </TableCell>
                    </TableRow>
                  ))}

                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </>
  );
};

export default GiftCardHistory;
