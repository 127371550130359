export const INTERVAL = 20
export const CANVAS_WIDTH = window.innerWidth
export const CANVAS_HEIGHT = window.innerHeight

// clouds
export const CLOUDS = new Image()
CLOUDS.src = "./images/clouds-game.png"
export const CLOUDS_HEIGHT = 480
export const CLOUDS_WIDTH = 400
export const CLOUDS_X = 0
export const CLOUDS_Y = 0

// bird
export const BIRD = new Image()
BIRD.src = "./images/bird-game.png"
export const BIRD_HEIGHT = 60
export const BIRD_WIDTH = 50

// ground
export const GROUND = new Image()
GROUND.src = "./images/ground-game.png"
export const GROUND_HEIGHT = window.innerHeight
export const GROUND_WIDTH = CANVAS_WIDTH
export const GROUND_Y = CANVAS_HEIGHT - GROUND_HEIGHT
export const HEIGHT_GROUND = 59

// pipes
export const PIPE_WIDTH = 60
export const PIPE_HEIGHT = CANVAS_HEIGHT / 2
export const PIPE_GAP = CANVAS_HEIGHT / 2 - HEIGHT_GROUND - 150

// movements
export const JUMP_SPEED = -300
export const FALL_SPEED = -600
export const SPEED = 3