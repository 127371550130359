import React from "react";
import QRCode from "react-qr-code";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import "./styles.css";
import { backgroundGiftCard } from "../../../../../components/ImgExport";
import html2canvas from "html2canvas";
import * as userSlice from "../../../../../store/slices/userSlice";
import { useSelector } from "react-redux";
import { DetailGiftVoucher } from "../../../../../api/apiOwner/giftCodeVoucher";

interface GiftCardItemProp {
  listCardVoucher: DetailGiftVoucher;
  handleUpdateStatus: (giftId: number) => void;
  index: number;
  is_never_expire: number;
}

const GiftCardItem = ({
  listCardVoucher,
  handleUpdateStatus,
  index,
  is_never_expire,
}: GiftCardItemProp) => {
  // const nameStore = useSelector(userSlice.selectorNameStore);

  function downloadURI(uri: string, name: string) {
    var link = document.createElement("a");

    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    // clearDynamicLink(link);
  }
  function DownloadAsImage(element: any, name: string) {
    html2canvas(element).then(function (canvas) {
      var myImage = canvas.toDataURL();
      downloadURI(myImage, name);
    });
  }
  const downOne = (id: string, name: string) => {
    const el: any = document.querySelector(
      ".gift-card-printer-" + listCardVoucher.id
    );
    DownloadAsImage(el, name + ".png");
  };

  return (
    <div className="h-fit">
      <div
        className={`shadow-button-default rounded-4 p-4 w-200px h-fit ${
          listCardVoucher.is_used === 1 ? "d-none" : ""
        }`}
      >
        <p className="m-0 text-4a4a6a font-bold text-center mb-1 text-lg">
          {listCardVoucher.voucher_name}
        </p>
        <QRCode
          style={{ width: "100%", height: "fit-content" }}
          id="qrcode"
          value={listCardVoucher.voucher_code}
          level={"H"}
        />
        <p className="m-0 text-4a4a6a font-normal text-center mt-1">
          {listCardVoucher.voucher_code}
        </p>
        <div className="mt-1 rounded-5 h-40px d-flex overflow-hidden">
          <div className="w-30 bg-primary h-full d-flex align-items-center justify-content-center text-white font-bold flex-shrink-0">
            {index}
          </div>
          <div
            className={`w-70 h-full text-white font-bold d-flex justify-content-center align-items-center ${
              listCardVoucher.is_download === 0 ? "bg-orange" : "bg-red"
            } `}
            onClick={() => {
              downOne("1", listCardVoucher.voucher_code);
              handleUpdateStatus(Number(listCardVoucher.id));
            }}
          >
            <FileDownloadRoundedIcon className="text-white text-lg" />
            {listCardVoucher.is_download === 0 ? "Download" : "Downloaded"}
          </div>
        </div>
      </div>
      <div className="box-gift-card-printer ">
        <div
          className={`gift-card-printer gift-card-printer-${listCardVoucher.id}  shadow-button-default position-relative`}
        >
          <img src={backgroundGiftCard} alt="" className="w-100 h-100 " />
          <div className="position-absolute d-flex w-100 h-100 top-0 right-0 rounded-4">
            <div className="w-60 h-100 p-4 flex flex-column align-items-center justify-content-center">
              <p className="gift-card-name-store mb-0">
                {listCardVoucher.name}
              </p>
              <p className="gift-card-store-info mb-0">
                {listCardVoucher.owner_phone}
              </p>
              <div className="store-info">
                <p className="gift-card-store-info mb-0">
                  {listCardVoucher.owner_address}
                </p>
              </div>

              {/* <p className="gift-card-title text-center mb-0 text-truncate">
                {voucherName}
              </p> */}
              <p className="gift-card-value text-center mb-0 text-truncate">
                {listCardVoucher.discount_value}{" "}
                {Number(listCardVoucher.type) === 1 ? "%" : "$"}
              </p>
              <p className="gift-card-title text-center mb-0 text-truncate">
                {listCardVoucher.voucher_name}
              </p>
              {/* <div className="w-100">
                <p className="gift-card-content text-center">
                  {discountValue} {Number(type) === 1 ? "%" : "$"}
                </p>
              </div> */}
              {is_never_expire === 0 && (
                <p className="gift-card-expire-day mb-0">
                  {listCardVoucher.startdate} - {listCardVoucher.enddate}
                </p>
              )}
            </div>
            <div className="w-40 h-100 flex-shrink-0  d-flex justify-content-center align-items-center">
              <div className="p-2 bg-white rounded-4 w-80">
                <QRCode
                  style={{ width: "100%", height: "fit-content" }}
                  id="qrcode"
                  value={listCardVoucher.voucher_code}
                  level={"H"}
                />
                <p
                  className="m-0 text-4a4a6a text-center mt-1"
                  style={{ fontSize: "10px" }}
                >
                  {listCardVoucher.voucher_code}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GiftCardItem;
