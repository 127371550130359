import Modal from "react-bootstrap/Modal";
import "./Styles.css";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import React, { useState } from "react";
import { ListImg } from "../../../../api/apiAdmin/CreateCustomerAdmin";
import { targetImg } from "../../../../api/urlImage";
import { errImgFeedback } from "../../../../components/Image";

interface ImageSlideProps {
  fileImg: string;
}

interface ModalSlideImgProps {
  show: boolean;
  dismisModal: () => void;
  listImgShow: ListImg[];
}
const ImgSlide = ({ fileImg }: ImageSlideProps) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const handleImageLoad = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    const { naturalWidth, naturalHeight } = event.currentTarget;
    setDimensions({ width: naturalWidth, height: naturalHeight });
    console.log(naturalWidth, naturalHeight);
  };
  return (
    <img
      src={targetImg + fileImg}
      alt=""
      className={`rounded-4 ${
        dimensions.width > dimensions.height ||
        dimensions.width === dimensions.height
          ? "w-100 h-auto"
          : "w-auto h-100"
      }`}
      onError={(e) => {
        e.currentTarget.src = errImgFeedback;
      }}
      onLoad={handleImageLoad}
    />
  );
};
const ModalSlideImg = ({
  show,
  dismisModal,
  listImgShow,
}: ModalSlideImgProps) => {
  return (
    <Modal
      show={show}
      onHide={dismisModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-slide-img"
    >
      <Modal.Body>
        <div className="w-100 h-100 box-img-slider">
          <Slide
            infinite={false}
            autoplay={false}
            onChange={function noRefCheck() {}}
            onStartChange={function noRefCheck() {}}
          >
            {listImgShow.map((item, index) => (
              <div className="each-slide-effect">
                <div
                  // style={{ backgroundImage: `url(${targetImg + item.filename})` }}
                  className="w-100 h-100 bg-white"
                >
                  <ImgSlide fileImg={item.filename} />
                </div>
              </div>
            ))}
          </Slide>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default ModalSlideImg;
